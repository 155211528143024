/* eslint-disable no-param-reassign */
import { MoneyRemoveMask } from 'utils/masks';

/* return the percent difference between two values * -1  */
export function GetDiscountPercent(newValue: number, oldValue: number): number {
  // (B – A) / A x 100
  return ((newValue - oldValue) / oldValue) * 100 * -1;
}

/* return the percent difference between two values * -1  */
export function GetDiscountPercentInput(newValue: string, oldValue: string) {
  newValue = MoneyRemoveMask(newValue);
  oldValue = MoneyRemoveMask(oldValue);
  return GetDiscountPercent(+newValue, +oldValue);
}
