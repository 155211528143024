import { toast } from 'react-toastify';
import * as Masks from '../../../../../utils/masks';

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const RemovePattern = <T extends Record<string, any>>(obj: T): T => {
  if (obj === null || obj === undefined) {
    return obj;
  }

  try {
    const keys = Object.keys(obj);
    keys.forEach((key) => {
      if (typeof obj[key] === 'string') {
        const stringValue = obj[key] as string;
        if (stringValue.match(Masks.PhoneRegex)) {
          Reflect.set(obj, key, Masks.PhoneRemoveMask(stringValue));
        } else if (stringValue.match(Masks.CnpjRegex)) {
          Reflect.set(obj, key, Masks.CnpjMaskRemove(stringValue));
        } else if (stringValue.match(Masks.ZipCodeRegex)) {
          Reflect.set(obj, key, Masks.ZipCodeMaskRemove(stringValue));
        } else if (stringValue.match(Masks.MoneyRegex)) {
          Reflect.set(obj, key, Masks.MoneyRemoveMask(stringValue));
        } else if (stringValue.match(Masks.PercentageRegex)) {
          Reflect.set(obj, key, Masks.PercentMaskRemove(stringValue));
        }
      } else if (typeof obj[key] === 'object') {
        Reflect.set(obj, key, RemovePattern(obj[key]));
      }
    });

    return obj;
  } catch (err) {
    toast.error('Mask');
    throw err;
  }
};
