import { ProcessProductModel } from 'types/models';

export const FilterSort = (
  type: 'financier' | 'consultant',
  processProducts?: ProcessProductModel[]
): ProcessProductModel[] => {
  if (processProducts) {
    return processProducts
      .filter((processProduct) => processProduct.discount_orders?.[0])
      .sort((first, second) => {
        if (type === 'consultant') {
          if (second?.discount_orders?.[0].status === 'denied') return 1;
          if (first?.discount_orders?.[0].status === 'denied') return -1;
        }
        if (second?.discount_orders?.[0].status === 'requested') return 1;
        if (first?.discount_orders?.[0].status === 'requested') return -1;
        if (second?.discount_orders?.[0].status === 'contested') return 1;
        if (first?.discount_orders?.[0].status === 'contested') return -1;
        return 0;
      });
  }
  return [];
};
