import { Grid, Typography } from '@mui/material';
import React from 'react';
import { ThemeColors } from 'shared/enum';
import { ProcessModel } from 'types/models';
import { GetFocusUfValue } from 'utils/Functions/focusUf';
import { FormatName } from 'utils/Functions/FormatName';
import { GetProcessDirectLabel } from 'utils/Functions/GetProcessDirectLabel';

type ProcessInformationFooterProps = {
  process?: ProcessModel;
};

export function ProcessInformationFooter({
  process
}: ProcessInformationFooterProps) {
  return (
    <Grid
      container
      p={2}
      paddingTop="27px"
      paddingBottom="27px"
      bgcolor={ThemeColors.LightGrey}
      borderRadius={2}
    >
      <Grid container md={12}>
        <Grid item xs={6} sm={3}>
          <Typography
            variant="h4"
            style={{
              color: ThemeColors.TextDarkGrey,
              fontWeight: 'normal'
            }}
          >
            Política Comercial
          </Typography>
          <Typography
            variant="h4"
            style={{
              color: ThemeColors.TextDarkGrey,
              fontWeight: 'bold'
            }}
          >
            {process?.comercialPolitics ?? '-'}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography
            variant="h4"
            style={{
              color: ThemeColors.TextDarkGrey,
              fontWeight: 'normal'
            }}
          >
            Forma de participação
          </Typography>
          <Typography
            variant="h4"
            style={{
              color: ThemeColors.TextDarkGrey,
              fontWeight: 'bold'
            }}
          >
            {GetProcessDirectLabel(process?.processDirect)}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography
            variant="h4"
            style={{
              color: ThemeColors.TextDarkGrey,
              fontWeight: 'normal'
            }}
          >
            Período de fornecimento
          </Typography>
          <Typography
            variant="h4"
            style={{
              color: ThemeColors.TextDarkGrey,
              fontWeight: 'bold'
            }}
          >
            {process?.period ?? '-'}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography
            variant="h4"
            style={{
              color: ThemeColors.TextDarkGrey,
              fontWeight: 'normal'
            }}
          >
            Pode ser prorrogado?
          </Typography>
          <Typography
            variant="h4"
            style={{
              color: ThemeColors.Primary,
              fontWeight: 'bold'
            }}
          >
            {process?.canExtend ? 'Sim' : 'Não'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container md={12} style={{ marginTop: '15px' }}>
        <Grid item xs={6} sm={3}>
          <Typography
            variant="h4"
            style={{
              color: ThemeColors.TextDarkGrey,
              fontWeight: 'normal'
            }}
          >
            Cota
          </Typography>
          <Typography
            variant="h4"
            style={{
              color: ThemeColors.TextDarkGrey,
              fontWeight: 'bold'
            }}
          >
            {process?.competitionModality ?? '-'}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography
            variant="h4"
            style={{
              color: ThemeColors.TextDarkGrey,
              fontWeight: 'normal'
            }}
          >
            Número do Edital
          </Typography>
          <Typography
            variant="h4"
            style={{
              color: ThemeColors.TextDarkGrey,
              fontWeight: 'bold'
            }}
          >
            {process?.tradingCode ?? '-'}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography
            variant="h4"
            style={{
              color: ThemeColors.TextDarkGrey,
              fontWeight: 'normal'
            }}
          >
            Consultor(a)
          </Typography>
          <Typography
            variant="h4"
            style={{
              color: ThemeColors.TextDarkGrey,
              fontWeight: 'bold'
            }}
          >
            {process?.employee?.name ?? '-'}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography
            variant="h4"
            style={{
              color: ThemeColors.TextDarkGrey,
              fontWeight: 'normal'
            }}
          >
            Meses prorrogado
          </Typography>
          <Typography
            variant="h4"
            style={{
              color: ThemeColors.TextDarkGrey,
              fontWeight: 'bold'
            }}
          >
            {process?.extendMonths ?? '-'}
          </Typography>
        </Grid>
      </Grid>

      <Grid container md={12} style={{ marginTop: '15px' }}>
        <Grid item xs={6} sm={3}>
          <Typography
            variant="h4"
            style={{
              color: ThemeColors.TextDarkGrey,
              fontWeight: 'normal'
            }}
          >
            Cota
          </Typography>
          <Typography
            variant="h4"
            style={{
              color: ThemeColors.TextDarkGrey,
              fontWeight: 'bold'
            }}
          >
            {process?.quota ? FormatName(process.quota) : '-'}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography
            variant="h4"
            style={{
              color: ThemeColors.TextDarkGrey,
              fontWeight: 'normal'
            }}
          >
            Regional
          </Typography>
          <Typography
            variant="h4"
            style={{
              color: ThemeColors.TextDarkGrey,
              fontWeight: 'bold'
            }}
          >
            {process?.regional?.toUpperCase() ?? '-'}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography
            variant="h4"
            style={{
              color: ThemeColors.TextDarkGrey,
              fontWeight: 'normal'
            }}
          >
            Município Foco?
          </Typography>
          <Typography
            variant="h4"
            style={{
              color: ThemeColors.TextDarkGrey,
              fontWeight: 'bold'
            }}
          >
            {GetFocusUfValue(process)}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Typography
            variant="h4"
            style={{
              color: ThemeColors.TextDarkGrey,
              fontWeight: 'normal'
            }}
          >
            Classificação de Secretaria
          </Typography>
          <Typography
            variant="h4"
            style={{
              color: ThemeColors.TextDarkGrey,
              fontWeight: 'bold'
            }}
          >
            {process?.secretaryClassification ?? 'Não se Aplica'}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
