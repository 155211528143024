/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Grid } from '@mui/material';
import React from 'react';
import { FetchQuery } from 'services';
import UIUploadAddSection from 'shared/components/Upload/UploadAddSection';
import { useUploadAdd } from 'shared/hooks/UseUploadAdd';
import { AttachmentModel } from 'types/models/Attachments/Attachment.model';
import { DocumentTypes } from 'utils/Constants/DocumentTypes';

export type UploadAddSectionProps = {
  attachmentsRefetch: FetchQuery<AttachmentModel[], AttachmentModel>;
  processId: number;
  accessId?: number;
};

export function UploadAddSection({
  attachmentsRefetch,
  accessId,
  processId
}: UploadAddSectionProps) {
  const {
    buttonDisabled,
    uploadAttachment,
    handleFile,
    attachmentLoadingAdd,
    file,
    setCategory,
    category
  } = useUploadAdd(attachmentsRefetch, {
    accessId,
    table: 'process',
    tableId: processId
  });

  return (
    <>
      <Grid item md={7} xs={12}>
        <UIUploadAddSection
          title="Adicionar documentos"
          buttonDisabled={buttonDisabled}
          buttonOnClick={uploadAttachment}
          handleFile={handleFile}
          loading={attachmentLoadingAdd}
          selectedFilename={file?.name || ' '}
          selectLabel="Documento"
          selectOnChange={({ target }) => setCategory(target.value)}
          selectOptions={DocumentTypes}
          selectValue={category}
        />
      </Grid>
    </>
  );
}
