import { ProcessModel } from 'types/models';

export const getRealDate = (process: ProcessModel) => {
  if (process.tradingDate) {
    return new Date(process.tradingDate).toLocaleDateString('pt-BR', {
      dateStyle: 'long'
    });
  }
  if (process.createdAt)
    return new Date(process.createdAt).toLocaleDateString('pt-BR', {
      dateStyle: 'long'
    });
  return '-';
};
