import { toast } from 'react-toastify';
import { FetchQuery, QueryOptions } from 'services';
import { DistributorModel } from 'types/models';

type ValidateDistributorHasAccessProps = {
  willUpdate?: boolean;
  sold: string;
  distributorId?: number;
  fetchDistributor: FetchQuery<DistributorModel[], DistributorModel>;
};

export async function ValidateDistributorHasAccess({
  sold,
  willUpdate,
  distributorId,
  fetchDistributor
}: ValidateDistributorHasAccessProps) {
  const params: QueryOptions<DistributorModel> = {
    $limit: '-1',
    $select: ['id', 'sold'],
    sold
  };

  if (willUpdate && distributorId) {
    params['id[$ne]'] = distributorId;
  }

  const distributor = await fetchDistributor(params);
  if (distributor?.length !== 0) {
    toast.error('Distribuidor com sold já cadastrado.');
    return true;
  }
  return false;
}
