import * as yup from 'yup';
import { ERRORS } from 'utils/Errors';

export interface ForgotPasswordDomainInputs {
  email: string;
}

export interface ForgotPasswordDomainResponse {
  email: string;
}

export const ForgotPasswordValidationSchema = yup.object({
  email: yup
    .string()
    .email(ERRORS.INVALID_EMAIL)
    .required(ERRORS.REQUIRED_FIELD)
});
