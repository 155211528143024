/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  MenuItem
} from '@mui/material';
import { useCities } from 'hooks/useCities';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQuery } from 'services';
import { ControlledRadioGroup } from 'shared/components/ControlledComponents/ControlledRadioGroup';
import { ControlledTextField } from 'shared/components/ControlledComponents/ControlledTextField';
import { FormProps, UITextField } from 'shared/components/Inputs';
import { EmployeeModel, InstitutionModel, ProductsModel } from 'types/models';
import { OptionsBooleanToYes } from 'utils/Constants/BooleanToYes';
import { InstitutionsSegments, States, STATES_ARRAY } from 'utils/Enums';
import { HospitalType } from 'utils/Enums/HospitalType';
import { InstitutionStandardization } from 'utils/Enums/InstitutionStandardization';
import { Regional } from 'utils/Enums/Regional';
import {
  CreateHospitalInstitutionSchema,
  HospitalInstitutionFormRes
} from './domain';

interface HospitalInstitutionFormProps
  extends FormProps<HospitalInstitutionFormRes> {
  institution?: InstitutionModel;
  loading?: boolean;
}

export function HospitalInstitutionForm({
  institution,
  onComplete,
  loading
}: HospitalInstitutionFormProps) {
  const { handleSubmit, formState, watch, control, reset, setValue } =
    useForm<HospitalInstitutionFormRes>({
      resolver: yupResolver(CreateHospitalInstitutionSchema),
      mode: 'onTouched',
      defaultValues: {
        serviceChannel: 'Hospitalar'
      }
    });

  useEffect(() => {
    if (institution) {
      reset({
        ...institution,
        serviceChannel: 'Hospitalar'
      });
    }
  }, [institution]);

  const citiesData = useCities()
    .citiesData.filter((city) => city.uf === watch('uf'))
    .map((city) => city.city);

  const [products] = useQuery<ProductsModel[], ProductsModel>('/products', {
    $limit: '-1',
    $select: ['sku', 'id', 'description']
  });

  const [consultants] = useQuery<EmployeeModel[], EmployeeModel>('/employee', {
    $limit: '-1',
    $select: ['id', 'name', 'sap'],
    accessLevel: 'consultant'
  });

  const allProductsIds =
    products?.map((product) => (product?.id ? +product.id : 9999)) || [];

  return (
    <Box bgcolor={'white'} p={1}>
      <form onSubmit={handleSubmit(onComplete)}>
        <Grid item container spacing={2}>
          <Grid item xs={12} sm={4}>
            <ControlledTextField
              name={'name'}
              control={control}
              errors={formState.errors?.name?.message}
              label={'Nome'}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ControlledTextField
              name={'nutritionistName'}
              control={control}
              errors={formState.errors?.nutritionistName?.message}
              label={'Nome Nutricionista'}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ControlledTextField
              name="email"
              control={control}
              errors={formState.errors?.email?.message}
              label={'E-mail'}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ControlledTextField
              name={'regional'}
              control={control}
              errors={formState.errors.regional?.message}
              label={'Regional'}
              select
            >
              {Object.keys(Regional).map((key) => (
                <MenuItem value={Regional[key]} key={key}>
                  {Regional[key]}
                </MenuItem>
              ))}
            </ControlledTextField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <ControlledTextField
              name={'cnpj'}
              label={'CNPJ'}
              control={control}
              errors={formState.errors?.cnpj?.message}
              pattern="cnpj"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ControlledTextField
              name={'social'}
              control={control}
              errors={formState.errors.social?.message}
              label={'Razão Social'}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ControlledTextField
              name={'code'}
              control={control}
              errors={formState.errors.code?.message}
              label={'Sold to'}
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ControlledTextField
              name={'segment'}
              control={control}
              errors={formState.errors.segment?.message}
              label={'Segmento'}
              select
            >
              {Object.keys(InstitutionsSegments).map((key) => (
                <MenuItem
                  value={InstitutionsSegments[key]}
                  key={InstitutionsSegments[key]}
                >
                  {InstitutionsSegments[key]}
                </MenuItem>
              ))}
            </ControlledTextField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <ControlledTextField
              name={'uf'}
              control={control}
              errors={formState.errors.uf?.message}
              label={'Estado'}
              select
              onChange={(event) => {
                setValue('uf', event.target.value);
                setValue('city', '');
              }}
            >
              {STATES_ARRAY.map(({ uf }) => (
                <MenuItem value={uf} key={uf}>
                  {States[uf]}
                </MenuItem>
              ))}
            </ControlledTextField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name={'city'}
              control={control}
              render={({ field }) => (
                <Autocomplete
                  renderInput={(props) => (
                    <UITextField
                      label="Cidade"
                      error={!!formState.errors?.city?.message}
                      helperText={formState.errors?.city?.message}
                      {...props}
                    />
                  )}
                  {...field}
                  options={[...citiesData, '']}
                  onChange={(_, value) => {
                    _.preventDefault();
                    if (value && !Array.isArray(value)) {
                      setValue('city', value);
                    } else {
                      setValue('city', '');
                    }
                  }}
                  value={watch('city') || ''}
                  fullWidth
                  loading
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ControlledTextField
              name={'serviceChannel'}
              control={control}
              errors={formState.errors.serviceChannel?.message}
              label={'Canal de Atendimento'}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ControlledTextField
              name={'class'}
              control={control}
              errors={formState.errors.class?.message}
              label={'Classificação'}
              select
            >
              <MenuItem value={'null'}> Não se aplica </MenuItem>
              <MenuItem value={'GOLD'}> Gold </MenuItem>
              <MenuItem value={'SILVER'}> Silver </MenuItem>
            </ControlledTextField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <ControlledTextField
              name={'type'}
              control={control}
              errors={formState.errors.type?.message}
              label={'Tipo Hospital'}
              select
            >
              {HospitalType.map((type) => (
                <MenuItem value={type} key={type}>
                  {type}
                </MenuItem>
              ))}
            </ControlledTextField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <ControlledTextField
              name={'standardization'}
              control={control}
              errors={formState.errors.standardization?.message}
              label={'Padronização FI Partida'}
              select
            >
              {InstitutionStandardization.map((standardization) => (
                <MenuItem value={standardization} key={standardization}>
                  {standardization}
                </MenuItem>
              ))}
            </ControlledTextField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <ControlledTextField
              name={'specialStandardization'}
              control={control}
              errors={formState.errors.specialStandardization?.message}
              label={'Padronização FI Especiais'}
              select
            >
              {InstitutionStandardization.map((standardization) => (
                <MenuItem value={standardization} key={standardization}>
                  {standardization}
                </MenuItem>
              ))}
            </ControlledTextField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <ControlledRadioGroup
              control={control}
              label={'Órgão Público'}
              name={'organization'}
              options={OptionsBooleanToYes}
              value={Number(watch('organization'))}
              onChange={(event) => {
                setValue('organization', Boolean(+event.target.value));
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ControlledTextField
              name={'childBirthQuantity'}
              control={control}
              errors={formState.errors.childBirthQuantity?.message}
              label={'Nº de Partos/mês'}
              disabled={watch('type') !== 'Hospital Maternidade'}
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ControlledTextField
              name={'employeeId'}
              control={control}
              errors={formState.errors.employeeId?.message}
              label={'Consultor Responsável'}
              select
              onChange={(event) => {
                setValue('employeeId', +event.target.value);
              }}
            >
              {consultants?.map((consultant) => (
                <MenuItem value={consultant?.id} key={consultant?.id}>
                  {consultant?.sap} - {consultant?.name}
                </MenuItem>
              ))}
            </ControlledTextField>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name={'productIds'}
              control={control}
              render={({ field }) => (
                <Autocomplete
                  multiple
                  id="tags-filled"
                  renderTags={(value: number[], getTagProps) =>
                    value.map((option: number, index: number) => {
                      const findProduct = products?.find(
                        (product) => product?.id === option
                      );
                      return (
                        <Chip
                          variant="filled"
                          color="primary"
                          size="small"
                          label={`${findProduct?.sku || ''} - ${
                            findProduct?.description || ''
                          }`}
                          {...getTagProps({ index })}
                        />
                      );
                    })
                  }
                  renderInput={(props) => (
                    <UITextField label="Produtos Padronizados" {...props} />
                  )}
                  {...field}
                  options={allProductsIds}
                  getOptionLabel={(option) => {
                    const findProduct = products?.find(
                      (product) => product?.id === option
                    );
                    return `${findProduct?.sku || ''} - ${
                      findProduct?.description || ''
                    }`;
                  }}
                  onChange={(_, value) => {
                    _.preventDefault();
                    setValue('productIds', value);
                  }}
                  fullWidth
                  loading
                  value={watch('productIds') || []}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid mt={4} item container justifyContent="flex-end" spacing={2}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Button type="submit" variant="contained" color="primary">
              Confirmar Alterações
            </Button>
          )}
        </Grid>
      </form>
    </Box>
  );
}
