/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  MenuItem
} from '@mui/material';
import { useCities } from 'hooks/useCities';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQuery } from 'services';
import { ControlledRadioGroup } from 'shared/components/ControlledComponents/ControlledRadioGroup';
import { ControlledTextField } from 'shared/components/ControlledComponents/ControlledTextField';
import { FormProps, UITextField } from 'shared/components/Inputs';
import { EmployeeModel, InstitutionModel } from 'types/models';
import { OptionsBooleanToYes } from 'utils/Constants/BooleanToYes';
import { ProgramName } from 'utils/Constants/ProgramNames';
import { States, STATES_ARRAY } from 'utils/Enums';
import { Regional } from 'utils/Enums/Regional';
import {
  CreateGovernmentInstitutionSchema,
  GovernmentInstitutionFormRes
} from './domain';

interface GovernmentInstitutionFormProps
  extends FormProps<GovernmentInstitutionFormRes> {
  institution?: InstitutionModel;
  loading?: boolean;
}

export function GovernmentInstitutionForm({
  institution,
  onComplete,
  loading
}: GovernmentInstitutionFormProps) {
  const { handleSubmit, formState, watch, control, reset, setValue } =
    useForm<GovernmentInstitutionFormRes>({
      resolver: yupResolver(CreateGovernmentInstitutionSchema),
      mode: 'onTouched',
      defaultValues: {
        serviceChannel: 'Programa governamental',
        segment: 'Público'
      }
    });

  useEffect(() => {
    if (institution) {
      reset({
        ...institution,
        serviceChannel: 'Programa governamental',
        segment: 'Público'
      });
    }
  }, [institution]);

  const citiesData = useCities()
    .citiesData.filter((city) => city.uf === watch('uf'))
    .map((city) => city.city);

  const [consultants] = useQuery<EmployeeModel[], EmployeeModel>('/employee', {
    $limit: '-1',
    $select: ['id', 'name', 'sap'],
    accessLevel: 'consultant'
  });

  return (
    <Box bgcolor={'white'} p={1}>
      <form onSubmit={handleSubmit(onComplete)}>
        <Grid item container spacing={2}>
          <Grid item xs={12} sm={4}>
            <ControlledTextField
              name={'name'}
              control={control}
              errors={formState.errors?.name?.message}
              label={'Nome'}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ControlledTextField
              name={'regional'}
              control={control}
              errors={formState.errors.regional?.message}
              label={'Regional'}
              select
            >
              {Object.keys(Regional).map((key) => (
                <MenuItem value={Regional[key]} key={key}>
                  {Regional[key]}
                </MenuItem>
              ))}
            </ControlledTextField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <ControlledTextField
              name={'code'}
              control={control}
              errors={formState.errors.code?.message}
              label={'Sold'}
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ControlledTextField
              name={'segment'}
              control={control}
              errors={formState.errors.segment?.message}
              label={'Segmento'}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ControlledTextField
              name={'uf'}
              control={control}
              errors={formState.errors.uf?.message}
              label={'Estado'}
              select
              onChange={(event) => {
                setValue('uf', event.target.value);
                setValue('city', '');
              }}
            >
              {STATES_ARRAY.map(({ uf }) => (
                <MenuItem value={uf} key={uf}>
                  {States[uf]}
                </MenuItem>
              ))}
            </ControlledTextField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name={'city'}
              control={control}
              render={({ field }) => (
                <Autocomplete
                  renderInput={(props) => (
                    <UITextField
                      label="Cidade"
                      error={!!formState.errors?.city?.message}
                      helperText={formState.errors?.city?.message}
                      {...props}
                    />
                  )}
                  {...field}
                  options={[...citiesData, '']}
                  onChange={(_, value) => {
                    _.preventDefault();
                    if (value && !Array.isArray(value)) {
                      setValue('city', value);
                    } else {
                      setValue('city', '');
                    }
                  }}
                  value={watch('city') || ''}
                  fullWidth
                  loading
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ControlledTextField
              name={'employeeId'}
              control={control}
              errors={formState.errors.employeeId?.message}
              label={'Consultor Responsável'}
              select
              onChange={(event) => {
                setValue('employeeId', +event.target.value);
              }}
            >
              {consultants?.map((consultant) => (
                <MenuItem value={consultant?.id} key={consultant?.id}>
                  {consultant?.sap} - {consultant?.name}
                </MenuItem>
              ))}
            </ControlledTextField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <ControlledTextField
              name={'serviceChannel'}
              control={control}
              errors={formState.errors.serviceChannel?.message}
              label={'Canal de Atendimento'}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <ControlledRadioGroup
              control={control}
              label={'Município Foco'}
              name={'focus'}
              options={OptionsBooleanToYes}
              value={Number(watch('focus'))}
              onChange={(event) => {
                setValue('focus', Boolean(+event.target.value));
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <ControlledRadioGroup
              control={control}
              label={'Órgão Público'}
              name={'organization'}
              options={OptionsBooleanToYes}
              value={Number(watch('organization'))}
              onChange={(event) => {
                setValue('organization', Boolean(+event.target.value));
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={'programs'}
            control={control}
            render={({ field }) => (
              <Autocomplete
                multiple
                id="tags-filled"
                renderTags={(value: string[], getTagProps) =>
                  value.map((option: string, index: number) => {
                    return (
                      <Chip
                        variant="filled"
                        color="primary"
                        size="small"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    );
                  })
                }
                renderInput={(props) => (
                  <UITextField label="Programas existentes" {...props} />
                )}
                {...field}
                options={ProgramName}
                onChange={(_, value) => {
                  _.preventDefault();
                  setValue('programs', value);
                }}
                fullWidth
                loading
                value={watch('programs') || []}
              />
            )}
          />
        </Grid>
        <Grid mt={4} item container justifyContent="flex-end" spacing={2}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Button type="submit" variant="contained" color="primary">
              Confirmar Alterações
            </Button>
          )}
        </Grid>
      </form>
    </Box>
  );
}
