import { AxiosRequestConfig } from 'axios';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { ERRORS } from 'utils/Errors';
import api from '../config';

export type FetchFormData<DataType, ResponseType> = (
  data: DataType,
  headers?: AxiosRequestConfig['headers']
) => Promise<ResponseType | undefined>;

export type UseFormDataReturn<DataType, ResponseType> = [
  FetchFormData<DataType, ResponseType>,
  boolean,
  unknown
];

/**
 * @param query
 * @return
 * fetch, loading, errors
 */
export function useFormData<DataType = void, ResponseType = void>(
  query: string
): UseFormDataReturn<DataType, ResponseType> {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>(null);

  async function fetch(
    data: DataType,
    headers?: AxiosRequestConfig['headers']
  ): Promise<ResponseType | undefined> {
    try {
      setLoading(true);
      const formData = new FormData();
      Object.keys(data).forEach((name) => {
        formData.append(name, data[name]);
      });
      const response = await api.post<ResponseType>(query, formData, {
        headers
      });
      if (response.status.toString()[0] === '2') {
        setLoading(false);
        return response.data;
      }
      setLoading(false);
    } catch (err) {
      toast.error(ERRORS.SOMETHING_WENT_WRONG);
      setLoading(false);
      setError(err);
    }
    return undefined;
  }

  return [fetch, loading, error];
}
