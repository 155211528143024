import { UITabs } from 'shared/components/Tabs';
import { UITab } from 'shared/components/Tabs/Tab';
import React, { useState } from 'react';
import { Box } from '@mui/material';

interface ProductListTabsProps {
  onChange: (step: string) => void;
}

export function ProductListTab({ onChange }: ProductListTabsProps) {
  const [step, setStep] = useState('all');

  const handleChange = (event: React.SyntheticEvent, value: string) => {
    setStep(value);
    onChange(value);
  };

  return (
    <>
      <UITabs
        value={step}
        variant="scrollable"
        onChange={handleChange}
        scrollButtons
        allowScrollButtonsMobile
        aria-label="scrollable force tabs example"
      >
        <UITab value={'all'} label={'Todos'} />
        <UITab value={'Fórmulas infantis'} label={'Formúlas infantis'} />
        <UITab value={'Compostos lácteos'} label={'Compostos Lacteos'} />
        <UITab value={'Mucilon'} label={'Mucilon'} />
        <UITab value={'Papinhas'} label={'Papinhas'} />
        <Box flex="1" />
        <UITab value="inactive" label={'Inativo'} />
        <UITab value="rival-products" label={'Concorrentes'} />
      </UITabs>
    </>
  );
}
