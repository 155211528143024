/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import CheckIcon from '@mui/icons-material/Check';
import { Box, CircularProgress, Container, Fab } from '@mui/material';
import MakeOrderTitle from 'modules/Process/Order/Title';
import { OrderUploadSection } from 'modules/Process/Order/UploadSection';
import NewPageBreadcrumb from 'modules/Process/shared/Fragment/NewPageBreadcrumb';
import { ProcessOrderCreateTable } from 'modules/Process/shared/Tables/ProcessOrder/ProcessOrderCreate';
import { useAuth } from 'Providers/Auth';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { usePost, useQuery } from 'services';
import { useFormData } from 'services/api/UseFormData';
import { useFab } from 'shared/components/Fab';
import { UploadAttachment } from 'types/input/UploadAttachment';
import { ProcessProductModel } from 'types/models';
import { AttachmentModel } from 'types/models/Attachments/Attachment.model';
import { OrderItems, OrderModel } from 'types/models/Order/Order.model';
import { ProcessOrderModel } from 'types/models/ProcessOrder/ProcessOrder.model';
import { UrlParams } from 'types/services';

export function ProcessOrder() {
  const { auth } = useAuth();
  const { id } = useParams() as UrlParams;
  const history = useHistory();
  const [processProducts] = useQuery<ProcessProductModel[]>(
    '/process-products',
    {
      processId: id,
      winner: 1,
      $limit: -1
    }
  );

  const [attachmentAdd] = useFormData<UploadAttachment, AttachmentModel>(
    `/attachments`
  );

  const [, orderPost, orderLoading] = usePost<OrderModel, OrderModel>('/order');
  const [files, setFiles] = useState<SelectedFiles[]>([]);
  const [attachmentLoading, setAttachmentLoading] = useState<boolean>(false);

  const handleComplete = async (productsRows: ProcessOrderModel[]) => {
    const orderItems: OrderItems[] = productsRows.map((product): OrderItems => {
      return {
        processProductId: product.id,
        quantity: product.order_quantity,
        observation: product.order_observation
      };
    });

    if (files.length === 0) {
      toast.error('Adicione um documento para prosseguir com o pedido.');
      return;
    }

    if (id) {
      const order: OrderModel = {
        processId: Number(id),
        status: 'Processando',
        order_items: orderItems
      };
      const response = await orderPost(order);
      if (response) {
        setAttachmentLoading(true);
        for (const uploadedFile of files) {
          try {
            await attachmentAdd({
              file: uploadedFile.file,
              category: uploadedFile.category,
              table: 'order',
              tableId: response.id,
              accessId: auth?.id
            });
            toast.success(
              `Documento ${uploadedFile.category} - ${uploadedFile.file?.name}, subido com sucesso. `
            );
          } catch (error) {
            toast.error(
              `Erro ao subir documento ${uploadedFile.category} - ${uploadedFile.file?.name}. Tente anexar novamente nos detalhes do pedido.`
            );
          }
        }
        setAttachmentLoading(false);
        toast.success('Pedido efetuado com sucesso');
        history.push(`/processo/${id}`);
      } else {
        toast.error('Erro ao realizar pedido.');
      }
    }
  };

  const fabClasses = useFab();

  return (
    <Container maxWidth={'lg'}>
      <NewPageBreadcrumb id={id} title="Solicitar Pedido" />
      <MakeOrderTitle />
      <>
        {processProducts ? (
          <>
            <Box>
              <ProcessOrderCreateTable
                rows={processProducts.filter(
                  (pProduct) => (pProduct?.quantity || 0) > 0
                )}
                onComplete={handleComplete}
              />
            </Box>
            <Fab
              className={fabClasses.fab}
              color={'primary'}
              variant={'extended'}
              aria-label="add"
              type={'submit'}
              form={'order-items-table'}
              disabled={orderLoading || attachmentLoading}
            >
              {orderLoading || attachmentLoading ? (
                <>
                  <CircularProgress color="inherit" size={20} />
                  Aguarde enquanto subimos as informações
                </>
              ) : (
                <>
                  <CheckIcon />
                  Concluir
                </>
              )}
            </Fab>
          </>
        ) : null}
      </>
      <OrderUploadSection files={files} setFiles={setFiles} />
    </Container>
  );
}

interface SelectedFiles {
  file: File;
  category: string;
  id: number;
}
