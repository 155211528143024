import { CircularProgress, Grid } from '@mui/material';
import { useAuth } from 'Providers/Auth';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { FetchPatch, FetchPost, FetchQuery } from 'services';
import { FetchDelete } from 'services/api/UseDelete';
import { DefaultCard } from 'shared/components/Cards/DefaultCard/styles';
import { Comments } from 'types/models';
import { ERRORS } from 'utils/Errors';
import { CreateOrUpdateObservationModal } from './CreateOrUpdate';
import { DeleteObservationModal } from './Delete';
import { OpenModalProps } from './Domain';
import ObservationHeader from './Header';

export type ObservationsProps = {
  comments: Comments[];
  loading: boolean;
  postComment: FetchPost<Partial<Comments>, Comments>;
  patchComment: FetchPatch<Comments>;
  deleteComment: FetchDelete<Comments>;
  commentsRefetch: FetchQuery<Comments[], Comments>;
  processId: number;
};

export function Observations({
  comments,
  loading,
  postComment,
  commentsRefetch,
  patchComment,
  processId,
  deleteComment
}: ObservationsProps) {
  const [activeComment, setActiveComment] = useState<number>(0);
  const [openModal, setOpenModal] = useState<OpenModalProps>({
    open: false
  });

  const user = useAuth();

  const finishSubmit = () => {
    commentsRefetch();
    setOpenModal({
      open: false,
      type: undefined
    });
  };

  const functions = {
    create: async (comment: string) => {
      try {
        await postComment({
          comment,
          [`${user.auth?.type}Id`]: user.auth?.realId,
          processId
        });
        toast.success('Comentário criado com sucesso.');
        finishSubmit();
      } catch (error) {
        toast.error(ERRORS.SOMETHING_WENT_WRONG);
      }
    },
    update: async (comment: string) => {
      try {
        if (comments[activeComment].id) {
          await patchComment(
            {
              comment
            },
            comments[activeComment].id
          );
          toast.success('Comentário editado com sucesso.');
          finishSubmit();
        }
      } catch (error) {
        toast.error(ERRORS.SOMETHING_WENT_WRONG);
      }
    },
    delete: async () => {
      try {
        const commentId = comments[activeComment].id;
        if (commentId) {
          await deleteComment(commentId);
          toast.success('Comentário deletado com sucesso.');
          setActiveComment(0);
          finishSubmit();
        }
      } catch (error) {
        toast.error(ERRORS.SOMETHING_WENT_WRONG);
      }
    }
  };

  return (
    <>
      <DefaultCard
        style={{
          minHeight: '120px',
          paddingLeft: '26px',
          paddingBottom: '15px'
        }}
      >
        <Grid container>
          <ObservationHeader
            comments={comments}
            setActiveComment={setActiveComment}
            activeComment={activeComment}
            setOpenModal={setOpenModal}
          />
          <Grid item>{comments[activeComment]?.comment}</Grid>
        </Grid>
        {loading && (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
          </div>
        )}
      </DefaultCard>
      <CreateOrUpdateObservationModal
        open={
          ['create', 'update'].includes(openModal.type ?? '') && openModal.open
        }
        type={openModal.type}
        setOpenModal={setOpenModal}
        onSubmit={openModal.type ? functions[openModal.type] : () => {}}
        activeComment={
          openModal.type === 'update'
            ? comments[activeComment]?.comment
            : undefined
        }
      />
      <DeleteObservationModal
        open={openModal?.type === 'delete' && openModal.open}
        type={openModal.type}
        setOpenModal={setOpenModal}
        onSubmit={functions.delete}
      />
    </>
  );
}
