import React from 'react';
import { Typography } from '@mui/material';
import { UIBreadcrumb } from 'shared/components/Breadcrumb';
import { UILink } from 'shared/components/Link';

export function CreateProcessBreadcrumb() {
  return (
    <UIBreadcrumb>
      <UILink to={'/'} color={'inherit'}>
        Dashboard
      </UILink>
      <Typography color={'primary'}>Cadastro do Processo Público</Typography>
    </UIBreadcrumb>
  );
}
