import { Grid, Typography } from '@mui/material';
import React from 'react';
import { theme } from 'shared/theme';

export function InformResultTitle() {
  return (
    <Grid container flexDirection="column" gap={2} mb={4}>
      <Grid
        container
        item
        xs={12}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography
          variant="h2"
          style={{
            fontSize: theme.font.sizes.large
          }}
        >
          Informar Resultado
        </Typography>
      </Grid>
      <Typography
        variant="h3"
        style={{
          fontSize: theme.font.sizes.medium,
          color: theme.colors.TextGrey
        }}
      >
        Reveja os itens e informe o resultado.
      </Typography>
      <Typography
        variant="h3"
        style={{
          fontSize: theme.font.sizes.medium,
          color: theme.colors.TextGrey
        }}
      >
        Clique em validar nas opções, ou dê dois cliques para informar o
        resultado
      </Typography>
    </Grid>
  );
}
