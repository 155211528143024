import React from 'react';
import {
  CssBaseline,
  StyledEngineProvider,
  Theme,
  ThemeProvider
} from '@mui/material';
import { GlobalStyles, MaterialGlobal, theme } from 'shared/theme';
import { Routes } from 'routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { DatePickerProvider } from 'config/Providers/DatePickerProvider';
import { AuthProvider } from 'Providers/Auth';
import { ThemeProvider as SThemeProvider } from 'styled-components';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

function App() {
  return (
    <DatePickerProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={MaterialGlobal}>
          <SThemeProvider theme={theme}>
            <GlobalStyles />
            <CssBaseline />
            <AuthProvider>
              <Routes />
            </AuthProvider>
            <ToastContainer />
          </SThemeProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </DatePickerProvider>
  );
}

export default App;
