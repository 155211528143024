import { ERRORS } from 'utils/Errors';
import * as yup from 'yup';

export interface SyncEmployeeCity {
  uf: string;
  city: string;
}

export const SyncEmployeeCitySchema = yup.object().shape({
  uf: yup.string().required(ERRORS.REQUIRED_FIELD),
  city: yup.string().required(ERRORS.REQUIRED_FIELD)
});
