/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { PendenciesTable } from 'modules/Dashboard/Tables/PendenciesTable';
import { useQuery } from 'services';
import { Paginated } from 'types/services';
import { PendenciesModel, ProcessModel } from 'types/models';
import { pendenciesFields } from 'pages/Dashboard/Constants';

interface DashboardPendencyProps {
  employeeId?: number;
  distributorId?: number;
}

export function DashboardPendency({
  employeeId,
  distributorId
}: DashboardPendencyProps) {
  const pendenciesUseQuery = useQuery<Paginated<PendenciesModel>, ProcessModel>(
    '/pendencies',
    {
      $limit: 50,
      $select: pendenciesFields,
      $skip: 0,
      employeeId,
      distributorId,
      '$sort[createdAt]': -1,
      fetchOnInitialize: !!(employeeId || distributorId)
    }
  );

  useEffect(() => {
    if ((employeeId || distributorId) && !pendenciesUseQuery[0]?.data) {
      pendenciesUseQuery[1]();
    }
  }, [employeeId, distributorId]);

  const pendencies = pendenciesUseQuery[0];
  const pendenciesLoading = pendenciesUseQuery[2];

  return (
    <PendenciesTable
      loading={pendenciesLoading}
      pendencies={pendencies?.data || []}
      autoHeight={false}
      totalPendencies={pendencies?.total || 0}
    />
  );
}
