import { GridColDef } from '@mui/x-data-grid';
import { FormatPercent } from 'utils/Functions/FormatPercent';
import { MoneyMask } from 'utils/masks';

export const ProcessItemsColumns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'id',
    hide: true
  },
  {
    field: 'product.itemNumber',
    headerName: 'Item',
    width: 80,
    valueGetter: ({ row }) => row?.itemNumber || '-'
  },
  {
    field: 'specification',
    headerName: 'Descritivo',
    width: 120,
    valueGetter: ({ row }) => row?.specification || '-'
  },
  {
    field: 'quantity',
    headerName: 'Quantidade',
    width: 120,
    valueGetter: ({ row }) => row?.initialQuantity || '-'
  },
  {
    field: 'unity',
    headerName: 'Unidade',
    width: 100,
    valueGetter: () => 'UNI'
  },
  {
    field: 'approve',
    headerName: 'Atende',
    width: 100,
    valueGetter: ({ row }) => row?.approve || false,
    valueFormatter: ({ value }) => {
      return value ? 'Sim' : 'Não';
    }
  },
  {
    field: 'product.sku',
    headerName: 'SKU',
    width: 100,
    valueGetter: ({ row }) => row?.product?.sku || '-'
  },
  {
    field: 'product.description',
    headerName: 'Produto',
    width: 250,
    valueGetter: ({ row }) => row?.product?.description || '-'
  },
  {
    field: 'markup',
    headerName: 'Markup',
    width: 100,
    valueGetter: ({ row }) => row?.markup || '-',
    valueFormatter: ({ value }) => FormatPercent(String(value)) || '-'
  },
  {
    field: 'discountValue',
    headerName: 'Desconto',
    width: 100,
    valueGetter: ({ row }) => row?.discountValue || '-',
    valueFormatter: ({ value }) => FormatPercent(String(value)) || '-'
  },
  {
    field: 'nfPrice',
    headerName: '(R$) Preço NF liberado',
    width: 180,
    valueGetter: ({ row }) => row?.nfPrice || '-',
    valueFormatter: ({ value }) => MoneyMask(Number(value))
  },
  {
    field: 'priceList',
    headerName: '(R$) Preço lista',
    width: 150,
    valueGetter: ({ row }) => row?.priceList || '-',
    valueFormatter: ({ value }) => MoneyMask(Number(value || ''))
  },
  {
    field: 'institutionValue',
    headerName: '(R$) Preço Instituição',
    width: 180,
    valueGetter: ({ row }) => row?.institutionValue || '-',
    valueFormatter: ({ value }) => MoneyMask(Number(value || ''))
  },
  {
    field: 'discount',
    headerName: 'Necessita financeiro',
    width: 200,
    valueGetter: ({ row }) => (row?.discount_orders?.[0]?.id ? 'Sim' : 'Não')
  }
];
