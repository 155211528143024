import React from 'react';
import { AddProcessButton } from 'modules/Process/shared/Buttons/AddProcess';
import { HeaderOptionsProps } from 'types/Config/Headers';

export const ConsultantHeader: HeaderOptionsProps['options'] = [
  {
    key: 'dashboard',
    label: 'Dashboard',
    linkHref: '/dashboard',
    grid: { xs: 2 }
  },
  {
    key: 'processHistory',
    label: 'Histórico',
    linkHref: '/historico',
    grid: { xs: 2 }
  },
  {
    key: 'addProcess',
    component: <AddProcessButton />,
    grid: { xs: 4 }
  }
];
