import { Container } from '@mui/material';
import ImportsTable from 'modules/Admin/Imports/Fragments/ImportsTable';
import ImportsTitle from 'modules/Admin/Imports/Fragments/ImportsTitle';
import NewImportFragment from 'modules/Admin/Imports/Fragments/NewImport';
import React from 'react';
import { useQuery } from 'services';
import { ImportsModel } from 'types/models/Imports/Imports.model';

export function Imports() {
  const [imports, refetch, importLoading] = useQuery<
    ImportsModel[],
    ImportsModel
  >('/imports', {
    $limit: '-1',
    $select: ['id', 'createdAt', 'updatedAt', 'type', 'finishedDate', 'status'],
    '$sort[id]': '-1'
  });

  return (
    <Container maxWidth={'lg'}>
      <ImportsTitle />
      <NewImportFragment
        canImport={
          !imports?.some((newImport) => newImport.status === 'started')
        }
        refetchImports={refetch}
      />
      <ImportsTable
        imports={imports}
        loading={importLoading}
        refetchImports={refetch}
      />
    </Container>
  );
}
