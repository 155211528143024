import React from 'react';
import UIPaper from 'shared/components/Paper';
import { SelectedFiles } from 'types/interfaces/SelectedFiles';
import { ProcessModel, ProcessProductModel, ProductsModel } from 'types/models';
import { AdminEditProcessInformationForm } from '../../Forms/EditProcessInformation';
import { AdminEditProcessForm } from '../../Forms/EditProcessInformation/domain';
import { AdminEditItems } from '../AdminEditItems';
import { AdminUploadSection } from '../AdminUploadSection';

interface UrlParamsProps {
  process?: ProcessModel;
  processProducts?: ProcessProductModel[];
  products: ProductsModel[];
  step: number;
  onEditProcessInfoSubmit: (data: AdminEditProcessForm) => void;
  onEditProcessItemsSubmit: (
    oldProcessProducts: ProcessProductModel[],
    newProcessProducts: Partial<ProcessProductModel>[]
  ) => void;
  files: SelectedFiles[];
  setFiles: (files: SelectedFiles[]) => void;
  onFinished: () => void;
  buttonText?: string;
  onFinishedLoading: boolean;
}

export function AdminEditProcessForms({
  process,
  step,
  onEditProcessInfoSubmit,
  onEditProcessItemsSubmit,
  processProducts,
  files,
  setFiles,
  onFinished,
  buttonText,
  onFinishedLoading,
  products
}: UrlParamsProps) {
  return (
    <>
      {step === 0 && (
        <UIPaper title="Informações do processo">
          <AdminEditProcessInformationForm
            process={process}
            onSubmit={onEditProcessInfoSubmit}
          />
        </UIPaper>
      )}
      {step === 1 && (
        <AdminEditItems
          process={process || {}}
          processId={process?.id || 1}
          processProducts={processProducts}
          onSubmit={onEditProcessItemsSubmit}
          products={products}
        />
      )}
      {step === 2 && (
        <AdminUploadSection
          files={files}
          setFiles={setFiles}
          onFinished={onFinished}
          onFinishedLoading={onFinishedLoading}
          buttonText={buttonText}
        />
      )}
    </>
  );
}
