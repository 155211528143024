import { Button, Grid, Typography } from '@mui/material';
import React from 'react';
import { theme } from 'shared/theme';
import { useAuth } from 'Providers/Auth';
import { OrderModel } from 'types/models/Order/Order.model';

type OrderDetailsTitleProps = {
  order?: OrderModel;
  handleDelivery: () => void;
};

export default function OrderDetailsTitle({
  order,
  handleDelivery
}: OrderDetailsTitleProps) {
  const { auth } = useAuth();

  return (
    <Grid container flexDirection="column" gap={1} mb={2}>
      <Grid
        container
        item
        xs={12}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography
          variant="h2"
          style={{
            fontSize: theme.font.sizes.large
          }}
        >
          Detalhes do Pedido
        </Typography>
        {!order?.deliveryDate &&
        auth?.employee?.accessLevel === 'consultant' ? (
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={() => handleDelivery()}
          >
            Confirmar Entrega
          </Button>
        ) : null}
      </Grid>
      <Typography
        variant="h3"
        style={{
          fontSize: theme.font.sizes.medium,
          color: theme.colors.TextGrey
        }}
      >
        Reveja os itens e as notas fiscais do pedido.
      </Typography>
    </Grid>
  );
}
