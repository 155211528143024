import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { HeaderDesktop } from 'shared/components/Header/HeaderDesktop';
import { HeaderMobile } from 'shared/components/Header/HeaderMobile';
import { HeaderOptions } from 'types/Config/Headers';
import { useAuth } from 'Providers/Auth';
import { ConsultantHeader } from 'config/AccessLevel/Consultant/Header';
import { AdminHeader } from 'config/AccessLevel/Admin/Header';

export function Header() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const { auth } = useAuth();

  let options: HeaderOptions[] = [];

  if (auth?.distributor) {
    options = ConsultantHeader;
  } else if (auth?.employee?.accessLevel === 'consultant') {
    options = ConsultantHeader;
  } else if (auth?.employee?.accessLevel === 'admin') {
    options = AdminHeader;
  }

  return (
    <>
      {matches ? (
        <HeaderDesktop options={options} />
      ) : (
        <HeaderMobile options={options} />
      )}
    </>
  );
}
