/* eslint-disable @typescript-eslint/no-use-before-define */
import { Grid, MenuItem, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { UITextField } from 'shared/components/Inputs';
import { OcrProducts } from 'types/models';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

type Props = {
  ocrProducts: OcrProducts[];
};

export function ProductsOcrFields({ ocrProducts }: Props) {
  const [selectedItem, setSelectedItem] = useState<number | string | undefined>(
    ocrProducts?.[0]?.item
  );
  const item = ocrProducts.find(
    (ocrProduct) => ocrProduct.item === selectedItem
  );

  return (
    <Grid
      container
      item
      xs={12}
      spacing={1}
      height="400px"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={12}>
        <UITextField
          label={'Item'}
          value={selectedItem || ''}
          select
          fullWidth
          onChange={({ target }) => setSelectedItem(target.value)}
        >
          {ocrProducts.map((ocrProduct) => (
            <MenuItem value={ocrProduct?.item} key={ocrProduct?.item}>
              {ocrProduct?.item}
            </MenuItem>
          ))}
        </UITextField>
      </Grid>
      <OcrTextField label="Quantidade" value={item?.quantity} />
      <OcrTextField label="Descrição" value={item?.description} />
      <OcrTextField label="Preço unitário" value={item?.unityPrice} />
      <OcrTextField label="Preço total" value={item?.totalPrice} />
    </Grid>
  );
}

type OcrTextFieldProps = {
  label: string;
  value?: string | number;
};

function OcrTextField({ label, value }: OcrTextFieldProps) {
  return (
    <Grid container item xs={12} alignItems="center">
      <Grid item xs={11}>
        <UITextField
          label={label}
          value={value || '-'}
          disabled
          fullWidth
          variant="outlined"
        />
      </Grid>
      <Grid item xs={1}>
        <IconButton
          aria-label={'close'}
          size="small"
          onClick={() => {
            if (value) navigator.clipboard.writeText(`${value}`);
          }}
        >
          <ContentCopyIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}
