import React from 'react';
import { UITabs } from 'shared/components/Tabs';
import { UITab } from 'shared/components/Tabs/Tab';
import { ThemeColors } from 'shared/enum';

type DistCreateProcessTabsProps = {
  tab: number;
  handleChangeTab: (tab: number) => void;
};

export function PendenciesTabs({
  handleChangeTab,
  tab
}: DistCreateProcessTabsProps) {
  const actualColor: (keyof typeof ThemeColors)[] = ['Primary', 'Pink'];

  return (
    <UITabs
      value={tab}
      onChange={(_, pos) => handleChangeTab(pos)}
      colorIndicator={actualColor[tab] || actualColor[0]}
    >
      <UITab label={'Ações a serem executada'} />
    </UITabs>
  );
}
