import { Box, Container } from '@mui/material';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR/index';
import OrderDetailsBody from 'modules/Process/OrderDetails/Fragments/OrderDetailsBody';
import OrderDetailsTable from 'modules/Process/OrderDetails/Fragments/OrderDetailsTable';
import OrderDetailsTitle from 'modules/Process/OrderDetails/Fragments/OrderDetailsTitle';
import { OrderDetailsUpload } from 'modules/Process/OrderDetails/Fragments/OrderDetailsUpload';
import NewPageBreadcrumb from 'modules/Process/shared/Fragment/NewPageBreadcrumb';
import React from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { usePatch, useQuery } from 'services';
import { AttachmentModel } from 'types/models/Attachments/Attachment.model';
import { OrderModel } from 'types/models/Order/Order.model';
import { UrlParams } from 'types/services';

export function OrderDetails() {
  const { id } = useParams() as UrlParams;

  const [order, orderRefetch] = useQuery<OrderModel, OrderModel>(
    `/order/${id}`
  );

  const [, patchOrder] = usePatch<OrderModel>(`/order`);

  const handleDelivery = async () => {
    try {
      const deliveryDate = new Date();
      patchOrder(
        {
          deliveryDate: deliveryDate.toISOString()
        },
        +id
      );
      const formatDate = format(
        deliveryDate,
        "EEEE',' dd 'de' MMMM 'de' yyyy', às' HH:mm",
        {
          locale: ptBR
        }
      );
      toast.success(`Entrega confirmada, ${formatDate}`);
      orderRefetch();
    } catch (error) {
      toast.error('Erro ao confirmar entrega.');
    }
  };

  const attachmentsQuery = useQuery<AttachmentModel[], AttachmentModel>(
    `/attachments`,
    {
      table: 'order',
      $limit: '-1',
      tableId: id,
      fetchOnInitialize: false
    }
  );

  return (
    <>
      <Container maxWidth={'lg'}>
        <NewPageBreadcrumb
          id={order?.processId || 0}
          title="Detalhes do Pedido"
        />
        <OrderDetailsTitle order={order} handleDelivery={handleDelivery} />
        <OrderDetailsBody order={order} />
        <OrderDetailsTable
          loading={false}
          orderItems={order?.order_items || []}
        />
        {/* TO-DO: Melhoria, criar script no back end para transformar todas as notas fiscais na tabela correta. */}
        <OrderDetailsUpload attachmentsQuery={attachmentsQuery} orderId={id} />
        <Box mt={4} />
      </Container>
    </>
  );
}
