import { GridColDef } from '@mui/x-data-grid';
import { States } from 'utils/Enums';
import { ParseDateString } from 'utils/Functions';

export const EmployeeCityColumns: GridColDef[] = [
  { field: 'id', headerName: 'ID', hide: true },
  {
    field: 'city',
    headerName: 'Cidade',
    width: 200
  },
  {
    field: 'uf',
    headerName: 'Estado',
    width: 200,
    valueFormatter: ({ value }) => States[String(value)]
  },
  {
    field: 'regional',
    headerName: 'Regional',
    width: 80
  },
  {
    field: 'createdAt',
    width: 150,
    minWidth: 150,
    headerName: 'Vinculado em',
    flex: 1,
    valueFormatter: ({ value }) => ParseDateString(String(value))
  }
];
