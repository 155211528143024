import { useAuth } from 'Providers/Auth';
import React from 'react';
import { UseQueryReturn } from 'services';
import UploadAttachmentsSection from 'shared/components/Upload/UploadSection';
import { AttachmentModel } from 'types/models/Attachments/Attachment.model';

interface PrivateProcessAttachmentsProps {
  orderId: string;
  attachmentsQuery: UseQueryReturn<AttachmentModel[], AttachmentModel>;
}

export function OrderDetailsUpload({
  orderId,
  attachmentsQuery
}: PrivateProcessAttachmentsProps) {
  const { auth } = useAuth();

  return (
    <>
      <UploadAttachmentsSection
        attachmentsQuery={attachmentsQuery}
        selectOptions={['Nota Fiscal']}
        accessId={auth?.id}
        tableId={+orderId}
        table="order"
      />
    </>
  );
}
