import React from 'react';
import { useQuery } from 'services';
import SmallCard from 'shared/components/Cards/Smallcard';
import { Fade, Grid, Grow, Typography } from '@mui/material';
import { MoneyMask } from 'utils/masks';
import { ThemeColors } from 'shared/enum';

interface DashboardModel {
  BillingBallance: number;
  InProgressProcess: number;
  PartialLost: number;
  PercentBalance: number;
  PercentProgress: number;
  Process: number;
  Total: number;
}
export function DashboardMetricsCards() {
  const [dashboardMetric, , loading] = useQuery<DashboardModel>('/dashboard');

  return (
    <Grid container marginTop={1} spacing={2}>
      <Fade in={!loading}>
        <Grid item xs={12}>
          <Typography variant={'h3'} fontWeight={'bold'}>
            Etapas dos processos
          </Typography>
        </Grid>
      </Fade>
      <Grow in={!loading} timeout={500}>
        <Grid item xs={12} md={4}>
          <SmallCard
            borderColor={ThemeColors.Primary}
            number={dashboardMetric?.Process}
            title="Quantidade de processos identificados"
          />
        </Grid>
      </Grow>
      <Grow in={!loading} timeout={500 * 2}>
        <Grid item xs={12} md={4}>
          <SmallCard
            borderColor={ThemeColors.Pink}
            number={dashboardMetric?.InProgressProcess}
            title="Processos em andamento"
          />
        </Grid>
      </Grow>
      <Grow in={!loading} timeout={500 * 3}>
        <Grid item xs={12} md={4}>
          <SmallCard
            borderColor={ThemeColors.Orange}
            number={MoneyMask(dashboardMetric?.Total || '')}
            title="Valor em andamentos dos processos (R$)"
          />
        </Grid>
      </Grow>
      <Grow in={!loading} timeout={500 * 4}>
        <Grid item xs={12} md={4}>
          <SmallCard
            borderColor={ThemeColors.Primary}
            number={dashboardMetric?.InProgressProcess}
            title="Ganhos"
          />
        </Grid>
      </Grow>
      <Grow in={!loading} timeout={500 * 5}>
        <Grid item xs={12} md={4}>
          <SmallCard
            borderColor={ThemeColors.Pink}
            number={dashboardMetric?.PartialLost}
            title="Perdidos"
          />
        </Grid>
      </Grow>
      <Grow in={!loading} timeout={500 * 6}>
        <Grid item xs={12} md={4}>
          <SmallCard
            borderColor={ThemeColors.Orange}
            number={MoneyMask(dashboardMetric?.BillingBallance || '')}
            title="Saldo a faturar R$ (processos vigentes)"
          />
        </Grid>
      </Grow>
    </Grid>
  );
}
