import { Box, Typography } from '@mui/material';
import React from 'react';
import { UITextField } from 'shared/components/Inputs';
import { theme } from 'shared/theme';

type Props = {
  setJustify: (observation: string) => void;
  justify?: string;
  disabled?: boolean;
  observationTitle: string;
};

export default function InformDiscountObservation({
  setJustify,
  disabled = false,
  justify,
  observationTitle
}: Props) {
  return (
    <Box
      width="100%"
      bgcolor="white"
      display="flex"
      flexDirection="column"
      borderRadius="5px"
      mb={2}
      p={2}
    >
      <Typography
        variant="h3"
        style={{
          fontSize: theme.font.sizes.medium,
          color: theme.colors.TextDarkGrey,
          fontWeight: theme.font.semiBold
        }}
      >
        {observationTitle}
      </Typography>
      <Box mt={2} />
      <UITextField
        label={disabled ? null : 'Observação (opcional)'}
        multiline
        minRows={3}
        inputProps={{
          maxLength: 256
        }}
        variant={'outlined'}
        fullWidth
        onChange={({ target }) => setJustify(target.value)}
        disabled={disabled}
        value={justify}
      />
    </Box>
  );
}
