import { SegmentsOptions } from 'utils/Enums';
import { States } from 'utils/Enums/states';
import { ERRORS } from 'utils/Errors';
import { ParseDateStringYup } from 'utils/Functions/ParseDateStringYup';
import * as yup from 'yup';

export interface ProcessBasicInformationInput {
  uf: keyof typeof States | States | null;
  city: string | null;
  serviceChannel: string;
  tradingDate: Date | null;
  tradingCode: string;
  segment: SegmentsOptions;
  requiredDocuments: string[];
  observation: string;
  employeeId: string | number;
  institutionId?: number;
  secretaryClassification?: string | null;
  quota?: string | null;
}

export interface ProcessBasicInformationDomainResponse {
  uf: States;
  city: string;
  serviceChannel: string;
  tradingDate: Date;
  tradingCode: string;
  segment: SegmentsOptions;
  requiredDocuments: string[];
  observation: string;
}

export const ProcessBasicInformationSchema = yup.object({
  uf: yup
    .string()
    .required(ERRORS.REQUIRED_FIELD)
    .typeError(ERRORS.REQUIRED_FIELD),
  city: yup.string().nullable().required(ERRORS.REQUIRED_FIELD),
  serviceChannel: yup.string().required(ERRORS.REQUIRED_FIELD),
  tradingDate: yup
    .date()
    .transform(ParseDateStringYup)
    .required(ERRORS.REQUIRED_FIELD)
    .typeError(ERRORS.REQUIRED_FIELD),
  tradingCode: yup.string().required(ERRORS.REQUIRED_FIELD),
  segment: yup.string().required(ERRORS.REQUIRED_FIELD),
  requiredDocuments: yup.array(yup.string()),
  observation: yup.string(),
  institutionId: yup
    .number()
    .required(ERRORS.REQUIRED_FIELD)
    .typeError(ERRORS.REQUIRED_FIELD),
  employeeId: yup
    .number()
    .required(ERRORS.REQUIRED_FIELD)
    .typeError(ERRORS.REQUIRED_FIELD),
  secretaryClassification: yup
    .string()
    .nullable()
    .optional()
    .when('serviceChannel', {
      is: (serviceChannel: string) =>
        !!(
          !['Hospitalar', 'Concessionária de alimentos'].includes(
            serviceChannel || ''
          ) && serviceChannel
        ),
      then: yup
        .string()
        .required(ERRORS.REQUIRED_FIELD)
        .typeError(ERRORS.REQUIRED_FIELD)
    }),
  quota: yup
    .string()
    .required(ERRORS.REQUIRED_FIELD)
    .typeError(ERRORS.REQUIRED_FIELD)
});
