import { LinearProgress, Grid } from '@mui/material';
import { ConsultantNoticeAnalysisProps } from 'modules/Process/PublicProcess/Forms/ConsultantProcessForm/ConsultantNoticeAnalysis/Domain';
import { NoticeAnalysisForm } from 'modules/Process/PublicProcess/Forms/NoticeAnalysis';
import React from 'react';
import { toast } from 'react-toastify';
import { usePatch, useQuery } from 'services';
import UIPaper from 'shared/components/Paper';
import { PdfRender } from 'shared/components/Pdf/HtmlRender';
import { ProcessModel } from 'types/models';
import { AttachmentModel } from 'types/models/Attachments/Attachment.model';
import { Paginated } from 'types/services';

export function ConsultantProcessAnalysis({
  id,
  onComplete
}: ConsultantNoticeAnalysisProps) {
  const [process, , loading] = useQuery<Partial<ProcessModel>>(
    `/process/${id}`
  );

  const [, processPatch] = usePatch<Partial<ProcessModel>>(`/process`);

  const [attachmentData] = useQuery<Paginated<Partial<AttachmentModel>>>(
    `/attachments?category=Edital&tableId=${id}&table=process`
  );
  const pdfUrl = attachmentData?.data[0]?.key || '';

  const handleComplete = async (data: Partial<ProcessModel>) => {
    if (id) {
      const response = await processPatch(data, +id);
      if (response) {
        toast.success('Analise do edital atualizada com sucesso!');
        onComplete();
      }
    }
  };

  return loading ? (
    <LinearProgress />
  ) : (
    <>
      {pdfUrl && pdfUrl !== '' ? (
        <Grid item xs={12} md={7} height="100%">
          <PdfRender
            url={pdfUrl}
            xs={12}
            height="100%"
            style={{ height: '100%' }}
          />
        </Grid>
      ) : null}
      <Grid item xs={12} md={pdfUrl && pdfUrl !== '' ? 5 : 10}>
        <UIPaper title="Validação oficial do processo" mt={0}>
          <NoticeAnalysisForm onComplete={handleComplete} process={process} />
        </UIPaper>
      </Grid>
    </>
  );
}
