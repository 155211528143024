import { Typography } from '@mui/material';
import React from 'react';
import { theme } from 'shared/theme';

type TitleLabelProps = {
  label: string;
  title: string;
};
export function TitleLabel({ title, label }: TitleLabelProps) {
  return (
    <>
      <Typography
        variant="h4"
        style={{
          color: theme.colors.TextDarkGrey,
          fontWeight: 'normal'
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="h4"
        style={{
          color: theme.colors.Primary
        }}
      >
        {label}
      </Typography>
    </>
  );
}
