import { GridColDef } from '@mui/x-data-grid';
import { States } from 'utils/Enums';
import { MoneyMask } from 'utils/masks';

export const DistributorPricesColumns: GridColDef[] = [
  { field: 'id', headerName: 'ID', hide: true },
  {
    field: 'sku',
    headerName: 'SKU',
    width: 200
  },
  {
    field: 'description',
    headerName: 'Descrição',
    width: 250,
    valueFormatter: ({ value }) => States[String(value)]
  },
  {
    field: 'price',
    headerName: '(R$) Preço Lista',
    width: 150,
    minWidth: 150,
    flex: 1,
    valueFormatter: ({ value }) => (value ? MoneyMask(String(value)) : '-')
  }
];
