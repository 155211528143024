import { Autocomplete, Grid, MenuItem, TextField } from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';
import { UITextField } from 'shared/components/Inputs';
import { States, STATES_ARRAY } from 'utils/Enums';
import {
  CreateDistributorSchema,
  DistributorFormProps
} from 'modules/Admin/Users/Distributor/Forms/DistributorForm/domain';
import { useCities } from 'hooks/useCities';
import { RegionalsConstant } from 'utils/Constants/Regionals';

export function DistributorForm({ access, onComplete }: DistributorFormProps) {
  const { handleChange, handleSubmit, errors, touched, values, setFieldValue } =
    useFormik({
      initialValues: {
        distributor: access?.distributor || {
          name: '',
          telephone: '',
          contact: '',
          regional: '',
          socialName: '',
          cnpj: '',
          sold: '',
          uf: '',
          city: ''
        },
        email: access?.email || ''
      },
      validationSchema: CreateDistributorSchema,
      onSubmit: (fields) => {
        onComplete({ ...access, ...fields });
      }
    });

  const { citiesData } = useCities();

  return (
    <form onSubmit={handleSubmit} id={'distributor-form'}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField
            name={'distributor.name'}
            label={'Nome'}
            value={values.distributor.name}
            onChange={handleChange}
            error={!!errors.distributor?.name && touched.distributor?.name}
            helperText={touched.distributor?.name && errors.distributor?.name}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            name={'email'}
            label={'Email'}
            value={values.email}
            onChange={handleChange}
            error={!!errors?.email && touched?.email}
            helperText={touched?.email && errors?.email}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <UITextField
            name={'distributor.telephone'}
            label={'Telefone'}
            value={values.distributor.telephone}
            onChange={handleChange}
            error={
              !!errors.distributor?.telephone && touched.distributor?.telephone
            }
            helperText={
              touched.distributor?.telephone && errors.distributor?.telephone
            }
            pattern="phone"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            name={'distributor.contact'}
            label={'Contato'}
            value={values.distributor.contact}
            onChange={handleChange}
            error={
              !!errors.distributor?.contact && touched.distributor?.contact
            }
            helperText={
              touched.distributor?.contact && errors.distributor?.contact
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            name={'distributor.regional'}
            label={'Regional'}
            value={values.distributor.regional?.toUpperCase()}
            onChange={handleChange}
            error={
              !!errors.distributor?.regional && touched.distributor?.regional
            }
            helperText={
              touched.distributor?.regional && errors.distributor?.regional
            }
            fullWidth
            select
          >
            {RegionalsConstant.map((regional) => (
              <MenuItem value={regional} key={regional}>
                {regional}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            name={'distributor.socialName'}
            label={'Razão Social'}
            value={values.distributor.socialName}
            onChange={handleChange}
            error={
              !!errors.distributor?.socialName &&
              touched.distributor?.socialName
            }
            helperText={
              touched.distributor?.socialName && errors.distributor?.socialName
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <UITextField
            name={'distributor.cnpj'}
            label={'CNPJ'}
            value={values.distributor.cnpj}
            onChange={handleChange}
            error={!!errors.distributor?.cnpj && touched.distributor?.cnpj}
            helperText={touched.distributor?.cnpj && errors.distributor?.cnpj}
            pattern="cnpj"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            name={'distributor.sold'}
            label={'Sold'}
            value={values.distributor.sold}
            onChange={handleChange}
            error={!!errors.distributor?.sold && touched.distributor?.sold}
            helperText={touched.distributor?.sold && errors.distributor?.sold}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            name={'distributor.uf'}
            label={'UF'}
            onChange={handleChange}
            error={!!errors.distributor?.uf && touched.distributor?.uf}
            helperText={touched.distributor?.uf && errors.distributor?.uf}
            select
            fullWidth
          >
            {STATES_ARRAY.map(({ uf }) => (
              <MenuItem value={uf} key={uf}>
                {States[uf]}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Autocomplete
            renderInput={(props) => (
              <UITextField
                name={'distributor.city'}
                label="Municipio"
                value={values.distributor?.city}
                error={!!errors.distributor?.city && touched.distributor?.city}
                helperText={
                  touched.distributor?.city && errors.distributor?.city
                }
                {...props}
              />
            )}
            options={citiesData.filter(
              (city) => city.uf === values.distributor.uf
            )}
            getOptionLabel={(option) => option.city}
            onChange={(_, value) => {
              _.preventDefault();
              if (value && !Array.isArray(value)) {
                setFieldValue('distributor.city', value.city);
              } else {
                setFieldValue('distributor.city', '');
              }
            }}
            fullWidth
            loading
          />
        </Grid>
      </Grid>
    </form>
  );
}
