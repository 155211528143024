import React from 'react';
import { useQuery } from 'services';
import { Paginated } from 'types/services';
import { ProcessModel } from 'types/models';
import { Grid, Grow } from '@mui/material';
import ActionCard from 'shared/components/Cards/ActionCard';
import { LoadingActions } from 'modules/Dashboard/Cards/Actions/LoadingActions';
import { HeaderActions } from '../../HeaderActions';

export function InformDiscountAction() {
  const [getActionProcess, , loading] = useQuery<Paginated<ProcessModel>>(
    '/process',
    {
      status: 'awaiting financier',
      $limit: 4
    }
  );

  const actionsProcess = getActionProcess?.data || ([] as ProcessModel[]);

  return (
    <Grid item container direction="column" spacing={2}>
      {(getActionProcess?.total || 0) !== 0 && (
        <HeaderActions quantity={getActionProcess?.total || 0} />
      )}

      <Grid item container spacing={2} direction={{ xs: 'column', md: 'row' }}>
        {loading ? (
          <>
            {Array.from(new Array(4)).map(() => (
              <Grid item>
                <LoadingActions />
              </Grid>
            ))}
          </>
        ) : (
          <>
            {actionsProcess.map((actionProcess, key) => (
              <Grow in={!loading} timeout={key * 1000}>
                <Grid item md={3}>
                  <ActionCard
                    title="Financeiro"
                    redirect={`processo/${actionProcess.id}`}
                    action="Avalie os descontos solicitados."
                    chips={[]}
                    city={actionProcess?.city}
                    tradingCode={actionProcess.tradingCode}
                    createdAt={
                      actionProcess.createdAt
                        ? new Date(actionProcess.createdAt).toDateString()
                        : undefined
                    }
                  />
                </Grid>
              </Grow>
            ))}
          </>
        )}
      </Grid>
    </Grid>
  );
}
