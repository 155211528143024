import Pagination, { PaginationProps } from '@mui/material/Pagination';
import React from 'react';
import * as S from './styled';

interface UIPaginationProps extends PaginationProps {
  itemSize?: 'medium' | 'small';
}

export function UIPagination({
  itemSize = 'medium',
  ...rest
}: UIPaginationProps) {
  return (
    <Pagination
      renderItem={(item) => (
        <S.StyledPaginationItem
          itemSize={itemSize}
          style={{ borderRadius: 5 }}
          {...item}
          shape="circular"
        />
      )}
      {...rest}
    />
  );
}
