import { MoneyRemoveMask } from 'utils/masks';
import { PercentMaskRemove } from 'utils/masks/Percent';

export function CalcMarkup(
  nfPrice: number,
  markupPercent: number
): CalcMarkupReturnProps {
  const valueAdded = nfPrice * (markupPercent / 100);
  const markup = valueAdded + nfPrice;
  return { valueAdded, markup };
}

export function CalcMarkupInput(
  nfPrice: string | number,
  markupPercent: string | number
): CalcMarkupReturnProps {
  let newNfPrice = nfPrice;

  if (typeof nfPrice === 'string') {
    newNfPrice = MoneyRemoveMask(nfPrice);
  }

  let newMarkupPercent = markupPercent;

  if (typeof markupPercent === 'string') {
    newMarkupPercent = PercentMaskRemove(markupPercent);
  }

  return CalcMarkup(+newNfPrice, +newMarkupPercent);
}

interface CalcMarkupReturnProps {
  valueAdded: number;
  markup: number;
}
