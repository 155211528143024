import { Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { informDiscountColumns } from 'pages/Process/InformDiscount/Constants/columns';
import React, { useState } from 'react';
import { FetchPatch, FetchQuery } from 'services';
import { UITable } from 'shared/components';
import { UIDataGridApiPagination } from 'shared/components/Tables/DataGridApiPagination';
import { UIToolbar } from 'shared/components/Tables/ToolbarTable';
import {
  DiscountOrderModel,
  ProcessModel,
  ProcessProductModel,
  ProductsModel
} from 'types/models';
import ConsultantInformDiscountModal from '../../Modals/InformDiscountModal/consultant';
import FinancierInformDiscountModal from '../../Modals/InformDiscountModal/financier';
import { FilterSort } from './Functions/FilterSort';

type Props = {
  type: 'financier' | 'consultant';
  processProducts?: ProcessProductModel[];
  process: ProcessModel;
  patchDiscountOrder: FetchPatch<DiscountOrderModel>;
  patchProcessProduct: FetchPatch<ProcessProductModel>;
  processProductsRefetch: FetchQuery<
    ProcessProductModel[],
    ProcessProductModel
  >;
  loading: boolean;
  productsToPolitical?: ProductsModel[];
};
export default function InformDiscountItemsTable({
  type,
  processProducts,
  process,
  patchDiscountOrder,
  processProductsRefetch,
  patchProcessProduct,
  loading,
  productsToPolitical
}: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<ProcessProductModel>();

  const content = {
    financier: {
      shouldClickStatus: ['requested', 'contested'],
      label: 'Informar Desconto'
    },
    consultant: {
      shouldClickStatus: ['denied'],
      label: 'Contestar Desconto'
    }
  };

  const shouldClick = (status: string) =>
    content[type].shouldClickStatus.includes(status);

  const handleClick = (processProduct: ProcessProductModel) => {
    if (shouldClick(processProduct?.discount_orders?.[0].status || '')) {
      setOpen(true);
      setSelectedProduct(processProduct);
    }
  };

  return (
    <>
      <UITable
        rows={FilterSort(type, processProducts)}
        columns={informDiscountColumns}
        rowHeight={36}
        headerHeight={36}
        pageSize={5}
        onRowDoubleClick={({ row }) => handleClick(row as ProcessProductModel)}
        actions={[
          {
            label: content[type].label,
            onClick: (data) => handleClick(data as ProcessProductModel)
          }
        ]}
        showActions={(row: GridRenderCellParams['row']) =>
          shouldClick(row.discount_orders?.[0].status || '')
        }
        components={{
          Toolbar: () => (
            <UIToolbar
              title={<Typography variant="h2">Itens do processo</Typography>}
              elementsTopEnd={<UIDataGridApiPagination />}
            />
          )
        }}
      />
      {type === 'financier' && (
        <FinancierInformDiscountModal
          open={open}
          onClose={() => setOpen(false)}
          process={process}
          selectedProduct={selectedProduct || ({} as ProcessProductModel)}
          patchDiscountOrder={patchDiscountOrder}
          patchProcessProduct={patchProcessProduct}
          loading={loading}
          processProductsRefetch={processProductsRefetch}
          product={
            productsToPolitical?.find(
              (product) => product?.id === selectedProduct?.productId
            ) || {}
          }
        />
      )}
      {type === 'consultant' && (
        <ConsultantInformDiscountModal
          open={open}
          onClose={() => setOpen(false)}
          process={process}
          selectedProduct={selectedProduct || ({} as ProcessProductModel)}
          patchDiscountOrder={patchDiscountOrder}
          patchProcessProduct={patchProcessProduct}
          loading={loading}
          processProductsRefetch={processProductsRefetch}
          product={
            productsToPolitical?.find(
              (product) => product?.id === selectedProduct?.productId
            ) || {}
          }
        />
      )}
    </>
  );
}
