import { Container, Typography } from '@mui/material';
import { RegionalsList } from 'modules/Admin/Regionals/Fragments/RegionalsList';
import React from 'react';
import { UIBreadcrumb, UILink } from 'shared/components';

export function Regionals() {
  return (
    <Container maxWidth={'lg'}>
      <UIBreadcrumb>
        <UILink to={'/dashboard'}> Dashboard </UILink>
        <Typography color={'primary'}> Regionais </Typography>
      </UIBreadcrumb>
      <RegionalsList />
    </Container>
  );
}
