import { Grid, Grow } from '@mui/material';
import { LoadingActions } from 'modules/Dashboard/Cards/Actions/LoadingActions';
import React, { useState } from 'react';
import { useQuery } from 'services';
import { useDelete } from 'services/api/UseDelete';
import ActionCard from 'shared/components/Cards/ActionCard';
import { UIPagination } from 'shared/components/Pagination';
import { ProcessModel } from 'types/models';
import { Paginated } from 'types/services';
import lang from 'utils/lang';

export function ConsultantActionPendencies() {
  const actionPerPage = 12;
  const [page, setPage] = useState<number>(0);

  const [getActionProcess, refetchActions, loading] = useQuery<
    Paginated<ProcessModel>
  >('/process', {
    status: 'const recording',
    $limit: actionPerPage,
    '$sort[tradingDate]': 1
  });

  const [, deleteProcess] = useDelete<ProcessModel>(`/process`);

  const actionsProcess = getActionProcess?.data || ([] as ProcessModel[]);

  const redirect = (segment?: string, id?: number) => {
    if (segment === 'public' && id)
      return `cadastro/processo/consultor?processo=${id}`;
    if (segment === 'private' && id)
      return `cadastro/processo/privado?processo=${id}`;
    return 'dashboard';
  };

  return (
    <Grid item container direction="column">
      <Grid item container spacing={2} direction={{ xs: 'column', md: 'row' }}>
        {loading ? (
          <>
            {Array.from(new Array(12)).map(() => (
              <Grid item>
                <LoadingActions />
              </Grid>
            ))}
          </>
        ) : (
          <>
            {actionsProcess.map((actionProcess) => (
              <Grow in={!loading}>
                <Grid item md={3}>
                  <ActionCard
                    title="Consultor"
                    redirect={redirect(
                      actionProcess?.segment,
                      actionProcess?.id
                    )}
                    action="Adicionar processo ao sistema"
                    chips={[
                      {
                        color:
                          actionProcess?.segment === 'public'
                            ? 'blue'
                            : 'green',
                        title: lang.segments[actionProcess?.segment || '']
                      }
                    ]}
                    city={actionProcess?.city}
                    onDelete={async () => {
                      if (actionProcess.id) {
                        await deleteProcess(actionProcess.id);
                        refetchActions({
                          $skip: actionPerPage * page
                        });
                      }
                    }}
                    tradingCode={actionProcess?.tradingCode || '-'}
                    tradingDate={String(actionProcess?.tradingDate)}
                  />
                </Grid>
              </Grow>
            ))}
          </>
        )}
      </Grid>
      <Grid item container justifyContent="flex-end" mt={2}>
        <UIPagination
          count={Math.ceil((getActionProcess?.total || 0) / actionPerPage)}
          onChange={(e, newPage) => {
            setPage(newPage - 1);
            refetchActions({
              $skip: actionPerPage * (newPage - 1)
            });
          }}
          itemSize="small"
          page={page + 1}
        />
      </Grid>
    </Grid>
  );
}
