/* eslint-disable @typescript-eslint/no-use-before-define */
import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { theme } from 'shared/theme';

type Props = {
  subtitle: string;
};
export default function InformDiscountProductSubtitle({ subtitle }: Props) {
  return (
    <Box p={2}>
      <Grid container item xs={12}>
        <Typography
          variant="h3"
          style={{
            color: theme.colors.TextDarkGrey,
            fontWeight: theme.font.semiBold
          }}
        >
          {subtitle}
        </Typography>
      </Grid>
    </Box>
  );
}
