import { Container, Typography } from '@mui/material';
import { ClientFormFragment } from 'modules/Admin/Clients/Fragments/ClientFormFragment';
import { ClientPricesFragment } from 'modules/Admin/Clients/Fragments/ClientPricesFragment';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'services';
import { UIBreadcrumb, UILink } from 'shared/components';
import TitleWithSubtitle from 'shared/components/TitleWithSubtitle';
import { DistributorModel } from 'types/models';
import { UrlParams } from 'types/services';

export function ClientCreateUpdatePage() {
  const params = useParams() as UrlParams;
  const id = params?.id;

  const [distributor] = useQuery<DistributorModel, DistributorModel>(
    `/distributor/${id}`,
    {
      fetchOnInitialize: !!+id
    }
  );

  return (
    <Container maxWidth={'lg'}>
      <UIBreadcrumb>
        <UILink to={'/dashboard'}> Dashboard </UILink>
        <UILink to={'/clientes'}> Clientes </UILink>
        <Typography color={'primary'}>
          {id ? 'Editar Cliente' : 'Cadastrar Cliente'}
        </Typography>
      </UIBreadcrumb>
      <TitleWithSubtitle
        title="Clientes Nestlé"
        subtitle="Preencha as informações do cliente"
      />
      <ClientFormFragment id={+id} distributor={distributor} />
      {id && distributor ? <ClientPricesFragment id={+id} /> : null}
    </Container>
  );
}
