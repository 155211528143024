/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@mui/material';
import { useAuth } from 'Providers/Auth';
import React, { useEffect } from 'react';
import { useQuery } from 'services';
import UIPaper from 'shared/components/Paper';
import UploadAttachmentsSection from 'shared/components/Upload/UploadSection';
import { AttachmentModel } from 'types/models/Attachments/Attachment.model';

type Props = {
  id?: number;
};

export function ProductAttachments({ id }: Props) {
  const { auth } = useAuth();
  const attachmentsQuery = useQuery<AttachmentModel[], AttachmentModel>(
    `/attachments`,
    {
      table: 'product',
      $limit: '-1',
      tableId: id,
      fetchOnInitialize: false
    }
  );

  useEffect(() => {
    if (id) {
      attachmentsQuery[1]();
    }
  }, [id]);

  return (
    <Grid item xs={12} mb={4}>
      <UIPaper title="Documentos do produto" paddingChildren={1}>
        {id ? (
          <UploadAttachmentsSection
            attachmentsQuery={attachmentsQuery}
            selectOptions={['Ficha técnica']}
            tableId={id || 1}
            table="product"
            listTitle="Lista de anexos do produto"
            addTitle="Anexos do produto"
            spacing={0}
            accessId={auth?.id}
          />
        ) : (
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            textAlign="center"
          >
            Preencha as informações iniciais do produto para fazer upload de
            seus documentos
          </Grid>
        )}
      </UIPaper>
    </Grid>
  );
}
