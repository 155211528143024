import React from 'react';
import {
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover
} from '@mui/material';
import { usePopover } from 'hooks';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'Providers/Auth';

export function AddProcessButton() {
  const { auth } = useAuth();
  const { handleClickToPopover, handleCloseToPopoverEl, popoverAnchorEl } =
    usePopover();

  const history = useHistory();
  return (
    <>
      <Button
        variant={'outlined'}
        onClick={
          auth?.type === 'distributor'
            ? () => history.push('/cadastro/processo/distribuidor')
            : handleClickToPopover
        }
      >
        + Adicionar Processo
      </Button>
      <Popover
        anchorEl={popoverAnchorEl}
        onClose={handleCloseToPopoverEl}
        open={!!popoverAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <List>
          <ListItem
            button
            onClick={() => history.push('/cadastro/processo/consultor')}
          >
            <ListItemAvatar>
              <PublicRoundedIcon color={'primary'} />
            </ListItemAvatar>
            <ListItemText>Público</ListItemText>
          </ListItem>

          <ListItem
            button
            onClick={() => history.push('/cadastro/processo/privado')}
          >
            <ListItemAvatar>
              <ApartmentIcon color={'primary'} />
            </ListItemAvatar>
            <ListItemText>Privado</ListItemText>
          </ListItem>
        </List>
      </Popover>
    </>
  );
}
