import { Container, Typography } from '@mui/material';
import { InstitutionsList } from 'modules/Admin/Institutions/fragments/InstitutionsList';
import React from 'react';
import { UIBreadcrumb, UILink } from 'shared/components';

export function Institutions() {
  return (
    <Container maxWidth={'lg'}>
      <UIBreadcrumb>
        <UILink to={'/dashboard'}> Dashboard </UILink>
        <Typography color={'primary'}> Instituições </Typography>
      </UIBreadcrumb>
      <InstitutionsList />
    </Container>
  );
}
