import { Box } from '@mui/material';
import { DistributorProcessBasic } from 'modules/Process/PublicProcess/Forms/DistributorProcessForm/DistributorProcessBasic';
import { DistributorProcessItem } from 'modules/Process/PublicProcess/Forms/DistributorProcessForm/DistributorProcessItem';
import { useAuth } from 'Providers/Auth';
import React from 'react';
import { toast } from 'react-toastify';
import history from 'routes/History';
import { usePatch, UseQueryReturn } from 'services';
import UIPaper from 'shared/components/Paper';
import { ProcessModel } from 'types/models';
import { AttachmentModel } from 'types/models/Attachments/Attachment.model';
import DistributorProcessAttachments from './DistributorProcessAttachments';
import { DistributorProcessConclusion } from './DistributorProcessConclusion';

interface UrlParamsProps {
  id?: string;
  step: number;
  handleChangeStep: (step: number) => void;
  attachmentsQuery: UseQueryReturn<AttachmentModel[], AttachmentModel>;
}
/* Wrapper all distributor process forms into one single component to render  */
export function DistributorProcessForm({
  id,
  step,
  handleChangeStep,
  attachmentsQuery
}: UrlParamsProps) {
  const [, patchProcess] = usePatch<Partial<ProcessModel>>('/process');

  const handleComplete = async () => {
    if (attachmentsQuery?.[0]?.length === 0) {
      toast.error('Anexe ao menos um documento na etapa de: Anexos');
    } else if (id) {
      const response = await patchProcess(
        { status: 'const recording', step: 'const_proposal_sumary' },
        +id
      );
      if (response) {
        toast.success('Processo cadastrado com sucesso');
        history.push('/dashboard');
      }
    }
  };

  const { auth } = useAuth();

  return (
    <>
      {step === 0 && auth?.distributorId && (
        <UIPaper title="Informações do processo">
          <DistributorProcessBasic
            id={id}
            onComplete={() => handleChangeStep(1)}
            distributorId={+auth?.distributorId}
          />
        </UIPaper>
      )}

      {id && step === 1 ? (
        <Box bgcolor={'white'} padding={2} mt={2}>
          <DistributorProcessItem
            id={id}
            onFail={() => handleChangeStep(1)}
            onComplete={() => handleChangeStep(2)}
          />
        </Box>
      ) : null}
      {id && step === 2 ? (
        <DistributorProcessAttachments
          attachmentsQuery={attachmentsQuery}
          processId={+id}
          accessId={auth?.id}
          onComplete={() => {
            if (attachmentsQuery[0]?.length === 0)
              toast.error('Insira ao menos um documento para prosseguir.');
            else handleChangeStep(3);
          }}
        />
      ) : null}
      {id && step === 3 ? (
        <UIPaper title="Informações do processo">
          <DistributorProcessConclusion
            id={id}
            onComplete={handleComplete}
            attachmentsQuery={attachmentsQuery}
          />
        </UIPaper>
      ) : null}
    </>
  );
}
