import React from 'react';
import { Grid, Typography } from '@mui/material';
import { theme } from 'shared/theme';

type ProcessDescribeFragmentProps = {
  title: string;
  info: string;
};

export function ProcessDescribeFragment({
  title,
  info
}: ProcessDescribeFragmentProps) {
  return (
    <Grid container item direction="column" xs={6}>
      <Typography
        variant="h5"
        style={{
          color: theme.colors.TextDarkGrey
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="h5"
        style={{
          color: theme.colors.TextDarkGrey,
          fontWeight: theme.font.semiBold
        }}
      >
        {info}
      </Typography>
    </Grid>
  );
}
