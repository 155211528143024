import { Box, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import { UIChip } from 'shared/components/Chip';
import { ThemeColors } from 'shared/enum';
import { ProcessModel } from 'types/models';
import { FormatName } from 'utils/Functions/FormatName';
import { MoneyMask } from 'utils/masks';
import * as S from './styles';

type ProcessInformationHeaderProps = {
  process?: ProcessModel;
};

export function ProcessInformationBody({
  process
}: ProcessInformationHeaderProps) {
  return (
    <Grid container p={2}>
      <Grid container>
        <Grid item xs={12} sm={7} alignItems="center" justifyContent="center">
          <Grid container justifyContent="space-between">
            <Grid item xs>
              <Typography variant="h4" style={{ color: ThemeColors.TextGrey }}>
                Número do pregão
              </Typography>
              <Typography
                variant="h1"
                style={{
                  color: ThemeColors.TextDarkGrey,
                  fontWeight: 'normal'
                }}
              >
                {process?.tradingCode ?? '-'}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="h4" style={{ color: ThemeColors.TextGrey }}>
                Numero da PNE
              </Typography>
              <Typography
                variant="h1"
                style={{
                  color: ThemeColors.TextDarkGrey,
                  fontWeight: 'normal'
                }}
              >
                {process?.pne ?? '######'}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              {['gold', 'silver'].includes(
                process?.realClassification ?? '-'
              ) && (
                <UIChip
                  fontWeight="500"
                  title="Gold"
                  color="orange"
                  fontSize="10px"
                  height="20px"
                />
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Typography variant="h3" style={{ color: ThemeColors.TextGrey }}>
                {process?.institution?.name ?? '-'}
              </Typography>
              <S.StyledLine />
            </Box>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex' }}>
            <Typography variant="h3" style={{ color: ThemeColors.Primary }}>
              {process?.city ? FormatName(process.city) : ''} -{' '}
              {process?.uf ?? ''}
            </Typography>
            <Typography
              variant="h3"
              style={{
                color: ThemeColors.TextDarkGrey,
                margin: '0px 5px 0px 5px'
              }}
            >
              |
            </Typography>
            <Typography
              variant="h3"
              style={{ color: ThemeColors.TextDarkGrey }}
            >
              {process?.institution?.serviceChannel ?? '-'}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex' }}>
            <Typography
              variant="h5"
              style={{ color: ThemeColors.TextDarkGrey }}
            >
              Programa:
            </Typography>
            <Typography
              variant="h5"
              style={{
                color: ThemeColors.Primary,
                margin: '0px 5px 0px 5px'
              }}
              noWrap
            >
              {process?.programName ?? '-'}
            </Typography>
            <Typography
              variant="h5"
              style={{ color: ThemeColors.TextDarkGrey }}
            >
              Cliente:
            </Typography>
            <Typography
              variant="h5"
              style={{
                color: ThemeColors.Primary,
                margin: '0px 5px 0px 5px',
                textOverflow: 'ellipsis'
              }}
              noWrap
            >
              {process?.distributor?.name ?? '-'}
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            <Grid item>
              <Box mt={2}>
                <Typography
                  variant="h5"
                  style={{ color: ThemeColors.TextDarkGrey }}
                >
                  Valor contratado
                </Typography>
                <UIChip
                  title={MoneyMask(process?.totalValue ?? '0')}
                  color="green"
                  fontSize="15px"
                  fontWeight="bold"
                  height="30px"
                />
              </Box>
            </Grid>
            <Grid item>
              <Box mt={2}>
                <Typography
                  variant="h5"
                  style={{ color: ThemeColors.TextDarkGrey }}
                >
                  Valor a faturar
                </Typography>
                <UIChip
                  title={MoneyMask(process?.totalInvoice ?? '0')}
                  color="default"
                  fontSize="15px"
                  fontWeight="bold"
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Divider
          style={{ margin: '0 16px 0 16px' }}
          variant="middle"
          orientation="vertical"
          flexItem
        />
        <Grid
          xs={12}
          sm={4}
          container
          direction="column"
          justifyContent="center"
          spacing={2}
        >
          <Grid item>
            <span color="grey">Data da solicitação</span>
            <p>
              {process?.createdAt
                ? new Date(process.createdAt).toLocaleDateString('pt-BR', {
                    dateStyle: 'long'
                  })
                : '-'}
            </p>
          </Grid>
          <Grid item>
            <span color="grey">Início do fornecimento</span>
            <p>
              {process?.startPeriod
                ? new Date(process.startPeriod).toLocaleDateString('pt-BR', {
                    dateStyle: 'long'
                  })
                : '-'}
            </p>
          </Grid>

          <Grid item>
            <span color="grey">Fim do fornecimento</span>
            <p>
              {process?.endPeriod
                ? new Date(process.endPeriod).toLocaleDateString('pt-BR', {
                    dateStyle: 'long'
                  })
                : '-'}
            </p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
