import { format, isValid } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR/index';

export const GetDateFormat = (unformatted?: Date) => {
  if (isValid(new Date(unformatted || '')) && unformatted) {
    return format(new Date(unformatted), "dd 'de' MMMM 'de' Y", {
      locale: ptBR
    });
  }
  return '-';
};
