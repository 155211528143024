import { Grid } from '@mui/material';
import React from 'react';
import { UITable } from 'shared/components';
import { UIDataGridApiPagination } from 'shared/components/Tables/DataGridApiPagination';
import { UIToolbar } from 'shared/components/Tables/ToolbarTable';
import { DistributorPricesModel, ProductsModel } from 'types/models';
import { DistributorPricesColumns } from './columns';

type Props = {
  distributorPrices: DistributorPricesModel[];
  products: ProductsModel[];
  loading: boolean;
};

export default function DistributorPricesTable({
  distributorPrices = [],
  products = [],
  loading
}: Props) {
  const mapRows = distributorPrices.map((distributorPrice) => ({
    ...distributorPrice,
    sku: products.find((product) => product.id === distributorPrice.productId)
      ?.sku,
    description: products.find(
      (product) => product.id === distributorPrice.productId
    )?.description
  }));

  return (
    <>
      <Grid container item xs={12} sm={6} md={7}>
        <UITable
          rows={mapRows}
          loading={loading}
          columns={DistributorPricesColumns}
          rowHeight={36}
          headerHeight={36}
          pageSize={7}
          components={{
            Toolbar: () => (
              <UIToolbar
                elementsTopEnd={
                  <>
                    <UIDataGridApiPagination itemSize="medium" />
                  </>
                }
              />
            )
          }}
        />
      </Grid>
    </>
  );
}
