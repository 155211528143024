import React from 'react';
import { useAuth } from 'Providers/Auth';
import { ProfileEmployeeFragment } from 'modules/Profiles/employee/fragments/ProfileEmployeeFragment';
import { Grid, Typography } from '@mui/material';
import { theme } from 'shared/theme';
import { EmployeeBreadcrumb } from 'modules/Profiles/employee/breadcrumb';
import { useQuery } from 'services';
import { AccessModel } from 'types/models';

export function EmployeeProfilePage() {
  const { auth } = useAuth();

  const [access] = useQuery<AccessModel, AccessModel>(`/access/${auth?.id}`);

  return (
    <>
      <EmployeeBreadcrumb />
      <Grid container pt={3} pl={2} alignItems="center">
        <Typography
          variant="h2"
          style={{
            color: theme.colors.TextDarkGrey,
            fontWeight: theme.font.semiBold
          }}
        >
          Editar Informações
        </Typography>
      </Grid>
      <ProfileEmployeeFragment access={access || {}} />
    </>
  );
}
