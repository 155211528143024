export const GetBooleanString = (
  value?: boolean | string
): 'true' | 'false' | undefined => {
  if (value === true || value === 'true') return 'true';
  if (value === false || value === 'false') return 'false';
  return undefined;
};

export const GetStringBoolean = (
  value?: boolean | string
): boolean | undefined => {
  if (value === true || value === 'true') return true;
  if (value === false || value === 'false') return false;
  return undefined;
};
