import { Button, CircularProgress, Grid } from '@mui/material';
import { useAuth } from 'Providers/Auth';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { usePatch, useQuery, UseQueryReturn } from 'services';
import UploadAttachmentsSection from 'shared/components/Upload/UploadSection';
import { ProcessModel, ProcessProductModel } from 'types/models';
import { AttachmentModel } from 'types/models/Attachments/Attachment.model';
import { PrivateProcessDocuments } from 'utils/Constants/DocumentTypes/PrivateProcessDocuments';

interface PrivateProcessAttachmentsProps {
  id: string;
  attachmentsQuery: UseQueryReturn<AttachmentModel[], AttachmentModel>;
}

export function PrivateProcessAttachments({
  id,
  attachmentsQuery
}: PrivateProcessAttachmentsProps) {
  const { auth } = useAuth();
  const history = useHistory();
  const [, patchProcess] = usePatch<ProcessModel>('/process');
  const [processProducts, , loading] = useQuery<ProcessProductModel[]>(
    '/process-products',
    {
      $limit: -1,
      processId: id,
      $select: ['id', 'finalValue'],
      notInclude: ['product', 'products']
    }
  );

  const updateToFinancier = async () => {
    try {
      await patchProcess(
        {
          status: 'awaiting financier'
        },
        +id
      );
      toast.warn(
        'O processo possui itens que necessitam aprovação do financeiro. Aguarde aprovação para gerar PNE.'
      );
      history.push('/dashboard');
    } catch (error) {
      toast.error('Erro ao atualizar status para aguardando financeiro.');
    }
  };

  const updateToOrder = async () => {
    try {
      const process = await patchProcess(
        {
          status: 'awaiting order'
        },
        +id
      );
      if (process?.pne) {
        toast.success(
          `O processo foi concluído com sucesso, a PNE gerada foi: ${process?.pne}`
        );
        history.push(`/processo/${id}`);
      } else {
        toast.error('Erro ao gerar PNE.');
      }
    } catch (error) {
      toast.error('Erro ao gerar PNE.');
    }
  };

  const updateToInform = async () => {
    try {
      await patchProcess(
        {
          status: 'awaiting consultant result'
        },
        +id
      );
      toast.warn(
        'O processo possui itens que necessitam o informe do resultado. Informe o resultado para gerar PNE.'
      );
      history.push(`/processo/${id}`);
    } catch (error) {
      toast.error('Erro ao atualizar status para aguardando resultado.');
    }
  };

  const onComplete = async () => {
    if (processProducts) {
      if (processProducts.length > 0) {
        const needsFinancier = processProducts.some(
          (pp) => pp?.discount_orders?.[0]?.id
        );

        const needsInformResult = processProducts.some((pp) => !pp?.finalValue);
        if (needsFinancier) {
          await updateToFinancier();
        } else if (needsInformResult) {
          await updateToInform();
        } else {
          await updateToOrder();
        }
      } else {
        toast.error('Cadastre ao menos um produto para concluir o processo.');
      }
    }
  };

  return (
    <>
      <UploadAttachmentsSection
        attachmentsQuery={attachmentsQuery}
        selectOptions={PrivateProcessDocuments}
        accessId={auth?.id}
        tableId={+id}
      />
      {loading ? (
        <CircularProgress />
      ) : (
        <Grid container item direction={'row-reverse'} mt={4}>
          <Grid item xs={12} sm={2}>
            <Button
              type={'submit'}
              onClick={onComplete}
              fullWidth
              variant={'contained'}
            >
              Concluir
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
}
