import React from 'react';

import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import DocumentImage from 'assets/images/documentImageColor.svg';
import DownloadIcon from '@mui/icons-material/GetApp';
import RemoveIcon from '@mui/icons-material/HighlightOff';
import { theme } from 'shared/theme';

export type UploadItemProps = {
  name: string;
  size?: string;
  category?: string;
  loading?: boolean;
  onDelete?: () => void;
  onDownload?: () => void;
};

export default function UploadItem({
  name,
  size,
  loading,
  category,
  onDelete,
  onDownload
}: UploadItemProps) {
  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid container item xs={3} sm={2} md={2} alignItems="center">
        <img src={DocumentImage} alt="Icone de Documento" width={38} />
      </Grid>
      <Grid item xs={7} sm={8} md={8}>
        <Typography
          variant="h4"
          style={{
            fontWeight: theme.font.light
          }}
        >
          {category}
        </Typography>
        <Grid container spacing={1} alignItems="center">
          <Grid item style={{ maxWidth: '70%' }}>
            <Tooltip title={name}>
              <Typography
                variant="h5"
                style={{
                  color: theme.colors.TextDarkGrey,
                  fontWeight: theme.font.bold,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis'
                }}
              >
                {name}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item>|</Grid>
          <Grid item>
            <Typography variant="h6" style={{ color: theme.colors.TextGrey }}>
              {size}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1}>
        {loading ? (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress size={24} />
          </div>
        ) : (
          <IconButton onClick={onDownload} color="primary" size="large">
            <DownloadIcon />
          </IconButton>
        )}
      </Grid>
      {onDelete && (
        <Grid item xs={1}>
          <IconButton size="large">
            <RemoveIcon onClick={onDelete} />
          </IconButton>
        </Grid>
      )}
      <Grid item xs={12} alignItems="center">
        <Box mt={1} mb={1}>
          <Divider />
        </Box>
      </Grid>
    </Grid>
  );
}
