export enum ERRORS {
  'REQUIRED_FIELD' = 'Campo obrigatório.',
  'INVALID_EMAIL' = 'Digite um e-mail válido.',
  'INVALID_LOGIN' = 'E-mail ou senhas incorretos.',
  'MIN_DATE' = 'Você passou a data mínima.',
  'SOMETHING_WENT_WRONG' = 'Algo de errado aconteceu.',
  'EMAIL_INVALID' = 'E-mail está inválido',
  'NUMBER_FORMAT' = 'Digite apenas numeros',
  'NUMBER_GREATER_THAN' = 'Digite um valor acima de '
}
