import React from 'react';
import { useAuth } from 'Providers/Auth';
import { Container } from '@mui/material';
import { DistributorProfilePage } from 'pages/Profile/Distributor';
import { EmployeeProfilePage } from 'pages/Profile/Employee';

export function ProfilePage() {
  const { auth } = useAuth();

  return (
    <Container maxWidth={'lg'}>
      {auth?.employee ? <EmployeeProfilePage /> : null}

      {auth?.distributor?.id ? <DistributorProfilePage /> : null}
    </Container>
  );
}
