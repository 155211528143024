import { ServicesChannels } from 'utils/Enums';
import { ERRORS } from 'utils/Errors';
import * as yup from 'yup';

export interface GovernmentInstitutionFormRes {
  name: string;
  regional: string | null;
  code: string | null;
  segment: string | null;
  serviceChannel: ServicesChannels | null;
  uf: string;
  city: string;
  programs: string[] | null;
  focus?: boolean;
  organization?: boolean;
  employeeId?: number | null;
}

export const CreateGovernmentInstitutionSchema = yup.object().shape({
  name: yup.string().required(ERRORS.REQUIRED_FIELD),
  regional: yup.string().nullable(),
  code: yup.string().nullable(),
  segment: yup.string().nullable(),
  serviceChannel: yup.string().nullable(),
  uf: yup.string().required(ERRORS.REQUIRED_FIELD),
  city: yup.string().required(ERRORS.REQUIRED_FIELD),
  programs: yup.array().min(0).nullable(),
  focus: yup.boolean().nullable(),
  organization: yup.boolean().nullable(),
  employeeId: yup.number().nullable()
});
