import { Grid } from '@mui/material';
import React from 'react';
import { UITable } from 'shared/components';
import { UIDataGridApiPagination } from 'shared/components/Tables/DataGridApiPagination';
import { UIToolbar } from 'shared/components/Tables/ToolbarTable';
import { Cities, UfsRegionals } from 'types/models';
import { RegionalsTableColumns } from './columns';

type Props = {
  loading: boolean;
  cities: Cities[];
  ufsRegionals: UfsRegionals[];
  step: string;
};

export default function RegionalsTable({
  loading,
  cities = [],
  ufsRegionals = [],
  step
}: Props) {
  const mapRows = cities?.map((city) => ({
    ...city,
    regional:
      (ufsRegionals || []).find((uf) => uf.uf === city.uf)?.regional || '-'
  }));
  const filterByStep =
    mapRows.filter((city) =>
      step === 'all' ? true : city.regional === step
    ) || [];

  return (
    <Grid container item xs={12} md={7}>
      <UITable
        rows={filterByStep}
        loading={loading}
        columns={RegionalsTableColumns}
        rowHeight={36}
        headerHeight={36}
        pageSize={15}
        components={{
          Toolbar: () => (
            <UIToolbar
              noStart
              elementsTopEnd={
                <>
                  <Grid container p={1} justifyContent="flex-end">
                    <UIDataGridApiPagination itemSize="medium" />
                  </Grid>
                </>
              }
            />
          )
        }}
      />
    </Grid>
  );
}
