import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { theme } from 'shared/theme';
import { OpenModalProps } from '../Domain';

export type DeleteObservationModalProps = {
  setOpenModal: (openModal: OpenModalProps) => void;
  onSubmit: () => void;
} & OpenModalProps;

export function DeleteObservationModal({
  open,
  type,
  setOpenModal,
  onSubmit
}: DeleteObservationModalProps) {
  const onClose = () =>
    setOpenModal({
      open: false,
      type
    });

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Typography
          variant="h4"
          style={{
            fontWeight: theme.font.semiBold,
            color: theme.colors.TextDarkGrey
          }}
        >
          Excluir comentário
        </Typography>
        <IconButton aria-label={'close'} onClick={onClose} size="large">
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container md={12}>
          <Typography variant="h4">Deseja excluir o comentário?</Typography>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          item
          container
          direction={'row'}
          justifyContent={'flex-end'}
          xs={12}
        >
          <Grid item style={{ marginRight: '20px' }}>
            <Button variant="outlined" onClick={onClose} color={'primary'}>
              Voltar
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color={'primary'}
              onClick={() => onSubmit()}
            >
              Excluir
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
