import { GridColDef } from '@mui/x-data-grid';
import React from 'react';
import { UIChip } from 'shared/components/Chip';
import { FormatPercent } from 'utils/Functions/FormatPercent';
import { GetLangValueColor } from 'utils/Functions/GetLang';
import { MoneyMask } from 'utils/masks';

export const informDiscountColumns: GridColDef[] = [
  { field: 'id', headerName: 'ID', hide: true },
  {
    field: 'status',
    headerName: 'Status',
    renderCell: ({ value }) => (
      <UIChip
        color={
          GetLangValueColor('discountOrderStatus', value?.toString() || '')
            .color
        }
        title={
          GetLangValueColor('discountOrderStatus', value?.toString() || '')
            .value || ''
        }
        height="20px"
      />
    ),
    valueFormatter: ({ value }) => value || '-',
    width: 135,
    valueGetter: ({ row }) => row?.discount_orders?.[0]?.status
  },
  {
    field: 'product.description',
    headerName: 'Descrição',
    valueGetter: ({ row }) => row?.product?.description,
    valueFormatter: ({ value }) => value ?? '-',
    width: 300
  },
  {
    field: 'product.sku',
    headerName: 'SKU',
    valueGetter: ({ row }) => row?.product?.sku,
    valueFormatter: ({ value }) => value ?? '-',
    width: 100
  },
  {
    field: 'itemNumber',
    headerName: 'Item',
    width: 60,
    valueFormatter: ({ value }) => value ?? '-'
  },
  {
    field: 'maxQuantity',
    headerName: 'Quantidade',
    width: 95,
    valueFormatter: ({ value }) => value ?? '-'
  },
  {
    field: 'priceList',
    headerName: 'Preço Lista',
    width: 150,
    valueFormatter: ({ value }) => (value ? MoneyMask(`${value}`) : '-')
  },
  {
    field: 'nfPrice',
    headerName: 'Preço Proposto',
    width: 150,
    valueFormatter: ({ value }) => (value ? MoneyMask(`${value}`) : '-')
  },
  {
    field: 'markup',
    headerName: 'Markup',
    width: 80,
    valueFormatter: ({ value }) => (value ? FormatPercent(`${value}`) : '-')
  },
  {
    field: 'institutionValue',
    width: 150,
    headerName: 'Preço Instituição',
    valueFormatter: ({ value }) => (value ? MoneyMask(`${value}`) : '-')
  }
];
