import styled, { css } from 'styled-components';
import { Grid } from '@mui/material';

export const Wrapper = styled.div`
  background: #fff;
  border-radius: ${({ theme }) => theme.border.radius.medium};
  padding: 0.9rem;
  cursor: pointer;
  svg {
    font-size: ${({ theme }) => theme.font.sizes.small};
    color: ${({ theme }) => theme.colors.TextGrey};
  }

  &:hover {
    background: #c4c4c40f;
  }
`;

export const Title = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.TextGrey};
    font-size: ${theme.font.sizes.small};
    font-weight: ${theme.font.bold};
  `}
`;

export const Action = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.TextDarkGrey};
    font-size: ${theme.font.sizes.medium};
    font-weight: ${theme.font.bold};
  `}
`;

export const Institution = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.TextDarkGrey};
    font-size: ${theme.font.sizes.small};
    padding-left: 0.3rem;
  `}
`;

export const Location = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.Primary};
    font-size: ${theme.font.sizes.small};
    padding-right: 0.3rem;
  `}
`;

export const Details = styled(Grid)`
  ${({ theme }) => css`
    padding: 0.6rem 0;

    .details-values {
      font-size: ${theme.font.sizes.small};
      color: ${theme.colors.TextDarkGrey};
    }

    .details-title {
      color: ${theme.colors.TextGrey};
      font-size: ${theme.font.sizes.small};
      font-weight: ${theme.font.bold};
    }
  `}
`;

export const Bar = styled.span`
  width: 1.5rem;
  height: 0.2rem;
  background-color: ${({ theme }) => theme.colors.Primary};
  display: block;
  margin-top: 0.3rem;
`;
