import { Box, Container, Typography } from '@mui/material';
import { ConsultantProcessForm } from 'modules/Process/PublicProcess/Forms/ConsultantProcessForm';
import { EmployeeCreateProcessTabs } from 'modules/Process/PublicProcess/Fragments/CreateProcess/consultant/Tabs';
import { CreateProcessBreadcrumb } from 'modules/Process/PublicProcess/Fragments/CreateProcess/shared/Breadcrumb';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { usePatch, useQuery } from 'services';
import { theme } from 'shared/theme';
import { ProcessModel } from 'types/models';
import { AttachmentModel } from 'types/models/Attachments/Attachment.model';
import { steps } from './Constants/steps';

export function ConsultantCreateProcess() {
  const queryParams = new URLSearchParams(useLocation().search);
  const id = queryParams.get('processo');

  const [, patchProcess] = usePatch<ProcessModel>(`/process`);

  const [process] = useQuery<ProcessModel>(`/process/${id}`, {
    fetchOnInitialize: !!id,
    $select: ['step']
  });

  const [step, setStep] = useState(0);
  const handleChangeStep = (newValue: number) => {
    setStep(newValue);
    if (id) {
      patchProcess(
        {
          step: steps[newValue]
        },
        +id
      );
    }
  };

  const attachmentUseQuery = useQuery<AttachmentModel[], AttachmentModel>(
    `/attachments`,
    {
      table: 'process',
      $limit: '-1',
      tableId: id ?? '',
      fetchOnInitialize: false
    }
  );

  useEffect(() => {
    if (process?.step) {
      const indexOf = steps.indexOf(process?.step);
      setStep(indexOf === -1 ? 0 : indexOf);
    }
  }, [process]);

  return (
    <Container>
      <Box mb={4}>
        <CreateProcessBreadcrumb />
      </Box>
      <Box mb={3}>
        <Typography
          variant="h2"
          style={{
            color: theme.colors.TextDarkGrey,
            fontSize: theme.font.sizes.large
          }}
        >
          Adicionar processo
        </Typography>
      </Box>
      <EmployeeCreateProcessTabs
        id={id}
        step={step}
        handleChangeStep={handleChangeStep}
      />
      <Box mb={3} />
      <ConsultantProcessForm
        id={id}
        handleChangeStep={handleChangeStep}
        step={step}
        attachmentsQuery={attachmentUseQuery}
      />
    </Container>
  );
}
