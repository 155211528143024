export enum ThemeColors {
  Primary = '#5082c9',
  Success = '#65ac28',
  Pink = '#ff73c3',
  Orange = '#ef851b',
  TextDarkGrey = '#565656',
  TextGrey = '#c4c4c4',
  TextLightGrey = '#ebebeb',
  Error = '#c95050',
  White = '#fff',
  LightGrey = '#e5e5e5'
}
