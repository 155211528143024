/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Collapse,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField
} from '@mui/material';
import { STATES_ARRAY } from 'utils/Enums/states';
import { useCities } from 'hooks/useCities/index';
import { useFormik } from 'formik';
import { ProcessModel, UfsRegionals } from 'types/models';
import { Months } from 'utils/Functions/Months';
import { CompetitionModalities } from 'utils/Constants/CompetitionModality';
import { ProgramName } from 'utils/Constants/ProgramNames';
import { NoticeAnalysisSchema } from 'modules/Process/PublicProcess/Forms/NoticeAnalysis/domain';
import { FormProps } from 'shared/components/Inputs';
import { DatePicker } from '@mui/lab';
import { useQuery } from 'services';

export interface NoticeAnalysisFormProps
  extends FormProps<Partial<ProcessModel>> {
  process?: Partial<ProcessModel>;
}

export function NoticeAnalysisForm({
  process,
  onComplete
}: NoticeAnalysisFormProps) {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    handleBlur
  } = useFormik({
    initialValues: {
      tradingDate: process?.tradingDate || new Date(),
      uf: process?.uf || '',
      city: process?.city || '',
      quota: process?.quota || '',
      regional: process?.regional || '',
      newProgram: process?.newProgram || false,
      isImprovement: process?.isImprovement || false,
      programName: process?.programName || '',
      competitionModality: process?.competitionModality || '',
      tradingCode: process?.tradingCode || '',
      period: process?.period || ''
    },
    validationSchema: NoticeAnalysisSchema,
    onSubmit: (fields) => {
      const data = fields as Partial<ProcessModel>;
      onComplete(data);
    }
  });

  const { citiesData, citiesLoading } = useCities();
  const [ufsRegionals] = useQuery<UfsRegionals[], UfsRegionals>(
    '/ufs-regionals',
    {
      $limit: -1
    }
  );

  useEffect(() => {
    if (!values?.regional && values?.uf) {
      const findUfRegional = (ufsRegionals || []).find(
        (ufRegional) => ufRegional.uf === values?.uf
      );
      setFieldValue('regional', findUfRegional?.regional);
    }
  }, [ufsRegionals]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction={'column'} spacing={1}>
        <Grid item xs={12}>
          <DatePicker
            value={values?.tradingDate}
            label={'Abertura do processo'}
            renderInput={(props) => (
              <TextField
                {...props}
                error={!!errors.tradingDate && Boolean(touched.tradingDate)}
                helperText={touched.tradingDate && errors.tradingDate}
                onBlur={handleBlur}
              />
            )}
            onChange={(value) => setFieldValue('tradingDate', value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={'Cota'}
            name={'quota'}
            value={values.quota}
            error={!!errors.quota && touched.quota}
            helperText={touched.quota && errors.quota}
            onChange={handleChange}
            onBlur={handleBlur}
            select
            fullWidth
          >
            <MenuItem value={'ampla'}> Ampla </MenuItem>
            <MenuItem value={'reservada'}> Reservada </MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            renderInput={(props) => (
              <TextField
                {...props}
                name={'uf'}
                value={values.uf}
                label={'Estado'}
                helperText={touched.uf && errors.uf}
                error={!!errors.uf && Boolean(touched.uf)}
                onBlur={handleBlur}
              />
            )}
            options={STATES_ARRAY}
            defaultValue={
              values.uf
                ? STATES_ARRAY.find((option) => option.uf === values.uf)
                : null
            }
            getOptionLabel={(option) => option.state}
            onChange={(event, value) => {
              if (value && value.uf) {
                setFieldValue('uf', value.uf);
                const findUfRegional = (ufsRegionals || []).find(
                  (ufRegional) => ufRegional.uf === value.uf
                );
                setFieldValue('regional', findUfRegional?.regional);
              } else {
                setFieldValue('uf', null);
              }
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Collapse in={!citiesLoading} timeout={300}>
            {!citiesLoading && (
              <Autocomplete
                renderInput={(props) => (
                  <TextField
                    {...props}
                    name={'city'}
                    label={'Cidade'}
                    error={!!errors.city && touched.city}
                    helperText={touched.city && errors.city}
                    onBlur={handleBlur}
                  />
                )}
                options={citiesData.filter((city) => city.uf === values.uf)}
                defaultValue={
                  values.city
                    ? citiesData.find(
                        (option) =>
                          option.city.toLowerCase() ===
                          values.city.toLowerCase()
                      )
                    : null
                }
                getOptionLabel={(option) => option.city}
                onChange={(_, value) => {
                  _.preventDefault();
                  if (value && value.city) {
                    setFieldValue('city', value.city);
                  } else {
                    setFieldValue('city', null);
                  }
                }}
                fullWidth
                disabled={citiesLoading}
                loading={citiesLoading}
              />
            )}
          </Collapse>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={'Regional'}
            name={'regional'}
            value={values.regional || ''}
            error={!!errors.regional && touched.regional}
            helperText={touched.regional && errors.regional}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <Box mt={2}>
            <FormControl component={'fieldset'}>
              <FormLabel> É um novo programa governamental?</FormLabel>
              <RadioGroup
                name={'newProgram'}
                onChange={handleChange}
                onBlur={handleBlur}
                value={String(values.newProgram)}
                row
              >
                <FormControlLabel
                  value={'true'}
                  control={<Radio color={'primary'} />}
                  label={'Sim'}
                />
                <FormControlLabel
                  value={'false'}
                  control={<Radio color={'primary'} />}
                  label={'Não'}
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mt={1}>
            <FormControl component={'fieldset'}>
              <FormLabel>Melhoria no programa?</FormLabel>
              <RadioGroup
                name={'isImprovement'}
                onChange={handleChange}
                value={String(values.isImprovement)}
                onBlur={handleBlur}
                row
              >
                <FormControlLabel
                  value={'true'}
                  label={'Sim'}
                  control={<Radio color={'primary'} />}
                />
                <FormControlLabel
                  value={'false'}
                  label={'Não'}
                  control={<Radio color={'primary'} />}
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={'Nome do programa'}
            name={'programName'}
            value={values.programName}
            onChange={handleChange}
            helperText={touched.programName && errors.programName}
            error={!!errors.programName && touched.programName}
            onBlur={handleBlur}
            select
            fullWidth
          >
            {ProgramName.map((programName) => (
              <MenuItem value={programName}>{programName} </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          {String(values.newProgram) === 'false' ? (
            <TextField
              label={'Modalidade da concorrência'}
              name={'competitionModality'}
              value={values.competitionModality}
              onChange={handleChange}
              helperText={
                touched.competitionModality && errors.competitionModality
              }
              error={
                !!errors.competitionModality && touched.competitionModality
              }
              onBlur={handleBlur}
              select
              fullWidth
            >
              {CompetitionModalities.map((modality) => (
                <MenuItem value={modality} key={modality}>
                  {modality}
                </MenuItem>
              ))}
            </TextField>
          ) : (
            <TextField
              label={'Modalidade da concorrência'}
              name={'competitionModality'}
              value={values.competitionModality}
              onChange={handleChange}
              helperText={
                touched.competitionModality && errors.competitionModality
              }
              error={
                !!errors.competitionModality && touched.competitionModality
              }
              onBlur={handleBlur}
              fullWidth
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={'Número do edital'}
            name={'tradingCode'}
            value={values.tradingCode}
            helperText={touched.tradingCode && errors.tradingCode}
            error={!!errors.tradingCode && touched.tradingCode}
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={'Período de fornecimento (meses)'}
            name={'period'}
            value={values.period}
            onChange={handleChange}
            helperText={touched.period && errors.period}
            error={!!errors.period && touched.period}
            onBlur={handleBlur}
            select
            fullWidth
          >
            {Months().map((month) => (
              <MenuItem value={month} key={month}>
                {month}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} container direction={'row-reverse'}>
          <Grid item xs={12}>
            <Button type={'submit'} fullWidth variant={'contained'}>
              Avançar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}
