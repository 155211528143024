import React from 'react';
import { SimpleFormModal } from 'shared/components';
import { ScopeRegistrationCompleteForm } from 'modules/Process/PublicProcess/Forms/ScopeRegistration/complete';
import { ProcessItemModalParams } from 'modules/Process/PublicProcess/Modals/ProcessItem/Complete/ProcessItemModal';
import { Divider, Grid } from '@mui/material';
import { CSSProperties } from 'styled-components';
import { ProductsOcrFields } from 'modules/Process/PublicProcess/Fragments/OcrFragments/ProductsOcrFields';

const styleGrid: CSSProperties = {
  maxHeight: '700px',
  overflowY: 'scroll',
  overflowX: 'hidden',
  padding: '10px'
};

export function ProcessItemModal({
  title = 'Itens do processo',
  processItem,
  onComplete,
  disabledByHistory,
  processId,
  ocrProducts,
  ...rest
}: ProcessItemModalParams) {
  return (
    <SimpleFormModal
      title={title}
      {...rest}
      fullWidth
      maxWidth={ocrProducts ? 'md' : 'sm'}
    >
      <Grid container item xs={12}>
        {ocrProducts ? (
          <Grid
            container
            item
            xs={5}
            justifyContent="center"
            alignItems="center"
          >
            <ProductsOcrFields ocrProducts={ocrProducts} />
          </Grid>
        ) : null}
        <Grid container item xs={1} justifyContent="center">
          <Divider orientation="vertical" flexItem />
        </Grid>
        <Grid container item xs={ocrProducts ? 6 : 12} style={styleGrid}>
          <ScopeRegistrationCompleteForm
            onComplete={onComplete}
            processProduct={processItem}
            disabledByHistory={disabledByHistory}
            processId={processId}
          />
        </Grid>
      </Grid>
    </SimpleFormModal>
  );
}
