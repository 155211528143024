import { Container, Grid } from '@mui/material';
import { InformResultFragment } from 'modules/Process/PublicProcess/Fragments/InformResult';
import { InformResultUpload } from 'modules/Process/PublicProcess/Fragments/InformResult/Fragments/distributor/InformResultaUpload';
import { InformResultBreadcrumb } from 'modules/Process/PublicProcess/Fragments/InformResult/Fragments/InformResultBreadcrumb';
import { InformResultTitle } from 'modules/Process/PublicProcess/Fragments/InformResult/Fragments/InformResultTitle';
import React from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import history from 'routes/History';
import { usePatch, useQuery } from 'services';
import { AttachmentModel } from 'types/models/Attachments/Attachment.model';
import { UrlParams } from 'types/services';

export function InformResultDistributor() {
  const { id } = useParams() as UrlParams;

  const [, patchProcess] = usePatch('/process');

  const attachmentsQuery = useQuery<AttachmentModel[], AttachmentModel>(
    `/attachments`,
    {
      table: 'process',
      $limit: '-1',
      tableId: id,
      fetchOnInitialize: false,
      'category[$in]': ['Ata', 'Outros anexos']
    }
  );

  const handleComplete = async () => {
    if (attachmentsQuery[0]) {
      const hasDocument = attachmentsQuery[0].find(
        (attachment) => attachment.category === 'Ata'
      );
      if (!hasDocument) {
        toast.error('Faça upload da Ata para informar o resultado.');
      } else {
        const response = await patchProcess(
          { status: 'awaiting consultant result' },
          +id
        );
        if (response) {
          toast.success('Sucesso ao informar resultado.');
          history.push(`/processo/${id}`);
        }
      }
    }
  };

  return (
    <Container maxWidth={'lg'}>
      <InformResultBreadcrumb id={id} />
      <InformResultTitle />
      <Grid container>
        <InformResultFragment processId={id} onComplete={handleComplete} />
      </Grid>
      <InformResultUpload attachmentsQuery={attachmentsQuery} processId={id} />
    </Container>
  );
}
