import { GridColDef } from '@mui/x-data-grid';
import React from 'react';
import { UIChip } from 'shared/components/Chip';
import { ImportsStatus } from 'utils/Constants/ImportsStatus';
import { ImportsType } from 'utils/Constants/ImportsType';
import { ParseDateString } from 'utils/Functions';

export const ImportsColumns: GridColDef[] = [
  { field: 'id', headerName: 'ID', hide: true },
  {
    field: 'type',
    headerName: 'Tipo',
    width: 200,
    renderCell: ({ value }) => (
      <UIChip
        color={ImportsType[value?.toString() || 'distributor']?.color}
        title={ImportsType[value?.toString() || 'distributor']?.label}
        height="20px"
        width="80%"
      />
    )
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 180,
    renderCell: ({ value }) => (
      <UIChip
        color={ImportsStatus[value?.toString() || 'distributor']?.color}
        title={ImportsStatus[value?.toString() || 'distributor']?.label}
        height="20px"
        width="80%"
      />
    )
  },
  {
    field: 'createdAt',
    width: 250,
    headerName: 'Data de Inicio',
    valueFormatter: ({ value }) => ParseDateString(String(value))
  },
  {
    field: 'finishedDate',
    width: 250,
    headerName: 'Data da Final',
    valueFormatter: ({ value }) => ParseDateString(String(value))
  },
  {
    field: 'updatedAt',
    width: 150,
    flex: 1,
    headerName: 'Última atualização',
    valueFormatter: ({ value }) => ParseDateString(String(value))
  }
];
