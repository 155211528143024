import { useQuery } from 'services/api/UseQuery';
import { Cities } from 'types/models';

export function useCities() {
  const [citiesQueryRes, citiesFetch, citiesLoading, citiesErrors] = useQuery<
    Cities[]
  >('/cities', {
    $limit: -1
  });

  const citiesData = citiesQueryRes || [];

  return { citiesData, citiesFetch, citiesLoading, citiesErrors };
}
