import { ServicesChannels } from 'utils/Enums';
import { ERRORS } from 'utils/Errors';
import * as yup from 'yup';

export interface HospitalInstitutionFormRes {
  name: string;
  nutritionistName: string | null;
  email: string | null;
  regional: string | null;
  cnpj: string | null;
  social: string | null;
  code: string | null;
  segment: string | null;
  uf: string;
  city: string;
  serviceChannel: ServicesChannels | null;
  class?: string | null;
  type?: string | null;
  standardization?: string | null;
  specialStandardization?: string | null;
  organization?: boolean;
  childBirthQuantity?: number | null;
  employeeId?: number | null;
  productIds?: number[] | null;
}

export const CreateHospitalInstitutionSchema = yup.object().shape({
  name: yup.string().required(ERRORS.REQUIRED_FIELD),
  nutritionistName: yup.string().nullable(),
  email: yup.string().nullable().email(ERRORS.EMAIL_INVALID),
  regional: yup.string().nullable(),
  cnpj: yup.string().nullable(),
  social: yup.string().nullable(),
  code: yup.string().nullable(),
  segment: yup.string().nullable(),
  uf: yup.string().required(ERRORS.REQUIRED_FIELD),
  city: yup.string().required(ERRORS.REQUIRED_FIELD),
  serviceChannel: yup.string().nullable(),
  class: yup.string().nullable(),
  type: yup.string().nullable(),
  standardization: yup.string().nullable(),
  specialStandardization: yup.string().nullable(),
  organization: yup.boolean().nullable(),
  childBirthQuantity: yup.string().nullable(),
  employeeId: yup.number().nullable(),
  productIds: yup.array().min(0).nullable()
});
