/* eslint-disable react-hooks/exhaustive-deps */
import SearchIcon from '@mui/icons-material/Search';
import { Button, Grid, InputAdornment, MenuItem } from '@mui/material';
import { useAuth } from 'Providers/Auth';
import React, { useState } from 'react';
import { UITextField } from 'shared/components/Inputs';
import { GenerateReportModal } from '../Report/Modals/GenerateReport';
import { FilterProcessStatus } from './Constants/filterProcessStatus';

type ProcessSearchProps = {
  onChange: (value: string) => void;
  setStatus: (status: string) => void;
  actualStatus: string;
  isHistory?: boolean;
};

export function ProcessSearch({
  onChange,
  setStatus,
  actualStatus,
  isHistory = false
}: ProcessSearchProps) {
  const { auth } = useAuth();

  const [showReport, setShowReport] = useState<boolean>(false);

  return (
    <>
      <Grid
        container
        item
        xs={12}
        md={auth?.employee?.accessLevel === 'admin' || isHistory ? 9 : 12}
      >
        <Grid container item xs={8}>
          <UITextField
            name={'search'}
            label={'Pesquisar processos'}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            variant={'filled'}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position={'start'}>
                  <SearchIcon />
                </InputAdornment>
              )
            }}
            fullWidth
          />
        </Grid>
        <Grid container item xs={4}>
          <UITextField
            label={'Status do Processo'}
            variant={'filled'}
            value={actualStatus || 'all'}
            fullWidth
            select
            InputProps={{
              disableUnderline: true
            }}
            onChange={(e) => setStatus(e.target.value)}
          >
            {FilterProcessStatus.map(({ label, status }) => (
              <MenuItem key={status} value={status}>
                {label}
              </MenuItem>
            ))}
          </UITextField>
        </Grid>
      </Grid>
      {(auth?.employee?.accessLevel === 'admin' || isHistory) && (
        <Grid container item xs={3}>
          <Button
            fullWidth
            variant="contained"
            color={'primary'}
            onClick={() => setShowReport(true)}
          >
            Exportar Relatório
          </Button>
        </Grid>
      )}
      <GenerateReportModal
        open={showReport}
        onClose={() => setShowReport(false)}
      />
    </>
  );
}
