import { MoneyMask } from 'utils/masks';
import { GridColDef } from '@mui/x-data-grid';
import { ParseDateString } from 'utils/Functions';

export const OrderColumns = (
  orderNumber: number,
  index: number
): GridColDef[] => {
  return [
    {
      field: String(orderNumber),
      headerName: `Pedido ${index}`,
      valueGetter: ({ row }) => row[`order_${orderNumber}`]?.orderId || '-',
      cellClassName:
        'super-app-theme--order-cell super-app-theme--result-divisor'
    },
    {
      field: `order_${orderNumber}.quantity`,
      headerName: 'Volume',
      valueGetter: ({ row }) => row[`order_${orderNumber}`]?.quantity || '-',
      cellClassName: 'super-app-theme--order-cell'
    },
    {
      field: `${orderNumber}.total`,
      headerName: 'Total',
      valueGetter: ({ row }) => row[`order_${orderNumber}`]?.total,
      valueFormatter: ({ value }) =>
        value ? MoneyMask(String(value) || '') : '-',
      cellClassName: 'super-app-theme--order-cell'
    },
    {
      field: `${orderNumber}.createdAt`,
      headerName: 'Data do pedido',
      valueGetter: ({ row }) => row[`order_${orderNumber}`]?.createdAt,
      valueFormatter: ({ value }) =>
        value ? ParseDateString(String(value || '')) : '-',
      width: 150,
      cellClassName: 'super-app-theme--order-cell'
    }
  ];
};
