import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import React from 'react';
import { theme } from 'shared/theme';

type ContentType = {
  title: string;
  primaryText: string;
  secondaryText: string;
  buttonText: string;
};

export type DisableOrEnableModalProps = {
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
  type?: 'disable' | 'enable';
  content: {
    disable: ContentType;
    enable: ContentType;
  };
};

export function DisableOrEnableModal({
  open,
  onClose,
  onSubmit,
  type,
  content
}: DisableOrEnableModalProps) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Typography
          variant="h4"
          style={{
            fontWeight: theme.font.semiBold,
            color: theme.colors.TextDarkGrey
          }}
        >
          {type ? content[type]?.title : '-'}
        </Typography>
        <IconButton aria-label={'close'} onClick={onClose} size="large">
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container md={12} justifyContent="center" mb={2}>
          <Typography variant="h4">
            {type ? content[type]?.primaryText : '-'}
          </Typography>
        </Grid>
        <Grid container md={12}>
          <Typography variant="h4">
            {type ? content[type]?.secondaryText : '-'}
          </Typography>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          item
          container
          direction={'row'}
          justifyContent={'flex-end'}
          xs={12}
          spacing={2}
        >
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="outlined"
              onClick={onClose}
              color={'primary'}
            >
              Voltar
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="contained"
              color={'primary'}
              onClick={() => onSubmit()}
            >
              {type ? content[type]?.buttonText : '-'}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
