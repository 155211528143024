import React, { ChangeEvent, forwardRef } from 'react';

import { TextField, TextFieldProps } from '@mui/material';
import {
  GetPattern,
  PatternsTypes
} from 'shared/components/Inputs/Pattern/GetPattern';

type UITextFieldProps = {
  pattern?: PatternsTypes;
};

export type UIInputProps = UITextFieldProps & TextFieldProps;

export interface FormProps<THandle> {
  onComplete: (data: THandle) => void;
}

export const UITextField = forwardRef(function UITextField(
  {
    pattern,
    value,
    onChange,
    InputLabelProps,
    variant = 'standard',
    ...rest
  }: UIInputProps,
  ref
) {
  const innerValue =
    typeof value === 'string' && pattern
      ? GetPattern({ value, pattern })
      : value;

  const handleChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const targetValue = e.target.value;
    if (pattern) {
      const selectedPattern = GetPattern({ value: targetValue, pattern });
      e.target.value = selectedPattern || '';
    }

    return onChange && onChange(e);
  };

  return (
    <TextField
      value={innerValue}
      onChange={handleChange}
      variant={variant}
      {...rest}
      inputRef={ref}
      InputLabelProps={{
        ...InputLabelProps
      }}
    />
  );
});
