import { Button, Grid } from '@mui/material';
import { DistributorProcessItemProps } from 'modules/Process/PublicProcess/Forms/DistributorProcessForm/DistributorProcessItem/DistributorProcessItem';
import React from 'react';
import { toast } from 'react-toastify';
import { usePatch, usePost, useQuery } from 'services';
import { useDelete } from 'services/api/UseDelete';
import { ProcessModel, ProcessProductModel } from 'types/models';
import { PrivateProcessItemFormTable } from '../../Fragments/PrivateProcessItems';

export function PrivateProcessItems({
  id,
  onFail,
  onComplete
}: DistributorProcessItemProps) {
  const ProcessItemsQuery = useQuery<ProcessProductModel[]>(
    '/process-products',
    {
      $limit: -1,
      processId: id
    }
  );

  const [process] = useQuery<ProcessModel>(`/process/${id}`, {
    notInclude: ['institution', 'pendencies', 'process_products', 'employee']
  });

  const processItemsData = ProcessItemsQuery[0];
  const processItemsRefetch = ProcessItemsQuery[1];
  const processItemLoading = ProcessItemsQuery[2];

  const [, processItemPost] = usePost<
    Partial<ProcessProductModel>,
    Partial<ProcessProductModel>
  >('/process-products');

  const [, processItemPatch] =
    usePatch<Partial<ProcessProductModel>>('/process-products');

  const [, processItemDelete] =
    useDelete<Partial<ProcessProductModel>>('/process-products');

  /* POST function to save a process item */
  const handleAddProduct = async (data: Partial<ProcessProductModel>) => {
    if (id) {
      const newData = data;
      newData.processId = +id;
      const response = await processItemPost(newData);
      if (response) {
        toast.success(`Item cadastrado com sucesso`);
        await processItemsRefetch();
      }
    } else {
      toast.info(
        'Cadastre os dados basicos do processo antes de prosseguir com a adicao de produtos'
      );
      onFail();
    }
  };

  /* PATCH function to update a process item */
  const handleEditProduct = async (data: Partial<ProcessProductModel>) => {
    if (data.id) {
      await processItemPatch(data, data.id);
      await processItemsRefetch();
      toast.success(`Item editado com sucesso`);
    }
  };

  const handleComplete = () => {
    if (processItemsData && processItemsData.length > 0) {
      onComplete();
    } else {
      toast.warn(`Preencha ao menos um item para seguir para a próxima etapa.`);
    }
  };

  const handleDelete = async (itemId: number) => {
    try {
      await processItemDelete(itemId);
      toast.success(`Item excluído com sucesso.`);
      await processItemsRefetch();
    } catch (error) {
      toast.error(`Ocorreu um erro ao excluir item.`);
    }
  };

  return (
    <>
      <PrivateProcessItemFormTable
        onAddProduct={handleAddProduct}
        onEditProduct={handleEditProduct}
        onDelete={handleDelete}
        processItemData={processItemsData || []}
        loading={processItemLoading}
        processDirect={!!process?.processDirect}
        distributorUf={process?.distributor?.uf || ''}
        processId={process?.id || 1}
      />
      <Grid container item direction={'row-reverse'}>
        <Grid item xs={12} sm={2}>
          <Button
            type={'submit'}
            onClick={handleComplete}
            fullWidth
            variant={'contained'}
          >
            Avançar
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
