/* eslint-disable react-hooks/exhaustive-deps */
import { Box, CircularProgress, Grid, MenuItem, Popover } from '@mui/material';
import { usePopover } from 'hooks';
import React, { useEffect, useState } from 'react';
import history from 'routes/History';
import { usePatch, usePost, useQuery } from 'services';
import { UserCard } from 'shared/components/Cards/UserCard';
import { SearchAdmin } from 'shared/components/Header/Admin/SearchAdmin';
import { UIPagination } from 'shared/components/Pagination';
import { UITabs } from 'shared/components/Tabs';
import { UITab } from 'shared/components/Tabs/Tab';
import { AccessModel } from 'types/models';
import { Paginated } from 'types/services';
import useDebounce from 'hooks/useDebounce';
import { toast } from 'react-toastify';
import { UserStatusModel } from 'types/models/UserStatus/UserStatus.model';
import { ReturnUserModal } from './Modals/ReturnUser';
import { VacancyOrInactiveModal } from './Modals/VacancyOrInactive';

export function AllUsersFragments() {
  const actionPerPage = 12;
  const [page, setPage] = useState<number>(0);

  const [step, setStep] = useState('all');
  const [search, setSearch] = useState<string>('');

  const debouncedValue = useDebounce<string>(search, 500);

  const [, userStatusPatch] = usePatch<UserStatusModel>('/users-status');
  const [, userStatusPost, userStatusPostLoading] =
    usePost<UserStatusModel>('/users-status');

  const [, fetchUserStatus] = useQuery<Paginated<UserStatusModel>>(
    '/users-status',
    {
      fetchOnInitialize: false
    }
  );

  const [modalType, setModalType] = useState<{
    type: 'vacancy' | 'inactive' | 'active' | 'return vacancy';
    access: AccessModel;
  }>();

  const [consultantsActives, fetchConsultantsActives] = useQuery<
    Paginated<AccessModel>
  >('/access/admin', {
    $limit: -1,
    'employee[accessLevel]': 'consultant',
    status: 'active',
    $select: ['id', 'employeeId', 'email'],
    fetchOnInitialize: false
  });

  const [distributorActives, fetchDistributorActives] = useQuery<
    Paginated<AccessModel>
  >('/access/admin', {
    $limit: -1,
    status: 'active',
    $select: ['id', 'distributorId', 'email'],
    'distributorId[$ne]': 'null',
    fetchOnInitialize: false
  });

  const [accesses, refetch, loading] = useQuery<Paginated<AccessModel>>(
    '/access/admin',
    {
      $limit: 12,
      $skip: 0,
      'employee[accessLevel]':
        step !== 'all' && step !== 'distributor' && step !== 'inactive'
          ? step
          : null,
      'distributorId[$ne]': step === 'distributor' ? 'null' : null,
      'status[$ne]': step !== 'inactive' ? 'inactive' : null,
      status: step === 'inactive' ? 'inactive' : null,
      search
    }
  );

  const handleChangeStep = async (pos: string) => {
    setStep(pos);
    setPage(1);
  };

  useEffect(() => {
    refetch();
  }, [debouncedValue, step]);

  const { handleCloseToPopoverEl, popoverAnchorEl, handleClickToPopover } =
    usePopover();

  const handleEditUser = (access: AccessModel) => {
    if (access.distributor) {
      history.push(`/admin/usuario/distribuidor/editar/${access.id}`);
    } else if (access.employee) {
      history.push(`/admin/usuario/funcionario/editar/${access.id}`);
    }
  };

  const fetchUsersActives = (access: AccessModel) => {
    if (access.distributorId) {
      fetchDistributorActives();
    }
    if (access?.employeeId) {
      fetchConsultantsActives();
    }
  };

  const handleVacancy = (access: AccessModel) => {
    setModalType({
      access,
      type: 'return vacancy'
    });
  };

  const handleActive = (access: AccessModel) => {
    setModalType({
      access,
      type: 'vacancy'
    });
    fetchUsersActives(access);
  };

  const handleInactive = (access: AccessModel) => {
    setModalType({
      access,
      type: 'inactive'
    });
  };

  const contentActions = {
    active: {
      label: 'Inserir período de férias',
      onClick: handleActive
    },
    inactive: {
      label: 'Reativar usuário',
      onClick: handleInactive
    },
    vacancy: {
      label: 'Suspender período de férias',
      onClick: handleVacancy
    },
    default: {
      label: 'Usuário sem status.',
      onClick: () => {
        toast.error('Usuário sem status, algum erro aconteceu.');
      }
    }
  };

  const handleReturnUser = async (access: AccessModel) => {
    try {
      const userStatus = await fetchUserStatus({
        'status[$ne]': 'active',
        oldAccessId: access.id,
        $limit: 1,
        '$sort[createdAt]': -1
      });
      const userStatusId = userStatus?.data?.[0]?.id;
      if (userStatusId) {
        try {
          await userStatusPatch(
            {
              status: 'active'
            },
            userStatusId
          );
          toast.success('Usuário reativado com sucesso.');
        } catch (error) {
          toast.error('Erro ao atualizar o usuário.');
        }
      } else {
        toast.error(
          'Erro ao retornar usuário. Não foi possível encontrar o status anterior.'
        );
      }
    } catch (error) {
      toast.error('Erro ao retornar usuário.');
    }
    setModalType(undefined);
    refetch();
  };

  const handleInactiveOrVacancy = async (
    access: AccessModel,
    newAccess: AccessModel,
    status: string,
    period?: number
  ) => {
    try {
      await userStatusPost({
        status,
        oldAccessId: access.id, // Access Id of who is gonna be inactivated
        accessId: newAccess.id,
        period // Access Id of who is gonna get the inactivated's processes
      });
      if (status === 'vacancy')
        toast.success(`O Usuário ${access.email} está em férias.`);
      else toast.success(`O Usuário ${access.email} está suspenso.`);
    } catch (error) {
      if (status === 'vacancy')
        toast.error('Erro ao inserir período de férias.');
      else toast.error('Erro ao inativar usuário.');
    }
    setModalType(undefined);
    refetch();
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box mb={2}>
            <SearchAdmin
              title={'Usuários'}
              buttonText={'Adicionar usuários'}
              onButtonClick={handleClickToPopover}
              searchPlaceholder={'Pesquisar usuários'}
              handleChangeSearch={(t) => setSearch(t?.currentTarget?.value)}
            />
          </Box>
        </Grid>
        <Popover
          open={!!popoverAnchorEl}
          anchorEl={popoverAnchorEl}
          onClose={handleCloseToPopoverEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          <MenuItem
            onClick={() => history.push('/admin/usuario/funcionario/adicionar')}
          >
            Usuário Interno
          </MenuItem>
          <MenuItem
            onClick={() =>
              history.push('/admin/usuario/distribuidor/adicionar')
            }
          >
            Distribuidor
          </MenuItem>
        </Popover>
        <Grid item xs={12}>
          <UITabs
            value={step}
            onChange={(_, pos) => handleChangeStep(pos)}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
          >
            <UITab value="all" label={'Todos'} />
            <UITab value="distributor" label={'Distribuidor'} />
            <UITab value="consultant" label={'Consultor'} />
            <UITab value="supervisor" label={'Supervisor'} />
            <UITab value="manager" label={'Gerente'} />
            <UITab value="financier" label={'Financeiro'} />
            <UITab value="admin" label={'Administrador'} />
            <Box flex="1" />
            <UITab value="inactive" label={'Inativo'} />
          </UITabs>
        </Grid>
        {loading ? (
          <Grid
            item
            xs={12}
            height="300px"
            justifyContent="center"
            alignItems="center"
            container
          >
            <CircularProgress />
          </Grid>
        ) : (
          accesses &&
          accesses.data?.map((access) => (
            <Grid item xs={12} sm={6} md={4} container>
              <UserCard
                access={access}
                pencilClick={handleEditUser}
                actions={[
                  {
                    label: contentActions[access?.status || 'default'].label,
                    onClick: contentActions[access?.status || 'default'].onClick
                  }
                ]}
                deleteClick={() => {
                  setModalType({
                    access,
                    type: 'active'
                  });
                  fetchUsersActives(access);
                }}
              />
            </Grid>
          ))
        )}

        {loading ? (
          <></>
        ) : (
          <Grid item container justifyContent={'flex-end'} mb={5}>
            <UIPagination
              count={Math.ceil((accesses?.total || 0) / actionPerPage)}
              onChange={(e, newPage) => {
                setPage(newPage - 1);
                refetch({
                  $skip: actionPerPage * (newPage - 1)
                });
              }}
              itemSize="medium"
              page={page + 1}
            />
          </Grid>
        )}
      </Grid>
      <ReturnUserModal
        onClose={() => setModalType(undefined)}
        onSubmit={() => handleReturnUser(modalType?.access || {})}
        open={['return vacancy', 'inactive'].includes(modalType?.type || '')}
        type={modalType?.type ?? ''}
        access={modalType?.access || {}}
      />
      <VacancyOrInactiveModal
        onClose={() => setModalType(undefined)}
        access={modalType?.access || {}}
        type={modalType?.type ?? ''}
        open={['vacancy', 'active'].includes(modalType?.type || '')}
        onSubmit={(newAccess: AccessModel, period?: number) =>
          handleInactiveOrVacancy(
            modalType?.access || {},
            newAccess,
            modalType?.type === 'vacancy' ? 'vacancy' : 'inactive',
            period
          )
        }
        newAccesses={(
          (modalType?.access?.distributorId
            ? distributorActives?.data
            : consultantsActives?.data) || []
        )?.filter((newAccess) => newAccess.id !== modalType?.access?.id)}
        loading={userStatusPostLoading}
      />
    </>
  );
}
