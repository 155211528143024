import { Container } from '@mui/material';
import { PoliticalPricesFragment } from 'modules/Admin/Products/Fragments/PoliticalPricesFragment';
import { ProductFormFragment } from 'modules/Admin/Products/Fragments/ProductFormFragment';
import { ProductBreadcrumb } from 'modules/Admin/Products/Fragments/ProductFormFragment/BreadcrumbProduct';
import { ProductAttachments } from 'modules/Admin/Products/Fragments/ProductFormFragment/ProductAttachments';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'services';
import TitleWithSubtitle from 'shared/components/TitleWithSubtitle';
import { PoliticalPricesModel, ProductsModel } from 'types/models';
import { UrlParams } from 'types/services';

export function ProductCreateUpdatePage() {
  const params = useParams() as UrlParams;
  const id = params?.id;

  const [product] = useQuery<ProductsModel, ProductsModel>(`/products/${id}`, {
    fetchOnInitialize: !!+id,
    notInclude: ['political_prices', 'political_prices']
  });

  const [politicalPrices, refetchPoliticalPrices] = useQuery<
    PoliticalPricesModel[],
    PoliticalPricesModel
  >(`/political-prices/`, {
    fetchOnInitialize: !!+id,
    notInclude: ['product', 'product'],
    productId: id,
    'type[$ne]': 'null',
    'uf[$ne]': 'null',
    $limit: -1
  });

  return (
    <Container maxWidth={'lg'}>
      <ProductBreadcrumb edit={!!id} />
      <TitleWithSubtitle
        title="Produtos"
        subtitle="Preencha as informações do produto e sua política comercial"
      />
      <ProductFormFragment product={product} id={+id} />
      <PoliticalPricesFragment
        id={+id}
        politicalPrices={politicalPrices || []}
        refetchPoliticalPrices={refetchPoliticalPrices}
      />
      <ProductAttachments id={+id} />
    </Container>
  );
}
