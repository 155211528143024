/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Box, Button, Grid } from '@mui/material';
import { DeleteAttachmentModal } from 'modules/Process/History/Fragments/UploadSection/List/DeleteModal';
import React, { useEffect, useState } from 'react';
import { UseQueryReturn } from 'services';
import UIUploadAddSection from 'shared/components/Upload/UploadAddSection';
import UploadList from 'shared/components/Upload/UploadList';
import { useUploadAdd } from 'shared/hooks/UseUploadAdd';
import { useUploadList } from 'shared/hooks/UseUploadList';
import { AttachmentModel } from 'types/models/Attachments/Attachment.model';
import { ProcessDocuments } from 'utils/Constants/DocumentTypes/ProcessDocuments';

type Props = {
  attachmentsQuery: UseQueryReturn<AttachmentModel[], AttachmentModel>;
  accessId?: number;
  processId?: number;
  onComplete: () => void;
};
export default function DistributorProcessAttachments({
  attachmentsQuery,
  accessId,
  processId,
  onComplete
}: Props) {
  const [needOcr, setNeedOcr] = useState<boolean>(false);
  useEffect(() => {
    attachmentsQuery[1]();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    downloadAttachment,
    findLoading,
    onClickDelete,
    deleteModal,
    setDeleteModal,
    onDelete
  } = useUploadList(attachmentsQuery[1]);

  const {
    buttonDisabled,
    uploadAttachment,
    handleFile,
    attachmentLoadingAdd,
    file,
    setCategory,
    category
  } = useUploadAdd(attachmentsQuery[1], {
    accessId,
    table: 'process',
    tableId: processId,
    ocr: needOcr,
    putInHeaders: needOcr
  });

  return (
    <>
      <Grid container spacing={4} mt={1}>
        <Grid container item md={6} xs={12}>
          <Grid item xs={12}>
            <UploadList
              height="100%"
              title="Seus Documentos Anexados"
              items={
                attachmentsQuery[0]?.map((attachment) => ({
                  name: attachment.name || '-',
                  category: attachment.category || '-',
                  size: attachment.size || '-',
                  onDownload: () => downloadAttachment(attachment.id),
                  loading: findLoading(attachment.id!),
                  onDelete: () => onClickDelete(attachment.id!)
                })) || []
              }
              loading={attachmentsQuery[3]}
            />
          </Grid>
        </Grid>
        <Box mt={4} />
        <Grid item md={6} xs={12}>
          <UIUploadAddSection
            title="Anexos"
            buttonDisabled={buttonDisabled}
            buttonOnClick={uploadAttachment}
            handleFile={handleFile}
            loading={attachmentLoadingAdd}
            selectedFilename={file?.name || ' '}
            selectLabel="Documento"
            selectOnChange={({ target }) => {
              setCategory(target.value);
              if (target.value === 'Edital') setNeedOcr(true);
              else setNeedOcr(false);
            }}
            selectOptions={ProcessDocuments}
            selectValue={category}
          />
        </Grid>
      </Grid>
      <Grid container item direction={'row-reverse'} mt={2}>
        <Grid item xs={12} sm={2}>
          <Button
            type={'submit'}
            onClick={onComplete}
            fullWidth
            variant={'contained'}
          >
            Avançar
          </Button>
        </Grid>
      </Grid>
      <Box mt={5} />
      <DeleteAttachmentModal
        open={deleteModal.open}
        setOpen={setDeleteModal}
        onDelete={onDelete}
      />
    </>
  );
}
