import { useAuth } from 'Providers/Auth';
import React from 'react';
import { UseQueryReturn } from 'services';
import UIPaper from 'shared/components/Paper';
import { AttachmentModel } from 'types/models/Attachments/Attachment.model';
import { PrivateProcessAttachments } from './PrivateProcessAttachments';
import { PrivateProcessItems } from './PrivateProcessItems';
import { PrivateProcessProposalAnalysis } from './PrivateProcessProposalAnalysis';
import { PrivateProcessProposalSummary } from './PrivateProcessProposalSummary';

interface UrlParamsProps {
  id?: string;
  step: number;
  handleChangeStep: (step: number) => void;
  attachmentsQuery: UseQueryReturn<AttachmentModel[], AttachmentModel>;
}
/* Wrapper all private process forms into one single component to render  */
export function PrivateProcessForms({
  id,
  step,
  handleChangeStep,
  attachmentsQuery
}: UrlParamsProps) {
  const { auth } = useAuth();

  return (
    <>
      {step === 0 && auth?.employeeId && (
        <UIPaper title="Informações do processo">
          <PrivateProcessProposalSummary
            employeeId={auth?.employeeId}
            onComplete={() => handleChangeStep(1)}
            onFail={() => handleChangeStep(0)}
            id={id}
          />
        </UIPaper>
      )}

      {id && step === 1 && (
        <UIPaper title="Análise Da Proposta">
          <PrivateProcessProposalAnalysis
            onComplete={() => handleChangeStep(2)}
            onFail={() => handleChangeStep(1)}
            id={id}
          />
        </UIPaper>
      )}

      {id && step === 2 && (
        <PrivateProcessItems
          id={id}
          onComplete={() => handleChangeStep(3)}
          onFail={() => handleChangeStep(2)}
        />
      )}

      {id && step === 3 && (
        <PrivateProcessAttachments
          attachmentsQuery={attachmentsQuery}
          id={id}
        />
      )}
    </>
  );
}
