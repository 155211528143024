import { Divider, Typography, Box, BoxProps } from '@mui/material';
import React from 'react';
import { theme } from 'shared/theme';

type UIPaperProps = {
  title: string;
  children: React.ReactElement;
  onClickOnTitle?: () => void;
  paddingChildren?: number;
} & BoxProps;

export default function UIPaper({
  title,
  children,
  onClickOnTitle,
  paddingChildren = 2,
  ...rest
}: UIPaperProps) {
  return (
    <Box mt={2} bgcolor={theme.colors.White} borderRadius="5px" {...rest}>
      {title && (
        <>
          <Box
            pt={2}
            pb={2}
            pl={2}
            alignItems="center"
            onClick={() => (onClickOnTitle ? onClickOnTitle() : null)}
          >
            <Typography
              variant="h3"
              style={{
                color: theme.colors.TextDarkGrey,
                fontWeight: theme.font.semiBold
              }}
            >
              {title}
            </Typography>
          </Box>
          <Box pb={1}>
            <Divider />
          </Box>
        </>
      )}
      {children && (
        <Box bgcolor={'white'} padding={paddingChildren}>
          {children}
        </Box>
      )}
    </Box>
  );
}
