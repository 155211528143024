import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ForgotPasswordForm } from 'modules/Login/Forms/ForgotPasswordForm';
import { ForgotPasswordDomainResponse } from 'modules/Login/Forms/ForgotPasswordForm/domain';

interface ForgotPasswordModalParams extends DialogProps {
  email?: string;
  onHandleComplete: (email: ForgotPasswordDomainResponse) => void;
}

export function ForgotPasswordModal({
  email,
  onClose,
  onHandleComplete,
  ...rest
}: ForgotPasswordModalParams) {
  const handleCompleteModal = (formResponse: ForgotPasswordDomainResponse) => {
    onHandleComplete(formResponse);
  };

  return (
    <Dialog maxWidth={'xs'} {...rest} onClose={onClose} fullWidth>
      <DialogTitle>
        <Typography variant="h3" align={'center'}>
          Esqueci minha senha
        </Typography>
        {onClose && (
          <IconButton
            aria-label={'close'}
            onClick={(event) => onClose(event, 'escapeKeyDown')}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent dividers>
        <Box pt={2} pb={2}>
          <ForgotPasswordForm
            handleResponse={handleCompleteModal}
            initialValue={email}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Grid
          item
          container
          direction={'row'}
          justifyContent={'flex-end'}
          xs={12}
        >
          <Grid item xs={3}>
            {onClose && (
              <Button
                variant="outlined"
                color={'primary'}
                onClick={(e) => onClose(e, 'escapeKeyDown')}
              >
                Cancelar
              </Button>
            )}
          </Grid>
          <Grid item xs={4}>
            <Button
              form={'ForgotPasswordForm'}
              variant="outlined"
              color={'primary'}
              type={'submit'}
            >
              Enviar email
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
