/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Skeleton } from '@mui/material';
import { DisableOrEnableModal } from 'modules/Admin/shared/Modals/DisableOrEnableModal';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { usePatch, useQuery } from 'services';
import { SearchAdmin } from 'shared/components/Header/Admin/SearchAdmin';
import { UIPagination } from 'shared/components/Pagination';
import { DistributorModel } from 'types/models';
import { ClientCard } from './ClientCard';
import { ListClientsTab } from './ListClientsTab';

const itemsPerPage = 12;

export function ListClients() {
  const history = useHistory();

  const [search, setSearch] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [type, setType] = useState<string>('all');
  const [disableOrEnableDistributor, setDisableOrEnableDistributor] = useState<{
    type: 'disable' | 'enable';
    distributorId?: number;
  }>();
  const [, patchDistributor] = usePatch<DistributorModel>('/distributor');

  const [distributors, refetch, loading] = useQuery<
    DistributorModel[],
    DistributorModel
  >('/distributor', {
    $limit: -1,
    $select: ['email', 'name', 'city', 'uf', 'id', 'type', 'sold', 'disabled']
  });

  const GetFilteredDistributors = () => {
    const filterNotDisabled = (distributor: DistributorModel) =>
      !distributor.disabled;
    const filtDistributors: DistributorModel[] = [];
    if (distributors) {
      if (type === 'all') {
        filtDistributors.push(...distributors.filter(filterNotDisabled));
      } else if (type === 'inactive') {
        filtDistributors.push(
          ...distributors?.filter((distributor) => distributor.disabled)
        );
      } else {
        filtDistributors.push(
          ...distributors
            ?.filter((product) => product.type === type)
            ?.filter(filterNotDisabled)
        );
      }
    }

    return filtDistributors.filter((distributor) => {
      // TO-DO: Sounds good to me right now, but in the future a refact is gonna be nice.
      if (search) {
        let conditional = !!distributor.name
          ?.toUpperCase()
          ?.match(search?.toUpperCase());
        conditional =
          conditional ||
          !!distributor.city?.toUpperCase()?.match(search?.toUpperCase());
        conditional =
          conditional ||
          !!distributor.uf?.toUpperCase()?.match(search?.toUpperCase());
        conditional =
          conditional ||
          !!distributor.segment?.toUpperCase()?.match(search?.toUpperCase());
        conditional =
          conditional ||
          !!distributor.cnpj?.toUpperCase()?.match(search?.toUpperCase());
        conditional =
          conditional ||
          !!distributor.sold?.toUpperCase()?.match(search?.toUpperCase());
        return conditional;
      }
      return true;
    });
  };

  const filteredDistributors = GetFilteredDistributors() || [];
  const total = Math.ceil((filteredDistributors?.length || 0) / itemsPerPage);
  const cutDistributors = filteredDistributors.slice(
    page * itemsPerPage,
    page * itemsPerPage + 12
  );

  const handleInactivate = async () => {
    if (disableOrEnableDistributor?.distributorId) {
      await patchDistributor(
        {
          disabled: disableOrEnableDistributor?.type === 'disable'
        },
        disableOrEnableDistributor?.distributorId
      );
      setDisableOrEnableDistributor(undefined);
      toast.success('Atualização feita com sucesso');
      refetch();
    }
  };

  return (
    <>
      <Grid container spacing={2} mb={3}>
        <Grid item xs={12}>
          <Box mb={2}>
            <SearchAdmin
              title={'Clientes'}
              buttonText={'Adicionar cliente'}
              onButtonClick={() => history.push('/clientes/cadastro')}
              searchPlaceholder={'Pesquisar clientes'}
              handleChangeSearch={(t) => {
                setSearch(t.target.value);
                if (page !== 0) setPage(0);
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ListClientsTab
          onChange={(step) => {
            setType(step);
            setPage(0);
          }}
        />
      </Grid>
      <Grid container mb={4} mt={3}>
        <Grid item container xs={12} spacing={3}>
          {loading ? (
            Array.from(new Array(12)).map(() => (
              <Grid item xs={12} md={4}>
                <Skeleton
                  variant="rectangular"
                  width={'100%'}
                  height={'131px'}
                  animation="wave"
                />
              </Grid>
            ))
          ) : (
            <Grid item container spacing={3}>
              {(cutDistributors || []).map((distributor: DistributorModel) => (
                <ClientCard
                  distributor={distributor}
                  key={distributor.id}
                  onDelete={() =>
                    setDisableOrEnableDistributor({
                      distributorId: distributor.id,
                      type: type === 'inactive' ? 'enable' : 'disable'
                    })
                  }
                  onEdit={() => {
                    history.push(`/clientes/editar/${distributor.id}`);
                  }}
                />
              ))}
              <Grid item container justifyContent={'flex-end'}>
                <UIPagination
                  count={total}
                  onChange={(_, newPage) => setPage(newPage - 1)}
                  itemSize="medium"
                  page={page + 1}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <DisableOrEnableModal
        content={{
          disable: {
            title: 'Desativar Cliente',
            primaryText: 'Deseja desativar o cliente?',
            secondaryText:
              'O Cliente não poderá ser mais usado em futuros processos.',
            buttonText: 'Inativar'
          },
          enable: {
            title: 'Reativar Produto',
            primaryText: 'Deseja reativar o cliente?',
            secondaryText:
              'O Cliente poderá ser usado novamente em futuros processos.',
            buttonText: 'Reativar'
          }
        }}
        open={!!disableOrEnableDistributor}
        onClose={() => setDisableOrEnableDistributor(undefined)}
        onSubmit={handleInactivate}
        type={disableOrEnableDistributor?.type}
      />
    </>
  );
}
