import { Button, Grid, Typography } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';

type HeaderActionsProps = {
  quantity: number;
};

export function HeaderActions({ quantity }: HeaderActionsProps) {
  const history = useHistory();

  return (
    <Grid container item justifyContent="space-between" alignItems="center">
      <Grid container item xs alignItems="center">
        <Typography
          variant="h2"
          style={{ fontSize: '16px', marginRight: '15px' }}
        >
          Ações a serem executadas
        </Typography>
        <Typography
          variant="h3"
          style={{ fontSize: '12px', marginRight: '15px' }}
        >
          {quantity} {quantity === 1 ? 'ação' : 'ações'}
        </Typography>
      </Grid>
      <Button
        onClick={() => {
          history.push('/pendencias');
        }}
      >
        Ver todas
      </Button>
    </Grid>
  );
}
