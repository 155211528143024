import React, { useState } from 'react';
import {
  Avatar,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import BeachIcon from '@mui/icons-material/BeachAccessRounded';
import { UserCardProps } from 'shared/components/Cards/UserCard/UserCard';
import { MoneyMask } from 'utils/masks';
import PT from 'utils/lang';
import { GetDateFormat } from 'utils/Functions/FormatDate';
import { theme } from 'shared/theme';
import { GetAccessLevelColor } from 'utils/Functions/GetLang';

export function UserCard({
  deleteClick,
  pencilClick,
  access,
  actions
}: UserCardProps) {
  const handleEditClick = () => {
    return pencilClick && pencilClick(access);
  };
  const handleDeleteClick = () => {
    return deleteClick && deleteClick(access);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Paper sx={{ width: '100%', height: 220 }} elevation={1}>
      <Stack divider={<Divider />}>
        <Grid item container>
          <Grid item xs={2} pl={1} pt={1} pb={2}>
            <Avatar
              sx={{ width: 60, height: 60 }}
              src={access?.profilePicture}
            />
          </Grid>
          <Grid item container direction={'column'} xs={6} pl={2} pt={1} pb={2}>
            <Grid item>
              <Typography
                variant={'h4'}
                component={'label'}
                style={{
                  color: GetAccessLevelColor(
                    access?.employee?.accessLevel || ''
                  ),
                  fontWeight: theme.font.semiBold
                }}
              >
                {PT[access?.employee?.accessLevel || ''] || 'Distribuidor'}
              </Typography>
              {access?.status === 'vacancy' ? (
                <Tooltip title="Consultor em férias">
                  <IconButton>
                    <BeachIcon fontSize="small" color={'warning'} />
                  </IconButton>
                </Tooltip>
              ) : null}
            </Grid>
            <Tooltip title={access.email || ''}>
              <Grid
                item
                sx={{
                  width: '184px',
                  height: '24px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden'
                }}
              >
                <Typography
                  variant={'h4'}
                  component={'label'}
                  style={{
                    color: theme.colors.TextDarkGrey,
                    fontWeight: theme.font.semiBold
                  }}
                >
                  {access.email}
                </Typography>
              </Grid>
            </Tooltip>
            <Tooltip
              title={access?.employee?.name || access?.distributor?.name || ''}
            >
              <Grid
                item
                sx={{
                  width: '184px',
                  height: '24px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden'
                }}
              >
                <Typography
                  variant={'h4'}
                  component={'label'}
                  style={{
                    color: theme.colors.TextDarkGrey,
                    fontWeight: theme.font.normal
                  }}
                >
                  {access?.employee?.name || access?.distributor?.name}
                </Typography>
              </Grid>
            </Tooltip>
          </Grid>
          <Grid
            container
            item
            xs={4}
            justifyContent="flex-end"
            alignItems="flex-start"
          >
            <IconButton onClick={handleEditClick}>
              <CreateIcon color={'primary'} />
            </IconButton>
            {(access?.employee?.accessLevel === 'consultant' ||
              !!access?.distributorId) &&
            access?.status === 'active' ? (
              <IconButton onClick={handleDeleteClick}>
                <DeleteForeverIcon color={'error'} />
              </IconButton>
            ) : null}
            {access?.employee?.accessLevel === 'consultant' ? (
              <IconButton onClick={handleClick}>
                <MoreVertIcon />
              </IconButton>
            ) : null}
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {actions?.map(({ label, onClick }) => (
                <MenuItem onClick={() => onClick(access)} key={`${label}`}>
                  {label}
                </MenuItem>
              ))}
            </Menu>
          </Grid>
        </Grid>

        <Grid container p={2} spacing={2} justifyContent={'center'}>
          <Grid item xs={3}>
            <Typography variant={'h5'} textAlign={'center'}>
              Processos em aberto
            </Typography>
            <Typography variant={'h3'} color={'primary'} textAlign={'center'}>
              {access?.balance?.openProcesses || 0}
            </Typography>
          </Grid>
          <Divider orientation={'vertical'} flexItem sx={{ mt: 1, pl: 2 }} />
          <Grid item xs={4}>
            <Typography variant={'h5'} textAlign={'center'}>
              Saldo em aberto
            </Typography>
            <Typography
              variant={'h3'}
              color={'primary'}
              textAlign={'center'}
              sx={{ mt: 1 }}
            >
              {MoneyMask(access?.balance?.toInvoiceBalance || 0)}
            </Typography>
          </Grid>
          <Divider orientation={'vertical'} flexItem sx={{ mt: 1, pl: 2 }} />
          <Grid item xs={3}>
            <Typography variant={'h5'} textAlign={'center'}>
              Total de ganhos
            </Typography>
            <Typography variant={'h3'} color={'primary'} textAlign={'center'}>
              {access?.balance?.winnerProcess || 0}
            </Typography>
          </Grid>
        </Grid>

        <Grid item container spacing={2} justifyContent={'center'}>
          <Grid item>
            <Typography variant={'h5'} component={'label'} color={'lightgrey'}>
              Ultima interação:
            </Typography>
            <Typography component={'span'} variant={'h5'}>
              {GetDateFormat(access?.lastSeen)}
            </Typography>
          </Grid>
        </Grid>
      </Stack>
    </Paper>
  );
}
