import { Grid } from '@mui/material';
import React from 'react';
import { usePatch, usePost } from 'services';
import UIPaper from 'shared/components/Paper';
import { InstitutionModel } from 'types/models';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { HospitalInstitutionForm } from '../../Forms/InstitutionForm/Hospital';
import { GovernmentInstitutionForm } from '../../Forms/InstitutionForm/Goverment';

type Props = {
  institution?: InstitutionModel;
  id?: number;
  type: 'hospital' | 'government';
};

export function InstitutionFormFragment({ institution, id, type }: Props) {
  const history = useHistory();
  const [, patchInstitution, patchLoading] =
    usePatch<InstitutionModel>('/institution');
  const [, postInstitution, postLoading] = usePost<
    InstitutionModel,
    InstitutionModel
  >('/institution');

  const handleEdit = (data: InstitutionModel) => {
    try {
      patchInstitution(
        {
          ...data
        },
        id
      );
      toast.success('Informações atualizadas com sucesso');
    } catch (error) {
      toast.error(
        'Falha ao atualizar a instituição, tente novamente mais tarde'
      );
    }
  };

  const handleCreate = async (data: InstitutionModel) => {
    try {
      const productAdded = await postInstitution({
        ...data
      });
      if (productAdded?.id) {
        toast.success('Instituição cadastrado com sucesso.');
        history.push(`/instituicao`);
      } else {
        toast.error(
          'Falha ao cadastrar o instituição, tente novamente mais tarde'
        );
      }
    } catch (error) {
      toast.error(
        'Falha ao cadastrar o instituição, tente novamente mais tarde'
      );
    }
  };

  const handleCompleteInstitution = (data: InstitutionModel) => {
    if (id) {
      handleEdit(data);
    } else {
      handleCreate(data);
    }
  };

  return (
    <UIPaper title="Informações da instituição">
      <Grid item xs={12} mb={2}>
        {type === 'hospital' ? (
          <HospitalInstitutionForm
            onComplete={handleCompleteInstitution}
            institution={institution}
            loading={patchLoading || postLoading}
          />
        ) : null}
        {type === 'government' ? (
          <GovernmentInstitutionForm
            onComplete={handleCompleteInstitution}
            institution={institution}
            loading={patchLoading || postLoading}
          />
        ) : null}
      </Grid>
    </UIPaper>
  );
}
