/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@mui/material';
import React from 'react';
import { useQuery } from 'services';
import { DistributorPricesModel, ProductsModel } from 'types/models';
import { DistributorPriceForm } from './DistributorPriceForm';
import DistributorPricesTable from './DistributorPricesTable';

export interface Props {
  id: number;
}

export function ClientPricesFragment({ id }: Props) {
  const [products, , productsLoading] = useQuery<ProductsModel[]>('/products', {
    $limit: -1
  });

  const [distributorPrices, distributorPricesFetch, distributorPricesLoading] =
    useQuery<DistributorPricesModel[], DistributorPricesModel>(
      '/distributor-prices',
      {
        $limit: -1,
        distributorId: id
      }
    );

  return (
    <Grid container item xs={12} mb={4} spacing={2} mt={2} alignItems="center">
      <DistributorPricesTable
        products={products || []}
        distributorPrices={distributorPrices || []}
        loading={productsLoading || distributorPricesLoading}
      />
      <DistributorPriceForm
        products={products || []}
        distributorPrices={distributorPrices || []}
        distributorId={id}
        refetch={distributorPricesFetch}
      />
      {/* <SyncEmployeeForm
        cities={cities || []}
        citiesEmployees={citiesEmployees || []}
        employeeId={access?.employee?.id}
        refetch={fetchCitiesEmployees}
      /> */}
    </Grid>
  );
}
