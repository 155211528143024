import { Menu, MenuItem, Typography } from '@mui/material';
import React from 'react';
import { theme } from 'shared/theme';
import { OpenModalProps } from '../../Domain';

interface ObservationMenuProps {
  canEdit: boolean;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  anchorEl: HTMLElement | null;
  setOpenModal: (openModal: OpenModalProps) => void;
}
export default function ObservationMenu({
  canEdit,
  anchorEl,
  setAnchorEl,
  setOpenModal
}: ObservationMenuProps) {
  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={!!anchorEl}
      onClose={() => setAnchorEl(null)}
    >
      <MenuItem
        onClick={() =>
          setOpenModal({
            open: true,
            type: 'create'
          })
        }
      >
        <Typography
          style={{
            fontWeight: theme.font.semiBold,
            color: theme.colors.TextDarkGrey,
            fontSize: theme.font.sizes.medium
          }}
        >
          Criar Comentário
        </Typography>
      </MenuItem>
      {canEdit && (
        <MenuItem
          onClick={() =>
            setOpenModal({
              open: true,
              type: 'update'
            })
          }
        >
          <Typography
            style={{
              fontWeight: theme.font.semiBold,
              color: theme.colors.TextDarkGrey,
              fontSize: theme.font.sizes.medium
            }}
          >
            Editar Comentário
          </Typography>
        </MenuItem>
      )}
      {canEdit && (
        <MenuItem
          onClick={() =>
            setOpenModal({
              open: true,
              type: 'delete'
            })
          }
        >
          <Typography
            style={{
              fontWeight: theme.font.semiBold,
              color: theme.colors.Error,
              fontSize: theme.font.sizes.medium
            }}
          >
            Excluir Comentário
          </Typography>
        </MenuItem>
      )}
    </Menu>
  );
}
