import styled from 'styled-components';
import { PdfRenderHtml } from 'shared/components/Pdf/HtmlRender/PdfHtmlRender';

export const RenderHtml = styled.object<PdfRenderHtml>`  
  height: {( height ) => height};
  width: 100%;
  
  div#toolbar {
    color: #b11ada !important;
  }
`;
