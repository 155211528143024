/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useQuery } from 'services';
import {
  AccessModel,
  Cities,
  CitiesEmployee,
  UfsRegionals
} from 'types/models';
import EmployeeCityTable from './EmployeeCityTable';
import { SyncEmployeeForm } from './SyncEmployeeForm';

export interface SyncEmployeeCityFragmentProps {
  access?: AccessModel;
}

export function SyncEmployeeCityFragment({
  access
}: SyncEmployeeCityFragmentProps) {
  const [cities, citiesFetch, citiesLoading] = useQuery<Cities[]>('/cities', {
    $limit: -1,
    fetchOnInitialize: false
  });

  const [citiesEmployees, citiesEmployeesFetch, citiesEmployeesLoading] =
    useQuery<CitiesEmployee[], CitiesEmployee>('/cities-employees', {
      $limit: -1,
      fetchOnInitialize: false
    });

  const [ufsRegionals, ufsRegionalsFetch, ufsRegionalsLoading] = useQuery<
    UfsRegionals[],
    UfsRegionals
  >('/ufs-regionals', {
    $limit: -1,
    fetchOnInitialize: false
  });

  const fetchCitiesEmployees = () => {
    citiesEmployeesFetch({
      employeeId: access?.employee?.id
    });
  };

  useEffect(() => {
    if (access?.employee?.accessLevel === 'consultant') {
      citiesFetch();
      ufsRegionalsFetch();
      fetchCitiesEmployees();
    }
  }, [access]);

  return (
    <Grid container item xs={12} mb={4} spacing={2} mt={2} alignItems="center">
      <EmployeeCityTable
        cities={cities || []}
        ufsRegionals={ufsRegionals || []}
        citiesEmployees={citiesEmployees || []}
        loading={citiesLoading || citiesEmployeesLoading || ufsRegionalsLoading}
        refetch={fetchCitiesEmployees}
      />
      <SyncEmployeeForm
        cities={cities || []}
        citiesEmployees={citiesEmployees || []}
        employeeId={access?.employee?.id}
        refetch={fetchCitiesEmployees}
      />
    </Grid>
  );
}
