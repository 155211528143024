/* eslint-disable react/no-array-index-key */
import { Button, Grid } from '@mui/material';
import DocumentImage from 'assets/images/documents.svg';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { GridContainer } from './styles';

type UploadAreaProps = {
  handleFile: (file: File) => void;
  label?: string;
};
export default function UploadArea({
  handleFile,
  label = 'Arraste e solte o arquivo pdf aqui ou'
}: UploadAreaProps) {
  const onDrop = useCallback((files: File[]) => {
    handleFile(files[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <GridContainer
      container
      item
      xs={12}
      justifyContent="center"
      alignItems="center"
      {...getRootProps()}
    >
      <Grid container item justifyContent="center" xs={12} sm={4}>
        <img src={DocumentImage} alt="Imagem de documentos" />
      </Grid>
      <Grid container item xs={12} sm={4} md={8}>
        <Grid container spacing={2} alignItems="center" flexDirection="column">
          <Grid item xs={6}>
            <input {...getInputProps()} />
            <p style={{ textAlign: 'center' }}>{label}</p>
          </Grid>
          <Grid item>
            <Button variant={'outlined'} color={'primary'}>
              Inserir arquivo
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </GridContainer>
  );
}
