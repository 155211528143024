/* eslint-disable @typescript-eslint/no-explicit-any */
import { ERRORS } from 'utils/Errors';
import { MoneyRemoveMask } from 'utils/masks';
import * as yup from 'yup';

export interface ResultProcessItemInputs {
  id?: number;
  productId?: number;
  initialValue: number;
  finalValue: number;
  justify: string;
  winner?: boolean;
  rivalInitialValue: number;
  rivalFinalValue: number;
  rivalProductId?: number | null;

  // WE NEED THIS TO CHANGE THE VALUES IN FEATHERS HOOK.
  nfPrice: number;
  institutionValue: number;
  priceList: number;
  markup: number;
}

export const ResultProcessItemSchema = yup.object({
  productId: yup.number().required().typeError(ERRORS.REQUIRED_FIELD),
  winner: yup
    .boolean()
    .required(ERRORS.REQUIRED_FIELD)
    .typeError(ERRORS.REQUIRED_FIELD),
  initialValue: yup
    .string()
    .min(1)
    .required(ERRORS.REQUIRED_FIELD)
    .typeError(ERRORS.REQUIRED_FIELD)
    .test('greaterThan', 'Insira um valor maior que 0', (val?: string) => {
      return val ? +MoneyRemoveMask(val) > 0 : false;
    })
    .test(
      'greaterThanNfPrice',
      'Insira um valor maior ou igual ao Preço NF Nestlé',
      (val?: string, form?: any) => {
        if (form?.parent) {
          const { nfPrice } = form?.parent;
          if (nfPrice) {
            return val ? +MoneyRemoveMask(val) >= +nfPrice?.toFixed(2) : false;
          }
        }
        return false;
      }
    ),
  finalValue: yup
    .string()
    .min(1)
    .required(ERRORS.REQUIRED_FIELD)
    .typeError(ERRORS.REQUIRED_FIELD)
    .test('greaterThan', 'Insira um valor maior que 0', (val?: string) => {
      return val ? +MoneyRemoveMask(val) > 0 : false;
    })
    .test(
      'greaterThanNfPrice',
      'Insira um valor maior ou igual ao Preço NF Nestlé',
      (val?: string, form?: any) => {
        if (form?.parent) {
          const { nfPrice } = form?.parent;
          if (nfPrice) {
            return val ? +MoneyRemoveMask(val) >= +nfPrice?.toFixed(2) : false;
          }
        }
        return false;
      }
    ),
  rivalProductId: yup
    .number()
    .optional()
    .nullable()
    .typeError(ERRORS.REQUIRED_FIELD),
  rivalFinalValue: yup
    .string()
    .optional()
    .nullable()
    .when('rivalProductId', {
      is: (hasRival: boolean) => {
        return !!hasRival;
      },
      then: yup
        .string()
        .required(ERRORS.REQUIRED_FIELD)
        .typeError(ERRORS.REQUIRED_FIELD)
        .test('greaterThan', 'Insira um valor maior que 0', (val?: string) => {
          return val ? +MoneyRemoveMask(val) > 0 : false;
        })
    }),
  rivalInitialValue: yup
    .string()
    .optional()
    .nullable()
    .when('rivalProductId', {
      is: (hasRival: number) => {
        return !!hasRival;
      },
      then: yup
        .string()
        .required(ERRORS.REQUIRED_FIELD)
        .typeError(ERRORS.REQUIRED_FIELD)
        .test('greaterThan', 'Insira um valor maior que 0', (val?: string) => {
          return val ? +MoneyRemoveMask(val) > 0 : false;
        })
    }),
  justify: yup
    .string()
    .optional()
    .nullable()
    .when('winner', {
      is: (hasRival: number) => {
        return !hasRival;
      },
      then: yup
        .string()
        .required(ERRORS.REQUIRED_FIELD)
        .typeError(ERRORS.REQUIRED_FIELD)
    })
});
