import { DatePicker } from '@mui/lab';
import {
  Autocomplete,
  Button,
  Chip,
  Grid,
  LinearProgress,
  MenuItem,
  TextField,
  createFilterOptions
} from '@mui/material';
import { useFormik } from 'formik';
import { SecretaryClassificationDomain } from 'modules/Process/Domain/SecretaryClassificationDomain';
import {
  ProcessBasicInformationDomainResponse,
  ProcessBasicInformationInput,
  ProcessBasicInformationSchema
} from 'modules/Process/PublicProcess/Forms/ProcessBasicInformation/domain';
import React from 'react';
import { useQuery } from 'services';
import { FormProps, UITextField } from 'shared/components/Inputs';
import { Cities, InstitutionModel, ProcessModel } from 'types/models/';
import { RequestDocuments } from 'utils/Constants/DocumentTypes/';
import { Segments } from 'utils/Enums';
import {
  ServicesChannels,
  ServicesChannelsEnum,
  SERVICES_CHANNELS_ARRAY
} from 'utils/Enums/ServicesChannels';
import { STATES_ARRAY } from 'utils/Enums/states';

export interface ProcessBasicInformationFormParams
  extends FormProps<ProcessBasicInformationDomainResponse> {
  disabled?: boolean;
  process?: Partial<ProcessModel>;
  loading?: boolean;
}

export function ProcessBasicInformationForm({
  disabled = false,
  process,
  onComplete,
  loading = false
}: ProcessBasicInformationFormParams) {
  const { values, errors, touched, handleChange, handleSubmit, setFieldValue } =
    useFormik<ProcessBasicInformationInput>({
      initialValues: {
        tradingCode: process?.tradingCode || '',
        segment: process?.segment ?? 'public',
        tradingDate: process?.tradingDate || new Date(),
        city: process?.city || '',
        uf: process?.uf || null,
        observation: process?.observation || '',
        requiredDocuments: process?.requiredDocuments || [],
        serviceChannel: process?.serviceChannel || '',
        employeeId: process?.employeeId || '',
        institutionId: process?.institutionId,
        secretaryClassification: process?.secretaryClassification,
        quota: process?.quota
      },
      validationSchema: ProcessBasicInformationSchema,
      onSubmit: (fields) => {
        const data = fields as ProcessBasicInformationDomainResponse;
        onComplete({ ...data });
      }
    });

  const citiesQuery = useQuery<Cities[]>('/cities', {
    $limit: -1,
    includeEmployee: true
  });

  const [institutionResponse, , institutionLoading] = useQuery<
    InstitutionModel[]
  >('/institution', {
    $limit: -1,
    disabled: 0
  });

  const citiesData = citiesQuery[0];
  const citiesLoading = citiesQuery[2];

  const cities = citiesData || [];
  const institutionData = institutionResponse || [];

  const selectedInstitution = institutionData.find(
    (institution) => institution.id === values.institutionId
  );

  const filterOptions = createFilterOptions<InstitutionModel>({
    limit: 30
  });

  const onInstitutionChange = (institution: InstitutionModel) => {
    if (institution?.id) {
      setFieldValue('institutionId', institution?.id);
      const findInstitution = institutionData.find(
        (inst) => inst?.id === institution?.id
      );
      setFieldValue('serviceChannel', findInstitution?.serviceChannel);
      setFieldValue('focusUf', !!findInstitution?.focus);
      if (findInstitution?.serviceChannel !== 'Programa governamental') {
        setFieldValue('secretaryClassification', null);
      }
    } else {
      setFieldValue('institutionId', undefined);
    }
  };

  const employees =
    cities.find((city) => city.uf === values.uf && city.city === values.city)
      ?.cities_employees || [];

  return citiesLoading || institutionLoading ? (
    <LinearProgress />
  ) : (
    <form onSubmit={handleSubmit}>
      <Grid container item xs={12} direction={'row'} spacing={2}>
        <Grid item xs={6} sm={4}>
          <Autocomplete
            renderInput={(props) => (
              <TextField
                {...props}
                value={values.uf}
                label={'Estado'}
                error={!!errors.uf && touched.uf}
                helperText={errors.uf}
              />
            )}
            options={STATES_ARRAY}
            value={
              values.uf
                ? STATES_ARRAY.find((option) => option.uf === values.uf)
                : null
            }
            getOptionLabel={(option) => option.state}
            onChange={(event, value) => {
              if (value && value.uf) {
                setFieldValue('uf', value.uf);
              } else {
                setFieldValue('uf', null);
              }
            }}
            fullWidth
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <Autocomplete
            renderInput={(props) => (
              <TextField
                {...props}
                label={'Cidade'}
                error={!!errors.city}
                helperText={errors.city}
              />
            )}
            options={cities.filter((city) => city.uf === values.uf)}
            value={
              values.city
                ? cities.find((option) => option.city === values.city)
                : null
            }
            getOptionLabel={(option) => option.city}
            onChange={(_, value) => {
              _.preventDefault();
              if (value && value.city) {
                setFieldValue('city', value.city);
                const findNewEmployees =
                  cities.find(
                    (city) => city.uf === values.uf && city.city === value.city
                  )?.cities_employees || [];
                const employeeNotInactive =
                  findNewEmployees.filter(
                    (cityEmployee) =>
                      cityEmployee.employee?.access?.status !== 'inactive'
                  ) || [];
                setFieldValue(
                  'employeeId',
                  employeeNotInactive?.[0]?.employeeId
                );
              } else {
                setFieldValue('city', null);
                setFieldValue('employeeId', null);
              }
            }}
            fullWidth
            disabled={disabled}
            loading
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            name={'employeeId'}
            value={values.employeeId || ''}
            label={'Consultor'}
            fullWidth
            select
            error={!!errors.employeeId}
            helperText={errors.employeeId}
            onChange={handleChange}
            disabled={disabled}
          >
            {employees
              ?.filter(
                (cityEmployee) =>
                  cityEmployee.employee?.access?.status !== 'inactive'
              )
              .map((cityEmployee) => (
                <MenuItem
                  key={cityEmployee.cityId}
                  value={cityEmployee.employeeId}
                >
                  {cityEmployee.employee?.name}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            value={values.tradingDate}
            onChange={(date) => {
              setFieldValue('tradingDate', date);
            }}
            clearable
            label={'Data de abertura'}
            renderInput={(props) => (
              <TextField
                {...props}
                name={'tradingDate'}
                placeholder="10/10/2018"
                error={!!errors.tradingDate}
                helperText={errors.tradingDate}
              />
            )}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name={'tradingCode'}
            value={values.tradingCode}
            onChange={handleChange}
            label={'Número do pregão'}
            error={!!errors.tradingCode}
            helperText={errors.tradingCode}
            fullWidth
            disabled={disabled}
            defaultValue={process?.tradingCode}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name={'segment'}
            value={values.segment}
            label={'Segmento'}
            fullWidth
            select
            error={!!errors.segment}
            helperText={errors.segment}
            onChange={handleChange}
            disabled={disabled}
          >
            {Object.keys(Segments).map((key) => (
              <MenuItem key={key} value={key}>
                {Segments[key]}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={'Cota'}
            name={'quota'}
            value={values.quota}
            error={!!errors.quota}
            helperText={errors.quota}
            disabled={disabled}
            onChange={handleChange}
            select
            fullWidth
          >
            <MenuItem value={'ampla'}> Ampla </MenuItem>
            <MenuItem value={'reservada'}> Reservada </MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            options={institutionData}
            disabled={disabled}
            value={selectedInstitution || null}
            getOptionLabel={(opt) => `${opt?.name} - ${opt?.uf}` || ''}
            renderInput={(params) => (
              <UITextField
                value={selectedInstitution?.name || ''}
                label={'Nome do Órgão Público/ Inst.'}
                error={!!errors.institutionId}
                helperText={errors.institutionId}
                {...params}
              />
            )}
            onChange={(event, value) =>
              onInstitutionChange(value as InstitutionModel)
            }
            filterOptions={filterOptions}
          />
        </Grid>
        <Grid item xs={6}>
          <UITextField
            label={'Canal de atendimento'}
            name={'serviceChannel'}
            value={values.serviceChannel}
            fullWidth
            select
            disabled
          >
            {SERVICES_CHANNELS_ARRAY.map(({ key }) => (
              <MenuItem
                key={ServicesChannelsEnum[key]}
                value={ServicesChannelsEnum[key]}
              >
                {ServicesChannelsEnum[key]}
              </MenuItem>
            ))}
          </UITextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <UITextField
            label={'Classificação'}
            name={'class'}
            value={selectedInstitution?.class || ''}
            onChange={handleChange}
            fullWidth
            select
            disabled
          >
            <MenuItem value={''}>Não se aplica</MenuItem>
            <MenuItem value={'GOLD'}>Gold</MenuItem>
            <MenuItem value={'SILVER'}>Silver</MenuItem>
          </UITextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name={'secretaryClassification'}
            label={'Classificação da secretaria'}
            onChange={handleChange}
            fullWidth
            select
            disabled={
              SecretaryClassificationDomain({
                serviceChannel:
                  selectedInstitution?.serviceChannel as ServicesChannels,
                initialValue: null
              }).disabled || disabled
            }
            value={values.secretaryClassification}
            error={!!errors.secretaryClassification}
            helperText={errors.secretaryClassification}
          >
            <MenuItem value={'Secretaria de Educação'}>
              Secretaria de Educação
            </MenuItem>
            <MenuItem value={'Secretaria de Saúde'}>
              Secretaria de Saúde
            </MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            multiple
            id="tags-filled"
            options={RequestDocuments}
            defaultValue={values.requiredDocuments}
            disabled={disabled}
            freeSolo
            renderTags={(value: string[], getTagProps) =>
              value.map((option: string, index: number) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            onChange={(_, value) => {
              if (value) {
                setFieldValue('requiredDocuments', value);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Solicitar documentos"
                placeholder="Documentos a solicitar"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name={'observation'}
            value={values.observation}
            onChange={handleChange}
            disabled={disabled}
            label={'Observação (opcional)'}
            variant={'outlined'}
            minRows={2}
            multiline
            fullWidth
          />
        </Grid>
        {!disabled && (
          <Grid container item direction={'row-reverse'}>
            <Grid item xs={12} sm={2}>
              {loading ? (
                <LinearProgress />
              ) : (
                <Button type={'submit'} fullWidth variant={'contained'}>
                  Avançar
                </Button>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </form>
  );
}
