import VMasker from 'vanilla-masker';

export const MoneyRegex = /(R\$) ([0-9]\d{0,2}(\.))*([0-9]{1,3})*,([0-9]{2})*/;

export const MoneyMask = (value: number | string): string => {
  let parseValue = value;

  if (typeof parseValue === 'string') {
    if (parseValue.search('R') === -1) {
      parseValue = Number(parseValue).toFixed(2);
    }
  }

  if (typeof value === 'number') {
    parseValue = value.toFixed(2);
  }

  return VMasker.toMoney(parseValue, { unit: 'R$ ' });
};

export const NumberToMoney = (value: number): string => {
  return VMasker.toMoney(value, {
    unit: 'R$ '
  });
};

export const MoneyReplaceRegex = /[.]/gm;

export const MoneyRemoveUnit = /^(R\$ )/;

export const MoneyRemoveMask = (value: string) => {
  return value
    .replace(MoneyReplaceRegex, '')
    .replace(',', '.')
    .replace(MoneyRemoveUnit, '');
};

// export const MoneyRegex = /^(0|R\$ [1-9]\d{0,2}(\.\d{3})*),\d{2}$/;
