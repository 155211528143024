/* eslint-disable no-nested-ternary */
import { Grid } from '@mui/material';
import React from 'react';
import ContactCard from 'shared/components/Cards/ContactCard';
import { ThemeColors } from 'shared/enum';
import { InstitutionModel } from 'types/models';

interface InstitutionCardProps {
  institution: InstitutionModel;
  onDelete: () => void;
  onEdit: () => void;
}

export function InstitutionCard({
  institution,
  onDelete,
  onEdit
}: InstitutionCardProps) {
  const color =
    institution?.serviceChannel === 'Hospitalar'
      ? ThemeColors.Pink
      : institution?.serviceChannel === 'Programa governamental'
      ? ThemeColors.Primary
      : ThemeColors.White;
  const chipColor =
    institution?.serviceChannel === 'Hospitalar'
      ? 'pink'
      : institution?.serviceChannel === 'Programa governamental'
      ? 'blue'
      : 'default';
  return (
    <Grid item xs={12} md={4} key={institution.id}>
      <ContactCard
        borderColor={color}
        chip={{
          color: chipColor,
          title: institution?.serviceChannel || '-',
          height: '20px'
        }}
        city={institution.city}
        institution=""
        name={institution.name}
        onMenuClick={() => {}}
        onPencilClick={() => onEdit()}
        onTrashClick={() => onDelete()}
        uf={institution.uf}
      />
    </Grid>
  );
}
