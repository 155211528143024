export const ProgramName = [
  'DST/AIDS - Municipal',
  'Alimentação escolar',
  'Carências nutricionais',
  'Vulnerabilidade social',
  'Leve leite',
  'Leite do meu filho',
  'DST/AIDS - Estadual',
  'Mandado judicial',
  'Farmácia alto custo',
  'Consumo hospitalar',
  'Outros'
];
