import { Button, Grid, Typography } from '@mui/material';
import { ProcessItemsTableProps } from 'modules/Process/shared/Tables/ProcessItems/ProcessItems';
import React from 'react';
import { UITable } from 'shared/components';
import { UIDataGridApiPagination } from 'shared/components/Tables/DataGridApiPagination';
import { UIToolbar } from 'shared/components/Tables/ToolbarTable';
import { PrivateProcessItemsColumns } from './Columns';

export function PrivateProcessItemsTable({
  processProduct = [],
  onAddItemClick,
  ...rest
}: ProcessItemsTableProps) {
  return (
    <UITable
      columns={PrivateProcessItemsColumns}
      rows={processProduct}
      rowHeight={36}
      headerHeight={36}
      pageSize={10}
      components={{
        Toolbar: () => (
          <UIToolbar
            title={<Typography variant="h2">Itens do processo </Typography>}
            elementsTopEnd={
              <>
                <Grid
                  container
                  item
                  alignItems="center"
                  justifyContent="flex-end"
                  xs={12}
                  p={1}
                >
                  <Grid container justifyContent="flex-end" item xs>
                    <UIDataGridApiPagination itemSize="small" />
                  </Grid>
                  <Grid container item xs={3}>
                    <Button
                      fullWidth
                      variant="text"
                      onClick={() => {
                        if (onAddItemClick) onAddItemClick();
                      }}
                    >
                      Adicionar Item
                    </Button>
                  </Grid>
                </Grid>
              </>
            }
          />
        )
      }}
      {...rest}
    />
  );
}
