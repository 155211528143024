import { GenerateArrayFromEnum } from 'utils/Functions/GenerateArrayFromEnum';

export enum ServicesChannelsEnum {
  'governement' = 'Programa governamental',
  'cest_basic' = 'Cesta basica',
  'Hospitalar' = 'Hospitalar'
}

const getServicesChannels = () => {
  return GenerateArrayFromEnum(ServicesChannelsEnum).map(({ key }) => {
    return {
      serviceChannelKey: key,
      serviceChannel: ServicesChannelsEnum[key]
    };
  });
};

export const SERVICE_CHANNEL_ARRAY = getServicesChannels();

export const SERVICES_CHANNELS_ARRAY =
  GenerateArrayFromEnum(ServicesChannelsEnum);

export type ServicesChannels =
  | 'Programa governamental'
  | 'Cesta básica'
  | 'Concessionária de alimentos'
  | 'Hospitalar'
  | 'Prefeitura';

export const ProvServicesChannels = [
  'Programa governamental',
  'Cesta básica',
  'Concessionária de alimentos',
  'Hospitalar',
  'Prefeitura'
];

export const getFormattedServiceChannel = (serviceChannel?: string) => {
  if (serviceChannel) {
    if (serviceChannel.toLowerCase() === 'cesta basica') return 'Cesta básica';
    return serviceChannel;
  }
  return '';
};
