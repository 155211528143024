import React from 'react';
import { Button, DialogActions, Grid } from '@mui/material';
import { PoliticalPriceForm } from 'modules/Admin/Products/forms/PoliticalPriceForm';
import { PoliticalPriceModalProps } from 'modules/Admin/Products/modals/PoliticalPriceModal/PoliticalPriceModal';
import { SimpleFormModal } from 'shared/components';

export function PoliticalPriceModal({
  politicalPrices,
  refetchPoliticalPrices,
  onClose,
  productId,
  ...rest
}: PoliticalPriceModalProps) {
  return (
    <SimpleFormModal
      title={'Política de comercial de preços'}
      {...rest}
      fullWidth
      bottomActions={
        <DialogActions>
          <Grid
            item
            container
            direction={'row'}
            justifyContent={'flex-end'}
            spacing={2}
            xs={12}
          >
            <Grid item xs={7}>
              <Button
                fullWidth
                variant="outlined"
                color={'primary'}
                onClick={() => (onClose ? onClose({}, 'escapeKeyDown') : null)}
              >
                Voltar
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      }
    >
      <PoliticalPriceForm
        politicalPrices={politicalPrices}
        refetchPoliticalPrices={refetchPoliticalPrices}
        id={productId}
      />
    </SimpleFormModal>
  );
}
