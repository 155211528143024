import { Button, Grid, Typography } from '@mui/material';
import { useAuth } from 'Providers/Auth';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { usePatch, useQuery } from 'services';
import { UIBreadcrumb, UILink } from 'shared/components';
import UploadAttachmentsSection from 'shared/components/Upload/UploadSection';
import { theme } from 'shared/theme';
import { AttachmentModel } from 'types/models/Attachments/Attachment.model';

interface SuspendProcessModalProps {
  processId: string;
}

export function SuspendProcessFragment({
  processId
}: SuspendProcessModalProps) {
  const history = useHistory();

  const attachmentsQuery = useQuery<AttachmentModel[], AttachmentModel>(
    `/attachments`,

    {
      table: 'process',
      $limit: '-1',
      tableId: processId,
      category: 'Comprovante de suspensão',
      fetchOnInitialize: false
    }
  );

  const [, patchProcess] = usePatch(`/process`);

  const { auth } = useAuth();

  const handleSuspendProcess = async () => {
    // TODO remove from hardcode
    const status = {
      type: 'suspending',
      message: 'Pedido de suspensão de processo feito com sucesso'
    };
    if (auth?.employee?.id) {
      status.type = 'suspended';
      status.message = 'Processo suspenso com sucesso';
    }
    const response = await patchProcess({ status: status.type }, +processId);
    if (response) {
      toast.success(status.message);
      history.push(`/processo/${processId}`);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <UIBreadcrumb>
          <UILink to={'/dashboard'} color={'inherit'}>
            Dashboard
          </UILink>
          <UILink to={'/dashboard'} color={'inherit'}>
            Processo
          </UILink>
          <Typography color={'primary'}> Suspender Processo </Typography>
        </UIBreadcrumb>
      </Grid>
      <Grid item xs={12} mt={4}>
        <Typography
          variant="h2"
          style={{
            fontSize: theme.font.sizes.large
          }}
        >
          Suspender processo
        </Typography>
      </Grid>
      <Grid item xs={12} mt={2}>
        <Typography
          variant="h3"
          style={{
            fontSize: theme.font.sizes.medium,
            color: theme.colors.TextGrey
          }}
        >
          Anexe opcionalmente um documento e suspenda o processo.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <UploadAttachmentsSection
          attachmentsQuery={attachmentsQuery}
          selectOptions={['Comprovante de suspensão']}
          tableId={+processId}
          table="process"
        />
      </Grid>
      <Grid item container justifyContent={'flex-end'} xs={12}>
        <Grid item sx={{ mt: 2 }}>
          <Button onClick={handleSuspendProcess} variant={'contained'}>
            Suspender Processo
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
