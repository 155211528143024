import React from 'react';
import { UITabs } from 'shared/components/Tabs';
import { UITab } from 'shared/components/Tabs/Tab';
import { ThemeColors } from 'shared/enum';

type DistCreateProcessTabsProps = {
  id?: string | number | null;
  step: number;
  handleChangeStep: (step: number) => void;
};

export function CreatePrivateProcessTabs({
  id,
  handleChangeStep,
  step
}: DistCreateProcessTabsProps) {
  const actualColor: (keyof typeof ThemeColors)[] = [
    'Primary',
    'Pink',
    'Orange',
    'Success'
  ];

  return (
    <UITabs
      value={step}
      onChange={(_, pos) => handleChangeStep(pos)}
      variant="scrollable"
      scrollButtons
      allowScrollButtonsMobile
      aria-label="scrollable force tabs example"
      colorIndicator={actualColor[step] || actualColor[0]}
    >
      <UITab label={'Resumo da proposta'} />
      <UITab disabled={!id} label={'Análise da proposta'} />
      <UITab disabled={!id || step <= 1} label={'Cadastro do escopo'} />
      <UITab disabled={!id || step <= 1} label={'Anexos'} />
    </UITabs>
  );
}
