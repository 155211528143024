import React, { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { TableEditOptionParams } from 'shared/components/Tables/TableActions/TableActions';

export function TableEditOption({
  row,
  actions,
  showActions
}: TableEditOptionParams) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (showActions) {
    if (!showActions(row)) {
      return <>-</>;
    }
  }

  return (
    <>
      <IconButton onClick={handleClick} size="large">
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {actions.map(({ label, onClick }) => (
          <MenuItem onClick={() => onClick(row)} key={`${label}`}>
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
