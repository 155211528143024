import React from 'react';
import { SuspendProcessFragment } from 'modules/Process/shared/Fragment/SuspendProcess';
import { Container } from '@mui/material';
import { useParams } from 'react-router-dom';
import { UrlParams } from 'types/services';

export function SuspendProcess() {
  const params = useParams() as UrlParams;
  const id = params?.id;

  return (
    <Container maxWidth={'lg'}>
      <SuspendProcessFragment processId={id} />
    </Container>
  );
}
