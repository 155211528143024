import VMasker from 'vanilla-masker';

export const ZipCodeMask = (value: string) => {
  const pattern = '99999-999';
  return VMasker.toPattern(value, pattern);
};

export const ZipCodeReplaceRegex = /-/g;

export const ZipCodeMaskRemove = (value: string) => {
  return value.replace(ZipCodeReplaceRegex, '');
};

export const ZipCodeRegex = /[0-9]{5}-[0-9]{3}/g;
