import { Container, Grid, Typography } from '@mui/material';
import { DistributorProfileFragment } from 'modules/Profiles/distributor/Fragments/DistributorProfile';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'services';
import { UILink } from 'shared/components';
import { UIBreadcrumb } from 'shared/components/Breadcrumb';
import { theme } from 'shared/theme';
import { AccessModel } from 'types/models';
import { UrlParams } from 'types/services';

export function DistributorUpdate() {
  const params = useParams() as UrlParams;
  const id = params?.id;

  const [access] = useQuery<AccessModel, AccessModel>(`/access/${id}`);

  return (
    <Container maxWidth={'lg'}>
      <UIBreadcrumb>
        <UILink to={'/dashboard'}> Dashboard </UILink>
        <Typography> Editar Informações </Typography>
      </UIBreadcrumb>
      <Grid container pt={3} pl={2} alignItems="center">
        <Typography
          variant="h2"
          style={{
            color: theme.colors.TextDarkGrey,
            fontWeight: theme.font.semiBold
          }}
        >
          Editar Informações
        </Typography>
      </Grid>
      <DistributorProfileFragment access={access} />
    </Container>
  );
}
