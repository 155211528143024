import DoneIcon from '@mui/icons-material/Done';
import { Fab, LinearProgress } from '@mui/material';
import { InformResultProps } from 'modules/Process/PublicProcess/Fragments/InformResult/InformResultProps';
import { InformResultModal } from 'modules/Process/PublicProcess/Modals/InformResult';
import { ValidateProcessItemsTable } from 'modules/Process/PublicProcess/Tables/ValidateProcessItems';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { usePatch, useQuery } from 'services';
import { useFab } from 'shared/components/Fab';
import { ProcessProductModel } from 'types/models';

export function InformResultFragment({
  processId,
  onComplete
}: InformResultProps) {
  const [edit, setEdit] = useState<ProcessProductModel>();

  const [products, refetchProducts, loading] = useQuery<
    Partial<ProcessProductModel>[],
    ProcessProductModel
  >(`/process-products`, {
    $limit: -1,
    processId,
    approve: '1'
  });

  const getProducts = products || [];

  const [, patchProducts] = usePatch('/process-products');

  const handleEdit = async (data: Partial<ProcessProductModel>) => {
    const response = await patchProducts(data, data.id);
    if (response) {
      toast.success('Resultado do item adicionado com sucesso');
      await refetchProducts();
      setEdit(undefined);
    }
  };

  const handleCompleteForm = async () => {
    const notCompletedProduct = getProducts
      .filter(
        (product) => product.winner === undefined || product.winner === null
      )
      .map((processProduct) => processProduct.itemNumber || '-');
    if (notCompletedProduct.length > 0) {
      toast.error(
        `Os seguintes itens não foram preenchidos: ${notCompletedProduct}`
      );
      return;
    }

    onComplete();
  };

  const handleOpenEdit = (data: ProcessProductModel) => {
    setEdit(data);
  };

  const classes = useFab();

  return (
    <>
      {loading && !products ? (
        <LinearProgress />
      ) : (
        <ValidateProcessItemsTable
          products={getProducts || []}
          onEdit={(a) => setEdit(a)}
          actions={[
            {
              label: 'Validar',
              onClick: handleOpenEdit
            }
          ]}
        />
      )}

      {edit ? (
        <InformResultModal
          open={Boolean(edit)}
          processProduct={edit}
          onHandleComplete={(editData) => handleEdit(editData)}
          onClose={() => setEdit(undefined)}
        />
      ) : null}

      <Fab
        variant="extended"
        className={classes.fab}
        color={'primary'}
        onClick={handleCompleteForm}
      >
        <DoneIcon />
        Concluir
      </Fab>
    </>
  );
}
