/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { UIChip } from 'shared/components/Chip';
import { TitleLabel } from 'shared/components/TitleLabel';
import { theme } from 'shared/theme';
import { OrderModel } from 'types/models/Order/Order.model';
import { GetDateFormat } from 'utils/Functions/FormatDate';
import { GetUiChipContent } from './Functions';

type OrderInExecutionProps = {
  orders: OrderModel[];
};

export function OrderInExecution({ orders }: OrderInExecutionProps) {
  const history = useHistory();
  const [selectedOrderIndex, setSelectedOrderIndex] = useState<string>('0');

  const selectedOrder = orders?.[+selectedOrderIndex];

  const getTitle = () => {
    if (orders?.length === 0) return 'Nenhum pedido realizado';
    if (selectedOrderIndex === '0') return 'Último pedido solicitado';
    return `Pedido  ${orders?.length - +selectedOrderIndex}`;
  };

  const getItensLabel = () => {
    if (!selectedOrder?.order_items) return '-';
    if (selectedOrder?.order_items?.length === 1)
      return `${selectedOrder?.order_items?.length} item`;
    return `${selectedOrder?.order_items?.length} itens`;
  };

  return (
    <Grid
      container
      bgcolor={theme.colors.White}
      borderRadius={2}
      flexDirection="column"
      justifyContent="flex-start"
    >
      <Grid item>
        <Grid
          container
          item
          p={1}
          pl={2}
          pr={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            variant="h3"
            style={{
              color: theme.colors.TextDarkGrey,
              fontWeight: theme.font.semiBold
            }}
          >
            {getTitle()}
          </Typography>
          {orders?.length > 0 && (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                if (selectedOrder?.id) {
                  history.push(`/processo/pedido/${selectedOrder?.id}`);
                }
              }}
            >
              Ver Informações
            </Button>
          )}
        </Grid>
        <Box pb={1} width="100%">
          <Divider />
        </Box>
      </Grid>
      <Collapse in={orders?.length > 0} timeout={400}>
        <Box pl={2} pr={2} pt={1}>
          <TextField
            label={'Selecione o pedido'}
            onChange={(e) => {
              setSelectedOrderIndex(e.target.value);
            }}
            fullWidth
            select
            value={selectedOrderIndex}
          >
            {orders?.map((order, key) => (
              <MenuItem key={order?.id} value={`${key}`}>
                Pedido {orders?.length - key} -{' '}
                {GetDateFormat(order?.createdAt)}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Collapse>
      <Grid
        container
        item
        p={2}
        alignItems="center"
        justifyContent="space-between"
        flexDirection="row"
        pt={3}
      >
        <Grid container item xs={6} flexDirection="column" mr={1} gap="10px">
          <UIChip
            color={GetUiChipContent(selectedOrder, orders.length || 0).color}
            fontWeight="800"
            title={GetUiChipContent(selectedOrder, orders.length || 0).title}
            height="25px"
          />
          <TitleLabel title="Itens solicitados" label={getItensLabel()} />
          <TitleLabel
            title="Pedido solicitado em: "
            label={GetDateFormat(selectedOrder?.createdAt)}
          />
        </Grid>
        <Divider orientation="vertical" />
        <Grid container item xs ml={1} gap="10px">
          <TitleLabel
            title="Pedido faturado em: "
            label={GetDateFormat(selectedOrder?.approvedDate)}
          />
          <TitleLabel
            title="Pedido entregado em: "
            label={GetDateFormat(selectedOrder?.deliveryDate)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
