import { Box, Container, Grid, Typography } from '@mui/material';
import { ProcessTable } from 'modules/Dashboard/Tables/ProcessTable';
import React from 'react';
import { useQuery } from 'services/api/';
import { UIBreadcrumb, UILink } from 'shared/components';
import { theme } from 'shared/theme';
import { ProcessModel } from 'types/models';
import { Paginated } from 'types/services';
import { processFields } from './Constants';

export function History() {
  const itemsPerPage = 10;

  const [responseData, resend, loading] = useQuery<
    Paginated<ProcessModel>,
    ProcessModel
  >('/process', {
    $limit: itemsPerPage,
    $select: processFields,
    $skip: 0
  });

  return (
    <Container maxWidth={'lg'}>
      <Box mb={3}>
        <UIBreadcrumb>
          <UILink to={'/'} color={'inherit'}>
            Dashboard
          </UILink>
          <Typography color={'primary'}> Histórico</Typography>
        </UIBreadcrumb>
      </Box>
      <Box mt={3} />
      <Typography
        variant="h2"
        style={{
          fontSize: theme.font.sizes.large
        }}
      >
        Processos
      </Typography>
      <Box mt={2} />
      <Typography
        variant="h3"
        style={{
          fontSize: theme.font.sizes.medium,
          color: theme.colors.TextGrey
        }}
      >
        Reveja os processos e exporte-os para uma planilha.
      </Typography>
      <Box mt={3} />
      <Grid container spacing={4}>
        <ProcessTable
          pageSize={itemsPerPage}
          responseData={responseData}
          loading={loading}
          resend={resend}
          isHistory
        />
      </Grid>
    </Container>
  );
}
