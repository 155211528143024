export const content = [
  {
    step: 1,
    title: 'Cadastro das informações iniciais do processo',
    description:
      'Nesta Etapa é necessário preencher todas as informações relacionadas a este processo, conforme edital/cotação. Caso seja necessário documentações para participação informar no campo “Solicitar documentos” e no campo “observações” identificar os itens.'
  },
  {
    step: 2,
    title: 'Cadastro dos itens para cotação',
    description:
      'Nesta etapa é necessário incluir os itens que serão cotados pelo consultor. Será necessário incluir o número do item, descrição e quantidade de acordo com edital ou cotação que foi anexada ao processo na etapa anterior.'
  },
  {
    step: 3,
    title: 'Inserir documentos complementares',
    description:
      'Nesta etapa, caso seja necessário anexar documentos complementares ao processo [termo de referência, questionamentos, impugnação, etc].'
  },
  {
    step: 4,
    title: 'Confirmar dados',
    description:
      'Nesta etapa de confirmação, certifique-se que todas as documentações/informações anexadas referem-se ao processo correto. Em caso de dúvidas, entre em contato com o consultor[a] Nestlé responsável.'
  }
];
