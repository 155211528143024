import React from 'react';
import { ThemeColors } from 'shared/enum';
import { TagChip } from './styled';

export interface ChipProps {
  color?: 'green' | 'red' | 'blue' | 'orange' | 'default' | 'white' | 'pink';
  title?: string;
  height?: string;
  width?: string;
  fontSize?: string;
  fontWeight?: string;
  backgroundColor?: string;
}

const colors = {
  green: {
    background: 'rgba(101, 172, 40, 0.25)',
    fontColor: ThemeColors.Success
  },
  red: {
    background: 'rgba(201, 80, 80, 0.16)',
    fontColor: ThemeColors.Error
  },
  blue: {
    background: 'rgba(80, 130, 201, 0.16)',
    fontColor: ThemeColors.Primary
  },
  orange: {
    background: 'rgba(255, 189, 20, 0.16)',
    fontColor: ThemeColors.Orange
  },
  default: {
    background: 'rgba(196, 196, 196, 0.5)',
    fontColor: ThemeColors.TextDarkGrey
  },
  pink: {
    background: '#ff73c320',
    fontColor: ThemeColors.Pink
  },
  white: {
    background: 'lightgrey',
    fontColor: 'white'
  }
};

export function UIChip({
  title,
  color = 'default',
  backgroundColor,
  height,
  fontSize,
  fontWeight,
  width
}: ChipProps) {
  return (
    <TagChip
      label={title}
      background={
        backgroundColor || colors[color].background || colors.default.background
      }
      fontColor={colors[color].fontColor || colors.default.fontColor}
      height={height}
      fontSize={fontSize}
      fontWeight={fontWeight}
      width={width}
    />
  );
}
