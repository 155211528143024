export const TableTranslate = {
  columnMenuSortAsc: 'Ordem crescente',
  columnMenuSortDesc: 'Ordem decrescente',
  columnMenuHideColumn: 'Ocultar',
  columnMenuShowColumns: 'Mostrar ocultos',
  columnMenuUnsort: 'Ordenar',
  columnMenuLabel: 'Menu',
  columnMenuFilter: 'Filtro',
  toolbarFilters: 'Filtros',
  toolbarFiltersLabel: 'Mostrar filtros',
  toolbarFiltersTooltipHide: 'Esconder filtros',
  toolbarFiltersTooltipShow: 'Mostrar filtros',
  filterPanelAddFilter: 'Adicionar filtro',
  filterPanelDeleteIconLabel: 'Remover',
  filterPanelOperators: 'Operadores',
  filterPanelOperatorAnd: 'E',
  filterPanelOperatorOr: 'Ou',
  filterPanelColumns: 'Colunas',
  filterPanelInputLabel: 'Valores',
  filterPanelInputPlaceholder: 'Valor a filtrar',
  filterOperatorContains: 'Contem',
  filterOperatorEquals: 'Igual',
  filterOperatorStartsWith: 'Começa com',
  filterOperatorEndsWith: 'Termina com',
  filterOperatorIs: 'É',
  filterOperatorNot: 'Não é',
  filterOperatorAfter: 'É depois',
  filterOperatorOnOrAfter: 'É em ou depois',
  filterOperatorBefore: 'É depois',
  filterOperatorOnOrBefore: 'É em ou depois',
  filterValueAny: 'Qualquer um',
  filterValueTrue: 'Verdadeiro',
  filterValueFalse: 'Falso',
  noRowsLabel: 'Sem dados',
  footerRowSelected: (count: number) =>
    count !== 1
      ? `${count.toLocaleString()} linhas selecionadas`
      : `${count.toLocaleString()} linha selecionada`,

  noResultsOverlayLabel: 'Nenhum resultado encontrado.',
  errorOverlayDefaultLabel: 'Um erro aconteceu.',

  // Density selector toolbar button text
  toolbarDensity: 'Densidade',
  toolbarDensityLabel: 'Densidade',
  toolbarDensityCompact: 'Compacto',
  toolbarDensityStandard: 'Padrão',
  toolbarDensityComfortable: 'Confortável',

  // Columns selector toolbar button text
  toolbarColumns: 'Colunas',
  toolbarColumnsLabel: 'Selecionar Colunas',

  // Filters toolbar button text
  toolbarFiltersTooltipActive: (count) =>
    count !== 1 ? `${count} filtros ativos` : `${count} filtros ativos`,

  // Export selector toolbar button text
  toolbarExport: 'Exportar',
  toolbarExportLabel: 'Exportar',
  toolbarExportCSV: 'Fazer download em CSV',

  // Columns panel text
  columnsPanelTextFieldLabel: 'Encontrar Coluna',
  columnsPanelTextFieldPlaceholder: 'Título da Coluna',
  columnsPanelDragIconLabel: 'Re-Organizar Coluna',
  columnsPanelShowAllButton: 'Mostrar todos',
  columnsPanelHideAllButton: 'Esconder todos',

  // Filter operators text
  filterOperatorIsEmpty: 'Está vazio',
  filterOperatorIsNotEmpty: 'Não está vazio',

  // Column header text
  columnHeaderFiltersTooltipActive: (count) =>
    count !== 1 ? `${count} filtros ativos` : `${count} filtros ativos`,
  columnHeaderFiltersLabel: 'Mostrar filtros',
  columnHeaderSortIconLabel: 'Reorganizar ',

  // Total rows footer text
  footerTotalRows: 'Total de linhas:',

  // Total visible rows footer text
  footerTotalVisibleRows: (visibleCount, totalCount) =>
    `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,

  // Checkbox selection text
  checkboxSelectionHeaderName: 'Seleção em caixa',

  // Boolean cell text
  booleanCellTrueLabel: 'verdadeiro',
  booleanCellFalseLabel: 'falso'
};
