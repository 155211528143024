import React from 'react';
import { Grid, GridSize, Typography } from '@mui/material';
import { theme } from 'shared/theme';

type ProductDescribeFragmentProps = {
  size: GridSize;
  label: string;
  value: string | number;
};

export function ProductDescribeFragment({
  size,
  label,
  value
}: ProductDescribeFragmentProps) {
  return (
    <Grid container item xs={size}>
      <Typography
        variant="h4"
        style={{
          color: theme.colors.TextGrey,
          fontWeight: theme.font.semiBold
        }}
      >
        {label}
      </Typography>
      <Typography
        variant="h4"
        style={{
          color: theme.colors.Primary
        }}
      >
        {value}
      </Typography>
    </Grid>
  );
}
