import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react';
import { UITextField } from 'shared/components/Inputs';
import { theme } from 'shared/theme';
import { OpenModalProps } from '../Domain';

export type CreateOrUpdateObservationModalProps = {
  activeComment?: string;
  setOpenModal: (openModal: OpenModalProps) => void;
  onSubmit: (createComment: string) => void;
} & OpenModalProps;

export function CreateOrUpdateObservationModal({
  open,
  type,
  setOpenModal,
  onSubmit,
  activeComment
}: CreateOrUpdateObservationModalProps) {
  const [comment, setComment] = useState<string | undefined>(activeComment);
  const onClose = () =>
    setOpenModal({
      open: false,
      type
    });

  useEffect(() => {
    setComment(activeComment);
  }, [activeComment]);

  const content = {
    update: {
      title: 'Editar um comentário',
      subtitle: 'Edite o comentário abaixo.'
    },
    create: {
      title: 'Criar um novo comentário',
      subtitle: 'Insira o comentário no campo abaixo.'
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        <Typography
          variant="h4"
          style={{
            fontWeight: theme.font.semiBold,
            color: theme.colors.TextDarkGrey
          }}
        >
          {type ? content[type]?.title : '-'}
        </Typography>
        <IconButton aria-label={'close'} onClick={onClose} size="large">
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container md={12}>
          <Typography variant="h4">
            {type ? content[type]?.subtitle : '-'}
          </Typography>
        </Grid>
        <Grid container md={12} style={{ marginTop: '20px' }}>
          <UITextField
            label={'Comentário'}
            multiline
            minRows={3}
            inputProps={{
              maxLength: 256
            }}
            variant={'outlined'}
            fullWidth
            value={comment ?? ''}
            onChange={({ target }) => setComment(target.value)}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          item
          container
          direction={'row'}
          justifyContent={'flex-end'}
          xs={12}
        >
          <Grid item style={{ marginRight: '20px' }}>
            <Button variant="outlined" onClick={onClose} color={'primary'}>
              Voltar
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              disabled={!comment}
              color={'primary'}
              onClick={() => {
                onSubmit(comment ?? '');
                setComment(undefined);
              }}
            >
              Confirmar
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
