/* eslint-disable @typescript-eslint/no-explicit-any */
import { ERRORS } from 'utils/Errors';
import { MoneyRemoveMask } from 'utils/masks';
import * as yup from 'yup';

export interface AdminEditItemProcessInputs {
  itemNumber: number;
  initialQuantity: number;
  productId?: number;
  priceList: string;
  discountValue: string;
  quantity: number;
  maxQuantity: number;
  newQuantity: number;
  markup?: string;
}

export const AdminEditItemProcessSchema = yup.object({
  itemNumber: yup
    .number()
    .required(ERRORS.REQUIRED_FIELD)
    .typeError(ERRORS.REQUIRED_FIELD),
  initialQuantity: yup
    .number()
    .required(ERRORS.REQUIRED_FIELD)
    .typeError(ERRORS.REQUIRED_FIELD),
  productId: yup
    .number()
    .required(ERRORS.REQUIRED_FIELD)
    .typeError(ERRORS.REQUIRED_FIELD),
  priceList: yup
    .string()
    .required(ERRORS.REQUIRED_FIELD)
    .typeError(ERRORS.REQUIRED_FIELD)
    .test('greaterThan', 'Insira um valor maior que 0', (val?: string) => {
      return val ? +MoneyRemoveMask(val) > 0 : false;
    }),
  discountValue: yup.string().typeError(ERRORS.REQUIRED_FIELD),
  quantity: yup
    .number()
    .required(ERRORS.REQUIRED_FIELD)
    .typeError(ERRORS.REQUIRED_FIELD),
  maxQuantity: yup
    .number()
    .required(ERRORS.REQUIRED_FIELD)
    .typeError(ERRORS.REQUIRED_FIELD),
  newQuantity: yup
    .number()
    .required(ERRORS.REQUIRED_FIELD)
    .typeError(ERRORS.REQUIRED_FIELD)
    .test(
      'greaterThanTotalQuantity',
      'Insira um valor maior que a quantidade produzida',
      (val?: number, form?: any) => {
        if (form?.parent?.maxQuantity - form?.parent?.quantity > (val || 0)) {
          return false;
        }
        return true;
      }
    )
});
