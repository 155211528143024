/* eslint-disable no-nested-ternary */
import { AccordionSummary, Grid, Typography } from '@mui/material';
import React from 'react';
import CheckIcon from '@mui/icons-material/CheckRounded';
import WarningIcon from '@mui/icons-material/PriorityHighRounded';

type Props = {
  title: string;
  isCreated: boolean;
  isUfSelected: boolean;
};

export function AccordionTitle({ title, isCreated, isUfSelected }: Props) {
  return (
    <AccordionSummary>
      <Grid container justifyContent="space-between" alignItems="center">
        <Typography variant="h2" align={'center'}>
          {title}
        </Typography>
        {isUfSelected ? (
          isCreated ? (
            <CheckIcon color="success" fontSize="large" />
          ) : (
            <WarningIcon color="warning" fontSize="large" />
          )
        ) : null}
      </Grid>
    </AccordionSummary>
  );
}
