import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { theme } from 'shared/theme';

type TitleWithSubtitleProps = {
  title: string;
  subtitle?: string;
};

export default function TitleWithSubtitle({
  title,
  subtitle
}: TitleWithSubtitleProps) {
  return (
    <>
      <Grid item xs={12}>
        <Box mt={3} />
        <Typography
          variant="h2"
          style={{
            fontSize: theme.font.sizes.large
          }}
        >
          {title}
        </Typography>
        <Box mt={2} />
        <Typography
          variant="h3"
          style={{
            fontSize: theme.font.sizes.medium,
            color: theme.colors.TextGrey
          }}
        >
          {subtitle}
        </Typography>
        <Box mt={1} />
      </Grid>
    </>
  );
}
