/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Button,
  CircularProgress,
  Grid,
  LinearProgress,
  Typography
} from '@mui/material';
import { ProcessBasicInformationForm } from 'modules/Process/PublicProcess/Forms/ProcessBasicInformation';
import { ProcessItemsTable } from 'modules/Process/shared/Tables/ProcessItems';
import React, { useEffect } from 'react';
import { useQuery, UseQueryReturn } from 'services';
import { UIDataGridApiPagination } from 'shared/components/Tables/DataGridApiPagination';
import { UIToolbar } from 'shared/components/Tables/ToolbarTable';
import UploadItem from 'shared/components/Upload/UploadItem';
import { useUploadList } from 'shared/hooks/UseUploadList';
import { ProcessModel, ProcessProductModel } from 'types/models';
import { AttachmentModel } from 'types/models/Attachments/Attachment.model';

export interface DistributorProcessConclusionProps {
  id?: string;
  onComplete: () => void;
  attachmentsQuery: UseQueryReturn<AttachmentModel[], AttachmentModel>;
}

export function DistributorProcessConclusion({
  id,
  onComplete,
  attachmentsQuery
}: DistributorProcessConclusionProps) {
  const ProcessQuery = useQuery<ProcessModel>(`/process/${id}`, {
    fetchOnInitialize: !!id
  });
  const ProcessItemsQuery = useQuery<ProcessProductModel[]>(
    '/process-products',
    {
      $limit: -1,
      processId: id
    }
  );
  const processLoading = ProcessQuery[2];
  const process = ProcessQuery[0];

  useEffect(() => {
    attachmentsQuery[1]();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { downloadAttachment, findLoading } = useUploadList(
    attachmentsQuery[1]
  );

  return processLoading ? (
    <LinearProgress />
  ) : (
    <>
      <ProcessBasicInformationForm
        onComplete={() => {}}
        process={process || {}}
        disabled
      />

      <Grid item xs={12} mt={2}>
        <ProcessItemsTable
          isDistributor
          processProduct={ProcessItemsQuery[0]}
          rowHeight={36}
          headerHeight={36}
          pageSize={5}
          components={{
            Toolbar: () => (
              <UIToolbar
                title={<Typography variant="h2">Itens do processo </Typography>}
                elementsTopEnd={<UIDataGridApiPagination itemSize="small" />}
              />
            )
          }}
          loading={ProcessItemsQuery[2]}
        />
      </Grid>

      <Grid container item xs={12} spacing={4} mt={2} mb={2}>
        {attachmentsQuery[3] ? (
          <CircularProgress size={40} />
        ) : (
          attachmentsQuery[0]?.map((attachment) => (
            <Grid item sm={6} xs={12}>
              <UploadItem
                key={`key-${attachment.name}`}
                onDownload={() => downloadAttachment(attachment.id)}
                name={attachment.name || '-'}
                category={attachment.category || '-'}
                size={attachment.size || '-'}
                loading={findLoading(attachment.id!)}
              />
            </Grid>
          ))
        )}
      </Grid>
      <Grid container item direction={'row-reverse'}>
        <Grid item xs={12} sm={2}>
          <Button onClick={onComplete} fullWidth variant={'contained'}>
            Concluir
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
