/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem
} from '@mui/material';
import { useCities } from 'hooks/useCities';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQuery } from 'services';
import { ControlledRadioGroup } from 'shared/components/ControlledComponents/ControlledRadioGroup';
import { ControlledTextField } from 'shared/components/ControlledComponents/ControlledTextField';
import { FormProps, UITextField } from 'shared/components/Inputs';
import { DistributorModel, EmployeeModel } from 'types/models';
import { OptionsBooleanToYes } from 'utils/Constants/BooleanToYes';
import { ComercialPolitics } from 'utils/Constants/ComercialPolitics';
import { InstitutionsSegments, States, STATES_ARRAY } from 'utils/Enums';
import { Regional } from 'utils/Enums/Regional';
import { MoneyMask, MoneyRemoveMask } from 'utils/masks';
import { ClientFormRes, CreateClientSchema } from './domain';

interface ClientFormProps extends FormProps<ClientFormRes> {
  distributor?: DistributorModel;
  loading?: boolean;
}

export function ClientForm({
  distributor,
  onComplete,
  loading
}: ClientFormProps) {
  const { handleSubmit, formState, watch, control, reset, setValue } =
    useForm<ClientFormRes>({
      resolver: yupResolver(CreateClientSchema),
      mode: 'onTouched'
    });

  useEffect(() => {
    if (distributor) {
      reset({
        ...distributor,
        regional: distributor?.regional?.toUpperCase(),
        creditLimit: distributor?.creditLimit
          ? (MoneyMask(distributor?.creditLimit) as unknown as number)
          : 0,
        uf: distributor?.uf?.toUpperCase()
      });
    }
  }, [distributor]);

  const citiesData = useCities()
    .citiesData.filter((city) => city.uf === watch('uf'))
    .map((city) => city.city);

  const [consultants] = useQuery<EmployeeModel[], EmployeeModel>('/employee', {
    $limit: '-1',
    $select: ['id', 'name', 'sap'],
    accessLevel: 'consultant'
  });

  const onSubmit = (data: ClientFormRes) => {
    const formattedData = data;
    if (formattedData.creditLimit)
      formattedData.creditLimit = +(+MoneyRemoveMask(
        `${formattedData.creditLimit}`
      )).toFixed(2);
    onComplete(data);
  };

  return (
    <Box bgcolor={'white'} p={1}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid item container spacing={2}>
          <Grid item xs={12} sm={4}>
            <ControlledTextField
              name={'type'}
              control={control}
              errors={formState.errors.type?.message}
              label={'Tipo de cliente'}
              select
            >
              {ComercialPolitics.map((clientType) => (
                <MenuItem value={clientType} key={clientType}>
                  {clientType}
                </MenuItem>
              ))}
            </ControlledTextField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <ControlledTextField
              name={'name'}
              control={control}
              errors={formState.errors?.name?.message}
              label={'Nome'}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ControlledTextField
              name={'email'}
              control={control}
              errors={formState.errors?.email?.message}
              label={'E-mail'}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ControlledTextField
              name={'telephone'}
              control={control}
              errors={formState.errors?.telephone?.message}
              label={'Telefone'}
              pattern="phone"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ControlledTextField
              name={'regional'}
              control={control}
              errors={formState.errors.regional?.message}
              label={'Regional'}
              select
            >
              {Object.keys(Regional).map((key) => (
                <MenuItem value={Regional[key]} key={key}>
                  {Regional[key]}
                </MenuItem>
              ))}
            </ControlledTextField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <ControlledTextField
              name={'cnpj'}
              control={control}
              errors={formState.errors?.cnpj?.message}
              label={'CNPJ'}
              pattern="cnpj"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ControlledTextField
              name={'socialName'}
              control={control}
              errors={formState.errors?.socialName?.message}
              label={'Razão Social'}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ControlledTextField
              name={'sold'}
              control={control}
              errors={formState.errors.sold?.message}
              label={'Sold'}
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ControlledTextField
              name={'contactPerson'}
              control={control}
              errors={formState.errors?.contactPerson?.message}
              label={'Pessoa de contato'}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ControlledTextField
              name={'segment'}
              control={control}
              errors={formState.errors.segment?.message}
              label={'Segmento'}
              select
            >
              {Object.keys(InstitutionsSegments).map((key) => (
                <MenuItem value={InstitutionsSegments[key]} key={key}>
                  {InstitutionsSegments[key]}
                </MenuItem>
              ))}
              <MenuItem value={'Público'}>{'Público'}</MenuItem>
            </ControlledTextField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <ControlledTextField
              name={'uf'}
              control={control}
              errors={formState.errors.uf?.message}
              label={'Estado'}
              select
              onChange={(event) => {
                setValue('uf', event.target.value);
                setValue('city', '');
              }}
            >
              {STATES_ARRAY.map(({ uf }) => (
                <MenuItem value={uf} key={uf}>
                  {States[uf]}
                </MenuItem>
              ))}
            </ControlledTextField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name={'city'}
              control={control}
              render={({ field }) => (
                <Autocomplete
                  renderInput={(props) => (
                    <UITextField
                      label="Cidade"
                      error={!!formState.errors?.city?.message}
                      helperText={formState.errors?.city?.message}
                      {...props}
                    />
                  )}
                  {...field}
                  options={[...citiesData, '']}
                  onChange={(_, value) => {
                    _.preventDefault();
                    if (value && !Array.isArray(value)) {
                      setValue('city', value);
                    } else {
                      setValue('city', '');
                    }
                  }}
                  value={watch('city') || ''}
                  fullWidth
                  loading
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ControlledTextField
              name={'creditLimit'}
              control={control}
              label={'(R$) Limite de Crédito'}
              errors={formState.errors?.creditLimit?.message}
              pattern="money"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ControlledTextField
              name={'employeeId'}
              control={control}
              errors={formState.errors.employeeId?.message}
              label={'Consultor Responsável'}
              select
              onChange={(event) => {
                setValue('employeeId', +event.target.value);
              }}
            >
              {consultants?.map((consultant) => (
                <MenuItem value={consultant?.id} key={consultant?.id}>
                  {consultant?.sap} - {consultant?.name}
                </MenuItem>
              ))}
            </ControlledTextField>
          </Grid>
          <Grid item xs={12} sm={2}>
            <ControlledRadioGroup
              control={control}
              label={'Órgão Público'}
              name={'organization'}
              options={OptionsBooleanToYes}
              value={Number(watch('organization'))}
              onChange={(event) => {
                setValue('organization', Boolean(+event.target.value));
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <ControlledRadioGroup
              control={control}
              label={'Recolhe ST'}
              name={'collectSt'}
              options={OptionsBooleanToYes}
              value={Number(watch('collectSt'))}
              onChange={(event) => {
                setValue('collectSt', Boolean(+event.target.value));
              }}
            />
          </Grid>
        </Grid>
        <Grid mt={4} item container justifyContent="flex-end" spacing={2}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Button type="submit" variant="contained" color="primary">
              Confirmar Alterações
            </Button>
          )}
        </Grid>
      </form>
    </Box>
  );
}
