import { States } from 'utils/Enums';
import { ERRORS } from 'utils/Errors';
import * as yup from 'yup';

export interface PrivateProcessProposalSummaryInputs {
  employeeSap?: string;
  employeeName?: string;
  regional?: string;
  institutionId?: number;
  institutionName?: string;
  uf?: States;
  city?: string;
  serviceChannel?: string;
  /* Class refers to classification */
  class?: string | null;
  secretaryClassification?: string | null;
  achievementType?: string | null;
  focusUf: 1 | 0;
  isBidding: 1 | 0;
  observation?: string;
}

export const PrivateProcessProposalSummaryValidation = yup.object({
  institutionId: yup.string().required(ERRORS.REQUIRED_FIELD),
  institutionName: yup.string().required(ERRORS.REQUIRED_FIELD),
  regional: yup.string().required(ERRORS.REQUIRED_FIELD),
  uf: yup.string().required(ERRORS.REQUIRED_FIELD),
  city: yup.string().required(ERRORS.REQUIRED_FIELD),
  serviceChannel: yup
    .string()
    .optional()
    .nullable()
    .when('institutionId', {
      is: (institutionId: string) => !!institutionId,
      then: yup.string().required(ERRORS.REQUIRED_FIELD)
    }),
  achievementType: yup
    .string()
    .optional()
    .nullable()
    .when('serviceChannel', {
      is: (serviceChannel: string) =>
        ['Hospitalar', 'Concessionária de alimentos'].includes(
          serviceChannel || ''
        ),
      then: yup.string().required(ERRORS.REQUIRED_FIELD)
    }),
  secretaryClassification: yup
    .string()
    .optional()
    .nullable()
    .when('serviceChannel', {
      is: (serviceChannel: string) =>
        !!(
          !['Hospitalar', 'Concessionária de alimentos'].includes(
            serviceChannel || ''
          ) && serviceChannel
        ),
      then: yup.string().required(ERRORS.REQUIRED_FIELD)
    }),
  observation: yup.string().optional().nullable()
});
