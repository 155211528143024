import { Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import { UIChip } from 'shared/components/Chip';
import { ThemeColors } from 'shared/enum';
import { RivalProductsModel } from 'types/models';

export interface RivalProductCardProps {
  rivalProduct: RivalProductsModel;
}

export function RivalProductCard({ rivalProduct }: RivalProductCardProps) {
  return (
    <Paper
      sx={{
        borderLeft: `5px solid ${ThemeColors.Error}`
      }}
    >
      <Grid item container justifyContent={'space-between'} sx={{ my: 1 }}>
        <Grid item xs={2} sx={{ pl: 2, pt: 1 }}>
          <UIChip
            color={'white'}
            title={'Concorrentes'}
            backgroundColor={ThemeColors.Error}
            height={'20px'}
          />
        </Grid>
      </Grid>
      <Grid item container justifyContent={'center'} sx={{ mb: 1 }} pb={2}>
        <Grid item xs={12} sx={{ pb: 2, pl: 1 }}>
          <Typography variant={'h2'}> {rivalProduct.description} </Typography>
        </Grid>
        <Grid item xs={6} sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
          <Typography variant={'h5'} color={'inherit'} textAlign={'center'}>
            Empresa
          </Typography>
          <Typography textAlign={'center'}>{rivalProduct.company}</Typography>
        </Grid>
        <Grid item xs={6} sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
          <Typography variant={'h5'} color={'inherit'} textAlign={'center'}>
            Gramatura
          </Typography>
          <Typography textAlign={'center'}>
            {rivalProduct?.grammage || '-'}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
