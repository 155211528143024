import React from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Typography } from '@mui/material';
import { ThemeColors } from 'shared/enum';
import * as S from './styles';

export type SmallCardProps = {
  borderColor?: ThemeColors;
  title?: string;
  number?: number | string;
  percentage?: number;
};

const SmallCard = ({
  title,
  number,
  percentage,
  borderColor
}: SmallCardProps) => {
  return (
    <S.Wrapper borderColor={borderColor}>
      <S.Title>{title}</S.Title>
      <S.Data>
        <Typography fontWeight={'bold'}>{number}</Typography>
        {percentage !== undefined && (
          <S.Percentage
            color={percentage >= 0 ? ThemeColors.Success : ThemeColors.Error}
          >
            <Typography color="inherit" fontWeight={'bold'}>
              {percentage}%
            </Typography>
            {percentage >= 0 ? (
              <ArrowUpwardIcon fontSize="small" color="inherit" />
            ) : (
              <ArrowDownwardIcon fontSize="small" color="inherit" />
            )}
          </S.Percentage>
        )}
      </S.Data>
    </S.Wrapper>
  );
};

export default SmallCard;
