import React from 'react';
import { Control, Controller, Path } from 'react-hook-form';
import { UIInputProps, UITextField } from 'shared/components/Inputs';

interface ControlledFieldProps<T> {
  name: Path<T>;
  label: string;
  control: Control<T>;
  errors?: string;
  children?: React.ReactNode;
}
export function ControlledTextField<T>({
  name,
  control,
  label,
  errors,
  ...rest
}: ControlledFieldProps<T> & UIInputProps) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <UITextField
            label={label}
            error={!!errors}
            helperText={errors}
            fullWidth
            {...field}
            value={field.value || ''}
            {...rest}
          />
        );
      }}
    />
  );
}
