import React, { useState } from 'react';
import {
  AppBar,
  Box,
  Container,
  Drawer,
  Grid,
  IconButton,
  Toolbar
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import MenuIcon from '@mui/icons-material/Menu';
import { HeaderProfile } from 'shared/components/Header/HeaderProfile';
import { HeaderOpt } from 'shared/components/Header/HeaderOpts';
import { HeaderOptions } from 'types/Config/Headers';

const DrawerStyles = withStyles({
  paperAnchorLeft: {
    width: '250px'
  }
})(Drawer);

interface HeaderMobileProps {
  options: HeaderOptions[];
}

export function HeaderMobile({ options }: HeaderMobileProps) {
  const [open, setOpen] = useState<boolean>(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
      <AppBar position={'static'}>
        <Container maxWidth={'xl'}>
          <Grid item container>
            <Grid item xs={2} sm={2}>
              <Toolbar>
                <IconButton onClick={toggleDrawer} size="large">
                  <MenuIcon color={'secondary'} />
                </IconButton>
              </Toolbar>
            </Grid>
            <Grid item container xs={10} justifyContent={'flex-end'}>
              <Grid item xs={12}>
                <HeaderProfile />
              </Grid>
            </Grid>
          </Grid>
          <DrawerStyles anchor={'left'} open={open} onClose={toggleDrawer}>
            <Box mt={2}>
              <HeaderOpt options={options} />
            </Box>
          </DrawerStyles>
        </Container>
      </AppBar>
      <Box mb={2} />
    </>
  );
}
