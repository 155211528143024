import { Grid } from '@mui/material';
import React from 'react';
import { ProcessModel } from 'types/models';
import { ProcessInformationBody } from './ProcessInformationBody';
import { ProcessInformationFooter } from './ProcessInformationFooter';
import { ProcessInformationHeader } from './ProcessInformationHeader';

export type ProcessInformationProps = {
  process?: ProcessModel;
};

export function ProcessInformation({ process }: ProcessInformationProps) {
  return (
    <Grid container bgcolor={'white'} borderRadius={2}>
      <ProcessInformationHeader
        segment={process?.segment}
        clientCode={process?.distributor?.sold}
        clientName={process?.distributor?.name}
      />
      <ProcessInformationBody process={process} />
      <ProcessInformationFooter process={process} />
    </Grid>
  );
}
