import React from 'react';
import { UITable } from 'shared/components';
import { ValidateOfficialProcessItemsColumns } from 'modules/Process/PublicProcess/Tables/ValidateProcessItems/Columns';
import { UITableProps } from 'shared/components/Tables/UITable';
import { makeStyles } from '@mui/styles';
import { ProcessProductModel } from 'types/models';
import { UIToolbar } from 'shared/components/Tables/ToolbarTable';
import { Grid, Typography } from '@mui/material';
import { UIDataGridApiPagination } from 'shared/components/Tables/DataGridApiPagination';

export interface ValidateOfficialProcessItemsTable extends UITableProps {
  onEdit: (data: ProcessProductModel) => void;
  products: Partial<ProcessProductModel>[];
}

const useStyles = makeStyles({
  root: {
    '& .super-app-theme--winner-cell': {
      backgroundColor: 'rgba(101, 172, 40, 0.25)'
    },
    '& .super-app-theme--lost-cell': {
      backgroundColor: 'rgba(201, 80, 80, 0.16)'
    }
  }
});

export function ValidateProcessItemsTable({
  onEdit,
  products,
  ...rest
}: ValidateOfficialProcessItemsTable) {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <div className={classes.root}>
        <UITable
          columns={ValidateOfficialProcessItemsColumns}
          rows={products}
          onRowDoubleClick={({ row }) => onEdit(row as ProcessProductModel)}
          rowHeight={36}
          headerHeight={36}
          pageSize={5}
          components={{
            Toolbar: () => (
              <UIToolbar
                title={<Typography variant="h2">Itens aprovados</Typography>}
                elementsTopEnd={<UIDataGridApiPagination />}
              />
            )
          }}
          getRowClassName={(params) => {
            if (params?.row?.winner === true)
              return `super-app-theme--winner-cell`;
            if (params?.row?.winner === false)
              return `super-app-theme--lost-cell`;
            return '';
          }}
          {...rest}
        />
      </div>
    </Grid>
  );
}
