export const AdminEditStatus: {
  value: string;
  text: string;
  tooltipText: string;
  color: 'green' | 'red' | 'blue' | 'orange' | 'default' | 'white' | 'pink';
}[] = [
  {
    value: 'dist recording',
    text: 'Distribuidor Cadastrando',
    tooltipText: '',
    color: 'orange'
  },
  {
    value: 'const recording',
    text: 'Consultor Cadastrando',
    tooltipText: '',
    color: 'orange'
  },
  {
    value: 'awaiting consultant result',
    text: 'Aguard. Resultado Consultor',
    tooltipText: '',
    color: 'orange'
  },
  {
    value: 'awaiting distributor result',
    text: 'Aguard. Resultado Distribuidor',
    tooltipText: '',
    color: 'orange'
  },
  {
    value: 'awaiting order',
    text: 'Em andamento',
    tooltipText: '',
    color: 'green'
  },
  {
    value: 'finished',
    text: 'Finalizado',
    tooltipText: '',
    color: 'blue'
  }
];
