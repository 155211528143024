import { theme } from 'shared/theme';
import styled from 'styled-components';

export const DefaultCard = styled.div<{ borderColor?: string }>`
  border-left: 5px solid;
  border-color: ${({ borderColor = theme.colors.Primary }) => borderColor};
  border-radius: 4px;
  background: #fff;
  padding: 0.5rem 0.8rem;
`;
