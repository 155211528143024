import React from 'react';
import { ProcessBasicInformationForm } from 'modules/Process/PublicProcess/Forms/ProcessBasicInformation';
import { ProcessBasicInformationDomainResponse } from 'modules/Process/PublicProcess/Forms/ProcessBasicInformation/domain';
import { usePatch, usePost, useQuery } from 'services';

import { ProcessModel } from 'types/models';
import { toast } from 'react-toastify';
import { LinearProgress } from '@mui/material';

import { DistributorProcessBasicProps } from 'modules/Process/PublicProcess/Forms/DistributorProcessForm/DistributorProcessBasic/DistributorProcessBasic';
import history from 'routes/History';

export function DistributorProcessBasic({
  id,
  onComplete,
  onFail,
  distributorId
}: DistributorProcessBasicProps) {
  const ProcessQuery = useQuery<ProcessModel>(`/process/${id}`, {
    fetchOnInitialize: !!id
  });
  const processLoading = ProcessQuery[2];
  const process = ProcessQuery[0];

  const [, processPostFetch, postLoading] = usePost<
    Partial<ProcessModel>,
    Partial<ProcessModel>
  >('/process');

  const [, processPatchFetch, patchLoading] = usePatch('/process');

  /* should create or update a process  */
  const onCompleteProcessBasicInformation = async (
    data: ProcessBasicInformationDomainResponse
  ) => {
    const queryData = {
      ...data,
      distributorId: +distributorId,
      processDirect: false
    };
    if (!process) {
      const response = await processPostFetch(queryData);

      if (response) {
        history.replace({
          pathname: `/cadastro/processo/distribuidor`,
          search: `?processo=${response.id}`
        });
        onComplete();
      } else {
        toast.error('Falha ao criar o processo ');
        if (onFail) {
          onFail();
        }
      }
    } else if (process && id) {
      await processPatchFetch(queryData, +id);
      onComplete();
    } else if (onFail) {
      onFail();
    }
  };

  return processLoading ? (
    <LinearProgress />
  ) : (
    <ProcessBasicInformationForm
      onComplete={onCompleteProcessBasicInformation}
      process={process || {}}
      loading={postLoading || patchLoading}
    />
  );
}
