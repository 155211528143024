/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
import { useState } from 'react';
import { toast } from 'react-toastify';
import { ERRORS } from 'utils/Errors';
import api from '../config';

export type FetchDelete<ModelType> = (
  id: number
) => Promise<ModelType | undefined>;

export type UseDeleteReturn<ModelType> = [
  ModelType | undefined,
  FetchDelete<ModelType>,
  boolean,
  any
];

/*
 * @Returns
 * removedData, fetchDelete, loadingDelete, errorsOnDelete
 */
export function useDelete<ModelType = unknown>(
  query: string
): UseDeleteReturn<ModelType> {
  const [loading, setLoading] = useState<boolean>(false);
  const [responseData, setResponseData] = useState<ModelType | undefined>();
  const [error, setError] = useState<unknown>(null);

  async function fetch(id: number): Promise<ModelType | undefined> {
    try {
      if (id) {
        setLoading(true);
        const urlQuery = `${query}/${id}`;
        const response = await api.delete<ModelType>(urlQuery);
        if (response.status === 200) {
          setResponseData(response.data);
          return response.data;
        }
        setLoading(false);
      }
      toast.error(ERRORS.SOMETHING_WENT_WRONG);
    } catch (err) {
      toast.error(ERRORS.SOMETHING_WENT_WRONG);
      setLoading(false);
      setError(err);
    }
  }

  return [responseData, fetch, loading, error];
}
