import React, { createContext, useContext, useEffect, useState } from 'react';

import { AccessModel } from 'types/models';

type UserAuthType = Partial<AccessModel> & {
  realId?: number;
  type?: 'distributor' | 'employee';
};

export interface UserContext {
  auth?: UserAuthType;
  setAuth: (data: UserAuthType) => void;
  token: string | null;
  setToken: (token: string) => void;
}

export const AuthContext = createContext<UserContext>({
  auth: {},
  setAuth: (auth: UserAuthType) => auth,
  token: '',
  setToken: (token: string) => token
});

export function AuthProvider({ children }) {
  const [auth, setAuth] = useState<UserAuthType>();
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    const localAuth = localStorage.getItem('user');
    const localToken = localStorage.getItem('token');
    if (localAuth && localToken) {
      const jsonAuth = JSON.parse(localAuth) as Partial<AccessModel>;
      setAuth({
        ...jsonAuth,
        realId: jsonAuth.distributorId ?? jsonAuth.employeeId,
        type: jsonAuth.distributorId ? 'distributor' : 'employee'
      });
      setToken(localToken);
    }
  }, [setAuth, setToken]);

  return (
    <AuthContext.Provider value={{ auth, token, setToken, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);
