import { Grid, Typography } from '@mui/material';
import React from 'react';
import { UITable } from 'shared/components';
import { UIDataGridApiPagination } from 'shared/components/Tables/DataGridApiPagination';
import { UIToolbar } from 'shared/components/Tables/ToolbarTable';
import { theme } from 'shared/theme';
import { OrderItems } from 'types/models/Order/Order.model';
import { OrderItemsColumns } from './Constants/columns';

type Props = {
  orderItems: OrderItems[];
  loading: boolean;
};
export default function OrderDetailsTable({ orderItems, loading }: Props) {
  return (
    <>
      <Grid container item pt={2} pb={2} alignItems="center">
        <Typography
          variant="h2"
          style={{ fontSize: '20px', marginRight: '15px' }}
        >
          Itens selecionados
        </Typography>
        <Typography variant="h5" style={{ color: theme.colors.TextGrey }}>
          {orderItems?.length}
          {orderItems?.length !== 1
            ? ' itens selecionados'
            : ' item selecionado'}
        </Typography>
      </Grid>
      <UITable
        rows={orderItems}
        columns={OrderItemsColumns}
        rowHeight={36}
        headerHeight={36}
        pageSize={5}
        components={{
          Toolbar: () => (
            <UIToolbar
              title={<Typography variant="h2">Itens do pedido</Typography>}
              elementsTopEnd={<UIDataGridApiPagination />}
            />
          )
        }}
        loading={loading}
      />
    </>
  );
}
