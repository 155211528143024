type VerifyMakeOrderButtonProps = {
  processEmpty: boolean;
  status?: string;
  type?: string;
  segment?: string;
  distributorHasAccess: boolean;
};

export const VerifyMakeOrderButton = ({
  processEmpty,
  status,
  type,
  segment,
  distributorHasAccess
}: VerifyMakeOrderButtonProps): boolean => {
  if (processEmpty || status !== 'awaiting order') return false;
  if (type === 'distributor') return true;
  if (type === 'consultant' && segment === 'private' && !distributorHasAccess)
    return true;
  return false;
};
