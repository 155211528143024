import { Container, Typography } from '@mui/material';
import { InstitutionFormFragment } from 'modules/Admin/Institutions/fragments/InstitutionFormFragment';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'services';
import { UIBreadcrumb, UILink } from 'shared/components';
import TitleWithSubtitle from 'shared/components/TitleWithSubtitle';
import { InstitutionModel } from 'types/models';
import { UrlParams } from 'types/services';

export function GovernmentInstitutionCreateUpdatePage() {
  const params = useParams() as UrlParams;
  const id = params?.id;

  const [institution] = useQuery<InstitutionModel, InstitutionModel>(
    `/institution/${id}`,
    {
      fetchOnInitialize: !!+id
    }
  );

  return (
    <Container maxWidth={'lg'}>
      <UIBreadcrumb>
        <UILink to={'/dashboard'}> Dashboard </UILink>
        <UILink to={'/instituicao'}> Instituições </UILink>
        <Typography color={'primary'}>
          {id
            ? 'Editar Instituição Governamental'
            : 'Cadastrar Instituição Governamental'}
        </Typography>
      </UIBreadcrumb>
      <TitleWithSubtitle
        title="Instituição Governamental"
        subtitle="Preencha as informações da instituição"
      />
      <InstitutionFormFragment
        type="government"
        id={+id}
        institution={institution}
      />
    </Container>
  );
}
