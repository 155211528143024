/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, CircularProgress, Grid, MenuItem } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { usePatch } from 'services';
import { ControlledTextField } from 'shared/components/ControlledComponents/ControlledTextField';
import UIPaper from 'shared/components/Paper';
import { UfsRegionals } from 'types/models';
import { States, STATES_ARRAY } from 'utils/Enums';
import { AlterUfsRegionals, AlterUfsRegionalsSchema } from './domain';

export interface AlterRegionalProps {
  ufsRegionals: UfsRegionals[];
  refetch: () => void;
}

export function AlterRegionalForm({
  ufsRegionals,
  refetch
}: AlterRegionalProps) {
  const [, patchRegional, patchLoading] =
    usePatch<UfsRegionals>('/ufs-regionals');

  const { handleSubmit, formState, control, setValue, reset } =
    useForm<AlterUfsRegionals>({
      resolver: yupResolver(AlterUfsRegionalsSchema),
      mode: 'onTouched'
    });

  const onComplete = async (data: AlterUfsRegionals) => {
    const findUfRegional = (ufsRegionals || []).find(
      (ufRegional) => ufRegional.uf === data.uf
    );
    if (findUfRegional?.id) {
      await patchRegional(data, findUfRegional.id);
      toast.success(
        `Estado: ${States[data?.uf]}, vinculado a regional: ${data.regional}`
      );
      reset();
      refetch();
    } else {
      toast.success(`Erro ao vincular estado e regional`);
    }
  };

  return (
    <Grid container item xs={12} sm={6} md={5} height="100%">
      <UIPaper title="Alterar Regional" width="100%" mt={0}>
        <>
          <form onSubmit={handleSubmit(onComplete)}>
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <ControlledTextField
                  name={'uf'}
                  control={control}
                  label={'Estado'}
                  select
                  onChange={(event) => {
                    const uf = event.target.value as keyof typeof States;
                    setValue('uf', uf);
                    setValue(
                      'regional',
                      ufsRegionals.find((ufRegional) => ufRegional.uf === uf)
                        ?.regional || ''
                    );
                  }}
                >
                  {STATES_ARRAY.map(({ uf }) => (
                    <MenuItem value={uf} key={uf}>
                      {States[uf]}
                    </MenuItem>
                  ))}
                </ControlledTextField>
              </Grid>
              <Grid item xs={12}>
                <ControlledTextField
                  name={'regional'}
                  control={control}
                  label={'Regional'}
                  select
                >
                  <MenuItem value={'CNN'}>CNN</MenuItem>
                  <MenuItem value={'SSP'}>SSP</MenuItem>
                </ControlledTextField>
              </Grid>
            </Grid>
            <Grid mt={4} item container justifyContent="flex-end">
              {patchLoading ? (
                <CircularProgress />
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  color="primary"
                  disabled={!formState?.isValid}
                >
                  Alterar Regional
                </Button>
              )}
            </Grid>
          </form>
        </>
      </UIPaper>
    </Grid>
  );
}
