import { ProcessModel } from 'types/models';
import { ServicesChannels } from 'utils/Enums/ServicesChannels';

interface FocusUfParams {
  serviceChannel: ServicesChannels;
  publicGovernment?: boolean;
}

const NotAllowedClassifications = ['Hospitalar', 'Concessionária de alimentos'];

export const FocusUfDomain = ({
  serviceChannel,
  publicGovernment = false
}: FocusUfParams) => {
  return !(
    NotAllowedClassifications.includes(serviceChannel) || publicGovernment
  );
};

export const GetFocusUfValue = (process?: ProcessModel) => {
  if (process?.institution?.focus === false) return 'Não';
  if (process?.institution?.focus === true) return 'Sim';
  return process?.focusUf ? 'Sim' : 'Não';
};
