import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { PendenciesTable } from 'modules/Dashboard/Tables/PendenciesTable';
import { Observations } from 'modules/Process/History/Fragments/Observations';
import { OrderInExecution } from 'modules/Process/History/Fragments/OrderInExecution';
import { ProcessHistoryItemTable } from 'modules/Process/History/Fragments/ProcessHistoryItemTable';
import { ProcessInformation } from 'modules/Process/History/Fragments/ProcessInformation';
import { UploadSection } from 'modules/Process/History/Fragments/UploadSection';
import { StatusStepper } from 'modules/Process/shared/Fragment/StatusStepper';
import { ConsultantBottomButton } from 'pages/Process/ProcessHistory/shared/buttons/bottom/ConsultantBottomButton';
import { DistributorBottomButton } from 'pages/Process/ProcessHistory/shared/buttons/bottom/DistributorBottomButton';
import { HistoryHeaderButtons } from 'pages/Process/ProcessHistory/shared/buttons/header/HistoryHeaderButtons';
import { useAuth } from 'Providers/Auth';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { usePatch, usePost, useQuery } from 'services';
import { useDelete } from 'services/api/UseDelete';
import { UILink } from 'shared/components';
import { UIBreadcrumb } from 'shared/components/Breadcrumb';
import {
  AccessModel,
  Comments,
  PendenciesModel,
  ProcessModel,
  ProcessProductModel
} from 'types/models';
import { AttachmentModel } from 'types/models/Attachments/Attachment.model';
import { OrderModel } from 'types/models/Order/Order.model';
import { UrlParams } from 'types/services';

export function ProcessHistory() {
  const params = useParams() as UrlParams;
  const id = params?.id;

  const [processData, processRefetch, processLoading] = useQuery<
    ProcessModel,
    ProcessModel
  >(`/process/${id}`, {
    notInclude: ['comments', 'pendencies', 'process_products']
  });

  const [processProducts, processProductsRefetch, processProductLoading] =
    useQuery<ProcessProductModel[], ProcessProductModel>(`/process-products`, {
      processId: id,
      $limit: -1
    });

  const [pendencies] = useQuery<PendenciesModel[]>('/pendencies', {
    $limit: -1,
    '$sort[createdAt]': -1,
    processId: id
  });

  const [orders] = useQuery<OrderModel[]>('/order', {
    $limit: -1,
    '$sort[createdAt]': -1,
    processId: id
  });

  const [, fetchAccess, loadingFetchAccess] = useQuery<
    AccessModel[],
    AccessModel
  >('/access', {
    $limit: -1,
    fetchOnInitialize: false
  });

  const [comments, commentsRefetch, commentsLoading] = useQuery<
    Comments[],
    Comments
  >(`/comments`, {
    $limit: '-1',
    processId: id
  });

  const [attachments, attachmentsRefetch, attachmentsLoading] = useQuery<
    AttachmentModel[],
    AttachmentModel
  >(`/attachments`, {
    $limit: '-1',
    tableId: id,
    table: 'process'
  });

  const [, postComment] = usePost<Partial<Comments>, Comments>(`/comments`);
  const [, patchComment] = usePatch<Comments>(`/comments`);
  const [, deleteComment] = useDelete<Comments>(`/comments`);

  useEffect(() => {
    processRefetch();
    processProductsRefetch();
    commentsRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const { auth } = useAuth();

  const process = { ...processData, process_products: processProducts || [] };

  return (
    <Container maxWidth={'lg'}>
      <UIBreadcrumb>
        <UILink to={'/'} color={'inherit'}>
          Dashboard
        </UILink>
        <UILink to={`/processo/${id}`} color={'inherit'}>
          Processo
        </UILink>
        <Typography color={'primary'}> {id} </Typography>
      </UIBreadcrumb>
      <Box mb={2}>
        {id && (
          <HistoryHeaderButtons
            id={id}
            type={
              auth?.distributor
                ? 'distributor'
                : auth?.employee?.accessLevel || ''
            }
            process={process || {}}
            fetchAccess={fetchAccess}
            orders={orders || []}
            loadingFetchAccess={loadingFetchAccess}
          />
        )}
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StatusStepper status={process?.status} sx={{ my: 4 }} />
        </Grid>
        <Grid container item xs={12} spacing={2} alignItems="stretch">
          <Grid container item xs={12} lg={8}>
            <ProcessInformation process={process} />
          </Grid>
          <Grid container spacing={2} item xs={12} lg={4}>
            <Grid item xs={12} md={6} lg={12}>
              {pendencies ? (
                <PendenciesTable
                  loading={processLoading}
                  pendencies={pendencies}
                  totalPendencies={pendencies.length}
                  autoHeight={false}
                  style={{ minHeight: '300px' }}
                />
              ) : null}
            </Grid>
            <Grid container item xs={12} md={6} lg={12} minHeight="300px">
              <OrderInExecution orders={orders || []} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Observations
            comments={comments || []}
            loading={commentsLoading}
            postComment={postComment}
            patchComment={patchComment}
            commentsRefetch={commentsRefetch}
            deleteComment={deleteComment}
            processId={+id}
          />
        </Grid>
        <UploadSection
          attachments={attachments || []}
          attachmentsLoading={attachmentsLoading}
          attachmentsRefetch={attachmentsRefetch}
          processId={+id}
          accessId={auth?.id}
        />
        <ProcessHistoryItemTable
          loading={processLoading || processProductLoading}
          process={process || {}}
          processProductsRefetch={processProductsRefetch}
        />
        {process?.status && process?.id && auth?.distributor?.id ? (
          <DistributorBottomButton
            status={process.status}
            userType={'distributor'}
            id={+process.id}
          />
        ) : null}
        {process?.status && process?.id && auth?.employee?.id ? (
          <ConsultantBottomButton
            status={process.status}
            userType={
              auth?.distributor
                ? 'distributor'
                : auth?.employee?.accessLevel || ''
            }
            id={+process.id}
          />
        ) : null}
        {process?.status &&
        !['completed', 'suspended', 'finished'].includes(process?.status) &&
        process?.id ? (
          <Grid item container justifyContent={'flex-end'}>
            <Grid item>
              <UILink to={`/processo/${process?.id}/suspender`}>
                <Button variant={'contained'}>Suspender processo</Button>
              </UILink>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
      <Box mb={4} />
    </Container>
  );
}
