import React from 'react';
import { Grid, MenuItem, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { AccessLevels } from 'utils/Constants/AccessLevels/AccessLevels';
import {
  CreateEmployeeFormRes,
  CreateEmployeeSchema
} from 'modules/Admin/Users/Employee/Forms/CreateEmployee/domain';
import { RegionalsConstant } from 'utils/Constants/Regionals';
import { AccessModel } from 'types/models';
import { FormProps } from 'shared/components/Inputs';

interface CreateEmployeeFormProps extends FormProps<CreateEmployeeFormRes> {
  access?: AccessModel;
}

export function CreateEmployeeForm({
  access,
  onComplete
}: CreateEmployeeFormProps) {
  const { values, handleChange, handleSubmit, errors, touched } = useFormik({
    initialValues: {
      employee: access?.employee || {
        name: '',
        sap: '',
        lastName: '',
        regional: '',
        accessLevel: ''
      },
      email: access?.email || ''
    },
    validationSchema: CreateEmployeeSchema,
    onSubmit: (fields) => {
      onComplete({ ...access, ...fields });
    }
  });

  return (
    <form onSubmit={handleSubmit} id={'employee-form'}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField
            label={'Nome'}
            name={'employee.name'}
            value={values.employee.name}
            onChange={handleChange}
            error={!!errors.employee?.name && touched.employee?.name}
            helperText={touched.employee?.name && errors.employee?.name}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label={'Sobrenome'}
            name={'employee.lastName'}
            value={values.employee.lastName}
            onChange={handleChange}
            error={!!errors.employee?.lastName && touched.employee?.lastName}
            helperText={touched.employee?.lastName || errors.employee?.lastName}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label={'Email'}
            name={'email'}
            value={values.email}
            onChange={handleChange}
            error={!!errors.email && touched.email}
            helperText={touched.email && errors.email}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label={'Nível de Acesso'}
            name={'employee.accessLevel'}
            value={values.employee.accessLevel}
            onChange={handleChange}
            error={
              !!errors.employee?.accessLevel && touched.employee?.accessLevel
            }
            helperText={
              touched.employee?.accessLevel && errors.employee?.accessLevel
            }
            fullWidth
            select
          >
            {AccessLevels.map(({ value, text }) => (
              <MenuItem value={value}> {text} </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label={'Regional'}
            name={'employee.regional'}
            value={values.employee.regional}
            onChange={handleChange}
            error={!!errors.employee?.regional && touched.employee?.regional}
            helperText={touched.employee?.regional && errors.employee?.regional}
            fullWidth
            select
          >
            {RegionalsConstant.map((regional) => (
              <MenuItem key={regional} value={regional}>
                {regional}
              </MenuItem>
            ))}
            <MenuItem key={'SEDE'} value={'SEDE'}>
              {'SEDE'}
            </MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label={'SAP'}
            name={'employee.sap'}
            value={values.employee.sap}
            onChange={handleChange}
            error={!!errors.employee?.sap && touched.employee?.sap}
            helperText={touched.employee?.sap && errors.employee?.sap}
            fullWidth
          />
        </Grid>
      </Grid>
    </form>
  );
}
