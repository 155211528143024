import * as yup from 'yup';
import { ERRORS } from 'utils/Errors';
import { MoneyRemoveMask } from 'utils/masks';

export const ScopeRegistrationSchema = yup.object({
  itemNumber: yup
    .number()
    .typeError(ERRORS.NUMBER_FORMAT)
    .required(ERRORS.REQUIRED_FIELD),
  specification: yup.string().required(ERRORS.REQUIRED_FIELD),
  quantity: yup
    .number()
    .min(1, `${ERRORS.NUMBER_GREATER_THAN} 0`)
    .required(ERRORS.REQUIRED_FIELD),
  approve: yup.boolean().required(ERRORS.REQUIRED_FIELD),
  justify: yup.string(),
  priceList: yup
    .string()
    .test('greaterThan', 'Insira um valor maior que 0', (val?: string) => {
      return val ? +MoneyRemoveMask(val) > 0 : false;
    }),
  discount: yup.boolean(),
  discountValue: yup.string().nullable(),
  markup: yup.string(),
  productId: yup.number().required(ERRORS.REQUIRED_FIELD)
});
