import { Box, Container, Typography } from '@mui/material';
import { ConsultantActionPendencies } from 'modules/Pendencies/ConsultantActionPendencies';
import { DistributorActionPendencies } from 'modules/Pendencies/DistributorActionPendencies';
import { FinancierActionPendencies } from 'modules/Pendencies/FinancierActionPendencies';
import { ManagerActionPendencies } from 'modules/Pendencies/ManagerActionPendencies';
import { PendenciesTabs } from 'modules/Pendencies/Tabs';
import { useAuth } from 'Providers/Auth';
import React, { useState } from 'react';
import { UIBreadcrumb, UILink } from 'shared/components';
import { theme } from 'shared/theme';

export function Pendencies() {
  const { auth } = useAuth();

  const [tab, setTab] = useState(0);
  const handleChangeTab = (newValue: number) => {
    setTab(newValue);
  };

  return (
    <Container maxWidth={'lg'}>
      <Box mb={3}>
        <UIBreadcrumb>
          <UILink to={'/'} color={'inherit'}>
            Dashboard
          </UILink>
          <Typography color={'primary'}> Pendências</Typography>
        </UIBreadcrumb>
      </Box>
      <Box mt={3} />
      <Typography
        variant="h2"
        style={{
          fontSize: theme.font.sizes.large
        }}
      >
        Pendências
      </Typography>
      <Box mt={2} />
      <Typography
        variant="h3"
        style={{
          fontSize: theme.font.sizes.medium,
          color: theme.colors.TextGrey
        }}
      >
        Reveja todas as pendências e as ações a serem executada.
      </Typography>
      <Box mt={3} />
      <PendenciesTabs tab={tab} handleChangeTab={handleChangeTab} />
      <Box mt={3} />

      {auth?.distributor ? <DistributorActionPendencies /> : <> </>}
      {auth?.employee?.accessLevel === 'consultant' ? (
        <ConsultantActionPendencies />
      ) : (
        <> </>
      )}
      {auth?.employee?.accessLevel === 'financier' ? (
        <FinancierActionPendencies />
      ) : (
        <> </>
      )}
      {auth?.employee?.accessLevel === 'manager' ? (
        <ManagerActionPendencies />
      ) : (
        <> </>
      )}
    </Container>
  );
}
