import {
  Button,
  Collapse,
  Grid,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import UIPaper from 'shared/components/Paper';
import { PdfRender } from 'shared/components/Pdf/HtmlRender';
import { theme } from 'shared/theme';
import { AttachmentModel } from 'types/models/Attachments/Attachment.model';
import { Paginated } from 'types/services';

interface InformResultPreviewDocumentParams {
  processDocument?: Paginated<AttachmentModel>;
  onInvalidDocument: () => void;
}

export function InformResultPreviewDocument({
  processDocument,
  onInvalidDocument
}: InformResultPreviewDocumentParams) {
  const [selectedDocument, setSelectedDocument] = useState<string>();
  const [openPdf, setOpenPdf] = useState<boolean>(false);

  useEffect(() => {
    if (processDocument?.data?.[0]?.key) {
      setSelectedDocument(processDocument?.data?.[0]?.key);
      setOpenPdf(true);
    }
  }, [processDocument]);

  return (
    <>
      <UIPaper
        title="Documento anexado"
        onClickOnTitle={() => setOpenPdf(!openPdf)}
      >
        <>
          <Collapse in={openPdf}>
            {processDocument?.data && selectedDocument ? (
              <>
                <TextField
                  label={'Selecione a Ata'}
                  onChange={(e) => setSelectedDocument(e.target.value)}
                  select
                  value={selectedDocument || ''}
                >
                  {processDocument?.data
                    .filter((attachment) => attachment.key !== undefined)
                    .map((attachment) => (
                      <MenuItem key={attachment?.key} value={attachment?.key}>
                        {attachment?.name} - {attachment?.size}
                      </MenuItem>
                    ))}
                </TextField>
                <PdfRender url={selectedDocument} mt={2} />
              </>
            ) : (
              <></>
            )}
          </Collapse>

          {!openPdf && (
            <Grid
              container
              justifyContent="center"
              onClick={() => setOpenPdf(!openPdf)}
            >
              <Typography
                variant="h4"
                style={{
                  fontWeight: theme.font.semiBold
                }}
              >
                Clique para expandir.
              </Typography>
            </Grid>
          )}
        </>
      </UIPaper>
      <Grid item container justifyContent={'flex-end'} xs={12} sx={{ mt: 2 }}>
        <Grid item>
          <Button onClick={onInvalidDocument} variant={'contained'}>
            Reportar anexo errado
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
