import { Link, Typography } from '@mui/material';
import React from 'react';
import { UIBreadcrumb } from 'shared/components/Breadcrumb';

export function CreatePrivateProcessBreadcrumb() {
  return (
    <UIBreadcrumb>
      <Link href={'/'} color={'inherit'}>
        Dashboard
      </Link>
      <Typography color={'primary'}>Cadastro do Processo Privado</Typography>
    </UIBreadcrumb>
  );
}
