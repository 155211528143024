import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import React from 'react';
import { UIChip } from 'shared/components/Chip';
import { FormatPercent } from 'utils/Functions/FormatPercent';
import { MoneyMask } from 'utils/masks';

export const ProcessHistoryItemsColumns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'id',
    hide: true
  },
  {
    field: 'product.itemNumber',
    headerName: 'Item',
    width: 80,
    valueGetter: ({ row }) => row?.itemNumber || '-',
    renderCell: ({ value }) => {
      return (
        <UIChip
          color="blue"
          title={value ? `${value}` : '-'}
          height="20px"
          width="80px"
          fontWeight="800"
        />
      );
    }
  },
  {
    field: 'specification',
    headerName: 'Descritivo',
    width: 120,
    valueGetter: ({ row }) => row?.specification || '-',
    cellClassName: 'super-app-theme--result-divisor super-app-theme--info-cell'
  },
  {
    field: 'quota',
    headerName: 'Cota',
    width: 100,
    valueGetter: ({ row }) => row?.quota || '-',
    cellClassName: 'super-app-theme--info-cell'
  },
  {
    field: 'initialQuantity',
    headerName: 'Quantidade',
    width: 120,
    valueGetter: ({ row }) => row?.initialQuantity || '-',
    cellClassName: 'super-app-theme--info-cell'
  },
  {
    field: 'unity',
    headerName: 'Unidade',
    width: 100,
    valueGetter: () => 'UNI',
    cellClassName: 'super-app-theme--info-cell'
  },
  {
    field: 'approve',
    headerName: 'Atende',
    width: 100,
    valueGetter: ({ row }) => row?.approve || false,
    valueFormatter: ({ value }) => {
      return value ? 'Sim' : 'Não';
    },
    cellClassName: (params: GridCellParams) => {
      if (params?.row?.approve)
        return 'super-app-theme--info-cell super-app-theme--result-yes';
      return 'super-app-theme--info-cell super-app-theme--result-no';
    }
  },
  {
    field: 'needFinancier',
    headerName: 'Necessita Financeiro?',
    width: 151,
    valueGetter: ({ row }) => (row?.discount_orders?.[0]?.id ? 'Sim' : 'Não'),
    cellClassName: (params: GridCellParams) => {
      if (params?.row?.discount_orders?.[0]?.id)
        return 'super-app-theme--info-cell super-app-theme--financier-yes';
      return 'super-app-theme--info-cell super-app-theme--financier-no';
    }
  },
  {
    field: 'product.sku',
    headerName: 'SKU',
    width: 100,
    valueGetter: ({ row }) => row?.product?.sku || '-',
    cellClassName: 'super-app-theme--info-cell'
  },
  {
    field: 'product.description',
    headerName: 'Produto',
    width: 250,
    valueGetter: ({ row }) => row?.product?.description || '-',
    cellClassName: 'super-app-theme--info-cell'
  },
  {
    field: 'quantityUsed',
    headerName: 'Quant./prod.',
    width: 100,
    valueGetter: ({ row }) => row?.maxQuantity - row?.quantity || '-',
    cellClassName: 'super-app-theme--info-cell'
  },
  {
    field: 'priceList',
    headerName: '(R$) Preço lista',
    width: 150,
    valueFormatter: ({ value }) => (value ? MoneyMask(`${value}`) : '-'),
    valueGetter: ({ row }) => row?.priceList,
    cellClassName: 'super-app-theme--info-cell super-app-theme--result-divisor'
  },
  {
    field: 'opportunityValue',
    headerName: '(R$) Valor da oportunidade',
    width: 180,
    valueGetter: ({ row }) => {
      if (row?.nfPrice && row?.initialQuantity)
        return row?.nfPrice * row?.initialQuantity;
      return 0;
    },
    valueFormatter: ({ value }) => MoneyMask(Number(value)),
    cellClassName:
      'super-app-theme--result-cell super-app-theme--result-divisor'
  },
  {
    field: 'winner',
    headerName: 'Resultado',
    cellClassName: 'super-app-theme--result-cell',
    renderCell: ({ value }) => {
      const content: {
        color: 'green' | 'red' | 'orange';
        value: string;
      } = {
        color: 'orange',
        value: 'Aguardando'
      };
      if (value === true) {
        content.color = 'green';
        content.value = 'Ganho';
      }
      if (value === false) {
        content.color = 'red';
        content.value = 'Perda';
      }
      return (
        <UIChip
          color={content.color}
          title={content.value || ''}
          height="20px"
        />
      );
    },
    valueFormatter: ({ value }) => value ?? '-',
    width: 120
  },
  {
    field: 'initialValue',
    headerName: '(R$) Preço Inicial',
    width: 150,
    valueFormatter: ({ value }) => (value ? MoneyMask(`${value}`) : '-'),
    valueGetter: ({ row }) => row?.initialValue,
    cellClassName: 'super-app-theme--result-cell'
  },
  {
    field: 'finalValue',
    headerName: '(R$) Preço Final',
    width: 150,
    valueFormatter: ({ value }) => (value ? MoneyMask(`${value}`) : '-'),
    valueGetter: ({ row }) => row?.finalValue,
    cellClassName: 'super-app-theme--result-cell'
  },
  {
    field: 'nfPrice',
    headerName: '(R$) Preço NF Liberado',
    width: 150,
    valueFormatter: ({ value }) => (value ? MoneyMask(`${value}`) : '-'),
    valueGetter: ({ row }) => row?.nfPrice,
    cellClassName: 'super-app-theme--result-cell'
  },
  {
    field: 'markup',
    headerName: 'Markup',
    width: 100,
    valueGetter: ({ row }) => row?.markup,
    valueFormatter: ({ value }) => (value ? FormatPercent(String(value)) : '-'),
    cellClassName: 'super-app-theme--result-cell'
  },
  {
    field: 'institutionValue',
    headerName: '(R$) Preço Instituição',
    width: 150,
    valueFormatter: ({ value }) => (value ? MoneyMask(`${value}`) : '-'),
    valueGetter: ({ row }) => row?.institutionValue,
    cellClassName: 'super-app-theme--result-cell'
  },
  {
    field: 'institutionValue',
    headerName: '(R$) Preço Instituição',
    width: 150,
    valueFormatter: ({ value }) => (value ? MoneyMask(`${value}`) : '-'),
    valueGetter: ({ row }) => row?.institutionValue,
    cellClassName: 'super-app-theme--result-cell'
  },
  {
    field: 'contractValue',
    headerName: '(R$) Valor do contrato',
    width: 180,
    valueGetter: ({ row }) => {
      if (row?.nfPrice && row?.initialQuantity)
        return row?.nfPrice * row?.initialQuantity;
      return 0;
    },
    renderCell: ({ value }) => {
      return (
        <UIChip
          color="default"
          title={value ? `${MoneyMask(Number(value))}` : '-'}
          height="20px"
          fontWeight="800"
        />
      );
    },
    cellClassName: 'super-app-theme--result-cell'
  },
  {
    field: 'balanceValue',
    headerName: '(R$) Saldo do contrato',
    width: 180,
    valueGetter: ({ row }) => {
      if (row?.nfPrice && row?.quantity) return row?.nfPrice * row?.quantity;
      return 0;
    },
    renderCell: ({ value }) => {
      return (
        <UIChip
          color="blue"
          title={value ? `${MoneyMask(Number(value))}` : '-'}
          height="20px"
          fontWeight="800"
        />
      );
    },
    cellClassName: 'super-app-theme--result-cell'
  },
  {
    field: 'quantity',
    headerName: 'Volume restante',
    width: 180,
    valueGetter: ({ row }) => {
      if (row?.maxQuantity && row?.quantity && row?.initialQuantity) {
        if (row?.maxQuantity - row?.quantity > row?.initialQuantity) return 0;
        return row?.quantity - (row?.maxQuantity - row?.initialQuantity);
      }
      return '-';
    },
    cellClassName: 'super-app-theme--result-cell'
  },
  {
    field: 'rivalCompany',
    headerName: 'Empresa concorrente',
    width: 150,
    valueGetter: ({ row }) => row?.rival_product?.company || '-',
    cellClassName: 'super-app-theme--end-cell'
  },
  {
    field: 'rivalProduct',
    headerName: 'Produto concorrente',
    width: 150,
    valueGetter: ({ row }) => row?.rival_product?.description || '-',
    cellClassName: 'super-app-theme--end-cell'
  }
];
