import styled from 'styled-components';
import { Tab } from '@mui/material';
import { theme } from 'shared/theme';

export const StyledTab = styled(Tab)`
  text-transform: capitalize;
  padding: 0;
  margin-right: 24px;
  min-height: 30px;
  min-width: 0px;

  color: ${() => theme.colors.TextGrey};

  &.Mui-selected {
    color: ${() => theme.colors.TextDarkGrey} !important;
  }
`;
