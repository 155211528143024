import React from 'react';
import { Button, Grid } from '@mui/material';
import history from 'routes/History';
import { DistributorInformResultButton } from 'pages/Process/ProcessHistory/Domain/distributor';

export function DistributorBottomButton({
  id,
  status,
  userType
}: {
  id: number;
  status: string;
  userType: string;
}) {
  return (
    <Grid container justifyContent={'flex-end'}>
      {DistributorInformResultButton({
        processStatus: status,
        user: userType
      }) ? (
        <Grid item>
          <Button
            variant={'contained'}
            onClick={() =>
              history.push(`/processo/${id}/informar-resultado/distribuidor`)
            }
          >
            Informar resultado
          </Button>
        </Grid>
      ) : null}
    </Grid>
  );
}
