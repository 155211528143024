import { CalcDiscountInput } from 'modules/Process/Domain/ProcessItem/Discount';
import { CalcMarkupInput } from 'modules/Process/Domain/ProcessItem/Markup';
import { ProcessProductModel } from 'types/models';
import { ERRORS } from 'utils/Errors';
import { PercentMaskRemove } from 'utils/masks';
import * as yup from 'yup';

export interface InformDiscountConsultant {
  justify: string;
  discountValue: string;
}

export const InformDiscountConsultantSchema = yup.object().shape({
  justify: yup.string().optional(),
  discountValue: yup.string().required(ERRORS.REQUIRED_FIELD).notOneOf(['0%'])
});

export const getAlterData = (
  processProduct: ProcessProductModel,
  discountValue: string
) => {
  const newDiscount = +PercentMaskRemove(discountValue).toFixed(2);
  const nfPrice = +CalcDiscountInput(
    processProduct.priceList || 0,
    newDiscount
  ).discountValue?.toFixed(2);
  const institutionValue = +CalcMarkupInput(
    nfPrice,
    +PercentMaskRemove(String(processProduct?.markup))
  )?.markup?.toFixed(2);

  return {
    newDiscount,
    nfPrice,
    institutionValue
  };
};
