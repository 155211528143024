/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Button, CircularProgress, Grid } from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { usePatch, usePost } from 'services';
import { ControlledTextField } from 'shared/components/ControlledComponents/ControlledTextField';
import { UITextField } from 'shared/components/Inputs';
import UIPaper from 'shared/components/Paper';
import { DistributorPricesModel, ProductsModel } from 'types/models';
import { MoneyMask, MoneyRemoveMask } from 'utils/masks';
import {
  CreateUpdateDistributorPrice,
  CreateUpdateDistributorPriceSchema
} from './domain';

export interface DistributorPriceFragmentProps {
  distributorId: number;
  products: ProductsModel[];
  distributorPrices: DistributorPricesModel[];
  refetch: () => void;
}

export function DistributorPriceForm({
  distributorId,
  products,
  distributorPrices,
  refetch
}: DistributorPriceFragmentProps) {
  const [, createDistributorPrice, postLoading] = usePost<
    Partial<DistributorPricesModel>,
    DistributorPricesModel
  >('/distributor-prices');
  const [, editDistributorPrice, patchLoading] =
    usePatch<DistributorPricesModel>('/distributor-prices');

  const { handleSubmit, formState, watch, control, setValue, reset } =
    useForm<CreateUpdateDistributorPrice>({
      resolver: yupResolver(CreateUpdateDistributorPriceSchema),
      mode: 'onTouched'
    });

  const selectedProduct = products.find(
    (product) => product.id === watch('productId')
  );

  const onComplete = async (data: CreateUpdateDistributorPrice) => {
    const submitData = {
      distributorId,
      ...data,
      price: +MoneyRemoveMask(data.price || '0')
    };
    if (data?.distributorPriceId) {
      await editDistributorPrice(submitData, data?.distributorPriceId);
      toast.success(
        `Preço do produto: ${selectedProduct?.sku}, editado com sucesso`
      );
    } else {
      await createDistributorPrice(submitData);
      toast.success(
        `Preço do produto: ${selectedProduct?.sku}, cadastrado com sucesso`
      );
    }
    reset();
    refetch();
  };

  return (
    <Grid container item xs={12} sm={6} md={5} height="100%">
      <UIPaper title="Cadastrar ou Editar Preço Lista" width="100%" mt={0}>
        <>
          <form onSubmit={handleSubmit(onComplete)}>
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name={'productId'}
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      renderInput={(props) => (
                        <UITextField label="Produto" {...props} />
                      )}
                      {...field}
                      options={products}
                      onChange={(_, value) => {
                        _.preventDefault();
                        if (value && !Array.isArray(value)) {
                          setValue('productId', value?.id);
                          const newDistributorPrice = distributorPrices?.find(
                            (distributorPrice) =>
                              distributorPrice.productId === value?.id
                          );
                          setValue(
                            'price',
                            newDistributorPrice?.price
                              ? MoneyMask(newDistributorPrice?.price)
                              : undefined
                          );
                          setValue(
                            'distributorPriceId',
                            newDistributorPrice?.id
                          );
                        } else {
                          setValue('productId', undefined);
                          setValue('price', undefined);
                          setValue('distributorPriceId', undefined);
                        }
                      }}
                      value={selectedProduct || {}}
                      getOptionLabel={(value) =>
                        value?.sku && value?.description
                          ? `${value?.sku} - ${value?.description}`
                          : ''
                      }
                      fullWidth
                      loading
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <ControlledTextField
                  name={'price'}
                  control={control}
                  label={'(R$) Preço Lista'}
                  errors={formState.errors?.price?.message}
                  pattern="money"
                />
              </Grid>
            </Grid>
            <Grid mt={4} item container justifyContent="flex-end">
              {postLoading || patchLoading ? (
                <CircularProgress />
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  color="primary"
                  disabled={!formState?.isValid}
                >
                  {watch('distributorPriceId')
                    ? 'Atualizar Preço Lista'
                    : 'Adicionar Preço Lista'}
                </Button>
              )}
            </Grid>
          </form>
        </>
      </UIPaper>
    </Grid>
  );
}
