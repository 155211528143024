/* eslint-disable react/no-unescaped-entities */
import { Box, Typography } from '@mui/material';
import React from 'react';
import { theme } from 'shared/theme';

export default function MakeOrderTitle() {
  return (
    <>
      <Box mt={3} />
      <Typography
        variant="h2"
        style={{
          fontSize: theme.font.sizes.large
        }}
      >
        Solicitar Pedido
      </Typography>
      <Box mt={2} />
      <Typography
        variant="h3"
        style={{
          fontSize: theme.font.sizes.medium,
          color: theme.colors.TextGrey
        }}
      >
        Selecione os itens e informe a quantidade por produto.
      </Typography>
      <Box mt={1} />
      <Typography
        variant="h3"
        style={{
          fontSize: theme.font.sizes.medium,
          color: theme.colors.TextGrey
        }}
      >
        Após isso informe o documento "Autorização de Fornecimento"
      </Typography>
      <Box mt={1} />
    </>
  );
}
