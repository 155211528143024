export const InstitutionsServicesChannelsArray = [
  'Programa governamental',
  'Cesta básica',
  'Concessionária de alimentos',
  'Hospitalar',
  'Prefeitura'
];

// export enum InstitutionsServicesChannelsEnum {
//   'Programa governamental' = 'programa governamental'
// }

export enum InstitutionsServicesChannelsEnum {
  'programa governamental' = 'Programa governamental'
}
