/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Button, Grid } from '@mui/material';
import { PoliticalPriceModal } from 'modules/Admin/Products/modals/PoliticalPriceModal';
import { ProductUfTable } from 'modules/Admin/Products/tables/ProductUfTable';
import React, { useState } from 'react';
import UIPaper from 'shared/components/Paper';
import { PoliticalPricesModel } from 'types/models';

type Props = {
  id?: number;
  politicalPrices: PoliticalPricesModel[];
  refetchPoliticalPrices: () => void;
};

export function PoliticalPricesFragment({
  id,
  politicalPrices,
  refetchPoliticalPrices
}: Props) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <UIPaper title="Política comercial de preços">
        {id ? (
          <Grid container>
            <Grid item xs={12}>
              <ProductUfTable politicalPrices={politicalPrices} />
            </Grid>
            <Grid item xs={12} container justifyContent={'flex-end'}>
              <Grid item xs={12} sm md={3}>
                <Button
                  fullWidth
                  variant={'contained'}
                  onClick={() => setOpen(true)}
                >
                  Adicionar política comercial
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            textAlign="center"
          >
            Preencha as informações iniciais do produto para fazer cadastrar a
            política comercial de preços
          </Grid>
        )}
      </UIPaper>
      <PoliticalPriceModal
        open={open}
        politicalPrices={politicalPrices}
        refetchPoliticalPrices={refetchPoliticalPrices}
        productId={id!}
        onClose={() => setOpen(false)}
      />
    </>
  );
}
