/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import {
  Autocomplete,
  Button,
  createFilterOptions,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField
} from '@mui/material';
import { useFormik } from 'formik';
import { SecretaryClassificationDomain } from 'modules/Process/Domain/SecretaryClassificationDomain';
import { ProposalSummaryValidation } from 'modules/Process/PublicProcess/Forms/ProposalSummary/domain';
import { useAuth } from 'Providers/Auth';
import React, { useEffect } from 'react';
import { useQuery } from 'services';
import { FormProps, UITextField } from 'shared/components/Inputs';
import {
  AccessModel,
  DistributorModel,
  EmployeeModel,
  InstitutionModel,
  ProcessModel
} from 'types/models';
import { InstitutionsServicesChannelsArray } from 'utils/Constants/InstitutionsServicesChannels';
import { ServicesChannels } from 'utils/Enums';

export interface ProposalSummaryProps extends FormProps<Partial<ProcessModel>> {
  process?: Partial<ProcessModel>;
}

export function ProposalSummaryForm({
  process,
  onComplete
}: ProposalSummaryProps) {
  const { auth } = useAuth();

  const { values, errors, touched, handleSubmit, setFieldValue, handleChange } =
    useFormik({
      initialValues: {
        distributor: process?.distributor
          ? process?.distributor
          : {
              sold: '',
              name: ''
            },
        institution: process?.institution
          ? process?.institution
          : {
              id: '',
              name: '',
              class: ''
            },
        employee: process?.employee
          ? process?.employee
          : auth?.employee
          ? auth?.employee
          : { id: '', sap: '', name: '' },
        employeeId: process?.employee?.id || auth?.employeeId || '',
        processDirect: process?.processDirect || false,
        serviceChannel: process?.serviceChannel || '',
        focusUf: process?.focusUf || false,
        secretaryClassification: process?.secretaryClassification || ''
      },
      validationSchema: ProposalSummaryValidation,
      onSubmit: (fields) => {
        const fieldsValues = {
          ...fields,
          institution: undefined,
          employee: undefined,
          distributor: undefined
        };
        if (fields.employee.id) {
          fieldsValues.employeeId = fields.employee.id;
        }
        if (fieldsValues.institution) {
          delete fieldsValues.institution;
        }
        if (fieldsValues.employee) {
          delete fieldsValues.employee;
        }
        if (fieldsValues.distributor) {
          delete fieldsValues.distributor;
        }

        onComplete(fieldsValues as Partial<ProcessModel>);
      }
    });

  useEffect(() => {
    if (auth?.employee && !process?.employee?.id) {
      setFieldValue('employee', auth?.employee);
    }
  }, [auth]);

  const [distributorResponse, , distributorLoading] = useQuery<
    DistributorModel[]
  >('/distributor', {
    $limit: -1
  });

  const [distributorAcessesResponse, , distributorAcessesLoading] = useQuery<
    AccessModel[]
  >('/access', {
    $limit: -1,
    'distributorId[$ne]': 'null',
    $select: ['id', 'distributorId'],
    notInclude: ['distributor', 'employee', 'balance']
  });

  const [institutionResponse] = useQuery<InstitutionModel[]>('/institution', {
    $limit: -1,
    disabled: 0
  });

  const [employeeResponse] = useQuery<EmployeeModel[]>('/employee', {
    $limit: -1
  });

  const distributorData =
    distributorResponse && distributorAcessesResponse
      ? distributorResponse.filter((distributor) => {
          const indexOf = distributorAcessesResponse.findIndex(
            (indexDistributor) =>
              indexDistributor.distributorId === distributor.id
          );
          return indexOf !== -1;
        })
      : [];

  const institutionData = institutionResponse || [];

  const employeeData = employeeResponse || [];

  const onChangeSold = (distributor: DistributorModel) => {
    if (distributor?.sold) {
      const findDistributor = distributorData.find(
        (dist) => distributor?.sold === dist?.sold
      );

      if (findDistributor) {
        setFieldValue('distributor', findDistributor);
        setFieldValue('distributorId', findDistributor?.id);
      }
    } else {
      setFieldValue('distributor', null);
    }
  };

  const onInstitutionChange = (institution: InstitutionModel) => {
    const findInstitution = institutionData.find(
      (inst) => inst?.name === institution?.name
    );
    if (findInstitution && findInstitution.name) {
      setFieldValue('institution', findInstitution);
      setFieldValue('institutionId', findInstitution?.id);
      setFieldValue('focusUf', !!findInstitution?.focus);
      setFieldValue('serviceChannel', findInstitution?.serviceChannel);
      if (findInstitution?.serviceChannel !== 'Programa governamental') {
        setFieldValue('secretaryClassification', null);
      }
    } else {
      setFieldValue('institution', null);
    }
  };

  const onEmployee = (employee: EmployeeModel) => {
    if (employee) {
      const findEmployee = employeeData.find(
        (searchEmployee) => searchEmployee?.sap === employee?.sap
      );
      if (findEmployee && findEmployee.sap) {
        setFieldValue('employee', findEmployee);
        setFieldValue('employeeId', findEmployee?.id);
      } else {
        setFieldValue('employee', null);
      }
    } else {
      setFieldValue('employee', {
        name: '',
        sap: ''
      });
    }
  };

  const filterOptions = createFilterOptions<InstitutionModel>({
    limit: 30
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Autocomplete
            defaultValue={values.distributor as DistributorModel}
            loading={distributorLoading || distributorAcessesLoading}
            renderInput={(params) => (
              <UITextField
                label={'Cliente (sold)'}
                error={
                  !!errors?.distributor?.sold && touched?.distributor?.sold
                }
                helperText={
                  touched?.distributor?.sold && errors?.distributor?.sold
                }
                value={values.distributor?.sold}
                {...params}
              />
            )}
            onChange={(event, value) => onChangeSold(value as DistributorModel)}
            options={distributorData}
            getOptionLabel={(option) => option?.sold || ''}
            disabled={!!process?.distributorId}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <UITextField
            label={'Cliente (Razão social)'}
            name={'distributor.name'}
            value={values.distributor?.name || ''}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Autocomplete
            options={institutionData}
            defaultValue={values.institution as InstitutionModel}
            getOptionLabel={(opt) =>
              opt?.uf ? `${opt?.name} - ${opt?.uf}` : ''
            }
            renderInput={(params) => (
              <UITextField
                value={values.institution?.name || ''}
                label={'Nome do Órgão Público/ Inst.'}
                error={!!errors.institution?.name && touched?.institution?.name}
                helperText={
                  touched?.institution?.name && errors.institution?.name
                }
                {...params}
              />
            )}
            onChange={(event, value) =>
              onInstitutionChange(value as InstitutionModel)
            }
            filterOptions={filterOptions}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Autocomplete
            options={employeeData}
            getOptionLabel={(opt) => opt.sap || ''}
            value={values.employee as EmployeeModel}
            renderInput={(params) => (
              <UITextField
                label={'SAP do Consultor'}
                onChange={handleChange}
                error={!!errors.employee?.sap && touched.employee?.sap}
                helperText={touched?.employee?.sap && errors.employee?.sap}
                {...params}
              />
            )}
            onChange={(event, value) => onEmployee(value as EmployeeModel)}
            disabled={auth?.employee?.accessLevel === 'consultant'}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <UITextField
            label={'Nome do Consultor'}
            value={values.employee?.name}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl
            component={'fieldset'}
            error={!!errors.processDirect}
            disabled
          >
            <FormLabel> Forma de participação</FormLabel>
            <RadioGroup
              name={'processDirect'}
              value={String(values.processDirect)}
              onChange={handleChange}
              row
            >
              <FormControlLabel
                value={'true'}
                control={<Radio color={'primary'} />}
                label={'Direta'}
              />
              <FormControlLabel
                value={'false'}
                control={<Radio color={'primary'} />}
                label={'Indireta'}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <UITextField
            label={'Canal de atendimento'}
            name={'serviceChannel'}
            onChange={handleChange}
            value={values.serviceChannel}
            error={!!errors.serviceChannel && touched.serviceChannel}
            helperText={touched?.serviceChannel && errors.serviceChannel}
            select
            fullWidth
            disabled
          >
            <MenuItem value={undefined} disabled>
              {' '}
            </MenuItem>
            {InstitutionsServicesChannelsArray.map((value) => (
              <MenuItem value={value} key={value}>
                {value}
              </MenuItem>
            ))}
          </UITextField>
        </Grid>
        <Grid item xs={12} sm={4}>
          <UITextField
            label={'Classificação'}
            name={'class'}
            value={values.institution?.class || ''}
            onChange={handleChange}
            fullWidth
            select
            disabled
            error={!!errors.institution?.class && touched.institution?.class}
            helperText={touched.institution?.class && errors.institution?.class}
          >
            <MenuItem value={'null'}>Não se aplica</MenuItem>
            <MenuItem value={'GOLD'}>Gold</MenuItem>
            <MenuItem value={'SILVER'}>Silver</MenuItem>
          </UITextField>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl component={'fieldset'} error={!!errors.focusUf} disabled>
            <FormLabel> Munícipio foco</FormLabel>
            <RadioGroup
              row
              name={'focusUf'}
              value={String(values.focusUf)}
              onChange={handleChange}
            >
              <FormControlLabel
                value={'true'}
                control={<Radio color={'primary'} />}
                label={'Sim'}
              />
              <FormControlLabel
                value={'false'}
                control={<Radio color={'primary'} />}
                label={'Não'}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            name={'secretaryClassification'}
            label={'Classificação da secretaria'}
            onChange={handleChange}
            fullWidth
            select
            disabled={
              SecretaryClassificationDomain({
                serviceChannel: values.serviceChannel as ServicesChannels,
                initialValue: null
              }).disabled
            }
            value={values.secretaryClassification}
            error={
              !!errors.secretaryClassification &&
              touched.secretaryClassification
            }
            helperText={errors.secretaryClassification}
          >
            <MenuItem value={'Secretaria de Educação'}>
              Secretaria de Educação
            </MenuItem>
            <MenuItem value={'Secretaria de Saúde'}>
              Secretaria de Saúde
            </MenuItem>
          </TextField>
        </Grid>
      </Grid>
      <Grid item container direction={'row-reverse'} mt={2}>
        <Grid item xs={12} sm={3}>
          <Button type={'submit'} fullWidth variant={'contained'}>
            Avançar
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
