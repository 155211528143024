/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { AxiosRequestConfig } from 'axios';
import { useEffect, useMemo, useState } from 'react';
import api from '../config';

export type Fields<Typed> = {
  [name in keyof Typed]?: string | number;
};

export type QueryOptions<Typed> = {
  $limit?: string | number;
  $select?: (keyof Typed)[] | string[];
  $skip?: number;
  notInclude?: string[];
  newQuery?: string;
  additionalAxiosOptions?: AxiosRequestConfig;
} & Fields<Typed> &
  Record<string, any>;

type InitialQueryOptions = {
  fetchOnInitialize?: boolean;
};

export type FetchQuery<ResponseType, ModelType> = (
  queryOptions?: QueryOptions<ModelType> | undefined
) => Promise<ResponseType | undefined>;

export type UseQueryReturn<ResponseType, ModelType = unknown> = [
  ResponseType | undefined,
  FetchQuery<ResponseType, ModelType>,
  boolean,
  any
];

/**
 * @param query
 * @param initialQueryParams
 * @return
 * data, resend, loading, errors
 */
export function useQuery<ResponseType, ModelType = unknown>(
  query: string,
  initialQueryParams?: QueryOptions<ModelType> & InitialQueryOptions
): UseQueryReturn<ResponseType, ModelType> {
  const initialQueryOptions = {
    ...initialQueryParams,
    fetchOnInitialize: initialQueryParams?.fetchOnInitialize ?? true
  } as QueryOptions<ModelType> & InitialQueryOptions;

  const [loading, setLoading] = useState<boolean>(false);
  const [responseData, setResponseData] = useState<ResponseType | undefined>();
  const [error, setError] = useState(null);

  async function resend(queryOptions?: QueryOptions<ModelType>) {
    try {
      setLoading(true);
      const url = queryOptions?.newQuery || query;
      if (queryOptions?.newQuery) {
        queryOptions.newQuery = undefined;
      }
      const config: AxiosRequestConfig = {
        ...queryOptions?.additionalAxiosOptions
      };
      if (queryOptions?.additionalAxiosOptions) {
        queryOptions.additionalAxiosOptions = undefined;
      }
      const params = {
        ...initialQueryOptions,
        ...queryOptions
      };
      delete params.fetchOnInitialize;
      config.params = params;
      const response = await api.get<ResponseType>(url, config);
      if (response.status === 200) {
        setResponseData(response.data);
        setLoading(false);
        return response.data;
      }
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      setError(err);
      if (err?.response?.status === 401) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
      }
    }
    return undefined;
  }

  useEffect(() => {
    if (initialQueryOptions.fetchOnInitialize) resend(initialQueryOptions);
  }, []);

  const memoizedData = useMemo(() => responseData, [responseData]);

  return [memoizedData, resend, loading, error];
}
