import * as yup from 'yup';
import { ERRORS } from 'utils/Errors';

// export interface NoticeAnalysisFormParams
//   extends FormProps<NoticeAnalysisComplete> {}

export interface NoticeAnalysisComplete {
  id?: number;
  item: number;
  specification: string;
  quantity: number;
  unity: 'UNI';
  approve: boolean;
  justify: string;
  sku: number;
  description: string;
  priceList: number;
  discount: boolean;
  discountValue?: number;
  nfPrice: number;
  markup: number;
  institutionValue: number;
}

export const NoticeAnalysisSchema = yup.object({
  tradingDate: yup.date().required(ERRORS.REQUIRED_FIELD),
  uf: yup.string().required(ERRORS.REQUIRED_FIELD),
  city: yup.string().required(ERRORS.REQUIRED_FIELD),
  quota: yup.string().required(ERRORS.REQUIRED_FIELD),
  regional: yup.string().required(ERRORS.REQUIRED_FIELD),
  newProgram: yup.string().required(ERRORS.REQUIRED_FIELD),
  isImprovement: yup.boolean().required(ERRORS.REQUIRED_FIELD),
  programName: yup.string().required(ERRORS.REQUIRED_FIELD),
  competitionModality: yup.string().required(ERRORS.REQUIRED_FIELD),
  tradingCode: yup.string().required(ERRORS.REQUIRED_FIELD),
  period: yup.number().required(ERRORS.REQUIRED_FIELD)
});
