import { Grid, Typography } from '@mui/material';
import { DistributorProfileFragment } from 'modules/Profiles/distributor/Fragments/DistributorProfile';
import { useAuth } from 'Providers/Auth';
import React from 'react';
import { UIBreadcrumb } from 'shared/components/Breadcrumb';
import { theme } from 'shared/theme';
import { UILink } from 'shared/components';

export function DistributorProfilePage() {
  const { auth } = useAuth();
  return (
    <>
      <UIBreadcrumb>
        <UILink to={'/dashboard'}> Dashboard </UILink>
        <Typography> Editar Informações </Typography>
      </UIBreadcrumb>
      <Grid container pt={3} pl={2} alignItems="center">
        <Typography
          variant="h2"
          style={{
            color: theme.colors.TextDarkGrey,
            fontWeight: theme.font.semiBold
          }}
        >
          Editar Informações
        </Typography>
      </Grid>
      <DistributorProfileFragment access={auth} />
    </>
  );
}
