import { Grid, Grow } from '@mui/material';
import { LoadingActions } from 'modules/Dashboard/Cards/Actions/LoadingActions';
import React from 'react';
import { useQuery } from 'services';
import { useDelete } from 'services/api/UseDelete';
import ActionCard from 'shared/components/Cards/ActionCard';
import { ProcessModel } from 'types/models';
import { Paginated } from 'types/services';
import lang from 'utils/lang';
import { HeaderActions } from '../../HeaderActions';

export function FinishRegisterProcessAction() {
  const [getActionProcess, refetchActions, loading] = useQuery<
    Paginated<ProcessModel>
  >('/process', {
    status: 'const recording',
    $limit: 4,
    '$sort[tradingDate]': 1
  });

  const [, deleteProcess] = useDelete<ProcessModel>(`/process`);

  const actionsProcess = getActionProcess?.data || ([] as ProcessModel[]);

  const redirect = (segment?: string, id?: number) => {
    if (segment === 'public' && id)
      return `cadastro/processo/consultor?processo=${id}`;
    if (segment === 'private' && id)
      return `cadastro/processo/privado?processo=${id}`;
    return 'dashboard';
  };

  return (
    <Grid item container direction="column" spacing={2}>
      {(getActionProcess?.total || 0) !== 0 && (
        <HeaderActions quantity={getActionProcess?.total || 0} />
      )}
      <Grid item container spacing={2} direction={{ xs: 'column', md: 'row' }}>
        {loading ? (
          <>
            {Array.from(new Array(4)).map(() => (
              <Grid item>
                <LoadingActions />
              </Grid>
            ))}
          </>
        ) : (
          <>
            {actionsProcess.map((actionProcess, key) => (
              <Grow in={!loading} timeout={key * 1000}>
                <Grid item md={3}>
                  <ActionCard
                    title="Consultor"
                    redirect={redirect(
                      actionProcess?.segment,
                      actionProcess?.id
                    )}
                    action="Adicionar processo ao sistema"
                    chips={[
                      {
                        color:
                          actionProcess?.segment === 'public'
                            ? 'blue'
                            : 'green',
                        title: lang.segments[actionProcess?.segment || '']
                      }
                    ]}
                    city={actionProcess?.city}
                    onDelete={async () => {
                      if (actionProcess.id) {
                        await deleteProcess(actionProcess.id);
                        refetchActions();
                      }
                    }}
                    tradingCode={actionProcess?.tradingCode || '-'}
                    tradingDate={String(actionProcess?.tradingDate)}
                  />
                </Grid>
              </Grow>
            ))}
          </>
        )}
      </Grid>
    </Grid>
  );
}
