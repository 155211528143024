/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState } from 'react';
import { Box, Table, TableBody, TableContainer } from '@mui/material';
import { ProcessProductModel } from 'types/models';
import { ProcessOrderCreateRow } from 'modules/Process/shared/Tables/ProcessOrder/ProcessOrderCreateRow';
import { ProcessOrderModel } from 'types/models/ProcessOrder/ProcessOrder.model';
import { toast } from 'react-toastify';
import { ProcessOrderHeader } from 'modules/Process/shared/Tables/ProcessOrder/ProcessOrderHeader';

interface ProcessOrderProps {
  rows: ProcessProductModel[];
  onComplete: (orderItems: ProcessOrderModel[]) => void;
}

export function ProcessOrderCreateTable({
  rows,
  onComplete
}: ProcessOrderProps) {
  const [selected, setSelected] = useState<ProcessOrderModel[]>([]);
  const [errors, setErrors] = useState<number[]>([]);

  const handleSelectAll = () => {
    if (selected.length === rows.length) {
      setSelected([]);
    } else {
      setSelected([...rows]);
    }
  };

  const handleSelect = (row: ProcessProductModel) => {
    const findIndex = selected.findIndex((data) => data.id === row.id);
    if (findIndex === -1) {
      setSelected([...selected, row]);
    } else {
      const values = [...selected];
      values.splice(findIndex, 1);
      setSelected([...values]);
    }
  };

  const handleChangeQuantity = (id: number, quantity: number) => {
    const indexOf = selected.findIndex((row) => row.id === id);
    const processProduct = rows.find((row) => row.id === id);
    if (indexOf !== -1) {
      const selectedProducts = selected;
      selectedProducts[indexOf] = {
        ...selectedProducts[indexOf],
        order_quantity: quantity
      };
      setSelected([...selectedProducts]);
      if ((processProduct?.quantity || 0) < quantity) {
        setErrors([...new Set([...errors, id])]);
      } else {
        setErrors(errors.filter((error) => error !== id));
      }
    }
  };

  const handleChangeObservation = (id: number, observation: string) => {
    const indexOf = selected.findIndex((row) => row.id === id);
    if (indexOf !== -1) {
      const selectedProducts = selected;
      selectedProducts[indexOf] = {
        ...selectedProducts[indexOf],
        order_observation: observation
      };
      setSelected([...selectedProducts]);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (selected.length === 0) {
      toast.error('Adicione a quantidade em um produto pelo menos');
      return;
    }

    const withoutQuantity = selected
      .filter((selectedProduct) => !selectedProduct.order_quantity)
      .map((result) => result.itemNumber);

    if (withoutQuantity.length > 0) {
      toast.error(
        `Adicione a quantidade nos items: ${JSON.stringify(withoutQuantity)}`
      );
      return;
    }
    const withErrors = selected
      .filter((selectedProduct) => errors.includes(selectedProduct.id!))
      .map((result) => result.itemNumber);
    if (errors.length > 0) {
      toast.error(
        `Adicione uma quantidade correta nos itens: ${JSON.stringify(
          withErrors
        )}`
      );
      return;
    }
    onComplete(selected);
  };

  return (
    <form id={'order-items-table'} onSubmit={handleSubmit}>
      <Box bgcolor={'white'}>
        <TableContainer>
          <Table>
            <ProcessOrderHeader handleSelectAll={handleSelectAll} />
            <TableBody>
              {rows.map((processProduct) => (
                <ProcessOrderCreateRow
                  processProduct={processProduct}
                  key={processProduct.id}
                  onChange={handleSelect}
                  onChangeQuantity={handleChangeQuantity}
                  onChangeObservation={handleChangeObservation}
                  isSelected={
                    !!selected.find((select) => select.id === processProduct.id)
                  }
                  hasError={
                    !!errors.find((error) => error === processProduct.id)
                  }
                  valueRow={
                    selected.find((select) => select.id === processProduct.id)
                      ?.order_quantity || 0
                  }
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </form>
  );
}
