import React from 'react';
import {
  Grid,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { HeaderOptionsProps } from 'types/Config/Headers';
import { UILink } from 'shared/components/Link';
import { theme as ShareTheme } from 'shared/theme';

export function HeaderOpt({ options }: HeaderOptionsProps) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <>
      {!matches
        ? options.map((option) => (
            <MenuItem onClick={option.onClick} key={`header-${option.label}`}>
              {option.label}
              {option.component}
            </MenuItem>
          ))
        : options.map((option) => (
            <Grid
              item
              container
              alignItems={'center'}
              xs
              {...option.grid}
              justifyContent={'center'}
              key={`header-option-${option?.key}`}
            >
              {option.linkHref && option.label ? (
                <UILink style={{ textDecoration: 'none' }} to={option.linkHref}>
                  <Typography
                    variant={'h4'}
                    fontWeight={500}
                    color={ShareTheme.colors.TextDarkGrey}
                  >
                    {option?.label}{' '}
                  </Typography>
                </UILink>
              ) : null}
              {option.component && option.component}
            </Grid>
          ))}
    </>
  );
}
