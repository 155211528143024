export const PrivateProcessDocuments = [
  'Edital',
  'Cotação',
  'Ata',
  'Contrato',
  'E-mail de aceite do cliente',
  'Autorização de fornecimento',
  'Pedido de compra',
  'E-mail de aprovação',
  'Outros'
];
