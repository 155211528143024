import axios from 'axios';
import { SetApiHeader } from 'services/api/config/setApiHeader';

export const defaultApi = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT || 'http://localhost:3000/'
});

export const api = SetApiHeader(defaultApi);

export default api;
