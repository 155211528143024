/* eslint-disable @typescript-eslint/no-explicit-any */
import { HelpOutline } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/ArrowCircleDown';
import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useFormData } from 'services/api/UseFormData';
import UIPaper from 'shared/components/Paper';
import UploadArea from 'shared/components/Upload/UploadArea';
import { theme } from 'shared/theme';
import { ImportsModel } from 'types/models/Imports/Imports.model';
import { ImportsOptions, ImportsType } from 'utils/Constants/ImportsType';
import { DownloadDemo } from 'utils/Functions/Download/DownloadDemo';

type Props = {
  canImport: boolean;
  refetchImports: () => void;
};

type ImportFormDataType = {
  file: File;
  type: string;
  options?: string;
};

export default function NewImportFragment({
  canImport,
  refetchImports
}: Props) {
  const [file, setFile] = useState<File>();
  const [category, setCategory] = useState<ImportsOptions>();
  const [importAdd, importLoading] = useFormData<
    ImportFormDataType,
    ImportsModel
  >(`/imports`);

  const { values, setFieldValue } = useFormik({
    initialValues: {
      options: {} as any
    },
    onSubmit: () => {}
  });

  const handleImport = async () => {
    if (file && category) {
      const data: ImportFormDataType = {
        file,
        type: category
      };
      if (values.options?.updateProcess) {
        data.options = JSON.stringify(values.options);
      }
      await importAdd(data);
      refetchImports();
      setFile(undefined);
      setCategory(undefined);
    }
  };

  const importSelected = category ? ImportsType[category] : undefined;

  const OptionsByType: Partial<Record<ImportsOptions, React.ReactElement>> = {
    'cities-consultants': (
      <>
        <Grid item container justifyContent={'center'} alignItems={'center'}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                onChange={() => {
                  setFieldValue('options', {
                    updateProcess: !values.options?.updateProcess
                  });
                }}
                color={'primary'}
                checked={!!values.options?.updateProcess}
                defaultValue={String(!!values.options?.updateProcess)}
                title="Atualizar processos"
              />
            }
            label="Atualizar processos"
          />
          <Tooltip
            placement="top"
            title="Habilitando essa opção os processos serão atualizados, em caso de duplicidade de cidades na importação com esse campo habilitado, os processos serão migrados para o último consultor importado."
          >
            <HelpOutline color="primary" />
          </Tooltip>
        </Grid>
      </>
    )
  };

  return (
    <>
      <UIPaper title="Começar nova importação">
        {canImport ? (
          <>
            <Grid item container xs={12} spacing={2} p={2} pt={0}>
              <Grid item container xs={12} spacing={2}>
                <Grid container item sm={8}>
                  <TextField
                    label={'Selecione o tipo de importação'}
                    select
                    onChange={(e) => {
                      setCategory(e.target.value as ImportsOptions);
                      setFieldValue('options', {});
                    }}
                    value={category || ''}
                  >
                    {Object.keys(ImportsType)
                      .filter((importType) => ImportsType[importType]?.isLive)
                      .map((importType) => (
                        <MenuItem value={importType} key={importType}>
                          {ImportsType[importType]?.label}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
                <Grid item container alignItems="center" xs={12} sm>
                  <Chip
                    label="Baixar modelo"
                    onClick={() =>
                      DownloadDemo(
                        `/demos/${importSelected?.file}`,
                        importSelected?.filename || 'demo.xlsx'
                      )
                    }
                    icon={<DownloadIcon />}
                    variant="filled"
                    color="primary"
                    clickable
                    style={{ width: '100%' }}
                    disabled={!category}
                  />
                </Grid>
              </Grid>
              <Grid item container xs={12} flexDirection="column" spacing={2}>
                <Grid item>
                  <UploadArea
                    handleFile={(fileSelected) => setFile(fileSelected)}
                    label="Arraste e solte o arquivo .xlsx aqui ou"
                  />
                </Grid>
                <Grid item>
                  <Typography
                    variant={'h2'}
                    style={{ color: theme.colors.TextDarkGrey }}
                  >
                    Arquivo: {file?.name}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12} spacing={2}>
                <Grid item>{OptionsByType[category || ''] ?? null}</Grid>
              </Grid>
              <Grid
                item
                container
                xs={12}
                justifyContent="flex-end"
                spacing={2}
              >
                <Grid item xs={12} sm={5} md={3}>
                  {importLoading ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      disabled={!category || !file}
                      onClick={handleImport}
                    >
                      Realizar importação!
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Typography
              variant={'h2'}
              style={{ color: theme.colors.TextDarkGrey }}
            >
              Importação em andamento. Quando terminar, poderá fazer uma nova
              importação.
            </Typography>
          </>
        )}
      </UIPaper>
    </>
  );
}
