import React from 'react';
import { Button, Grid } from '@mui/material';
import history from 'routes/History';
import {
  ConsultantContestDiscountButton,
  ConsultantInformResultButton
} from 'pages/Process/ProcessHistory/Domain/consultant';

export function ConsultantBottomButton({
  id,
  status,
  userType
}: {
  id: number;
  status: string;
  userType: string;
}) {
  return (
    <Grid container justifyContent={'flex-end'}>
      {ConsultantInformResultButton({
        processStatus: status,
        user: userType
      }) ? (
        <Grid item>
          <Button
            variant={'contained'}
            onClick={() =>
              history.push(`/processo/${id}/informar-resultado/consultor`)
            }
          >
            Informar resultado
          </Button>
        </Grid>
      ) : null}
      {ConsultantContestDiscountButton({
        processStatus: status,
        user: userType
      }) ? (
        <Grid item>
          <Button
            variant={'outlined'}
            onClick={() =>
              history.push(`/processo/${id}/informar-desconto/consultor`)
            }
          >
            Validação do preço financeiro
          </Button>
        </Grid>
      ) : null}
    </Grid>
  );
}
