import { Container, Grid } from '@mui/material';
import { ConsultantProcessAnalysis } from 'modules/Process/PublicProcess/Forms/ConsultantProcessForm/ConsultantNoticeAnalysis';
import { ConsultantProcessItems } from 'modules/Process/PublicProcess/Forms/ConsultantProcessForm/ConsultantProcessItems';
import { ConsultantProcessSummary } from 'modules/Process/PublicProcess/Forms/ConsultantProcessForm/ConsultantProcessSummary';
import React from 'react';
import { UseQueryReturn } from 'services';
import UIPaper from 'shared/components/Paper';
import { AttachmentModel } from 'types/models/Attachments/Attachment.model';
import { ConsultantProcessAttachments } from './ConsultantProcessAttachments';

type ConsultantProcessFormProps = {
  id?: string | null;
  handleChangeStep: (step: number) => void;
  step: number;
  attachmentsQuery: UseQueryReturn<AttachmentModel[], AttachmentModel>;
};

export function ConsultantProcessForm({
  id,
  handleChangeStep,
  step,
  attachmentsQuery
}: ConsultantProcessFormProps) {
  return (
    <>
      <Container>
        <Grid container spacing={2}>
          {step === 0 ? (
            <UIPaper title="Informações do processo">
              <ConsultantProcessSummary
                id={id}
                onComplete={() => handleChangeStep(1)}
              />
            </UIPaper>
          ) : null}
          {id && step === 1 ? (
            <Grid
              container
              item
              xs={12}
              spacing={2}
              pb={2}
              justifyContent="center"
            >
              <ConsultantProcessAnalysis
                id={id}
                onComplete={() => handleChangeStep(2)}
              />
            </Grid>
          ) : null}
          {id && step === 2 ? (
            <>
              <Grid item xs={12}>
                <ConsultantProcessItems
                  id={id}
                  onComplete={() => handleChangeStep(3)}
                />
              </Grid>
            </>
          ) : null}
          {id && step === 3 ? (
            <>
              <Grid item xs={12}>
                <ConsultantProcessAttachments
                  id={id}
                  attachmentsQuery={attachmentsQuery}
                />
              </Grid>
            </>
          ) : null}
        </Grid>
      </Container>
    </>
  );
}
