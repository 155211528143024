/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Grid, Typography } from '@mui/material';
import { PrivateProcessItemsTable } from 'modules/Process/PrivateProcess/Fragments/PrivateProcessItems/PrivateProcessItemsTable';
import { PrivateProcessItemModal } from 'modules/Process/PrivateProcess/Modals/ProcessItem';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { theme } from 'shared/theme';
import { ProcessModel, ProcessProductModel, ProductsModel } from 'types/models';
import { AdminEditItemProcessModal } from '../../Modals/AdminEditItemProcessModal';

interface AdminEditItemsProps {
  processProducts?: ProcessProductModel[];
  products: ProductsModel[];
  process: ProcessModel;
  processId: number;
  onSubmit: (
    oldProcessProducts: ProcessProductModel[],
    newProcessProducts: Partial<ProcessProductModel>[]
  ) => void;
}

export function AdminEditItems({
  processProducts,
  processId,
  onSubmit,
  process,
  products
}: AdminEditItemsProps) {
  const [editProcessProducts, setEditProcessProducts] = useState<
    ProcessProductModel[]
  >(processProducts || []);
  const [newProcessProducts, setNewProcessProducts] = useState<
    Partial<ProcessProductModel>[]
  >([]);

  const [newTemporaryId, setNewTemporaryId] = useState<number>(-1);

  const [modalType, setModalType] = useState<{
    type: 'new product' | 'old product';
    processProduct?: Partial<ProcessProductModel> | ProcessProductModel;
  }>();

  const onCreateNewProcessItem = (data: Partial<ProcessProductModel>) => {
    setNewProcessProducts([
      ...newProcessProducts,
      { ...data, id: newTemporaryId }
    ]);
    setModalType(undefined);
    setNewTemporaryId(newTemporaryId - 1);
  };

  const onEditNewProcessItem = (item: Partial<ProcessProductModel>) => {
    const newProcessProductsArr = newProcessProducts.map((processProduct) =>
      processProduct?.id === item?.id ? item : processProduct
    );
    setNewProcessProducts(newProcessProductsArr);
    setModalType(undefined);
  };

  const onEditOldProcessItem = (item: ProcessProductModel) => {
    setEditProcessProducts(
      editProcessProducts.map((processProduct) =>
        processProduct?.id === item?.id ? item : processProduct
      )
    );
    setModalType(undefined);
  };

  const onRemoveNewProcessItem = (item: Partial<ProcessProductModel>) => {
    setNewProcessProducts(
      newProcessProducts.filter(
        (processProduct) => processProduct?.id !== item?.id
      )
    );
    setModalType(undefined);
  };

  const onClickEdit = (data: any) => {
    if (data?.id < 0) {
      setModalType({
        type: 'new product',
        processProduct: data as Partial<ProcessProductModel>
      });
    } else if (data?.id > 0) {
      setModalType({
        type: 'old product',
        processProduct: data as ProcessProductModel
      });
    }
  };

  const onClickRemove = (data: any) => {
    if (data?.id < 0) {
      onRemoveNewProcessItem(data as Partial<ProcessProductModel>);
    } else if (data?.id > 0) {
      toast.error('Você não pode remover um item que já esta em andamento.');
    }
  };

  const onCompleteNewProcessItem = (data: Partial<ProcessProductModel>) => {
    if (modalType?.processProduct) {
      onEditNewProcessItem({ ...data, id: modalType?.processProduct?.id });
    } else {
      onCreateNewProcessItem(data);
    }
  };

  const onCompleteOldProcessItem = (data: Partial<ProcessProductModel>) => {
    if (modalType?.processProduct) {
      onEditOldProcessItem({
        ...(modalType?.processProduct as ProcessProductModel),
        ...data
      });
    }
  };

  const onCloseModal = () => {
    setModalType(undefined);
  };

  const newProcessProductsMap: Partial<ProcessProductModel>[] =
    newProcessProducts?.map((processProduct) => ({
      ...processProduct,
      product: products.find(
        (product) => product?.id === processProduct?.productId
      )
    }));

  const processItemData = [...editProcessProducts, ...newProcessProductsMap];

  const onFinished = () => {
    onSubmit(editProcessProducts, newProcessProducts);
  };

  return (
    <>
      <Grid container pt={2}>
        <Grid container item pt={2} pb={2} alignItems="center">
          <Typography
            variant="h2"
            style={{ fontSize: '24px', marginRight: '15px' }}
          >
            Itens adicionados
          </Typography>
          <Typography variant="h5" style={{ color: theme.colors.TextGrey }}>
            {processItemData?.length}
            {processItemData?.length !== 1
              ? ' itens adicionados'
              : ' item adicionado'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <PrivateProcessItemsTable
            onRowDoubleClick={(data) => {
              onClickEdit(data?.row as Partial<ProcessProductModel>);
            }}
            processProduct={processItemData}
            actions={[
              {
                label: 'Editar',
                onClick: onClickEdit
              },
              {
                label: 'Remover',
                onClick: onClickRemove
              }
            ]}
            loading={!processProducts?.length && processProducts?.length !== 0}
            onAddItemClick={() =>
              setModalType({
                type: 'new product'
              })
            }
          />
        </Grid>
        <PrivateProcessItemModal
          open={modalType?.type === 'new product'}
          onComplete={onCompleteNewProcessItem}
          onClose={onCloseModal}
          title={modalType?.processProduct ? 'Editar Item' : 'Adicionar Item'}
          processItem={modalType?.processProduct}
          processDirect={!!process?.processDirect}
          processId={processId}
          distributorUf={process?.distributor?.uf}
          isAdmin
        />
        <AdminEditItemProcessModal
          open={modalType?.type === 'old product'}
          onComplete={onCompleteOldProcessItem}
          onClose={onCloseModal}
          processItem={modalType?.processProduct}
        />
        <Grid container item justifyContent="flex-end" xs={12} mt={2}>
          <Grid item xs={12} sm={4} md={3}>
            <Button
              fullWidth
              onClick={onFinished}
              variant="contained"
              color="primary"
            >
              Avançar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
