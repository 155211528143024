import { LinearProgress } from '@mui/material';
import { ConsultantProcessSummaryProps } from 'modules/Process/PublicProcess/Forms/ConsultantProcessForm/ConsultantProcessSummary/ConsultantProcessSummary';
import { ProposalSummaryForm } from 'modules/Process/PublicProcess/Forms/ProposalSummary';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { usePatch, usePost, useQuery } from 'services';
import { ProcessModel } from 'types/models';

export function ConsultantProcessSummary({
  id,
  onComplete
}: ConsultantProcessSummaryProps) {
  const [processData, , loading] = useQuery<Partial<ProcessModel>>(
    `/process/${id}`,
    {
      fetchOnInitialize: !!id
    }
  );

  const [, processPost] = usePost<Partial<ProcessModel>, Partial<ProcessModel>>(
    '/process'
  );

  const [, processPatch] = usePatch<Partial<ProcessModel>>('/process');

  const history = useHistory();
  const handleCompleteProposalSummary = async (
    proposalSummary: Partial<ProcessModel>
  ) => {
    if (id) {
      const patchResponse = await processPatch(proposalSummary, +id);
      if (patchResponse) {
        onComplete();
      }
    } else {
      const postResponse = await processPost({
        ...proposalSummary,
        status: 'const recording',
        segment: 'public'
      });
      if (postResponse && postResponse) {
        history.replace({
          search: `?processo=${postResponse?.id}`
        });
        toast.success('Processo adicionado com sucesso');
        onComplete(postResponse?.id);
      }
    }
  };

  return (
    <>
      {id && loading ? (
        <LinearProgress />
      ) : (
        <ProposalSummaryForm
          onComplete={handleCompleteProposalSummary}
          process={processData}
        />
      )}
    </>
  );
}
