import { ProcessProductModel } from 'types/models';

export const GetItemsWithStatus = (
  processProducts: ProcessProductModel[],
  status: string[]
) => {
  return processProducts
    ?.filter((processProduct) =>
      status.includes(processProduct?.discount_orders?.[0]?.status || '')
    )
    ?.map((processProduct) => processProduct.itemNumber);
};
