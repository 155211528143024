export function ConsultantInformResultButton({
  processStatus,
  user
}: {
  processStatus: string;
  user: string;
}) {
  return (
    user === 'consultant' && processStatus === 'awaiting consultant result'
  );
}

export function ConsultantContestDiscountButton({
  processStatus,
  user
}: {
  processStatus: string;
  user: string;
}) {
  return (
    user === 'consultant' &&
    processStatus === 'waiting to confirm consultant values'
  );
}
