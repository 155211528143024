import { ServicesChannels } from 'utils/Enums/ServicesChannels';
import * as yup from 'yup';
import { ERRORS } from 'utils/Errors';
import {
  DistributorModel,
  EmployeeModel,
  InstitutionModel
} from 'types/models';

export interface ProposalSummaryInputs {
  distributor: DistributorModel;
  distributorId?: number;
  institution: InstitutionModel;
  institutionId?: number;
  employee: EmployeeModel;
  employeeId?: number;
  processDirect: boolean;
  serviceChannel: ServicesChannels;
  focusUf: boolean;
  secretaryClassification: string;
}

export const ProposalSummaryValidation = yup.object({
  distributor: yup.object({
    name: yup.string().required(ERRORS.REQUIRED_FIELD),
    sold: yup.string().required(ERRORS.REQUIRED_FIELD)
  }),
  institution: yup.object({
    id: yup.string().required(ERRORS.REQUIRED_FIELD),
    name: yup.string().required(ERRORS.REQUIRED_FIELD)
  }),
  employee: yup.object({
    sap: yup.string().required(ERRORS.REQUIRED_FIELD),
    name: yup.string().required(ERRORS.REQUIRED_FIELD)
  }),
  processDirect: yup.string().required(ERRORS.REQUIRED_FIELD),
  serviceChannel: yup.string().required(ERRORS.REQUIRED_FIELD),
  focusUf: yup.string().required(ERRORS.REQUIRED_FIELD)
});
