/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  MenuItem
} from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { usePost } from 'services';
import { ControlledTextField } from 'shared/components/ControlledComponents/ControlledTextField';
import { UITextField } from 'shared/components/Inputs';
import UIPaper from 'shared/components/Paper';
import { Cities, CitiesEmployee } from 'types/models';
import { States, STATES_ARRAY } from 'utils/Enums';
import { SyncEmployeeCity, SyncEmployeeCitySchema } from './domain';

export interface SyncEmployeeCityFragmentProps {
  employeeId?: number;
  citiesEmployees: CitiesEmployee[];
  cities: Cities[];
  refetch: () => void;
}

export function SyncEmployeeForm({
  employeeId,
  citiesEmployees,
  cities,
  refetch
}: SyncEmployeeCityFragmentProps) {
  const [, syncEmployeeCity, postLoading] = usePost<
    CitiesEmployee,
    CitiesEmployee
  >('/cities-employees');

  const { handleSubmit, formState, watch, control, setValue, reset } =
    useForm<SyncEmployeeCity>({
      resolver: yupResolver(SyncEmployeeCitySchema),
      mode: 'onTouched'
    });

  const citiesIds = citiesEmployees?.map(
    (cityEmployee) => cityEmployee?.cityId
  );

  const citiesData = (cities || [])
    .filter((city) => city.uf === watch('uf'))
    .filter((city) => (city?.id ? !citiesIds?.includes(city.id) : true))
    .map((city) => city.city);

  const onComplete = async (data: SyncEmployeeCity) => {
    const findCity = (cities || []).find(
      (city) => city.uf === data.uf && city.city === data.city
    );
    if (employeeId && findCity?.id) {
      await syncEmployeeCity({
        cityId: findCity.id,
        employeeId
      });
      toast.success(
        `Cidade: ${findCity?.city}-${findCity?.uf}, vinculado com sucesso`
      );
      reset();
      refetch();
    } else {
      toast.success(`Erro ao vincular cidade, dados inválidos`);
    }
  };

  return (
    <Grid container item xs={12} sm={6} md={5} height="100%">
      <UIPaper title="Vincular novo município" width="100%" mt={0}>
        <>
          <form onSubmit={handleSubmit(onComplete)}>
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <ControlledTextField
                  name={'uf'}
                  control={control}
                  label={'Estado'}
                  select
                  onChange={(event) => {
                    setValue('uf', event.target.value);
                    setValue('city', '');
                  }}
                >
                  {STATES_ARRAY.map(({ uf }) => (
                    <MenuItem value={uf} key={uf}>
                      {States[uf]}
                    </MenuItem>
                  ))}
                </ControlledTextField>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name={'city'}
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      renderInput={(props) => (
                        <UITextField label="Cidade" {...props} />
                      )}
                      {...field}
                      options={[...citiesData, '']}
                      onChange={(_, value) => {
                        _.preventDefault();
                        if (value && !Array.isArray(value)) {
                          setValue('city', value);
                        } else {
                          setValue('city', '');
                        }
                      }}
                      value={watch('city') || ''}
                      fullWidth
                      loading
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid mt={4} item container justifyContent="flex-end">
              {postLoading ? (
                <CircularProgress />
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  color="primary"
                  disabled={!formState?.isValid}
                >
                  Vincular Município
                </Button>
              )}
            </Grid>
          </form>
        </>
      </UIPaper>
    </Grid>
  );
}
