import VMasker from 'vanilla-masker';

export const PercentMask = (value: string | number) => {
  if (value === '' || !value || value === '%') {
    return '0%';
  }

  const justNumbersRegex = /[^0-9]/g;

  let parsedValue = value;

  if (String(parsedValue).search(',') === -1) {
    return VMasker.toPattern(parsedValue, '99,99%');
  }

  if (!value) {
    const theValue = String(parsedValue).replace(',', '.');
    const parseValue = String(theValue).replace(justNumbersRegex, '');
    parsedValue = parseFloat(parseValue);
  }

  if (!parsedValue) {
    return '0%';
  }

  if (parsedValue > 100) {
    return VMasker.toPattern(100, {
      pattern: '100,00%'
    });
  }

  if (parsedValue <= 0 || value === '') {
    return '0%';
  }

  return VMasker.toPattern(parsedValue, '99,99%');
};

export const PercentMaskRemove = (value: string) => {
  return +value.replace(/,/g, '.').replace(/%/g, '');
};

export const PercentageRegex = /[0-9]{0,3},[0-9]{0,2}%|[0-9]{0,3}%/gm;
