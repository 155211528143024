/* eslint-disable react-hooks/exhaustive-deps */
import CheckIcon from '@mui/icons-material/Check';
import { Container, Fab, LinearProgress } from '@mui/material';
import InformDiscountBottom from 'modules/Process/InformDiscount/Fragments/Bottom';
import InformDiscountItemsTable from 'modules/Process/InformDiscount/Fragments/ItemsTable';
import InformDiscountTitle from 'modules/Process/InformDiscount/Fragments/Title';
import NewPageBreadcrumb from 'modules/Process/shared/Fragment/NewPageBreadcrumb';
import { useAuth } from 'Providers/Auth';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { usePatch, useQuery } from 'services';
import { useFab } from 'shared/components/Fab';
import {
  DiscountOrderModel,
  ProcessModel,
  ProcessProductModel,
  ProductsModel
} from 'types/models';
import { AttachmentModel } from 'types/models/Attachments/Attachment.model';
import { UrlParams } from 'types/services';
import { GetItemsWithStatus } from '../Functions/GetItemsWithStatus';

export function InformDiscountFinancier() {
  const { id } = useParams() as UrlParams;
  const { auth } = useAuth();
  const history = useHistory();

  const [justify, setJustify] = useState<string>();

  const [processProducts, processProductsRefetch] = useQuery<
    ProcessProductModel[],
    ProcessProductModel
  >(`/process-products`, {
    discount: 1,
    $limit: '-1',
    processId: id
  });

  const [process] = useQuery<ProcessModel, ProcessModel>(`/process/${id}`);
  const [productsToPolitical, fetchProductsToPolitical] = useQuery<
    ProductsModel[],
    ProductsModel
  >(`/products`, {
    fetchOnInitialize: false,
    $limit: -1,
    $select: ['id', 'sku'],
    processId: id
  });

  useEffect(() => {
    if (processProducts) {
      fetchProductsToPolitical({
        'id[$in]': processProducts?.map(
          (processProduct) => processProduct.productId
        )
      });
    }
  }, [processProducts]);

  const [attachments, attachmentsRefetch, attachmentsLoading] = useQuery<
    AttachmentModel[],
    ProcessProductModel
  >(`/attachments`, {
    table: 'process',
    $limit: '-1',
    tableId: id,
    accessId: auth?.id,
    fetchOnInitialize: false
  });

  const [, patchDiscountOrder, patchDiscountOrderLoading] =
    usePatch<DiscountOrderModel>(`/discount-order`);
  const [, patchProcessProduct, patchProcessProductLoading] =
    usePatch<ProcessProductModel>(`/process-products`);
  const [, patchProcess, patchProcessLoading] =
    usePatch<ProcessModel>(`/process`);

  useEffect(() => {
    if (auth) {
      if (auth.employee?.accessLevel !== 'financier') {
        history.push(`/processo/${id}`);
      }
    }
    attachmentsRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.id]);

  const handleSubmit = async () => {
    const contestedOrRequested = GetItemsWithStatus(processProducts || [], [
      'requested',
      'contested'
    ]);
    if (contestedOrRequested?.length !== 0) {
      toast.error(
        `Os seguintes itens não foram preenchidos: ${contestedOrRequested}`
      );
    } else {
      await patchProcess(
        {
          status: 'waiting to confirm consultant values',
          justify
        },
        +id
      );
      toast.success(`Descontos informados com sucesso. `);
      history.push(`/processo/${id}`);
    }
  };

  const fabClasses = useFab();

  return (
    <>
      <Container maxWidth={'lg'}>
        <NewPageBreadcrumb id={id} title="Informar Descontos" />
        {patchProcessLoading && <LinearProgress color="primary" />}
        <InformDiscountTitle
          title="Informar Desconto"
          subtitle="Analise os descontos solicitados para que o processo prossiga."
        />
        <InformDiscountItemsTable
          type="financier"
          processProducts={processProducts}
          process={process || {}}
          patchDiscountOrder={patchDiscountOrder}
          processProductsRefetch={processProductsRefetch}
          patchProcessProduct={patchProcessProduct}
          loading={patchProcessProductLoading || patchDiscountOrderLoading}
          productsToPolitical={productsToPolitical}
        />
        <InformDiscountBottom
          setJustify={setJustify}
          attachments={attachments || []}
          attachmentsRefetch={attachmentsRefetch}
          attachmentsLoading={attachmentsLoading}
          accessId={auth?.id}
          processId={+id}
          observationTitle="Escreva uma observação que será enviada para o consultor do processo."
          justify={justify}
        />
      </Container>
      <Fab
        className={fabClasses.fab}
        color={'primary'}
        variant={'extended'}
        aria-label="add"
        onClick={handleSubmit}
        disabled={patchProcessLoading}
      >
        <CheckIcon />
        Concluir
      </Fab>
    </>
  );
}
