import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import React from 'react';
import UploadArea from 'shared/components/Upload/UploadArea';
import { ThemeColors } from 'shared/enum';
import { theme } from 'shared/theme';

type UploadAddSectionProps = {
  title?: string;
  loading: boolean;
  handleFile: (file: File) => void;
  buttonDisabled: boolean;
  buttonOnClick: React.MouseEventHandler<HTMLButtonElement> | undefined;

  selectedFilename: string;

  selectOptions?: string[];
  selectOnChange?: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
  selectValue?: string;
  selectLabel?: string;
};

export default function UIUploadAddSection({
  title,
  handleFile,
  loading,
  buttonDisabled,
  buttonOnClick,
  selectedFilename,
  selectOptions,
  selectOnChange,
  selectValue,
  selectLabel
}: UploadAddSectionProps) {
  return (
    <Box bgcolor="white" borderRadius="5px" height="100%">
      {title && (
        <>
          <Box pt={2} pb={2} pl={2} alignItems="center">
            <Typography
              variant="h3"
              style={{
                color: ThemeColors.TextDarkGrey,
                fontWeight: theme.font.semiBold
              }}
            >
              {title}
            </Typography>
          </Box>
          <Box pb={1}>
            <Divider />
          </Box>
        </>
      )}
      {selectOptions && (
        <Box pt={1} pb={2} pl={2} pr={2} alignItems="center">
          <TextField
            label={selectLabel}
            select
            fullWidth
            onChange={selectOnChange}
            value={selectValue}
          >
            {selectOptions.map((option) => (
              <MenuItem value={option} key={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      )}
      <Box pt={1} pb={2} pl={2} pr={2} alignItems="center">
        <Grid container item xs={12}>
          <UploadArea handleFile={handleFile} />
        </Grid>
      </Box>
      <Box pt={1} pb={2} pl={2} pr={2} alignItems="center">
        <Grid
          container
          item
          justifyContent="space-between"
          alignItems="center"
          xs={12}
        >
          {selectedFilename ? (
            <Typography
              variant="h3"
              style={{
                color: ThemeColors.TextDarkGrey,
                fontWeight: theme.font.semiBold,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                width: '80%'
              }}
            >
              Arquivo Selecionado: {selectedFilename}
            </Typography>
          ) : null}

          {loading ? (
            <CircularProgress size={30} />
          ) : (
            <Button
              disabled={buttonDisabled}
              variant={'outlined'}
              color={'primary'}
              onClick={buttonOnClick}
            >
              Adicionar
            </Button>
          )}
        </Grid>
      </Box>
    </Box>
  );
}
