import React, { useState } from 'react';
import {
  Checkbox,
  Collapse,
  Fade,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { MoneyMask } from 'utils/masks';
import { UITextField } from 'shared/components/Inputs';
import { OrderItems } from 'types/models/Order/Order.model';

interface ProcessOrderRowProps {
  orderItems: OrderItems;
  onChange: (row: OrderItems) => void;
  isSelected: boolean;
  onChangeQuantity: (id: number, quantity: number) => void;
}

// TABLE HEADER:
//  - Item
//  - SKU
//  - Produto
//  - Valor NF Nestlé (R$) (UNI)
//  - Valor Instituição (R$) (UNI)
//  - Valor total do item (R$)
//  - Faturado (R$)
//  - Saldo do item (UNI)
//  - Quantidade (UNI)

export function ProcessOrderE2ORow({
  orderItems,
  onChange,
  isSelected,
  onChangeQuantity
}: ProcessOrderRowProps) {
  const [open, setOpen] = useState(false);

  const handleOpenRow = () => {
    setOpen(!open);
  };

  const handleSelect = () => {
    onChange(orderItems);
  };

  const handleChangeInput = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    event.preventDefault();
    const { value } = event.target;
    onChangeQuantity(Number(orderItems.id), Number(value));
  };

  return (
    <>
      <TableRow style={{ backgroundColor: isSelected ? 'white' : '#f3f3f3' }}>
        <TableCell padding="checkbox">
          <Checkbox
            onChange={handleSelect}
            color={'primary'}
            checked={isSelected}
            defaultValue={String(isSelected)}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={handleOpenRow}
          >
            <KeyboardArrowDownIcon />
          </IconButton>
        </TableCell>
        <TableCell align={'center'}>
          {orderItems?.process_product?.itemNumber}
        </TableCell>
        <TableCell align={'center'}>
          {orderItems?.process_product?.product?.sku}
        </TableCell>
        <TableCell align={'center'}>
          {orderItems?.process_product?.product?.description}
        </TableCell>
        <TableCell align={'center'}>
          {MoneyMask(String(orderItems?.process_product?.nfPrice || ''))}
        </TableCell>
        <TableCell align={'center'}>
          {MoneyMask(
            String(orderItems?.process_product?.institutionValue || '')
          )}
        </TableCell>
        <TableCell align={'center'}>
          {(orderItems?.process_product?.quantity || 0) *
            (orderItems?.process_product?.institutionValue || 0)}
        </TableCell>
        <TableCell align={'center'}>
          {orderItems?.process_product?.quantity}
        </TableCell>
        <TableCell align={'center'}>
          <Fade in={isSelected}>
            <UITextField
              name={'quantity'}
              label={'Quantidade'}
              value={orderItems?.quantity}
              type={'number'}
              onChange={handleChangeInput}
              disabled
            />
          </Fade>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width={'40%'}> Descritivo</TableCell>
                  <TableCell width={'60%'}> Observações </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    {orderItems?.process_product?.specification}
                  </TableCell>
                  <TableCell>
                    <UITextField
                      name={'observation'}
                      label={
                        'Observações (Ex: Local de entrega, validade dos produtos etc)'
                      }
                      placeholder={
                        'Ex. Local de entrega, validade dos produtos etc.'
                      }
                      multiline
                      minRows={4}
                      value={orderItems?.observation}
                      disabled
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
