/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  Autocomplete,
  Box,
  Button,
  Collapse,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup
} from '@mui/material';
import { useFormik } from 'formik';
import { CalcDiscountInput } from 'modules/Process/Domain/ProcessItem/Discount';
import { CalcMarkupInput } from 'modules/Process/Domain/ProcessItem/Markup';
import {
  ScopeRegistrationFormFields,
  ScopeRegistrationFormParams
} from 'modules/Process/PublicProcess/Forms/ScopeRegistration/complete/domain';
import { ScopeRegistrationSchema } from 'modules/Process/PublicProcess/Forms/ScopeRegistration/complete/domain/formValidation';
import React, { useEffect } from 'react';
import { useQuery } from 'services';
import BlockCard from 'shared/components/Cards/BlockCard';
import { UITextField } from 'shared/components/Inputs';
import { ProcessProductModel, ProductsModel } from 'types/models';
import { FormatPercent } from 'utils/Functions/FormatPercent';
import { GetStringBoolean } from 'utils/Functions/GetBooleanString';
import {
  MoneyMask,
  MoneyRemoveMask,
  PercentMask,
  PercentMaskRemove
} from 'utils/masks';

/* JUST NESTLE EMPLOYERS CAN SEE THIS FORM */
export function ScopeRegistrationCompleteForm({
  onComplete,
  processProduct,
  disabledByHistory = false,
  processId
}: ScopeRegistrationFormParams) {
  const [products] = useQuery<ProductsModel[], ProductsModel>('/products', {
    $limit: '-1',
    processId,
    disabled: 0
  });

  const {
    handleSubmit,
    values,
    handleChange,
    handleBlur,
    errors,
    setFieldValue,
    touched
  } = useFormik<ScopeRegistrationFormFields>({
    initialValues: {
      itemNumber: processProduct?.itemNumber || 1,
      specification: processProduct?.specification || '',
      unity: processProduct?.unity || 'UNI',
      quantity: processProduct?.initialQuantity || 0,
      approve: processProduct?.approve || false,
      justify: processProduct?.justify || '',
      priceList: MoneyMask(processProduct?.priceList || 0) || 0,
      discount: processProduct?.discount || false,
      discountValue: FormatPercent(processProduct?.discountValue || 0) || 0,
      nfPrice: processProduct?.nfPrice || 0,
      markup: FormatPercent(processProduct?.markup || 0) || 0,
      institutionValue: processProduct?.institutionValue || 0,
      productId: processProduct?.productId
    },
    validationSchema: ScopeRegistrationSchema,
    onSubmit: (fields) => {
      const data = fields;
      if (fields?.discountValue) {
        data.discountValue = PercentMaskRemove(String(fields?.discountValue));
      }
      if (fields?.markup) {
        data.markup = PercentMaskRemove(String(fields?.markup));
      }
      if (fields?.priceList) {
        data.priceList = +MoneyRemoveMask(String(fields?.priceList));
      }
      if (fields?.approve) {
        data.approve = GetStringBoolean(fields?.approve) || false;
      }
      if (fields?.discount) {
        data.discount = GetStringBoolean(fields?.discount) || false;
      }
      data.nfPrice = nfPrice;
      data.institutionValue = institutionValue;
      onComplete(data as Partial<ProcessProductModel>);
    }
  });

  const product = products?.find((p) => p.id === values?.productId);

  const onChangeSKU = (value: ProductsModel) => {
    if (value && value.id) {
      setFieldValue('productId', value.id);
      if (value?.political_prices?.[0]?.price) {
        setFieldValue(
          'priceList',
          MoneyMask(value?.political_prices?.[0]?.price || 0)
        );
      } else {
        setFieldValue('priceList', MoneyMask(0));
      }
    } else {
      setFieldValue('productId', undefined);
    }
  };

  const handleChangeDiscount = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(event);
    setFieldValue('discountValue', PercentMask(0));
  };

  const politicalPrice =
    (
      (products?.find((p) => p.id === product?.id)?.political_prices?.[0]
        ?.preDiscount || 0) * 100
    )?.toFixed(2) || '';

  const politicalPriceType = 'Distribuidor';

  const nfPrice = +CalcDiscountInput(
    values?.priceList,
    values?.discountValue
  ).discountValue?.toFixed(2);

  const institutionValue = +CalcMarkupInput(
    nfPrice,
    +PercentMaskRemove(String(values.markup))
  ).markup?.toFixed(2);

  useEffect(() => {
    if (processProduct?.productId && products) {
      if (!processProduct?.priceList) {
        const productSelectedByDistributor = products.find(
          (productFind) => productFind.id === processProduct.productId
        );
        setFieldValue(
          'priceList',
          MoneyMask(
            productSelectedByDistributor?.political_prices?.[0]?.price || 0
          )
        );
      }
    }
  }, [processProduct, products]);

  return (
    <form id={'ProcessItem'} style={{ width: '100%' }} onSubmit={handleSubmit}>
      <Grid container item xs={12} direction={'column'} spacing={1}>
        <Grid item xs={12}>
          <UITextField
            label={'Item'}
            name={'itemNumber'}
            value={values.itemNumber}
            onChange={handleChange}
            error={!!errors.itemNumber}
            helperText={errors.itemNumber}
            onBlur={handleBlur}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Box mt={1} />
          <UITextField
            label={'Descritivo'}
            name={'specification'}
            value={values.specification}
            onChange={handleChange}
            error={!!errors.specification && touched.specification}
            helperText={touched.specification && errors.specification}
            onBlur={handleBlur}
            multiline
            maxRows={3}
            inputProps={{
              maxLength: 256
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <UITextField
            label={'Unidade'}
            name={'unity'}
            value={'UNI'}
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <UITextField
            label={'Quantidade'}
            name={'quantity'}
            value={values.quantity}
            onChange={handleChange}
            error={!!errors.quantity}
            helperText={touched.quantity && errors.quantity}
            onBlur={handleBlur}
            type={'number'}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Box mt={2}>
            <FormControl component={'fieldset'}>
              <FormLabel>Produto pode ser atendido?</FormLabel>
              <RadioGroup
                name={'approve'}
                value={String(values.approve)}
                onChange={handleChange}
                row
              >
                <FormControlLabel
                  value={'true'}
                  control={<Radio color={'primary'} />}
                  label={'Sim'}
                />
                <FormControlLabel
                  value={'false'}
                  control={<Radio color={'primary'} />}
                  label={'Não'}
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Grid>
        <Collapse in={values.approve === 'false' || !values.approve}>
          <Grid item xs={12}>
            <Box mt={1} />
            <UITextField
              label={'Justificativa'}
              multiline
              minRows={3}
              inputProps={{
                maxLength: 256
              }}
              name={'justify'}
              variant={'outlined'}
              value={values.justify}
              onChange={handleChange}
              error={!!errors.justify && touched.justify}
              helperText={touched.justify && errors.justify}
              onBlur={handleBlur}
              fullWidth
            />
          </Grid>
        </Collapse>
        <Grid item xs={12}>
          <Autocomplete
            value={product || {}}
            renderInput={(props) => (
              <UITextField
                name={'sku'}
                label={'SKU'}
                value={product?.sku || ''}
                error={!!errors?.productId && touched?.productId}
                helperText={errors?.productId || errors?.productId}
                {...props}
              />
            )}
            onChange={(_, value) => {
              onChangeSKU(value as ProductsModel);
            }}
            options={products || []}
            getOptionLabel={(data) => data?.sku || ''}
            disabled={disabledByHistory}
          />
        </Grid>
        <Grid item xs={12}>
          <UITextField
            label={'Produto'}
            value={product?.description || ''}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <UITextField
            name={'priceList'}
            label={'(R$) Preço lista'}
            value={values.priceList || ''}
            onChange={handleChange}
            error={!!errors.priceList && touched.priceList}
            helperText={touched.priceList && errors.priceList}
            onBlur={handleBlur}
            pattern={'money'}
            fullWidth
            disabled={disabledByHistory}
          />
        </Grid>
        <Grid item xs={12}>
          <UITextField
            label={'Desconto Pré-Aprovado'}
            value={PercentMask(politicalPrice) || ''}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <UITextField
            label={'Tipo de política comercial'}
            value={politicalPriceType || ''}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <Box mt={2}>
            <FormControl component={'fieldset'} disabled={disabledByHistory}>
              <FormLabel>Desconto?</FormLabel>
              <RadioGroup
                row
                name={'discount'}
                value={String(values.discount)}
                onChange={handleChangeDiscount}
              >
                <FormControlLabel
                  value={'true'}
                  control={<Radio color={'primary'} />}
                  label={'Sim'}
                />
                <FormControlLabel
                  value={'false'}
                  control={<Radio color={'primary'} />}
                  label={'Não'}
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <UITextField
            name={'discountValue'}
            label={'Desconto Proposto'}
            value={values.discountValue}
            onChange={handleChange}
            pattern={'percentage'}
            error={!!errors.discountValue && touched.discountValue}
            helperText={touched.discountValue && errors.discountValue}
            onBlur={handleBlur}
            disabled={String(values.discount) !== 'true' || disabledByHistory}
            fullWidth
          />
        </Grid>
        {}
        {PercentMaskRemove(String(values.discountValue) || '0') >
        (product?.political_prices?.[0]?.preDiscount || 0) * 100 ? (
          <Grid item xs={12}>
            <BlockCard
              title="Desconto excede política pré-aprovada"
              message="Será necessário a aprovação do financeiro para prosseguir com esse valor."
            />
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <UITextField
            label={'(R$) Preço NF Nestlé'}
            name={'nfPrice'}
            value={MoneyMask(nfPrice)}
            pattern={'money'}
            error={!!errors.nfPrice && touched.nfPrice}
            helperText={touched.nfPrice && errors.nfPrice}
            onBlur={handleBlur}
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <UITextField
            name={'markup'}
            label={'Markup'}
            pattern={'percentage'}
            value={values.markup}
            error={!!errors.markup && touched.markup}
            helperText={touched.markup && errors.markup}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <UITextField
            label={'(R$) Preço instituição'}
            value={MoneyMask(institutionValue)}
            onBlur={handleBlur}
            pattern={'money'}
            disabled
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid item container direction={'row-reverse'}>
        <Grid item xs={12} mt={2}>
          <Button fullWidth type={'submit'} variant="contained">
            Confirmar
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
