import VMasker from 'vanilla-masker';
import * as Masks from 'utils/masks';

export type PatternsTypes =
  | 'cnpj'
  | 'zipcode'
  | 'phone'
  | 'money'
  | 'percentage'
  | string;

interface Patterns {
  value: string;
  pattern: PatternsTypes;
}

export function GetPattern({ value, pattern }: Patterns): string {
  switch (pattern) {
    case 'cnpj':
      return Masks.CnpjMask(value);
    case 'phone':
      return Masks.PhoneMask(value);
    case 'zipcode':
      return Masks.ZipCodeMask(value);
    case 'money':
      return Masks.MoneyMask(value);
    case 'percentage':
      return Masks.PercentMask(value);
    default:
      return VMasker.toPattern(value, pattern);
  }
}
