import styled from 'styled-components';
import Chip from '@mui/material/Chip';

interface Props {
  fontColor?: string;
  background?: string;
  fontSize?: string;
  height?: string;
  width?: string;
  fontWeight?: string;
}
export const TagChip = styled(Chip)<Props>`
  color: ${(props) => props.fontColor || '#5082c9'} !important;
  background: ${(props) =>
    props.background || 'rgba(80, 130, 201, 0.16)'} !important;
  font-weight: ${(props) => props.fontWeight || '500'} !important;
  height: ${(props) => props.height} !important;
  width: ${(props) => props.width} !important;
  font-size: ${(props) => props.fontSize || '10px'} !important;
  padding: 0 0.3rem 0 0.3rem !important;
`;
