/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
import React, { useEffect } from 'react';
import { FormProps, UITextField } from 'shared/components/Inputs';
import { AccessModel } from 'types/models';
import { AccessLevels } from 'utils/Constants/AccessLevels/AccessLevels';
import { useFormik } from 'formik';
import { Grid, MenuItem } from '@mui/material';
import { UpdateEmployee, UpdateEmployeeSchema } from './domain';

interface AdminProfileFormProps extends FormProps<UpdateEmployee> {
  access?: AccessModel;
  isAdmin?: boolean;
}

export function EmployeeProfileForm({
  access,
  onComplete,
  isAdmin = true
}: AdminProfileFormProps) {
  const { values, handleChange, errors, handleSubmit, resetForm } =
    useFormik<UpdateEmployee>({
      validationSchema: UpdateEmployeeSchema,
      validateOnBlur: true,
      initialValues: {
        email: access?.email || '',
        employee: access?.employee || {
          name: '',
          lastName: '',
          sap: '',
          regional: ''
        },
        password: '',
        confirmPassword: ''
      },
      onSubmit: (fields) => {
        const data = fields;
        if (!fields.confirmPassword || !fields.password) {
          delete data.password;
          delete data.confirmPassword;
        }
        onComplete(data);
      }
    });

  useEffect(() => {
    resetForm({
      values: {
        email: access?.email || '',
        employee: access?.employee || {
          name: '',
          lastName: '',
          sap: '',
          regional: ''
        },
        password: '',
        confirmPassword: ''
      }
    });
  }, [access]);

  return (
    <form id="profile-update" onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <UITextField
            name={'employee.name'}
            label={'Nome'}
            value={values.employee?.name || ''}
            onChange={handleChange}
            disabled={!isAdmin}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <UITextField
            name={'employee.lastName'}
            label={'Sobrenome'}
            value={values.employee?.lastName}
            onChange={handleChange}
            disabled={!isAdmin}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <UITextField
            name={'email'}
            label={'Email'}
            value={values?.email}
            onChange={handleChange}
            disabled={!isAdmin}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <UITextField
            name={'employee.regional'}
            label={'Regional'}
            value={values.employee?.regional?.toLowerCase()}
            onChange={handleChange}
            disabled={!isAdmin}
            fullWidth
            select
          >
            <MenuItem value={'cnn'}> CNN </MenuItem>
            <MenuItem value={'ssp'}> SSP </MenuItem>
            <MenuItem value={'sede'}> SEDE </MenuItem>
          </UITextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <UITextField
            name={'employee.sap'}
            label={'SAP'}
            value={values.employee?.sap}
            onChange={handleChange}
            disabled={!isAdmin}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {isAdmin ? (
            <UITextField
              name={'employee.accessLevel'}
              label={'Nível de acesso'}
              value={values.employee?.accessLevel || ''}
              onChange={handleChange}
              disabled={!isAdmin}
              fullWidth
              select
            >
              {AccessLevels.map(({ value, text }) => (
                <MenuItem value={value}> {text} </MenuItem>
              ))}
            </UITextField>
          ) : null}
        </Grid>
        <Grid item xs={12} md={6}>
          <UITextField
            name={'password'}
            label={'Senha'}
            onChange={handleChange}
            type="password"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <UITextField
            name={'confirmPassword'}
            label={'Confirmar Senha'}
            onChange={handleChange}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword}
            type="password"
          />
        </Grid>
      </Grid>
    </form>
  );
}
