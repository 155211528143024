/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  CircularProgress,
  Collapse,
  createFilterOptions,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch
} from '@mui/material';
import { add } from 'date-fns';
import { useCities } from 'hooks/useCities';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'services';
import { UIChip } from 'shared/components/Chip';
import { ControlledAutocomplete } from 'shared/components/ControlledComponents/ControlledAutocomplete';
import { ControlledTextField } from 'shared/components/ControlledComponents/ControlledTextField';
import { UITextField } from 'shared/components/Inputs';
import {
  AccessModel,
  DistributorModel,
  EmployeeModel,
  InstitutionModel,
  ProcessModel,
  UfsRegionals
} from 'types/models';
import { ServicesChannels, States, STATES_ARRAY } from 'utils/Enums';
import { GetDateFormat } from 'utils/Functions/FormatDate';
import { Months } from 'utils/Functions/Months';
import { AdminEditStatus } from './Constants';
import { AdminEditProcessForm, AdminEditProcessFormSchema } from './domain';

type EditProcessFormProps = {
  process?: ProcessModel;
  onSubmit: (data: AdminEditProcessForm) => void;
};

export function AdminEditProcessInformationForm({
  process,
  onSubmit
}: EditProcessFormProps) {
  const { handleSubmit, watch, control, reset, setValue, formState } =
    useForm<AdminEditProcessForm>({
      resolver: yupResolver(AdminEditProcessFormSchema),
      mode: 'all'
    });
  useEffect(() => {
    if (process) {
      reset({
        institutionId: process?.institutionId,
        employeeId: process?.employeeId,
        distributorId: process?.distributorId,
        focusUf: process?.focusUf,
        serviceChannel: process?.serviceChannel,
        uf: process?.uf,
        city: process?.city,
        regional: process?.regional,
        status: process?.status,
        willExtend: false,
        newEndPeriod: process?.endPeriod
      });
    }
  }, [process]);

  const { citiesData, citiesLoading } = useCities();
  const [institutionsQueryRes, , institutionsLoading] = useQuery<
    InstitutionModel[]
  >('/institution', {
    $limit: -1,
    $select: ['id', 'name', 'uf', 'class', 'focus', 'serviceChannel', 'city'],
    disabled: 0
  });

  const [ufsRegionals] = useQuery<UfsRegionals[], UfsRegionals>(
    '/ufs-regionals',
    {
      $limit: -1
    }
  );
  useEffect(() => {
    if (watch('uf')) {
      const findUfRegional = (ufsRegionals || []).find(
        (ufRegional) => ufRegional.uf === String(watch('uf'))
      );
      setValue('regional', findUfRegional?.regional);
    }
  }, [ufsRegionals]);

  const institutionsData = institutionsQueryRes || [];
  const institutionFilterOptions = createFilterOptions<InstitutionModel>({
    limit: 20
  });
  const distributorFilterOptions = createFilterOptions<DistributorModel>({
    limit: 20
  });

  const citiesFiltered = citiesData
    .filter((city) => city.uf === (watch('uf') as string))
    .map((city) => city.city);

  const [consultants] = useQuery<EmployeeModel[], EmployeeModel>('/employee', {
    $limit: '-1',
    $select: ['id', 'name', 'sap'],
    accessLevel: 'consultant'
  });

  const [distributorQueryRes, , distributorLoading] = useQuery<
    DistributorModel[]
  >('/distributor', {
    $limit: -1,
    $select: ['id', 'socialName', 'sold', 'name', 'type']
  });
  const distributorsData = distributorQueryRes || [];
  const [distributorAccesses, , distributorAccessLoading] = useQuery<
    AccessModel[],
    AccessModel
  >('/access', {
    $limit: -1,
    $select: ['distributorId', 'id'],
    'distributorId[$ne]': 'null',
    notInclude: ['distributor', 'employee', 'balance']
  });
  const distributorsIdsWithAccess =
    distributorAccesses?.map((access) => access?.distributorId!) || [];
  const distributors =
    process?.segment === 'public'
      ? distributorsData?.filter((distributor) =>
          distributorsIdsWithAccess.includes(distributor?.id!)
        )
      : distributorsData;

  const allLoadings =
    distributorAccessLoading ||
    distributorLoading ||
    institutionsLoading ||
    citiesLoading;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid item container justifyContent="center" spacing={2}>
        {allLoadings ? (
          <CircularProgress size={20} />
        ) : (
          <>
            <Grid item xs={12} sm={4}>
              <ControlledAutocomplete<AdminEditProcessForm, InstitutionModel>
                name={'institutionId'}
                control={control}
                disableClearable
                label={'Nome do Órgão Público/Inst.'}
                options={[...institutionsData]}
                getOptionLabel={(option) =>
                  option?.name
                    ? `${option?.id} - ${option?.name} - ${option?.uf}`
                    : ''
                }
                filterOptions={institutionFilterOptions}
                onChange={(_, value) => {
                  _.preventDefault();
                  if (value && !Array.isArray(value)) {
                    setValue('institutionId', value.id);
                    const serviceChannel =
                      value?.serviceChannel as ServicesChannels;
                    setValue('serviceChannel', serviceChannel);
                    setValue('focusUf', value?.focus);
                  } else {
                    setValue('institutionId', undefined);
                  }
                }}
                value={
                  institutionsData.find(
                    (institution) => institution.id === watch('institutionId')
                  ) || null
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ControlledTextField
                name={'employeeId'}
                control={control}
                errors={formState.errors.employeeId?.message}
                label={'Consultor Responsável'}
                select
                onChange={(event) => {
                  setValue('employeeId', +event.target.value);
                }}
              >
                {consultants?.map((consultant) => (
                  <MenuItem value={consultant?.id} key={consultant?.id}>
                    {consultant?.sap} - {consultant?.name}
                  </MenuItem>
                ))}
              </ControlledTextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <ControlledAutocomplete<AdminEditProcessForm, DistributorModel>
                name={'distributorId'}
                control={control}
                label={'Cliente/Distribuidor'}
                options={[...distributors]}
                getOptionLabel={(option) =>
                  option?.sold ? `${option?.sold} - ${option?.name || ''}` : ''
                }
                filterOptions={distributorFilterOptions}
                onChange={(_, value) => {
                  _.preventDefault();
                  if (value && !Array.isArray(value) && value?.id) {
                    setValue('distributorId', value.id);
                  } else {
                    setValue('distributorId', undefined);
                  }
                }}
                value={
                  distributors.find(
                    (distributor) => distributor.id === watch('distributorId')
                  ) || null
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ControlledTextField
                name={'uf'}
                control={control}
                errors={formState.errors.uf?.message}
                label={'Estado'}
                select
                onChange={(event) => {
                  setValue('uf', event.target.value as States);
                  const findUfRegional = (ufsRegionals || []).find(
                    (ufRegional) => ufRegional.uf === event.target.value
                  );
                  setValue('regional', findUfRegional?.regional);
                  setValue('city', '');
                }}
              >
                {STATES_ARRAY.map(({ uf }) => (
                  <MenuItem value={uf} key={uf}>
                    {States[uf]}
                  </MenuItem>
                ))}
              </ControlledTextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <ControlledAutocomplete<AdminEditProcessForm, string>
                name={'city'}
                control={control}
                label={'Cidade'}
                options={citiesFiltered}
                errors={formState.errors.city?.message}
                onChange={(_, value) => {
                  _.preventDefault();
                  if (value && !Array.isArray(value)) {
                    setValue('city', value);
                  } else {
                    setValue('city', undefined);
                  }
                }}
                value={watch('city') || null}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ControlledTextField
                disabled
                name={'regional'}
                control={control}
                label={'Regional'}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <ControlledTextField
                name={'status'}
                control={control}
                label={'Status do Process'}
                select
                onChange={(event) => {
                  setValue('status', event.target.value);
                }}
                value={watch('status') || ''}
              >
                {AdminEditStatus.map((status) => (
                  <MenuItem value={status.value} key={status.value}>
                    <UIChip
                      color={status.color}
                      title={status.text}
                      height="24px"
                      fontSize="11px"
                      width="100%"
                    />
                  </MenuItem>
                ))}
              </ControlledTextField>
            </Grid>
            <Grid container alignItems="center" item xs={4} sm={3} md={2}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      color={'primary'}
                      name={'willExtend'}
                      onChange={(_, newValue) => {
                        setValue('willExtend', newValue);
                        setValue('moreMonths', undefined);
                        if (newValue === false) {
                          setValue('newEndPeriod', undefined);
                        } else {
                          setValue('newEndPeriod', process?.endPeriod);
                        }
                      }}
                      value={watch('willExtend')}
                    />
                  }
                  label={'Prorrogar?'}
                  labelPlacement={'start'}
                />
              </FormControl>
            </Grid>
            <Grid item xs={8} sm={5} md={4}>
              <Collapse in={watch('willExtend') === true} timeout={300}>
                <ControlledTextField
                  name={'moreMonths'}
                  control={control}
                  errors={formState.errors.moreMonths?.message}
                  label={'Meses a ser prorrogado'}
                  select
                  onChange={(e) => {
                    const month = +e.target.value;
                    if (month) {
                      const newDate = add(
                        new Date(process?.endPeriod || new Date()),
                        {
                          months: month
                        }
                      );
                      setValue('moreMonths', month);
                      setValue('newEndPeriod', newDate);
                    }
                  }}
                >
                  {Months().map((month) => (
                    <MenuItem value={month} key={month}>
                      {month}
                    </MenuItem>
                  ))}
                </ControlledTextField>
              </Collapse>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Collapse in={watch('willExtend') === true} timeout={300}>
                <UITextField
                  disabled
                  name={'newEndPeriod'}
                  label={'Novo término de fornecimento'}
                  value={GetDateFormat(watch('newEndPeriod')) || ''}
                />
              </Collapse>
            </Grid>
            <Grid container item justifyContent="flex-end" xs={12} mt={2}>
              <Grid item xs={12} sm={4} md={3}>
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Avançar
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </form>
  );
}
