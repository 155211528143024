import { ServicesChannels } from 'utils/Enums/ServicesChannels';

interface SecretaryClassificationDomainParams {
  serviceChannel: ServicesChannels;
  initialValue?: string | null;
}

interface SecretaryClassificationDomainResponse {
  disabled: boolean;
  value?: string | null;
}

export const SecretaryClassificationDomain = ({
  serviceChannel,
  initialValue
}: SecretaryClassificationDomainParams): SecretaryClassificationDomainResponse => {
  if (serviceChannel === 'Programa governamental') {
    return { disabled: false, value: initialValue ?? null };
  }
  return {
    disabled: true,
    value: null
  };
};
