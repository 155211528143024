import { ERRORS } from 'utils/Errors';
import * as yup from 'yup';

export interface ClientFormRes {
  type: string;
  name: string;
  email: string;
  telephone: string;
  regional?: string;
  cnpj?: string;
  socialName?: string;
  sold?: string;
  contactPerson?: string;
  segment?: string;
  uf: string;
  city: string;
  billingPlant?: string;
  creditLimit?: number;
  organization?: boolean;
  collectSt?: boolean;
  employeeId?: number;
}

export const CreateClientSchema = yup.object().shape({
  type: yup.string().required(ERRORS.REQUIRED_FIELD),
  name: yup.string().required(ERRORS.REQUIRED_FIELD),
  email: yup.string().required(ERRORS.REQUIRED_FIELD),
  telephone: yup.string().nullable(),
  regional: yup.string().nullable(),
  cnpj: yup.string().nullable(),
  socialName: yup.string().nullable(),
  sold: yup.string().required(ERRORS.REQUIRED_FIELD),
  contactPerson: yup.string().nullable(),
  segment: yup.string().nullable(),
  uf: yup.string().required(ERRORS.REQUIRED_FIELD),
  city: yup.string().required(ERRORS.REQUIRED_FIELD),
  billingPlant: yup.string().nullable(),
  creditLimit: yup.string().nullable(),
  organization: yup.boolean().nullable(),
  collectSt: yup.boolean().nullable(),
  employeeId: yup.number().nullable()
});
