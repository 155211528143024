/* eslint-disable @typescript-eslint/no-use-before-define */
import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Typography
} from '@mui/material';
import { GetDiscountPercentInput } from 'modules/Process/Domain/ProcessItem/GetDiscountPercent';
import { CalcMarkupInput } from 'modules/Process/Domain/ProcessItem/Markup';
import {
  InformDiscountFinancier,
  InformDiscountFinancierSchema
} from 'modules/Process/InformDiscount/Modals/InformDiscountModal/financier/domain';
import InformDiscountProcessInformation from 'modules/Process/InformDiscount/Modals/InformDiscountModal/shared/ProcessInformation';
import InformDiscountProductInformation from 'modules/Process/InformDiscount/Modals/InformDiscountModal/shared/ProductInformation';
import InformDiscountProductSubtitle from 'modules/Process/InformDiscount/Modals/InformDiscountModal/shared/Subtitles';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { FetchPatch, FetchQuery } from 'services';
import { UIChip } from 'shared/components/Chip';
import { ControlledRadioGroup } from 'shared/components/ControlledComponents/ControlledRadioGroup';
import { ControlledTextField } from 'shared/components/ControlledComponents/ControlledTextField';
import { UITextField } from 'shared/components/Inputs';
import {
  DiscountOrderModel,
  ProcessModel,
  ProcessProductModel,
  ProductsModel
} from 'types/models';
import { GetLangValueColor } from 'utils/Functions/GetLang';
import { MoneyMask, MoneyRemoveMask } from 'utils/masks';

type Props = {
  selectedProduct: ProcessProductModel;
  process: ProcessModel;
  open: boolean;
  onClose: () => void;
  patchDiscountOrder: FetchPatch<DiscountOrderModel>;
  patchProcessProduct: FetchPatch<ProcessProductModel>;
  processProductsRefetch: FetchQuery<
    ProcessProductModel[],
    ProcessProductModel
  >;
  loading: boolean;
  product?: ProductsModel;
};
export default function FinancierInformDiscountModal({
  selectedProduct,
  process,
  open,
  onClose,
  patchDiscountOrder,
  processProductsRefetch,
  patchProcessProduct,
  loading,
  product
}: Props) {
  const firstPrice = selectedProduct?.nfPrice
    ? MoneyMask(selectedProduct?.nfPrice)
    : '';
  const { handleSubmit, formState, watch, control, reset, setValue } =
    useForm<InformDiscountFinancier>({
      resolver: yupResolver(InformDiscountFinancierSchema),
      mode: 'onTouched',
      defaultValues: {
        discountValue: selectedProduct?.nfPrice
          ? MoneyMask(selectedProduct?.nfPrice)
          : '',
        status: selectedProduct?.discount_orders?.[0]?.status
      }
    });

  useEffect(() => {
    if (selectedProduct?.nfPrice) {
      reset({
        discountValue: MoneyMask(selectedProduct?.nfPrice),
        status: ['denied', 'approved'].includes(
          selectedProduct?.discount_orders?.[0]?.status || ''
        )
          ? selectedProduct?.discount_orders?.[0]?.status
          : undefined
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct]);

  const onComplete = async (data: InformDiscountFinancier) => {
    try {
      if (
        data.status &&
        data.discountValue &&
        selectedProduct?.discount_orders?.[0]?.id &&
        selectedProduct?.priceList &&
        selectedProduct?.id
      ) {
        const nfPrice = +(+MoneyRemoveMask(data.discountValue)).toFixed(2);
        const { markup } = CalcMarkupInput(
          nfPrice,
          selectedProduct?.markup || 0
        );
        const newDiscount = +GetDiscountPercentInput(
          data.discountValue,
          MoneyMask(selectedProduct.priceList)
        ).toFixed(2);
        await patchDiscountOrder(
          {
            ...data,
            discountValue: newDiscount
          },
          selectedProduct?.discount_orders?.[0]?.id
        );
        await patchProcessProduct(
          {
            nfPrice,
            institutionValue: +markup.toFixed(2),
            discountValue: newDiscount
          },
          selectedProduct?.id
        );
        toast.success('Desconto foi informado com sucesso');
        processProductsRefetch();
        onClose();
      } else {
        toast.error('Erro ao informar o resultado');
      }
    } catch (error) {
      toast.error('Erro ao informar o resultado');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        <Typography variant="h3" align={'center'}>
          Confirmar valores
        </Typography>
        <IconButton aria-label={'close'} onClick={onClose} size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <InformDiscountProductSubtitle subtitle="Informações do processo" />
      <InformDiscountProcessInformation process={process} product={product} />
      <InformDiscountProductSubtitle subtitle="Item selecionado" />
      <Box pl={1} pb={1}>
        <UIChip
          color={
            GetLangValueColor(
              'discountOrderStatus',
              selectedProduct?.discount_orders?.[0]?.status
            ).color
          }
          title={
            GetLangValueColor(
              'discountOrderStatus',
              selectedProduct?.discount_orders?.[0]?.status
            ).value || ''
          }
          height="20px"
        />
      </Box>
      <InformDiscountProductInformation
        processProduct={selectedProduct}
        product={product}
        showReferentialPrice
      />
      <InformDiscountProductSubtitle subtitle="Análise Financeira" />
      <form id={'inform-financier-result'} onSubmit={handleSubmit(onComplete)}>
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ControlledRadioGroup
                control={control}
                label={'Desconto aprovado?'}
                name={'status'}
                options={[
                  {
                    value: 'approved',
                    label: 'Sim'
                  },
                  {
                    value: 'denied',
                    label: 'Não'
                  }
                ]}
                value={watch('status')}
                onChange={({ target }) => {
                  setValue('status', target.value);
                  setValue('discountValue', firstPrice);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <UITextField
                label="(R$) Valor do último processo"
                fullWidth
                value={
                  selectedProduct.priceList
                    ? MoneyMask(selectedProduct.priceList?.toFixed(2))
                    : '-'
                }
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ControlledTextField
                name={'discountValue'}
                control={control}
                label={'Preço nota fiscal nestlé liberado'}
                pattern="money"
                disabled={watch('status') !== 'denied'}
              />
            </Grid>
            <Grid item xs={12}>
              <UITextField
                label="Justificativa dada pelo consultor irá aparecer aqui."
                multiline
                minRows={3}
                inputProps={{
                  maxLength: 256
                }}
                variant={'outlined'}
                fullWidth
                value={selectedProduct?.discount_orders?.[0]?.justify}
                disabled
              />
            </Grid>
          </Grid>
        </Box>
      </form>
      <Divider />
      <DialogActions>
        <Grid
          item
          container
          direction={'row'}
          justifyContent={'space-between'}
          xs={12}
        >
          <Grid item xs={3}>
            <Button
              fullWidth
              color={'primary'}
              onClick={onClose}
              variant="outlined"
            >
              Voltar
            </Button>
          </Grid>
          <Grid container item justifyContent={'space-between'} xs={6}>
            {loading ? (
              <Box width="100%" p={2}>
                <LinearProgress color="primary" />
              </Box>
            ) : (
              <Button
                fullWidth
                color={'primary'}
                variant="contained"
                disabled={!formState.isValid || loading}
                form={'inform-financier-result'}
                type={'submit'}
              >
                Confirmar valor
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
