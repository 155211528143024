/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import history from 'routes/History';
import { FetchQuery } from 'services';
import { AccessModel, ProcessModel } from 'types/models';
import { OrderModel } from 'types/models/Order/Order.model';
import { VerifyMakeOrderButton } from './domain';

interface ConsultantHeaderButtonsProps {
  id: string | number;
  type: string;
  process: ProcessModel;
  fetchAccess: FetchQuery<AccessModel[], AccessModel>;
  loadingFetchAccess: boolean;
  orders: OrderModel[];
}

export function HistoryHeaderButtons({
  id,
  type,
  process,
  fetchAccess,
  orders,
  loadingFetchAccess
}: ConsultantHeaderButtonsProps) {
  const [processEmpty, setProcessEmpty] = useState<boolean>(true);
  const [distributorHasAccess, setDistributorHasAccess] = useState<
    boolean | undefined
  >(undefined);
  const [hasOrderToInvoice, setHasOrderToInvoice] = useState<boolean>(false);

  const verifyHasDistributor = async (distributorId: number) => {
    try {
      const access = await fetchAccess({
        $limit: '-1',
        $select: ['distributorId', 'id'],
        distributorId
      });
      if (access?.[0]?.distributorId) {
        setDistributorHasAccess(true);
      } else {
        setDistributorHasAccess(false);
      }
    } catch (error) {
      toast.error('Erro ao tentar verificar se o Distribuidor possui acesso.');
    }
  };

  const verifyHasInvoice = () => {
    const hasInvoice = orders?.some((order) => order?.status === 'Processando');
    setHasOrderToInvoice(hasInvoice);
  };

  useEffect(() => {
    if (process?.process_products)
      if (process?.process_products?.length > 0) {
        const hasSomeNotEmpty = process.process_products.some(
          (processProduct) => {
            return (
              !!processProduct.winner && (processProduct.quantity || 0) > 0
            );
          }
        );
        setProcessEmpty(!hasSomeNotEmpty);
      }
    if (
      process?.distributorId &&
      type === 'consultant' &&
      distributorHasAccess === undefined
    ) {
      // Need to do this because of looping
      setDistributorHasAccess(false);
      verifyHasDistributor(process?.distributorId);
    }
  }, [process]);

  useEffect(() => {
    if (orders) {
      verifyHasInvoice();
    }
  }, [orders]);

  return (
    <Grid container justifyContent={'flex-end'} spacing={2}>
      {type === 'consultant' && hasOrderToInvoice ? (
        <Grid item xs={4} md={2}>
          <Button
            variant={'contained'}
            fullWidth
            onClick={() => history.push(`/processo/${id}/email-to-order`)}
          >
            Gerar E2O
          </Button>
        </Grid>
      ) : null}
      {type === 'financier' && process?.status === 'awaiting financier' ? (
        <Grid item xs={2}>
          <Button
            variant={'contained'}
            fullWidth
            onClick={() =>
              history.push(`/processo/${id}/informar-desconto/financeiro`)
            }
          >
            Informar Desconto
          </Button>
        </Grid>
      ) : null}
      {VerifyMakeOrderButton({
        distributorHasAccess: loadingFetchAccess
          ? true
          : !!distributorHasAccess,
        processEmpty,
        segment: process?.segment,
        status: process?.status,
        type
      }) ? (
        <Grid item xs={6} md={3}>
          <Button
            variant={'contained'}
            fullWidth
            onClick={() => history.push(`/processo/${id}/pedido`)}
          >
            Solicitar pedido
          </Button>
        </Grid>
      ) : null}
      {type === 'admin' && process?.status === 'awaiting order' ? (
        <Grid item xs={2}>
          <Button
            variant={'contained'}
            fullWidth
            onClick={() => history.push(`/processo/admin/${id}/editar`)}
          >
            Editar Processo
          </Button>
        </Grid>
      ) : null}
    </Grid>
  );
}
