import { TableColumnsDistributorCantSee } from 'utils/Constants/ColumnsConsultantCantSee';
import { DistributorCensoredColumns } from 'utils/Constants/DistributorCensoredColumns';
import { ProcessStatus } from 'utils/Constants/ProcessStatus';
import { ProcessHistoryItemsColumns } from '../Constants';

export const ProcessHistoryTable = (isDistributor: boolean, status: string) => {
  let columns = [...ProcessHistoryItemsColumns];
  if (isDistributor) {
    columns = columns.filter(
      (column) => !TableColumnsDistributorCantSee.includes(column.field)
    );
  }

  const position = ProcessStatus.find((processStatus) =>
    processStatus.status.includes(status || '')
  )?.position;

  if (position && isDistributor) {
    if (position < 4) {
      columns = columns.filter(
        (column) => !DistributorCensoredColumns.includes(column.field)
      );
    }
  }
  return columns;
};
