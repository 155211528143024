import styled from 'styled-components';
import { Tabs, TabsProps } from '@mui/material';
import { theme } from 'shared/theme';

export type StyledProps = {
  colorIndicator?: keyof typeof theme.colors;
} & TabsProps;

export const StyledTabs = styled(Tabs)<StyledProps>`
  border-bottom: 1px solid #e8e8e8;
  min-height: 30px;

  .MuiTabs-indicator {
    background: ${({ colorIndicator }) =>
      theme.colors[colorIndicator || 'Primary']};
  }
`;
