/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogProps,
  DialogTitle,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import { PdfRender } from 'shared/components/Pdf/HtmlRender';
import { AccessTermsModel, TermsModel } from 'types/models/Terms/Terms.model';
import { theme } from 'shared/theme';
import { usePost, useQuery } from 'services';
import { AttachmentModel } from 'types/models/Attachments/Attachment.model';
import { Paginated } from 'types/services';

export interface TermsOfUseProps extends DialogProps {
  onComplete: () => void;
  termsOfUse: TermsModel[];
  token?: string;
  accessId?: number;
}

export function TermsOfUseModal({
  onComplete,
  termsOfUse,
  onClose,
  token,
  accessId,
  ...rest
}: TermsOfUseProps) {
  const additionalAxiosOptions = {
    headers: {
      Authorization: token
    }
  };

  const [actualTerm, setActualTerm] = useState<number>(0);
  const total = termsOfUse.length;

  const [termAttachment, attachmentsTermRefetch, attachmentsTermLoading] =
    useQuery<Paginated<AttachmentModel>, AttachmentModel>(`/attachments`, {
      table: 'terms',
      fetchOnInitialize: false
    });
  const [, termApprove, termApproveLoading] =
    usePost<Partial<AccessTermsModel>>('/access-terms');

  useEffect(() => {
    if (termsOfUse?.[actualTerm]?.id) {
      attachmentsTermRefetch({
        tableId: termsOfUse?.[actualTerm]?.id,
        additionalAxiosOptions
      });
    }
  }, [termsOfUse]);

  const onCancel = () => {
    if (onClose) {
      setActualTerm(0);
      onClose({}, 'escapeKeyDown');
    }
  };

  const onConfirmTerm = async () => {
    await termApprove(
      {
        accessId,
        status: 'approved',
        termId: termsOfUse?.[actualTerm]?.id
      },
      false,
      additionalAxiosOptions
    );
    if (actualTerm < total - 1) {
      setActualTerm(actualTerm + 1);
      await attachmentsTermRefetch({
        tableId: termsOfUse?.[actualTerm + 1]?.id,
        additionalAxiosOptions
      });
    } else {
      onComplete();
    }
  };

  const renderPdf = () => {
    if (attachmentsTermLoading) {
      return (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          mt={6}
          mb={6}
        >
          {' '}
          <CircularProgress />{' '}
        </Grid>
      );
    }

    if (termAttachment?.data?.[0]?.key) {
      return <PdfRender url={termAttachment?.data?.[0]?.key} />;
    }
    return (
      <Grid container justifyContent="center" alignItems="center" mt={6} mb={6}>
        <Typography
          variant={'h2'}
          style={{
            fontWeight: theme.font.semiBold,
            color: theme.colors.TextGrey
          }}
        >
          Termo não possui documento.
        </Typography>
      </Grid>
    );
  };

  return (
    <Dialog maxWidth={'md'} fullWidth {...rest}>
      <DialogTitle>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          mt={2}
          mb={2}
        >
          <Typography
            variant={'h2'}
            style={{
              fontWeight: theme.font.semiBold,
              color: theme.colors.TextDarkGrey
            }}
          >
            {termsOfUse?.[actualTerm]?.name}
          </Typography>
          <Typography
            variant={'h5'}
            style={{
              fontWeight: theme.font.semiBold,
              color: theme.colors.TextGrey
            }}
          >
            ({actualTerm + 1}/{total})
          </Typography>
        </Grid>
      </DialogTitle>
      <Box mb={3}>{renderPdf()}</Box>
      <Divider />
      <DialogActions>
        <Grid container justifyContent={'flex-end'} spacing={2}>
          {termApproveLoading ? (
            <CircularProgress size={25} />
          ) : (
            <>
              <Grid item xs={3}>
                <Button
                  fullWidth
                  variant={'outlined'}
                  onClick={() => onCancel()}
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid
                item
                container
                justifyContent="center"
                alignItems="center"
                xs={4}
              >
                <Button
                  fullWidth
                  variant={'contained'}
                  onClick={() => onConfirmTerm()}
                >
                  Aceitar
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
