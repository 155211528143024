/* eslint-disable no-await-in-loop */
/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress, Container, Fab } from '@mui/material';
import InformDiscountBottom from 'modules/Process/InformDiscount/Fragments/Bottom';
import InformDiscountItemsTable from 'modules/Process/InformDiscount/Fragments/ItemsTable';
import InformDiscountTitle from 'modules/Process/InformDiscount/Fragments/Title';
import NewPageBreadcrumb from 'modules/Process/shared/Fragment/NewPageBreadcrumb';
import { useAuth } from 'Providers/Auth';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { usePatch, useQuery } from 'services';
import { useFab } from 'shared/components/Fab';
import {
  DiscountOrderModel,
  ProcessModel,
  ProcessProductModel,
  ProductsModel
} from 'types/models';
import { AttachmentModel } from 'types/models/Attachments/Attachment.model';
import { UrlParams } from 'types/services';
import CheckIcon from '@mui/icons-material/Check';
import { toast } from 'react-toastify';

export function ApproveDiscount() {
  const { id } = useParams() as UrlParams;
  const { auth } = useAuth();
  const history = useHistory();

  const [processProducts, processProductsRefetch] = useQuery<
    ProcessProductModel[],
    ProcessProductModel
  >(`/process-products`, {
    discount: 1,
    $limit: '-1',
    processId: id
  });

  const [process] = useQuery<ProcessModel, ProcessModel>(`/process/${id}`);

  const [attachments, attachmentsRefetch, attachmentsLoading] = useQuery<
    AttachmentModel[],
    ProcessProductModel
  >(`/attachments`, {
    table: 'process',
    $limit: '-1',
    tableId: id,
    accessId: auth?.id,
    fetchOnInitialize: false
  });

  const [productsToPolitical, fetchProductsToPolitical] = useQuery<
    ProductsModel[],
    ProductsModel
  >(`/products`, {
    fetchOnInitialize: false,
    $limit: -1,
    $select: ['id', 'sku'],
    processId: id
  });
  useEffect(() => {
    if (processProducts) {
      fetchProductsToPolitical({
        'id[$in]': processProducts?.map(
          (processProduct) => processProduct.productId
        )
      });
    }
  }, [processProducts]);

  const [, patchDiscountOrder, patchDiscountOrderLoading] =
    usePatch<DiscountOrderModel>(`/discount-order`);
  const [, patchProcessProduct, patchProcessProductLoading] =
    usePatch<ProcessProductModel>(`/process-products`);

  useEffect(() => {
    if (auth) {
      if (auth.employee?.accessLevel !== 'manager') {
        history.push(`/processo/${id}`);
      }
    }
    attachmentsRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.id]);

  const confirmDiscount = async (discountOrder: DiscountOrderModel) => {
    const patchedDiscount = await patchDiscountOrder(
      {
        managerApproved: true
      },
      discountOrder.id
    );
    return patchedDiscount;
  };

  const handleSubmit = async () => {
    const discountOrders: DiscountOrderModel[] = [];
    processProducts?.forEach((processProduct) => {
      if (processProduct?.discount_orders) {
        discountOrders.push(...processProduct.discount_orders);
      }
    });
    await Promise.all(
      discountOrders.map(async (discountOrder) => {
        const confirmedDiscount = await confirmDiscount(discountOrder);
        return confirmedDiscount;
      })
    );
    toast.success(`Descontos confirmados com sucesso.`);
    history.push(`/processo/${id}`);
  };

  const fabClasses = useFab();

  return (
    <>
      <Container maxWidth={'lg'}>
        <NewPageBreadcrumb id={id} title="Ciência de Descontos" />
        <InformDiscountTitle
          title="Ciência de Descontos"
          subtitle="Analise os descontos já aprovados e confirme-os."
        />
        <InformDiscountItemsTable
          type="consultant"
          processProducts={processProducts}
          process={process || {}}
          patchDiscountOrder={patchDiscountOrder}
          processProductsRefetch={processProductsRefetch}
          patchProcessProduct={patchProcessProduct}
          loading={patchProcessProductLoading || patchDiscountOrderLoading}
          productsToPolitical={productsToPolitical}
        />
        <InformDiscountBottom
          setJustify={() => {}}
          attachments={attachments || []}
          attachmentsRefetch={attachmentsRefetch}
          attachmentsLoading={attachmentsLoading}
          accessId={auth?.id}
          processId={+id}
          observationTitle="A observação criada pelo financeiro aparecerá abaixo."
          justify={process?.justify}
          disabled
        />
      </Container>
      <Fab
        className={fabClasses.fab}
        color={'primary'}
        variant={'extended'}
        aria-label="add"
        onClick={handleSubmit}
        disabled={patchDiscountOrderLoading}
      >
        {patchDiscountOrderLoading ? (
          <>
            <CircularProgress color="inherit" size={20} />
            Aguarde...
          </>
        ) : (
          <>
            <CheckIcon />
            Confirmar
          </>
        )}
      </Fab>
    </>
  );
}
