/* eslint-disable no-return-assign */
import { Grid, Typography } from '@mui/material';
import { GetUiChipContent } from 'modules/Process/History/Fragments/OrderInExecution/Functions';
import React from 'react';
import { UIChip } from 'shared/components/Chip';
import { TitleLabel } from 'shared/components/TitleLabel';
import { theme } from 'shared/theme';
import { OrderModel } from 'types/models/Order/Order.model';
import { GetDateFormat } from 'utils/Functions/FormatDate';
import { MoneyMask } from 'utils/masks';

type OrderDetailsBodyProps = {
  order?: OrderModel;
};

export default function OrderDetailsBody({ order }: OrderDetailsBodyProps) {
  const GetTotal = () => {
    if (order) {
      if (order.order_items?.length > 0) {
        const total = order?.total;
        const totalQuantity = order?.order_items.reduce(
          (acc, item) => acc + (item.quantity || 0),
          0
        );
        return {
          total,
          totalQuantity
        };
      }
    }
    return {
      total: 0,
      totalQuantity: 0
    };
  };

  const Totalizer = GetTotal();

  return (
    <Grid container item mb={2} spacing={2}>
      <Grid container item xs={12} sm={2}>
        <Grid container item xs={2} gap={1} flexDirection="column">
          <Typography
            variant="h4"
            style={{
              color: theme.colors.TextDarkGrey,
              fontWeight: 'normal'
            }}
          >
            Status
          </Typography>
          <UIChip
            color={GetUiChipContent(order).color}
            fontWeight="800"
            title={GetUiChipContent(order).title}
            height="25px"
          />
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={4}
        sm={3}
        lg={2}
        gap={1}
        flexDirection="column"
        justifyContent="space-between"
      >
        <TitleLabel
          title="Pedido solicitado em: "
          label={GetDateFormat(order?.createdAt)}
        />
      </Grid>
      <Grid
        container
        item
        xs={4}
        sm={3}
        lg={2}
        gap={1}
        flexDirection="column"
        justifyContent="space-between"
      >
        <TitleLabel
          title="Pedido faturado em: "
          label={GetDateFormat(order?.approvedDate)}
        />
      </Grid>
      <Grid
        container
        item
        xs={4}
        sm={3}
        lg={2}
        gap={1}
        flexDirection="column"
        justifyContent="space-between"
      >
        <TitleLabel
          title="Pedido entregado em: "
          label={GetDateFormat(order?.deliveryDate)}
        />
      </Grid>
      <Grid
        container
        item
        xs={4}
        sm={6}
        lg={2}
        gap={1}
        flexDirection="column"
        justifyContent="space-between"
      >
        <TitleLabel
          title="(R$) Total: "
          label={MoneyMask(`${Totalizer.total}`)}
        />
      </Grid>
      <Grid
        container
        item
        xs={4}
        sm={6}
        lg={2}
        gap={1}
        flexDirection="column"
        justifyContent="space-between"
      >
        <TitleLabel
          title="(UNI) Quantidade Total: "
          label={`${Totalizer.totalQuantity}`}
        />
      </Grid>
    </Grid>
  );
}
