import React from 'react';

import { Box, Container, Grid, Typography } from '@mui/material';
import { HeaderProfile } from 'shared/components/Header/HeaderProfile';
import { HeaderOpt } from 'shared/components/Header/HeaderOpts';
import { HeaderOptions } from 'types/Config/Headers';
import { UILink } from 'shared/components/Link';

interface HeaderDesktopProps {
  options: HeaderOptions[];
}

export function HeaderDesktop({ options }: HeaderDesktopProps) {
  return (
    <Box pt={1} pb={1} mb={2} bgcolor="white" boxShadow={2}>
      <Container maxWidth={'lg'}>
        <Grid container alignItems={'center'}>
          <Grid item container xs={3}>
            <Grid container item alignItems={'center'}>
              <UILink to={'/dashboard'} style={{ textDecoration: 'none' }}>
                <Typography
                  variant={'h1'}
                  style={{
                    fontFamily: '"Be Vietnam Pro", sans-serif',
                    fontSize: '23px'
                  }}
                >
                  Vendas Institucionais
                </Typography>
              </UILink>
              <Box mb={1} />
            </Grid>
          </Grid>
          <Grid
            item
            container
            alignItems={'center'}
            xs={7}
            justifyContent={'flex-end'}
          >
            <HeaderOpt options={options} />
          </Grid>
          <Grid item xs={2}>
            <Box pr={2}>
              <HeaderProfile />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
