/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-nested-ternary */
import { Grid } from '@mui/material';
import React from 'react';
import ContactCard from 'shared/components/Cards/ContactCard';
import { ThemeColors } from 'shared/enum';
import { DistributorModel } from 'types/models';

interface ClientCardProps {
  distributor: DistributorModel;
  onDelete: () => void;
  onEdit: () => void;
}

export function ClientCard({ distributor, onDelete, onEdit }: ClientCardProps) {
  const color = TypesColor[distributor?.type || '-']?.borderColor;
  const chipColor = TypesColor[distributor?.type || '-']?.chipColor;

  return (
    <Grid item xs={12} md={4} key={distributor.id}>
      <ContactCard
        borderColor={color}
        chip={{
          color: chipColor,
          title: distributor?.type || '-',
          height: '20px'
        }}
        city={distributor.city}
        institution=""
        name={distributor.name}
        email={distributor.email}
        onMenuClick={() => {}}
        onPencilClick={() => onEdit()}
        onTrashClick={() => onDelete()}
        uf={distributor.uf}
      />
    </Grid>
  );
}

const TypesColor = {
  Distribuidor: { chipColor: 'blue', borderColor: ThemeColors.Primary },
  Hospitalar: { chipColor: 'pink', borderColor: ThemeColors.Pink },
  'Cesta básica': { chipColor: 'orange', borderColor: ThemeColors.Orange },
  Concessionária: { chipColor: 'green', borderColor: ThemeColors.Success }
};
