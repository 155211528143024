import React from 'react';
import { Grid, Grow } from '@mui/material';
import ActionCard from 'shared/components/Cards/ActionCard';
import { useQuery } from 'services';
import { Paginated } from 'types/services';
import { ProcessModel } from 'types/models';
import { LoadingActions } from 'modules/Dashboard/Cards/Actions/LoadingActions';
import { useDelete } from 'services/api/UseDelete';
import { HeaderActions } from '../../HeaderActions';

export function DistributorIncompleteProcessAction() {
  const [getActionProcess, refetchActions, loading] = useQuery<
    Paginated<ProcessModel>
  >('/process', {
    status: 'dist recording',
    $limit: 4
  });

  const [, deleteProcess] = useDelete<ProcessModel>(`/process`);

  const actionsProcess = getActionProcess?.data || ([] as ProcessModel[]);

  return (
    <Grid item container direction="column" spacing={2}>
      {(getActionProcess?.total || 0) !== 0 && (
        <HeaderActions quantity={getActionProcess?.total || 0} />
      )}
      <Grid item container spacing={2} direction={{ xs: 'column', md: 'row' }}>
        {loading ? (
          <>
            {Array.from(new Array(4)).map(() => (
              <Grid item>
                <LoadingActions />
              </Grid>
            ))}
          </>
        ) : (
          <>
            {actionsProcess.map((actionProcess, key) => (
              <Grow in={!loading} timeout={key * 1000}>
                <Grid item md={3}>
                  <ActionCard
                    title="Distribuidor"
                    redirect={`/cadastro/processo/distribuidor?processo=${actionProcess.id}`}
                    action="Adicionar processo ao sistema"
                    chips={[]}
                    city={actionProcess?.city}
                    onDelete={async () => {
                      if (actionProcess.id) {
                        await deleteProcess(actionProcess.id);
                        refetchActions();
                      }
                    }}
                    tradingCode={actionProcess.tradingCode}
                    createdAt={String(actionProcess.createdAt)}
                  />
                </Grid>
              </Grow>
            ))}
          </>
        )}
      </Grid>
    </Grid>
  );
}
