/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { theme } from 'shared/theme';
import { ProcessModel, ProductsModel } from 'types/models';
import { FormatName } from 'utils/Functions/FormatName';
import { ProcessDescribeFragment } from 'modules/Process/InformDiscount/Modals/InformDiscountModal/shared/ProcessInformation/ProcessDescribe';
import { getRealDate } from 'modules/Process/InformDiscount/Modals/InformDiscountModal/shared/ProcessInformation/functions/getRealDate';
import { PoliticalPriceType } from 'utils/Constants/PoliticalPriceType';
import { getFormattedServiceChannel } from 'utils/Enums';
import { BooleanToYes } from 'utils/Constants/BooleanToYes';
import { GetFocusUfValue } from 'utils/Functions/focusUf';

type Props = {
  process: ProcessModel;
  product?: ProductsModel;
};

export default function InformDiscountProcessInformation({
  process,
  product
}: Props) {
  return (
    <Box p={2} bgcolor={theme.colors.LightGrey} width="100%">
      <Grid container item xs={12}>
        <Grid
          container
          item
          direction="column"
          sm={5}
          xs={12}
          flexWrap="nowrap"
          justifyContent="center"
        >
          <Typography
            variant="h3"
            style={{
              color: theme.colors.TextDarkGrey,
              fontWeight: theme.font.semiBold,
              fontSize: '20px'
            }}
          >
            {process.institution?.name}
          </Typography>
          <Box mb={1} />
          <Typography
            variant="h3"
            style={{
              color: theme.colors.Primary
            }}
          >
            {process?.city ? FormatName(process.city) : ''} -{' '}
            {process.institution?.uf}
          </Typography>
        </Grid>
        <Grid
          container
          item
          direction="column"
          justifyContent="center"
          alignItems="center"
          xs={1}
        >
          <Divider orientation="vertical" />
        </Grid>

        <Grid container item direction="column" sm={6} xs={12}>
          <Grid container spacing={1}>
            <ProcessDescribeFragment
              title="Política comercial"
              info={
                PoliticalPriceType[product?.political_prices?.[0]?.type || '']
                  ?.label || '-'
              }
            />
            <ProcessDescribeFragment
              title="Número do pregão"
              info={process.tradingCode || '-'}
            />
            <ProcessDescribeFragment
              title="Classificação"
              info={process.realClassification || '-'}
            />
            <ProcessDescribeFragment
              title="Sold To"
              info={process.distributor?.sold || '-'}
            />
            <ProcessDescribeFragment
              title="Abertura da licitação"
              info={getRealDate(process)}
            />
            <ProcessDescribeFragment
              title="Município foco"
              info={GetFocusUfValue(process)}
            />
            <ProcessDescribeFragment
              title="Razão Social (Cliente)"
              info={process.distributor?.name || '-'}
            />
            <ProcessDescribeFragment
              title="UF (Cliente)"
              info={process.distributor?.uf?.toUpperCase() || '-'}
            />
            <ProcessDescribeFragment
              title="Forma de participação"
              info={process.processDirect ? 'Direta' : 'Indireta'}
            />
            <ProcessDescribeFragment
              title="Canal de atendimento"
              info={getFormattedServiceChannel(process.serviceChannel || '-')}
            />
            {/* TO-DO: Recolher ST, na melhoria de cliente. */}
            <ProcessDescribeFragment
              title="Recolhe ST"
              info={
                process.distributor?.collectSt ||
                process.distributor?.collectSt === false
                  ? BooleanToYes[String(process.distributor?.collectSt)]
                  : '-'
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
