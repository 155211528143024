import { ThemeColors } from 'shared/enum';

export const theme = {
  border: {
    radius: {
      small: '2px',
      medium: '4px',
      large: '10px',
      rounded: '50%'
    }
  },
  font: {
    family: '"Roboto", "Helvetica", "Arial", sans-serif',
    light: 300,
    normal: 400,
    semiBold: 500,
    bold: 600,
    black: 700,
    sizes: {
      xxsmall: '0.4rem',
      xsmall: '0.6rem',
      small: '0.8rem',
      medium: '0.9rem',
      large: '1.5rem',
      xlarge: '2rem'
    }
  },
  colors: ThemeColors,
  transition: {
    default: '0.3s ease-in-out',
    fast: '0.1s ease-in-out'
  }
};
