import { Button, Grid } from '@mui/material';
import { ProductForm } from 'modules/Admin/Products/forms/product';
import React from 'react';
import { usePatch, usePost } from 'services';
import UIPaper from 'shared/components/Paper';
import { ProductsModel } from 'types/models';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

type Props = {
  product?: ProductsModel;
  id?: number;
};

export function ProductFormFragment({ product, id }: Props) {
  const history = useHistory();
  const [, patchProduct] = usePatch<ProductsModel>('/products');
  const [, postProduct] = usePost<ProductsModel, ProductsModel>('/products');

  const handleEdit = (data: ProductsModel) => {
    try {
      patchProduct(
        {
          ...data
        },
        id
      );
      toast.success('Informações atualizadas com sucesso');
    } catch (error) {
      toast.error('Falha ao atualizar o produto, tente novamente mais tarde');
    }
  };

  const handleCreate = async (data: ProductsModel) => {
    try {
      const productAdded = await postProduct({
        ...data
      });
      if (productAdded?.id) {
        toast.success(
          'Produto cadastrado com sucesso, agora você já pode cadastrar as demais informações'
        );
        history.push(`/produto/editar/${productAdded?.id}`);
      } else {
        toast.error('Falha ao cadastrar o produto, tente novamente mais tarde');
      }
    } catch (error) {
      toast.error('Falha ao cadastrar o produto, tente novamente mais tarde');
    }
  };

  const handleCompleteProduct = (data: ProductsModel) => {
    if (id) {
      handleEdit(data);
    } else {
      handleCreate(data);
    }
  };

  return (
    <UIPaper title="Informações do produto">
      <Grid item xs={12} mb={2}>
        <ProductForm onComplete={handleCompleteProduct} product={product} />
        <Grid item xs={12} container justifyContent={'flex-end'}>
          <Grid item xs={12} sm md={3} mt={2}>
            <Button
              fullWidth
              variant={'contained'}
              form="product-form"
              type="submit"
            >
              Salvar Informações
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </UIPaper>
  );
}
