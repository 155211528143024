export const ImportsStatus = {
  'finished with error': {
    label: 'Finalizado com erros',
    color: 'red'
  },
  'finished with error catch mode': {
    label: 'Finalizado com erros',
    color: 'red'
  },
  'finished with error no catch': {
    label: 'Finalizado com erros',
    color: 'red'
  },
  finished: {
    label: 'Finalizado sem erros',
    color: 'green'
  },
  started: {
    label: 'Em andamento',
    color: 'orange'
  }
};
