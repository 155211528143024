import { ERRORS } from 'utils/Errors';
import * as yup from 'yup';

export interface InformDiscountFinancier {
  status: string;
  discountValue: string;
}

export const InformDiscountFinancierSchema = yup.object().shape({
  status: yup.string().required(ERRORS.REQUIRED_FIELD),
  discountValue: yup.string().when('status', {
    is: 'denied',
    then: yup.string().required()
  })
});
