import { theme } from 'shared/theme';
import lang from 'utils/lang';

type LangOptions = keyof typeof lang;

export const GetLangValueColor = (
  type: Extract<LangOptions, 'processStatus' | 'discountOrderStatus'>,
  key?: string
) => {
  if (key)
    if (lang[type][key]) {
      return lang[type][key];
    }
  return lang[type].default;
};

export const GetAccessLevelColor = (accessLevel: string) => {
  switch (accessLevel) {
    case 'consultant':
      return theme.colors.Primary;
    case 'supervisor':
      return theme.colors.Error;
    case 'manager':
      return theme.colors.TextDarkGrey;
    case 'financier':
      return theme.colors.Orange;
    case 'admin':
      return theme.colors.Pink;
    default:
      return theme.colors.Success;
  }
};
