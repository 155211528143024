import React from 'react';
import { Divider, Grid, IconButton, Menu, MenuItem } from '@mui/material';
import UIText from 'shared/components/UI/Text';
import { ChipProps, UIChip } from 'shared/components/Chip';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ThemeColors } from 'shared/enum';
import * as S from './styles';

interface ContactCardProps {
  borderColor?: string;
  institution?: string;
  name?: string;
  email?: string;
  city?: string;
  uf?: string;
  local?: string;
  onPencilClick?: () => void;
  onTrashClick?: (
    event:
      | React.MouseEvent<HTMLAnchorElement>
      | React.MouseEvent<HTMLButtonElement>
  ) => void;
  chip?: ChipProps;
  disabled?: boolean;
  onMenuClick?: () => void;
  menuItems?: { text: string; onClick: () => void }[];
}

export default function ContactCard({
  borderColor,
  name,
  email,
  onPencilClick,
  onTrashClick,
  chip,
  city,
  uf,
  disabled,
  onMenuClick,
  menuItems
}: ContactCardProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | SVGSVGElement>(null);

  const handleClick = (event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <S.Wrapper
      container
      direction="row"
      justifyContent="space-between"
      borderColor={borderColor}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" alignItems="center">
            {chip && <UIChip height={'.5rem'} {...chip} />}
            {!disabled ? (
              <Grid item>
                <IconButton size="large">
                  <EditIcon
                    onClick={onPencilClick}
                    cursor={'pointer'}
                    color={'primary'}
                  />
                </IconButton>

                <IconButton onClick={onTrashClick} size="large">
                  <DeleteForeverIcon cursor={'pointer'} color={'error'} />
                </IconButton>
              </Grid>
            ) : (
              <Grid item>
                <MoreVertIcon
                  fontSize={'medium'}
                  cursor={'pointer'}
                  onClick={(e) => {
                    handleClick(e);
                    if (onMenuClick) {
                      onMenuClick();
                    }
                  }}
                />
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {menuItems &&
                    menuItems.map((item) => (
                      <MenuItem
                        key={item.text}
                        onClick={() => {
                          item.onClick();
                          handleClose();
                        }}
                      >
                        <UIText text={item.text} />
                      </MenuItem>
                    ))}
                </Menu>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <UIText text={name} fontSize="16px" fontWeight="600" />
        </Grid>
        <Grid item xs={12}>
          <UIText text={email} fontSize="12px" fontWeight="400" />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid container justifyContent="flex-start" alignItems="center">
        <UIText
          text="Local: "
          fontSize="12px"
          fontWeight="400"
          textColor={ThemeColors.TextLightGrey}
          whiteSpace={'pre'}
        />
        <UIText
          text={`${city || ''}, ${uf || ''}`}
          fontSize="12px"
          fontWeight="600"
          textColor="black"
        />
      </Grid>
    </S.Wrapper>
  );
}
