import { Box, Typography } from '@mui/material';
import React from 'react';
import { UIBreadcrumb } from 'shared/components/Breadcrumb';
import { UILink } from 'shared/components';

type Props = {
  id: string | number;
  title: string;
};
export default function NewPageBreadcrumb({ id, title }: Props) {
  return (
    <Box mb={3}>
      <UIBreadcrumb>
        <UILink to={'/'} color={'inherit'}>
          Dashboard
        </UILink>
        <UILink to={`/processo/${id}`} color={'inherit'}>
          Processo
        </UILink>
        <Typography color={'primary'}> {title}</Typography>
      </UIBreadcrumb>
    </Box>
  );
}
