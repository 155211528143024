export const ImportsType: ImportsTypeType = {
  'political-prices': {
    label: 'Política Comercial',
    color: 'pink',
    file: 'political-price-demo.xlsx',
    filename: 'PoliticaComercialDemo.xlsx',
    isLive: true
  },
  products: {
    label: 'Produtos',
    color: 'orange',
    file: 'product-demo.xlsx',
    filename: 'ProdutosDemo.xlsx',
    isLive: true
  },
  'cities-consultants': {
    label: 'Cidade dos Consultores',
    color: 'blue',
    file: 'cities-consultants-demo.xlsx',
    filename: 'CidadesDosConsultoresDemo.xlsx',
    isLive: true
  },
  distributors: {
    label: 'Clientes',
    color: 'red',
    file: 'distributors-demo.xlsx',
    filename: 'ClientesDemo.xlsx',
    isLive: true
  },
  'price-distributors': {
    label: 'Preço dos Clientes',
    color: 'green',
    file: 'price-distributors-demo.xlsx',
    filename: 'PrecoListaDistribuidorDemo.xlsx',
    isLive: true
  },
  pne: {
    label: 'PNE',
    color: 'white',
    file: 'pne-demo.xlsx',
    filename: 'PneDemo.xlsx',
    isLive: false
  }
};
export type ImportsOptions =
  | 'political-prices'
  | 'products'
  | 'cities-consultants'
  | 'distributors'
  | 'price-distributors'
  | 'pne';

type ImportsTypeContent = {
  label: string;
  color: string;
  file: string;
  filename: string;
  isLive: boolean;
};

type ImportsTypeType = Record<ImportsOptions, ImportsTypeContent>;
