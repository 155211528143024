import { LinearProgress } from '@mui/material';
import React from 'react';
import { usePatch, useQuery } from 'services';
import { ProcessModel } from 'types/models';
import { PrivateProcessProposalAnalysisForm } from './PrivateProposalAnalysisForm';
import { PrivateProcessProposalAnalysisInputs } from './PrivateProposalAnalysisForm/domain';

type PrivateProcessProposalAnalysisProps = {
  id?: string;
  onComplete: () => void;
  onFail?: () => void;
};

export function PrivateProcessProposalAnalysis({
  id,
  onComplete,
  onFail
}: PrivateProcessProposalAnalysisProps) {
  const ProcessQuery = useQuery<ProcessModel>(`/process/${id}`, {
    fetchOnInitialize: !!id
  });
  const processLoading = ProcessQuery[2];
  const process = ProcessQuery[0];

  const [, processPatchFetch, patchLoading] = usePatch('/process');

  const onCompleteProposalAnalysis = async (
    data: PrivateProcessProposalAnalysisInputs
  ) => {
    const queryData = {
      ...data,
      processDirect: !!data?.processDirect,
      canExtend: !!data?.canExtend
    };
    if (process && id) {
      await processPatchFetch(queryData, +id);
      onComplete();
    } else if (onFail) {
      onFail();
    }
  };

  return processLoading ? (
    <LinearProgress />
  ) : (
    <PrivateProcessProposalAnalysisForm
      onComplete={onCompleteProposalAnalysis}
      process={process || {}}
      loading={patchLoading}
    />
  );
}
