import { Container, Grid } from '@mui/material';
import AddUserTitle from 'modules/Admin/Users/All/Fragments/Title';
import { BreadcrumbEmployeeAmin } from 'modules/Admin/Users/Employee/Fragment/BreadcrumbEmployeeAdmin';
import { CreateEmployeeFragment } from 'modules/Admin/Users/Employee/Fragment/CreateEmployee';
import React from 'react';

export function CreateOrUpdateEmployee() {
  return (
    <Container maxWidth={'lg'}>
      <BreadcrumbEmployeeAmin create />
      <AddUserTitle type="Funcionário Nestlé" />
      <Grid item>
        <CreateEmployeeFragment />
      </Grid>
    </Container>
  );
}
