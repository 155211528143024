import {
  Autocomplete,
  Box,
  Button,
  Collapse,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup
} from '@mui/material';
import { useFormik } from 'formik';
import {
  ProcessItemMinimalFormFields,
  ProcessItemMinimalFormProps
} from 'modules/Process/PublicProcess/Forms/ScopeRegistration/minimal/domain';
import { ProcessItemMinimalSchema } from 'modules/Process/PublicProcess/Forms/ScopeRegistration/minimal/domain/FormValidation';
import React from 'react';
import { useQuery } from 'services';
import { UITextField } from 'shared/components/Inputs';
import { ProcessProductModel, ProductsModel } from 'types/models';
import {
  GetBooleanString,
  GetStringBoolean
} from 'utils/Functions/GetBooleanString';

/* THIS FORM IS FOR NON NESTLE EMPLOYER */
export function ProcessItemMinimalForm({
  processProduct,
  onComplete
}: ProcessItemMinimalFormProps) {
  const [products] = useQuery<ProductsModel[], ProductsModel>('/products', {
    $limit: '-1',
    disabled: 0
  });

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    handleBlur,
    setFieldValue
  } = useFormik<ProcessItemMinimalFormFields>({
    initialValues: {
      itemNumber: processProduct?.itemNumber || 0,
      specification: processProduct?.specification || '',
      unity: 'UNI',
      quantity: processProduct?.initialQuantity || 0,
      approve: GetBooleanString(processProduct?.approve) || 'true',
      justify: processProduct?.justify || '',
      productId: processProduct?.productId
    },
    validationSchema: ProcessItemMinimalSchema,
    onSubmit: (fields) => {
      const finalResponse = fields;
      finalResponse.approve = GetStringBoolean(fields.approve);
      finalResponse.unity = 'UNI';
      onComplete(finalResponse as Partial<ProcessProductModel>);
    }
  });

  const product = (products || []).find((p) => p?.id === values?.productId);

  const onChangeSKU = (value: ProductsModel) => {
    if (value && value.id) {
      setFieldValue('productId', value.id);
    } else {
      setFieldValue('product', undefined);
    }
  };

  return (
    <form id={'ProcessItem'} onSubmit={handleSubmit}>
      <Grid container direction={'column'} spacing={1}>
        <Grid item xs={12}>
          <UITextField
            label={'Item'}
            name={'itemNumber'}
            value={values.itemNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.itemNumber && touched.itemNumber}
            helperText={errors.itemNumber}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Box mt={1} />
          <UITextField
            label={'Descritivo'}
            name={'specification'}
            value={values.specification}
            onChange={handleChange}
            error={!!errors.specification && !!touched.specification}
            onBlur={handleBlur}
            helperText={errors.specification}
            multiline
            maxRows={3}
            inputProps={{
              maxLength: 256
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <UITextField
            label={'Unidade'}
            name={'unity'}
            value={'UNI'}
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <UITextField
            label={'Quantidade'}
            name={'quantity'}
            value={values.quantity}
            onChange={handleChange}
            error={!!errors.quantity && !!touched.quantity}
            onBlur={handleBlur}
            helperText={errors.quantity && !!touched.quantity}
            type={'number'}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Box mt={2}>
            <FormControl component={'fieldset'}>
              <FormLabel>Produto pode ser atendido?</FormLabel>
              <RadioGroup
                name={'approve'}
                value={values.approve}
                onChange={handleChange}
                onBlur={handleBlur}
                row
              >
                <FormControlLabel
                  value={'true'}
                  control={<Radio color={'primary'} />}
                  label={'Sim'}
                />
                <FormControlLabel
                  value={'false'}
                  control={<Radio color={'primary'} />}
                  label={'Não'}
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Grid>
        <Collapse in={values.approve === 'false'}>
          <Grid item xs={12}>
            <Box mt={1} />
            <UITextField
              label={'Justificativa'}
              multiline
              minRows={3}
              inputProps={{
                maxLength: 256
              }}
              name={'justify'}
              variant={'outlined'}
              value={values.justify}
              onChange={handleChange}
              error={!!errors.justify && !!touched.justify}
              helperText={errors.justify}
              onBlur={handleBlur}
              fullWidth
            />
          </Grid>
        </Collapse>
        <Grid item xs={12}>
          <Autocomplete
            value={product || {}}
            renderInput={(props) => (
              <UITextField
                name={'productId'}
                label={'Descrição do produto'}
                value={product?.description || ''}
                error={!!errors.productId && touched.productId}
                helperText={errors.productId || errors.productId}
                {...props}
              />
            )}
            onChange={(_, value) => {
              onChangeSKU(value as ProductsModel);
            }}
            options={products || []}
            getOptionLabel={(data) => data?.description || ''}
          />
        </Grid>
        <Grid item xs={12}>
          <UITextField
            label={'Produto'}
            fullWidth
            value={product?.sku || ''}
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <UITextField
            label={'Gramatura'}
            fullWidth
            value={product?.grammage ? product?.grammage * 1000 : ''}
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <UITextField
            label={'Total (KG)'}
            fullWidth
            value={
              product?.grammage && values?.quantity
                ? +(product?.grammage * values?.quantity).toFixed(3)
                : ''
            }
            disabled
          />
        </Grid>
      </Grid>
      <Grid item container direction={'row-reverse'}>
        <Grid item xs={12} mt={2}>
          <Button fullWidth type={'submit'} variant="contained">
            Confirmar
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
