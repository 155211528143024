import React from 'react';
import { Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { usePatch, usePost, useQuery } from 'services';
import UIPaper from 'shared/components/Paper';
import { DistributorModel } from 'types/models';
import { ValidateDistributorHasAccess } from 'utils/Functions/Validate/DistributorHasAccess';
import { ClientForm } from '../../Forms/ClientForm';

type Props = {
  distributor?: DistributorModel;
  id?: number;
};

export function ClientFormFragment({ distributor, id }: Props) {
  const history = useHistory();
  const [, patchDistributor, patchLoading] =
    usePatch<DistributorModel>('/distributor');
  const [, postDistributor, postLoading] = usePost<
    DistributorModel,
    DistributorModel
  >('/distributor');

  const [, fetchDistributor] = useQuery<DistributorModel[], DistributorModel>(
    '/distributor',
    {
      fetchOnInitialize: false
    }
  );

  const handleEdit = (data: DistributorModel) => {
    try {
      patchDistributor(
        {
          ...data
        },
        id
      );
      toast.success('Informações atualizadas com sucesso');
    } catch (error) {
      toast.error('Falha ao atualizar o cliente, tente novamente mais tarde');
    }
  };

  const handleCreate = async (data: DistributorModel) => {
    try {
      const productAdded = await postDistributor({
        ...data
      });
      if (productAdded?.id) {
        toast.success('Cliente cadastrado com sucesso.');
        history.push(`/clientes`);
      } else {
        toast.error('Falha ao cadastrar o cliente, tente novamente mais tarde');
      }
    } catch (error) {
      toast.error('Falha ao cadastrar o cliente, tente novamente mais tarde');
    }
  };

  const handleCompleteDistributor = async (data: DistributorModel) => {
    if (!data.sold) return;
    const hasAccess = await ValidateDistributorHasAccess({
      sold: data.sold,
      willUpdate: !!id,
      distributorId: id,
      fetchDistributor
    });
    if (hasAccess) return;
    if (id) {
      await handleEdit(data);
    } else {
      await handleCreate(data);
    }
  };

  return (
    <UIPaper title="Informações do Cliente">
      <Grid item xs={12} mb={2}>
        <ClientForm
          onComplete={handleCompleteDistributor}
          distributor={distributor}
          loading={patchLoading || postLoading}
        />
      </Grid>
    </UIPaper>
  );
}
