/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
import { useState } from 'react';
import { toast } from 'react-toastify';
import { ERRORS } from 'utils/Errors';
import api from '../config';

type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

export type FetchPatch<ModelType> = (
  data: DeepPartial<ModelType> & Record<string, any>,
  id?: number | undefined,
  isMultiple?: boolean
) => Promise<ModelType | undefined>;

export type UsePatchReturn<ModelType> = [
  ModelType | undefined,
  FetchPatch<ModelType>,
  boolean,
  any
];

export type UsePatchOptions = {
  willFail?: boolean;
};

/*
 * @Returns
 * data, fetch, loading, errors
 */
export function usePatch<ModelType = unknown>(
  query: string,
  options?: UsePatchOptions
): UsePatchReturn<ModelType> {
  const [loading, setLoading] = useState<boolean>(false);
  const [responseData, setResponseData] = useState<ModelType | undefined>();
  const [error, setError] = useState<unknown>(null);

  async function fetch(
    data: DeepPartial<ModelType> & Record<string, any>,
    id?: number,
    isMultiple = false
  ): Promise<ModelType | undefined> {
    try {
      if (id || isMultiple) {
        setLoading(true);
        const urlQuery = !id ? query : `${query}/${id}`;
        const response = await api.patch<ModelType>(urlQuery, data);
        if (response.status === 200) {
          setResponseData(response.data);
          setLoading(false);
          setError(null);

          return response.data;
        }
        setLoading(false);
      }
      if (!options?.willFail) {
        toast.error(ERRORS.SOMETHING_WENT_WRONG);
      }
    } catch (err) {
      if (!options?.willFail) {
        toast.error(ERRORS.SOMETHING_WENT_WRONG);
      }
      setLoading(false);
      setError(err);
    }
  }

  return [responseData, fetch, loading, error];
}
