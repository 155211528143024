import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';
import MoreVert from '@mui/icons-material/MoreVert';
import { useAuth } from 'Providers/Auth';
import React, { useState } from 'react';
import { UIPagination } from 'shared/components/Pagination';
import { theme } from 'shared/theme';
import { Comments } from 'types/models';
import { OpenModalProps } from '../Domain';
import ObservationMenu from './Menu';

interface ObservationHeaderProps {
  comments: Comments[];
  activeComment: number;
  setActiveComment: (activeComment: number) => void;
  setOpenModal: (openModal: OpenModalProps) => void;
}

function ObservationHeader({
  setActiveComment,
  comments,
  activeComment,
  setOpenModal
}: ObservationHeaderProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const user = useAuth();
  const createdBy =
    comments[activeComment]?.distributor ?? comments[activeComment]?.employee;
  const createdType = comments[activeComment]?.distributor
    ? 'distributor'
    : 'employee';
  const canEdit =
    createdType === user.auth?.type && createdBy?.id === user.auth?.realId;

  return (
    <>
      <Grid container item justifyContent="flex-end">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <UIPagination
            count={comments.length}
            onChange={(e, page) => setActiveComment(page - 1)}
            itemSize="small"
            page={activeComment + 1}
          />
        </div>
        <IconButton size="small" onClick={(e) => setAnchorEl(e.currentTarget)}>
          <MoreVert />
        </IconButton>
        <ObservationMenu
          setAnchorEl={setAnchorEl}
          anchorEl={anchorEl}
          canEdit={canEdit}
          setOpenModal={setOpenModal}
        />
      </Grid>
      <Grid item xs={12}>
        <Box pb={2} display="flex">
          <Typography
            style={{
              color: theme.colors.TextDarkGrey,
              fontWeight: theme.font.semiBold,
              fontSize: theme.font.sizes.medium
            }}
          >
            Observações
          </Typography>
          {createdBy && (
            <>
              <Divider
                flexItem
                style={{
                  margin: '0 5px 0 5px',
                  background: theme.colors.TextDarkGrey
                }}
                variant="middle"
                orientation={'vertical'}
              />
              <Typography
                style={{
                  color: theme.colors.Primary,
                  fontSize: theme.font.sizes.medium
                }}
              >
                {createdBy.name}
              </Typography>
            </>
          )}
        </Box>
      </Grid>
    </>
  );
}

export default ObservationHeader;
