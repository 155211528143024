import {
  Autocomplete,
  Box,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';
import { useFormik } from 'formik';
import {
  ResultProcessItemInputs,
  ResultProcessItemSchema
} from 'modules/Process/PublicProcess/Forms/ProcessItemResult/domain';
import React from 'react';
import { useQuery } from 'services';
import { UITextField } from 'shared/components/Inputs';
import { theme } from 'shared/theme';
import {
  ProcessProductModel,
  ProductsModel,
  RivalProductsModel
} from 'types/models';
import { JustificationsNotWinner } from 'utils/Constants';
import { GetStringBoolean } from 'utils/Functions/GetBooleanString';
import { MoneyMask, MoneyRemoveMask } from 'utils/masks';

interface ProcessItemParams {
  processProduct?: ProcessProductModel;
  onHandleComplete: (data: Partial<ProcessProductModel>) => void;
}

export function ProcessItemResultForm({
  processProduct,
  onHandleComplete
}: ProcessItemParams) {
  const removeMaskMoneyFields = (value: string | number) => {
    if (typeof value === 'number') return value;
    if (typeof value === 'string')
      return +(+MoneyRemoveMask(String(value))).toFixed(2);
    return 0;
  };
  const { values, handleSubmit, errors, touched, handleChange, setFieldValue } =
    useFormik<ResultProcessItemInputs>({
      initialValues: {
        winner: processProduct?.winner,
        initialValue: processProduct?.initialValue || 0,
        finalValue: processProduct?.finalValue || 0,
        rivalInitialValue: processProduct?.rivalInitialValue || 0,
        rivalFinalValue: processProduct?.rivalFinalValue || 0,
        rivalProductId: processProduct?.rivalProductId,
        justify: processProduct?.justify || '',
        institutionValue: processProduct?.institutionValue || 0,
        markup: processProduct?.markup || 0,
        nfPrice: processProduct?.nfPrice || 0,
        priceList: processProduct?.priceList || 0,
        productId: processProduct?.productId || 0,
        id: processProduct?.id
      },
      validationSchema: ResultProcessItemSchema,
      onSubmit: (fields) => {
        const data = fields;
        data.winner = GetStringBoolean(fields.winner) || false;
        data.initialValue = removeMaskMoneyFields(fields.initialValue);
        data.finalValue = removeMaskMoneyFields(fields.finalValue);
        if (fields.rivalInitialValue) {
          data.rivalInitialValue = removeMaskMoneyFields(
            fields.rivalInitialValue
          );
        }
        if (fields.rivalFinalValue) {
          data.rivalFinalValue = removeMaskMoneyFields(fields.rivalFinalValue);
        }

        onHandleComplete(data as Partial<ProcessProductModel>);
      }
    });

  const [products] = useQuery<ProductsModel[]>('/products', {
    $limit: -1,
    disabled: 0
  });

  const [getRivals] = useQuery<RivalProductsModel[]>('/rival-products', {
    $limit: -1
  });

  const rivalProducts = getRivals || [];

  const FilterConcurrencyCompanies = () => {
    const allCompanies = rivalProducts.map(
      (concurrency) => concurrency.company
    );

    return [...new Set(allCompanies)];
  };
  const companies = FilterConcurrencyCompanies();

  const rivalProduct = rivalProducts?.find(
    (rp) => rp.id === values.rivalProductId
  );

  return (
    <form id={'ProcessItemForm'} onSubmit={handleSubmit}>
      <Box pt={3} pb={3}>
        <Divider />
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={12} mb={2}>
          <Typography
            variant="h3"
            style={{
              fontWeight: theme.font.semiBold,
              color: theme.colors.TextDarkGrey
            }}
          >
            Resultado do Item
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <FormControl
              component={'fieldset'}
              error={!!touched.winner && !!errors?.winner}
            >
              <FormLabel>Item Ganho</FormLabel>
              <RadioGroup
                name={'winner'}
                value={String(values.winner)}
                onChange={(e) => {
                  handleChange(e);
                  if (e.target.value === 'true') {
                    setFieldValue('justify', '');
                  }
                }}
                row
              >
                <FormControlLabel
                  value={'true'}
                  control={<Radio color={'primary'} />}
                  label={'Sim'}
                />
                <FormControlLabel
                  value={'false'}
                  control={<Radio color={'primary'} />}
                  label={'Não'}
                />
              </RadioGroup>
              <FormHelperText>
                {!!touched.winner && errors?.winner}
              </FormHelperText>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            renderInput={(opts) => <TextField label={'Produto'} {...opts} />}
            getOptionLabel={(option) => option?.description || ''}
            defaultValue={processProduct?.product}
            onChange={(e, value) => {
              setFieldValue('productId', value?.id);
            }}
            options={products || []}
          />
        </Grid>
        <Grid item xs={12}>
          <UITextField
            label={'(R$) Valor inicial'}
            name={'initialValue'}
            pattern={'money'}
            value={MoneyMask(values.initialValue)}
            error={!!touched.initialValue && !!errors?.initialValue}
            onChange={handleChange}
            helperText={!!touched.initialValue && errors?.initialValue}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <UITextField
            label={'(R$) Valor final'}
            pattern={'money'}
            fullWidth
            name={'finalValue'}
            defaultValue={MoneyMask(String(processProduct?.finalValue) || '0')}
            error={!!touched.finalValue && !!errors?.finalValue}
            helperText={!!touched.finalValue && errors?.finalValue}
            onChange={handleChange}
          />
        </Grid>
        <Box pt={3} pb={3}>
          <Divider />
        </Box>
        <Grid item xs={12} mt={2} mb={2}>
          <Typography
            variant="h3"
            style={{
              fontWeight: theme.font.semiBold,
              color: theme.colors.TextDarkGrey
            }}
          >
            Grade de Preço
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            renderInput={(opts) => <TextField {...opts} label={'Produto'} />}
            options={rivalProducts}
            getOptionLabel={(optionLabel) => optionLabel.description}
            groupBy={(optionGroup) => optionGroup.company}
            defaultValue={processProduct?.rival_product}
            onChange={(e, value) => {
              setFieldValue('rivalProductId', value?.id || null);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            renderInput={(opts) => (
              <TextField label={'Concorrente'} {...opts} />
            )}
            options={companies}
            value={rivalProduct?.company || null}
            defaultValue={processProduct?.rival_product?.company}
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <UITextField
            label={'(R$) Valor inicial concorrente'}
            pattern={'money'}
            name={'rivalInitialValue'}
            fullWidth
            defaultValue={MoneyMask(processProduct?.rivalInitialValue || 0)}
            onChange={handleChange}
            error={!!touched.rivalInitialValue && !!errors?.rivalInitialValue}
            helperText={
              !!touched.rivalInitialValue && errors?.rivalInitialValue
            }
          />
        </Grid>
        <Grid item xs={12}>
          <UITextField
            label={'(R$) Valor final concorrente'}
            name={'rivalFinalValue'}
            pattern={'money'}
            fullWidth
            defaultValue={MoneyMask(processProduct?.rivalFinalValue || 0)}
            onChange={handleChange}
            error={!!touched.rivalFinalValue && !!errors?.rivalFinalValue}
            helperText={!!touched.rivalFinalValue && errors?.rivalFinalValue}
          />
        </Grid>
        <Grid item xs={12}>
          <Collapse in={String(values?.winner) === 'false'}>
            <TextField
              label={'Justificativa'}
              name={'justify'}
              onChange={handleChange}
              value={values.justify}
              fullWidth
              select
              defaultValue={processProduct?.justify}
              error={!!touched.justify && !!errors?.justify}
              helperText={!!touched.justify && errors?.justify}
            >
              {JustificationsNotWinner.map((justify) => (
                <MenuItem key={justify.value} value={justify.value}>
                  {justify.text}
                </MenuItem>
              ))}
            </TextField>
          </Collapse>
        </Grid>
      </Grid>
    </form>
  );
}
