/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable react-hooks/exhaustive-deps */
import { Container, Grid, LinearProgress } from '@mui/material';
import { AdminEditProcessForm } from 'modules/Process/AdminEditProcess/Forms/EditProcessInformation/domain';
import { AdminEditProcessForms } from 'modules/Process/AdminEditProcess/Fragments/AdminEditProcessForms';
import { AdminEditProcessTabs } from 'modules/Process/AdminEditProcess/Fragments/Tabs';
import NewPageBreadcrumb from 'modules/Process/shared/Fragment/NewPageBreadcrumb';
import NewPageTitle from 'modules/Process/shared/Fragment/NewPageTitle';
import { useAuth } from 'Providers/Auth';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { usePatch, usePost, useQuery } from 'services';
import { useFormData } from 'services/api/UseFormData';
import { UploadAttachment } from 'types/input/UploadAttachment';
import { SelectedFiles } from 'types/interfaces/SelectedFiles';
import { ProcessModel, ProcessProductModel, ProductsModel } from 'types/models';
import { AttachmentModel } from 'types/models/Attachments/Attachment.model';
import { UrlParams } from 'types/services';

export function AdminEditProcess() {
  const { auth } = useAuth();
  const { id } = useParams() as UrlParams;
  const history = useHistory();

  const [step, setStep] = useState<number>(0);

  const [buttonText, setButtonText] = useState<string>();

  const [files, setFiles] = useState<SelectedFiles[]>([]);
  const [editedProcess, setEditedProcess] = useState<AdminEditProcessForm>();
  const [oldEditedProcessProducts, setOldEditedProcessProducts] = useState<
    ProcessProductModel[]
  >([]);
  const [newProcessProducts, setNewProcessProducts] = useState<
    Partial<ProcessProductModel>[]
  >([]);

  const [processProducts, , processProductsLoading] = useQuery<
    ProcessProductModel[],
    ProcessProductModel
  >(`/process-products`, {
    $limit: '-1',
    processId: id
  });
  const [process, , processLoading] = useQuery<ProcessModel, ProcessModel>(
    `/process/${id}`
  );
  const [products, , productsLoading] = useQuery<
    ProductsModel[],
    ProductsModel
  >(`/products`, {
    $limit: '-1'
  });

  const [, patchProcessProduct, patchProcessProductLoading] =
    usePatch<ProcessProductModel>(`/process-products`);
  const [, patchProcess, patchProcessLoading] =
    usePatch<ProcessModel>(`/process`);
  const [, postProcessProduct, postProcessProductLoading] = usePost<
    Partial<ProcessProductModel> & { isAdmin: boolean }
  >(`/process-products`);
  const [attachmentAdd, attachmentLoading] = useFormData<
    UploadAttachment,
    AttachmentModel
  >(`/attachments`);

  const onUpdateProcess = async () => {
    try {
      if (editedProcess && +id) {
        setButtonText('Alterando as informações do processo...');
        await patchProcess(
          {
            ...editedProcess,
            period: editedProcess?.moreMonths
              ? (process?.period || 0) + editedProcess?.moreMonths
              : process?.period,
            endPeriod: editedProcess?.newEndPeriod
              ? editedProcess?.newEndPeriod
              : process?.endPeriod
          },
          +id
        );
        toast.success('Processo atualizado com sucesso.');
      }
    } catch (error) {
      toast.error('Erro ao atualizar processo.');
    }
  };
  const onUpdateProcessItem = async (processItem: ProcessProductModel) => {
    try {
      await patchProcessProduct(
        { ...processItem, isAdmin: true },
        processItem?.id
      );
    } catch (error) {
      toast.error(`Erro ao atualizar item: ${processItem?.itemNumber}.`);
    }
  };
  const onCreateProcessItem = async (
    processItem: Partial<ProcessProductModel>
  ) => {
    try {
      delete processItem?.id;
      await postProcessProduct({
        ...processItem,
        isAdmin: true,
        processId: +id
      });
    } catch (error) {
      toast.error(`Erro ao atualizar item: ${processItem?.itemNumber}.`);
    }
  };

  const onUploadFiles = async () => {
    setButtonText('Fazendo upload dos arquivos...');
    for (const uploadedFile of files) {
      try {
        if (+id) {
          await attachmentAdd({
            file: uploadedFile.file,
            category: uploadedFile.category,
            table: 'process',
            tableId: +id,
            accessId: auth?.id
          });
          toast.success(
            `Documento ${uploadedFile.category} - ${uploadedFile.file?.name}, vinculado ao processo. `
          );
        }
      } catch (error) {
        toast.error(
          `Erro ao subir documento ${uploadedFile.category} - ${uploadedFile.file?.name}. Tente anexar novamente nos detalhes do pedido.`
        );
      }
    }
  };

  const onFinished = async () => {
    if (files.length === 0) {
      toast.error(
        'Adicione ao menos um documento para confirmar as alterações.'
      );
      return;
    }
    await onUploadFiles();
    await onUpdateProcess();
    setButtonText('Alterando as informações do itens...');
    for (const processItem of oldEditedProcessProducts) {
      await onUpdateProcessItem(processItem);
    }
    setButtonText('Criando novos itens ao processo...');
    for (const processItem of newProcessProducts) {
      await onCreateProcessItem(processItem);
    }
    toast.success('Alterações realizadas.');
    history.push(`/processo/${id}`);
  };

  const onFinishedLoading =
    attachmentLoading ||
    patchProcessProductLoading ||
    patchProcessLoading ||
    postProcessProductLoading ||
    productsLoading;

  const infosLoading = processProductsLoading || processLoading;

  return (
    <>
      <Container maxWidth={'lg'}>
        <NewPageBreadcrumb id={id} title="Editar Processo" />
        {infosLoading && <LinearProgress color="primary" />}
        <NewPageTitle
          title="Editar Processo em andamento"
          subtitles={[
            'Edite as informações do processo',
            'As mudanças só surtirão efeito após finalizar na etapa de Anexos.'
          ]}
        />
        <Grid mt={2} />
        <AdminEditProcessTabs step={step} />
        <AdminEditProcessForms
          step={step}
          files={files}
          setFiles={setFiles}
          process={process}
          onEditProcessInfoSubmit={(data) => {
            setEditedProcess(data);
            setStep(1);
          }}
          onEditProcessItemsSubmit={(oldProcessItems, newProcessItems) => {
            setOldEditedProcessProducts(oldProcessItems);
            setNewProcessProducts(newProcessItems);
            setStep(2);
          }}
          processProducts={processProducts}
          onFinished={onFinished}
          onFinishedLoading={onFinishedLoading}
          buttonText={buttonText}
          products={products || []}
        />
      </Container>
    </>
  );
}
