import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { ROUTES } from 'routes/Paths';
import { Login } from 'pages/Login';
import { RouteWrapper } from 'routes/RoutesConfig/RouteWrapper';
import { Header } from 'shared/components/Header';
import { useAuth } from 'Providers/Auth';
import history from 'routes/History';

export function Routes() {
  const token = localStorage.getItem('token');
  const { auth } = useAuth();
  return (
    <Router history={history}>
      {auth && token ? <Header /> : null}

      <Switch>
        <Route path={'/'} exact render={() => <Redirect to={'/dashboard'} />} />
        <Route
          path={'/login'}
          render={() => (!token ? <Login /> : <Redirect to={'/dashboard'} />)}
          exact
        />
        {ROUTES.map((route) => (
          <RouteWrapper {...route} key={`${route.path}`} />
        ))}
      </Switch>
    </Router>
  );
}
