import {
  createGlobalStyle,
  DefaultTheme,
  GlobalStyleComponent
} from 'styled-components';

export const GlobalStyles: GlobalStyleComponent<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  DefaultTheme
> = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #root {
    font-family: ${({ theme }) => theme.font.family};
    color:  ${({ theme }) => theme.colors.TextDarkGrey};
  }

  a, a:hover, a:visited, a:focus, a:active  {
    text-decoration: none;
    color: inherit;
    outline: 0;
  }
`;
