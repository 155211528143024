import { useState } from 'react';
import { toast } from 'react-toastify';
import { FetchQuery, useQuery } from 'services';
import { useDelete } from 'services/api/UseDelete';
import { AttachmentModel } from 'types/models/Attachments/Attachment.model';
import { DownloadFile } from 'utils/Functions/Download/DownloadFile';

type ItemLoading = {
  item?: number;
  loading: boolean;
};

type DeleteModal = {
  open: boolean;
  id?: number;
};

export function useUploadList(
  attachmentsRefetch: FetchQuery<AttachmentModel[], AttachmentModel>
) {
  const [itemLoading, setItemLoading] = useState<ItemLoading[]>();
  const [deleteModal, setDeleteModal] = useState<DeleteModal>({
    open: false
  });

  const [, attachmentFetch] = useQuery<AttachmentModel, AttachmentModel>(
    `/attachments`,
    {
      fetchOnInitialize: false
    }
  );

  const [, attachmentDelete] = useDelete<AttachmentModel>(`/attachments`);

  const setLoading = (item: number, loading: boolean) => {
    setItemLoading((loadingItem) => {
      if (loadingItem) {
        return [
          ...loadingItem.filter(
            (filteredLoadingItem) => filteredLoadingItem.item !== item
          ),
          {
            loading,
            item
          }
        ];
      }
      return [
        {
          loading,
          item
        }
      ];
    });
  };

  const findLoading = (item: number) =>
    !!(itemLoading || []).find((loadingItem) => loadingItem.item === item)
      ?.loading;

  const downloadAttachment = async (attachment_id?: number) => {
    try {
      if (attachment_id) {
        setLoading(attachment_id, true);
        const getAttachment = await attachmentFetch({
          newQuery: `/attachments/${attachment_id}`,
          $select: ['id', 'key', 'name']
        });
        if (getAttachment?.key && getAttachment.name) {
          await DownloadFile(getAttachment.key, getAttachment.name);
          toast.success('Download feito com sucesso.');
        } else {
          toast.error(
            'Ocorreu um erro ao fazer download do documento. Por favor, contate o administrador do sistema.'
          );
        }
        setLoading(attachment_id, false);
      }
    } catch (error) {
      toast.error('Ocorreu um erro ao fazer download do documento.');
      if (attachment_id) {
        setLoading(attachment_id, false);
      }
    }
  };

  const onClickDelete = (attachment_id: number) => {
    setDeleteModal({
      open: true,
      id: attachment_id
    });
  };

  const onDelete = async () => {
    try {
      setDeleteModal({
        open: false,
        id: undefined
      });
      if (deleteModal.id) {
        await attachmentDelete(deleteModal.id);
        toast.success('Anexo deletado com sucesso');
        attachmentsRefetch();
      }
    } catch (error) {
      toast.error('Ocorreu um erro ao deletar anexo.');
    }
  };

  return {
    downloadAttachment,
    findLoading,
    onClickDelete,
    onDelete,
    deleteModal,
    setDeleteModal
  };
}
