/* eslint-disable react/no-array-index-key */
import { Box, CircularProgress, Divider, Typography } from '@mui/material';
import React from 'react';
import { ThemeColors } from 'shared/enum';
import { theme } from 'shared/theme';
import UploadItem, { UploadItemProps } from '../UploadItem';

type UploadListProps = {
  title?: string;
  items: UploadItemProps[];
  height: string;
  icon?: HTMLElement;
  onDownload?: () => void;
  onDelete?: () => void;
  loading?: boolean;
};

export default function UploadList({
  title,
  items,
  icon,
  height,
  onDelete,
  onDownload,
  loading
}: UploadListProps) {
  return (
    <Box height={height} bgcolor={ThemeColors.White} borderRadius="5px">
      {icon && (
        <Box pl={2} pb={1}>
          {icon}
        </Box>
      )}
      {title && (
        <Box pt={2} pb={2} pl={2} alignItems="center">
          <Typography
            variant="h3"
            style={{
              color: ThemeColors.TextDarkGrey,
              fontWeight: theme.font.semiBold
            }}
          >
            {title}
          </Typography>
        </Box>
      )}
      {icon ||
        (title && (
          <Box pb={1}>
            <Divider />
          </Box>
        ))}
      <Box
        pl={2}
        pr={2}
        height={'85%'}
        overflow={'auto'}
        display="flex"
        alignItems="center"
        flexDirection="column"
        style={{ justifyContent: loading ? 'center' : 'initial' }}
      >
        {loading ? (
          <CircularProgress size={40} />
        ) : (
          items.map((item, key) => (
            <UploadItem
              key={`key-${item.name}-${key}`}
              onDelete={onDelete}
              onDownload={onDownload}
              {...item}
            />
          ))
        )}
      </Box>
    </Box>
  );
}
