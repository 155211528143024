import React, { useState } from 'react';
import { ProductUfColumns } from 'modules/Admin/Products/tables/ProductUfTable/columns';
import { UITable } from 'shared/components';
import { ProductUfTableProps } from 'modules/Admin/Products/tables/ProductUfTable/ProductUfTable';
import { UIToolbar } from 'shared/components/Tables/ToolbarTable';
import { UIDataGridApiPagination } from 'shared/components/Tables/DataGridApiPagination';
import { UITextField } from 'shared/components/Inputs';
import { PoliticalPriceType } from 'utils/Constants/PoliticalPriceType';
import { Grid, MenuItem } from '@mui/material';
import { States, STATES_ARRAY } from 'utils/Enums';

export function ProductUfTable({
  politicalPrices,
  ...rest
}: ProductUfTableProps) {
  const [type, setType] = useState<string>('all');
  const [selectedUf, setSelectedUf] = useState<string>('all');

  return (
    <>
      <Grid container spacing={2} justifyContent="flex-end" mb={2}>
        <Grid item xs={12} sm={3}>
          <UITextField
            label={'Tipo'}
            value={type}
            fullWidth
            select
            onChange={(e) => setType(e.target.value)}
          >
            {['all', ...Object.keys(PoliticalPriceType)].map((key) => (
              <MenuItem key={key} value={key}>
                {PoliticalPriceType[key]?.label || 'Todos'}
              </MenuItem>
            ))}
          </UITextField>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <UITextField
            label={'Estado'}
            value={selectedUf}
            fullWidth
            select
            onChange={(e) => setSelectedUf(e.target.value)}
          >
            {[
              {
                uf: 'all',
                state: 'Todos'
              },
              ...STATES_ARRAY
            ].map(({ uf }) => (
              <MenuItem value={uf} key={uf}>
                {States[uf] || 'Todos'}
              </MenuItem>
            ))}
          </UITextField>
        </Grid>
      </Grid>
      <UITable
        rows={politicalPrices.filter((pPrice) => {
          let conditional = true;
          if (selectedUf !== 'all')
            conditional = conditional && pPrice.uf === selectedUf;
          if (type !== 'all') conditional = conditional && pPrice.type === type;
          return conditional;
        })}
        columns={ProductUfColumns}
        rowHeight={36}
        headerHeight={36}
        pageSize={10}
        components={{
          Toolbar: () => (
            <UIToolbar
              elementsTopEnd={
                <>
                  <UIDataGridApiPagination />
                </>
              }
            />
          )
        }}
        {...rest}
      />
    </>
  );
}
