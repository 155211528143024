import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Typography
} from '@mui/material';

import VisibilityIcon from '@mui/icons-material/Visibility';

import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { UITextField } from 'shared/components/Inputs';

import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as FormValidation from './domain';

export interface LoginFormProps {
  onHandleComplete: (data: FormValidation.LoginFormResponse) => void;
  onHandleForgotClick: (email: string) => void;
}

export function LoginForm({
  onHandleComplete,
  onHandleForgotClick
}: LoginFormProps) {
  const [seePassword, setPassword] = useState(false);
  const [stayConnect, setStayConnect] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<FormValidation.LoginFormData>({
    resolver: yupResolver(FormValidation.LoginFormSchema)
  });

  const onSubmit: SubmitHandler<FormValidation.LoginFormResponse> = (data) => {
    onHandleComplete(data);
  };

  return (
    <Grid container direction="column">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid item>
          <UITextField
            {...register('email')}
            type={'email'}
            label="Email *"
            autoComplete={'email'}
            error={!!errors.email?.message}
            helperText={errors.email?.message}
            inputProps={{ 'data-testid': '__email' }}
            fullWidth
          />
        </Grid>
        <Grid item>
          <UITextField
            label={'Senha *'}
            type={!seePassword ? 'password' : 'text'}
            fullWidth
            error={!!errors.password?.message}
            helperText={errors.password?.message}
            autoComplete={'current-password'}
            InputProps={{
              inputProps: { 'data-testid': '__password' },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge={'end'}
                    onClick={() => setPassword(!seePassword)}
                    size="large"
                  >
                    {seePassword ? <VisibilityIcon /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            {...register('password')}
          />
        </Grid>
        <Box mt={3} />
        <Grid item container direction="row" justifyContent={'space-between'}>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={stayConnect}
                  onChange={() => setStayConnect(!stayConnect)}
                  color={'primary'}
                  name="stayConnected"
                />
              }
              label="Mantenha me conectado"
            />
          </Grid>
          <Grid item>
            <Button
              variant="text"
              onClick={() => onHandleForgotClick(watch('email'))}
            >
              <Typography variant="caption"> Esqueceu sua senha ? </Typography>
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <Box mt={3}>
            <Button variant={'contained'} fullWidth type={'submit'}>
              Confirmar
            </Button>
          </Box>
        </Grid>
      </form>
    </Grid>
  );
}
