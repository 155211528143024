import { LinearProgress } from '@mui/material';
import React from 'react';
import { toast } from 'react-toastify';
import history from 'routes/History';
import { usePatch, usePost, useQuery } from 'services';
import { ProcessModel } from 'types/models';
import { PrivateProcessProposalSummaryProps } from './PrivateProcessProposalSummary';
import { PrivateProcessProposalSummaryForm } from './PrivateProposalSummaryForm';
import { PrivateProcessProposalSummaryInputs } from './PrivateProposalSummaryForm/domain';

export function PrivateProcessProposalSummary({
  id,
  onComplete,
  onFail,
  employeeId
}: PrivateProcessProposalSummaryProps) {
  const ProcessQuery = useQuery<ProcessModel>(`/process/${id}`, {
    fetchOnInitialize: !!id
  });
  const processLoading = ProcessQuery[2];
  const process = ProcessQuery[0];

  const [, processPostFetch, postLoading] = usePost<
    Partial<ProcessModel>,
    Partial<ProcessModel>
  >('/process');

  const [, processPatchFetch, patchLoading] = usePatch('/process');

  /* should create or update a process  */
  const onCompleteProcessBasicInformation = async (
    data: PrivateProcessProposalSummaryInputs
  ) => {
    const queryData: Partial<ProcessModel> = {
      ...data,
      employeeId: employeeId && +employeeId,
      segment: 'private',
      focusUf: !!data.focusUf,
      status: 'const recording',
      isBidding: !!data.isBidding
    };
    if (!process) {
      const response = await processPostFetch(queryData);

      if (response) {
        history.replace({
          pathname: `/cadastro/processo/privado`,
          search: `?processo=${response.id}`
        });
        onComplete();
      } else {
        toast.error('Falha ao criar o processo ');
        if (onFail) {
          onFail();
        }
      }
    } else if (process && id) {
      await processPatchFetch(queryData, +id);
      onComplete();
    } else if (onFail) {
      onFail();
    }
  };

  return processLoading ? (
    <LinearProgress />
  ) : (
    <PrivateProcessProposalSummaryForm
      onComplete={onCompleteProcessBasicInformation}
      process={process || {}}
      loading={postLoading || patchLoading}
    />
  );
}
