import styled, { css } from 'styled-components';
import { Typography as Typo, TypographyProps } from '@mui/material';

interface TextProps {
  textColor?: string;
  cursor?: string;
  text?: string;
  fontSize?: string;
  fontWeight?: string;
  textAlign?: string;
  overflow?: string;
  textOverflow?: string;
  whiteSpace?: string;
}

export type STypography = TypographyProps & TextProps;

export const Typography = styled(Typo)<STypography>`
  ${({
    theme,
    textColor,
    fontSize,
    fontWeight,
    textAlign,
    textOverflow,
    overflow,
    whiteSpace
  }) => css`
    color: ${textColor} | ${theme?.colors?.TextDarkGrey || '#565656'};
    font-size: ${fontSize};
    font-weight: ${fontWeight};
    text-align: ${textAlign};
    text-overflow: ${textOverflow};
    overflow: ${overflow};
    white-space: ${whiteSpace};
  `}
`;
