import React from 'react';
import * as S from 'shared/components/Cards/BlockCard/styles';
import { Grid } from '@mui/material';

type BlockCardProps = {
  title?: string;
  message?: string;
};

export default function BlockCard({ title, message }: BlockCardProps) {
  return (
    <S.CardBox>
      <Grid container justifyContent="center" alignItems="center" spacing={1}>
        <Grid item xs={2} sm={1}>
          <Grid container justifyContent="center" alignItems="center">
            <S.ErrorIcon />
          </Grid>
        </Grid>
        <Grid item xs={10} sm={11}>
          <Grid container>
            <Grid item xs={12}>
              <S.Title>{title}</S.Title>
            </Grid>
            <Grid item xs={12}>
              <S.Message>{message}</S.Message>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </S.CardBox>
  );
}
