import { ERRORS } from 'utils/Errors';
import * as yup from 'yup';

export interface PrivateProcessProposalAnalysisInputs {
  tradingDate: string;
  distributorId?: number;
  distributorSold?: string;
  distributorSocialName?: string;
  programName: string;
  comercialPolitics: string;
  competitionModality: string;
  tradingCode: string;
  processDirect: 1 | 0;
  startPeriod: string;
  period: number;
  endPeriod: string;
  canExtend: 1 | 0;
  extendMonths?: number | null;
}

export const PrivateProcessProposalAnalysisValidation = yup.object({
  tradingDate: yup
    .string()
    .required(ERRORS.REQUIRED_FIELD)
    .typeError(ERRORS.REQUIRED_FIELD),
  distributorId: yup
    .string()
    .required(ERRORS.REQUIRED_FIELD)
    .typeError(ERRORS.REQUIRED_FIELD),
  programName: yup
    .string()
    .required(ERRORS.REQUIRED_FIELD)
    .typeError(ERRORS.REQUIRED_FIELD),
  comercialPolitics: yup
    .string()
    .required(ERRORS.REQUIRED_FIELD)
    .typeError(ERRORS.REQUIRED_FIELD),
  competitionModality: yup
    .string()
    .required(ERRORS.REQUIRED_FIELD)
    .typeError(ERRORS.REQUIRED_FIELD),
  tradingCode: yup
    .string()
    .required(ERRORS.REQUIRED_FIELD)
    .typeError(ERRORS.REQUIRED_FIELD),
  processDirect: yup.boolean().required(ERRORS.REQUIRED_FIELD),
  startPeriod: yup
    .string()
    .required(ERRORS.REQUIRED_FIELD)
    .typeError(ERRORS.REQUIRED_FIELD),
  period: yup
    .number()
    .required(ERRORS.REQUIRED_FIELD)
    .typeError(ERRORS.REQUIRED_FIELD),
  canExtend: yup.boolean().required(ERRORS.REQUIRED_FIELD),
  extendMonths: yup
    .number()
    .optional()
    .nullable()
    .when('canExtend', {
      is: (canExtend: boolean) => {
        return !!canExtend;
      },
      then: yup
        .number()
        .required(ERRORS.REQUIRED_FIELD)
        .typeError(ERRORS.REQUIRED_FIELD)
    })
});
