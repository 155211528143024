export const ProcessStatus = [
  {
    position: 1,
    status: ['dist recording']
  },
  {
    position: 2,
    status: ['const recording']
  },
  {
    position: 3,
    status: ['awaiting financier', 'waiting to confirm consultant values']
  },
  {
    position: 4,
    status: ['awaiting consultant result', 'awaiting distributor result']
  },
  {
    position: 5,
    status: ['awaiting order', 'suspending']
  },
  {
    position: 6,
    status: ['suspended', 'lost', 'finished']
  }
];
