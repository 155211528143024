import { MoneyRemoveMask } from 'utils/masks';
import { PercentMaskRemove } from 'utils/masks/Percent';

export function CalcDiscount(
  priceList: number,
  discountPercent: number
): CalcDiscountReturnProps {
  const discountDiff = priceList * (discountPercent / 100);
  const discountValue = priceList - discountDiff;
  return { discountValue, discountDiff };
}

export function CalcDiscountInput(
  priceList: string | number,
  discountPercent: string | number
): CalcDiscountReturnProps {
  let newPriceList = priceList;
  if (typeof priceList === 'string') {
    newPriceList = MoneyRemoveMask(priceList);
  }

  let newDiscountPercent = discountPercent;
  if (typeof discountPercent === 'string') {
    newDiscountPercent = PercentMaskRemove(discountPercent);
  }

  return CalcDiscount(+newPriceList, +newDiscountPercent);
}

interface CalcDiscountReturnProps {
  discountValue: number;
  discountDiff: number;
}
