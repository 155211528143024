export const DocumentTypes = [
  'Carta de credenciamento',
  'Ficha técnica',
  'Registro de produto',
  'Licença de funcionamento',
  'Contrato Nestlé',
  'CNPJ',
  'Atestado de responsabilidade técnica',
  'Certidões',
  'Boas práticas de fabricação',
  'Edital',
  'Cotação Hospitalar',
  'E-mail de aceite dos valores',
  'Ata de Registro',
  'Contrato Homologado',
  'Nota Fiscal',
  'Autorização de Fornecimento',
  'Outros'
];
