/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Box, Grid } from '@mui/material';
import { DeleteAttachmentModal } from 'modules/Process/History/Fragments/UploadSection/List/DeleteModal';
import React from 'react';
import { FetchQuery } from 'services';
import UIUploadAddSection from 'shared/components/Upload/UploadAddSection';
import UploadList from 'shared/components/Upload/UploadList';
import { useUploadAdd } from 'shared/hooks/UseUploadAdd';
import { useUploadList } from 'shared/hooks/UseUploadList';
import { AttachmentModel } from 'types/models/Attachments/Attachment.model';
import { FinancierDocumentTypes } from 'utils/Constants/DocumentTypes';
import InformDiscountObservation from './Observation';

type Props = {
  observationTitle: string;
  disabled?: boolean;
  justify?: string;
  setJustify: (observation: string) => void;
  attachments: AttachmentModel[];
  attachmentsRefetch: FetchQuery<AttachmentModel[], AttachmentModel>;
  attachmentsLoading: boolean;
  accessId?: number;
  processId?: number;
};
export default function InformDiscountBottom({
  observationTitle,
  disabled,
  justify,
  setJustify,
  attachments,
  attachmentsRefetch,
  attachmentsLoading,
  accessId,
  processId
}: Props) {
  const {
    downloadAttachment,
    findLoading,
    onClickDelete,
    deleteModal,
    setDeleteModal,
    onDelete
  } = useUploadList(attachmentsRefetch);

  const {
    buttonDisabled,
    uploadAttachment,
    handleFile,
    attachmentLoadingAdd,
    file,
    setCategory,
    category
  } = useUploadAdd(attachmentsRefetch, {
    accessId,
    table: 'process',
    tableId: processId
  });

  return (
    <>
      <Grid container spacing={4}>
        <Grid container item md={6} xs={12}>
          <Grid item xs={12}>
            <InformDiscountObservation
              observationTitle={observationTitle}
              disabled={disabled}
              justify={justify}
              setJustify={setJustify}
            />
          </Grid>
          <Grid item xs={12}>
            <UploadList
              height="300px"
              title="Documentos Anexados"
              items={
                attachments?.map((attachment) => ({
                  name: attachment.name || '-',
                  category: attachment.category || '-',
                  size: attachment.size || '-',
                  onDownload: () => downloadAttachment(attachment.id),
                  loading: findLoading(attachment.id!),
                  onDelete: () => onClickDelete(attachment.id!)
                })) || []
              }
              loading={attachmentsLoading}
            />
          </Grid>
        </Grid>
        <Box mt={4} />
        <Grid item md={6} xs={12}>
          <UIUploadAddSection
            title="Anexo da decisão (opcional)"
            buttonDisabled={buttonDisabled}
            buttonOnClick={uploadAttachment}
            handleFile={handleFile}
            loading={attachmentLoadingAdd}
            selectedFilename={file?.name || ' '}
            selectLabel="Documento"
            selectOnChange={({ target }) => setCategory(target.value)}
            selectOptions={FinancierDocumentTypes}
            selectValue={category}
          />
        </Grid>
      </Grid>
      <Box mt={5} />
      <DeleteAttachmentModal
        open={deleteModal.open}
        setOpen={setDeleteModal}
        onDelete={onDelete}
      />
    </>
  );
}
