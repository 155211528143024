/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  createFilterOptions,
  Grid,
  LinearProgress,
  MenuItem
} from '@mui/material';
import { useCities } from 'hooks/useCities';
import { SecretaryClassificationDomain } from 'modules/Process/Domain/SecretaryClassificationDomain';
import { useAuth } from 'Providers/Auth';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'services';
import { ControlledAutocomplete } from 'shared/components/ControlledComponents/ControlledAutocomplete';
import { ControlledRadioGroup } from 'shared/components/ControlledComponents/ControlledRadioGroup';
import { ControlledTextField } from 'shared/components/ControlledComponents/ControlledTextField';
import { FormProps } from 'shared/components/Inputs';
import { InstitutionModel, ProcessModel, UfsRegionals } from 'types/models/';
import { OptionsBooleanToYes } from 'utils/Constants/BooleanToYes';
import {
  ProvServicesChannels,
  ServicesChannels,
  States,
  STATES_ARRAY
} from 'utils/Enums';
import {
  PrivateProcessProposalSummaryInputs,
  PrivateProcessProposalSummaryValidation
} from './domain';

export interface PrivProcessProposalSummaryFormParams
  extends FormProps<PrivateProcessProposalSummaryInputs> {
  process?: Partial<ProcessModel>;
  loading?: boolean;
}

export function PrivateProcessProposalSummaryForm({
  process,
  onComplete,
  loading = false
}: PrivProcessProposalSummaryFormParams) {
  const {
    handleSubmit,
    watch,
    control,
    reset,
    setValue,
    formState,
    clearErrors
  } = useForm<PrivateProcessProposalSummaryInputs>({
    resolver: yupResolver(PrivateProcessProposalSummaryValidation),
    mode: 'all'
  });
  const { auth } = useAuth();

  useEffect(() => {
    if (process) {
      reset({
        employeeName: auth?.employee?.name,
        employeeSap: auth?.employee?.sap,
        regional: process?.regional,
        focusUf: process?.focusUf ? 1 : 0,
        institutionName: process?.institution?.name,
        institutionId: process?.institution?.id,
        achievementType: process?.achievementType || undefined,
        city: process?.city,
        class: process?.institution?.class,
        observation: process?.observation,
        secretaryClassification: process?.secretaryClassification || undefined,
        serviceChannel: process?.serviceChannel,
        uf: process?.uf,
        isBidding: process?.isBidding ? 1 : 0
      });
    }
  }, [process]);

  const { citiesData, citiesLoading } = useCities();
  const [institutionsQueryRes, , institutionsLoading] = useQuery<
    InstitutionModel[]
  >('/institution', {
    $limit: -1,
    $select: ['id', 'name', 'uf', 'class', 'focus', 'serviceChannel', 'city'],
    disabled: 0
  });

  const [ufsRegionals] = useQuery<UfsRegionals[], UfsRegionals>(
    '/ufs-regionals',
    {
      $limit: -1
    }
  );

  useEffect(() => {
    if (watch('uf')) {
      const findUfRegional = (ufsRegionals || []).find(
        (ufRegional) => ufRegional.uf === String(watch('uf'))
      );
      setValue('regional', findUfRegional?.regional);
    }
  }, [ufsRegionals]);

  const institutionsData = institutionsQueryRes || [];
  const filterOptions = createFilterOptions<InstitutionModel>({
    limit: 20
  });

  const citiesFiltered = citiesData
    .filter((city) => city.uf === (watch('uf') as string))
    .map((city) => city.city);

  return citiesLoading || loading || institutionsLoading ? (
    <LinearProgress />
  ) : (
    <form onSubmit={handleSubmit(onComplete)}>
      <Grid item container spacing={2}>
        <Grid item xs={12} sm={4}>
          <ControlledTextField
            disabled
            name={'employeeName'}
            control={control}
            label={'Nome do Consultor'}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ControlledTextField
            disabled
            name={'employeeSap'}
            control={control}
            label={'SAP do Consultor'}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ControlledTextField
            disabled
            name={'regional'}
            control={control}
            label={'Regional'}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ControlledAutocomplete<
            PrivateProcessProposalSummaryInputs,
            InstitutionModel
          >
            name={'institutionName'}
            control={control}
            label={'Nome do Órgão Público/Inst.'}
            errors={formState.errors.institutionId?.message}
            options={[...institutionsData]}
            getOptionLabel={(option) =>
              option?.name
                ? `${option?.id} - ${option?.name} - ${option?.uf}`
                : ''
            }
            filterOptions={filterOptions}
            onChange={(_, value) => {
              _.preventDefault();
              if (value && !Array.isArray(value)) {
                setValue('institutionName', value.name);
                setValue('institutionId', value.id);
                const serviceChannel =
                  value?.serviceChannel as ServicesChannels;
                setValue('serviceChannel', serviceChannel);
                setValue('class', value?.class as ServicesChannels);
                setValue('focusUf', value?.focus ? 1 : 0);
                const findUfRegional = (ufsRegionals || []).find(
                  (ufRegional) => ufRegional.uf === value?.uf
                );
                setValue('uf', value?.uf as States);
                setValue('regional', findUfRegional?.regional);
                setValue(
                  'city',
                  citiesData.find((city) => city.city === value?.city)?.city ||
                    ''
                );
                if (
                  ['Hospitalar', 'Concessionária de alimentos'].includes(
                    serviceChannel || ''
                  )
                ) {
                  setValue('secretaryClassification', null);
                  setValue('achievementType', undefined);
                } else {
                  setValue('achievementType', null);
                  setValue('secretaryClassification', undefined);
                }
              } else {
                setValue('institutionName', undefined);
                setValue('institutionId', undefined);
              }
              clearErrors('achievementType');
              clearErrors('secretaryClassification');
            }}
            value={
              institutionsData.find(
                (institution) => institution.id === watch('institutionId')
              ) || null
            }
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ControlledTextField
            name={'uf'}
            control={control}
            errors={formState.errors.uf?.message}
            label={'Estado'}
            select
            onChange={(event) => {
              setValue('uf', event.target.value as States);
              const findUfRegional = (ufsRegionals || []).find(
                (ufRegional) => ufRegional.uf === event.target.value
              );
              setValue('regional', findUfRegional?.regional);
              setValue('city', '');
            }}
          >
            {STATES_ARRAY.map(({ uf }) => (
              <MenuItem value={uf} key={uf}>
                {States[uf]}
              </MenuItem>
            ))}
          </ControlledTextField>
        </Grid>
        <Grid item xs={12} sm={4}>
          <ControlledAutocomplete<PrivateProcessProposalSummaryInputs, string>
            name={'city'}
            control={control}
            label={'Cidade'}
            options={citiesFiltered}
            errors={formState.errors.city?.message}
            onChange={(_, value) => {
              _.preventDefault();
              if (value && !Array.isArray(value)) {
                setValue('city', value);
              } else {
                setValue('city', undefined);
              }
            }}
            value={watch('city') || null}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ControlledTextField
            disabled
            name={'serviceChannel'}
            control={control}
            errors={formState.errors.serviceChannel?.message}
            label={'Canal de Atendimento'}
            select
          >
            {ProvServicesChannels.map((serviceChannel) => (
              <MenuItem value={serviceChannel} key={serviceChannel}>
                {serviceChannel}
              </MenuItem>
            ))}
          </ControlledTextField>
        </Grid>
        <Grid item xs={12} sm={4}>
          <ControlledTextField
            name={'class'}
            control={control}
            errors={formState.errors.class?.message}
            label={'Classificação'}
            select
            disabled
          >
            <MenuItem value={'null'}> Não se aplica </MenuItem>
            <MenuItem value={'GOLD'}> Gold </MenuItem>
            <MenuItem value={'SILVER'}> Silver </MenuItem>
          </ControlledTextField>
        </Grid>
        <Grid item xs={12} sm={4}>
          <ControlledTextField
            name={'secretaryClassification'}
            control={control}
            errors={formState.errors.secretaryClassification?.message}
            label={'Classificação da secretaria'}
            select
            disabled={
              SecretaryClassificationDomain({
                serviceChannel: watch('serviceChannel') as ServicesChannels,
                initialValue: null
              }).disabled
            }
          >
            <MenuItem value={'Secretaria de Educação'}>
              Secretaria de Educação
            </MenuItem>
            <MenuItem value={'Secretaria de Saúde'}>
              Secretaria de Saúde
            </MenuItem>
          </ControlledTextField>
        </Grid>
        <Grid item xs={12} sm={4}>
          <ControlledTextField
            name={'achievementType'}
            control={control}
            errors={formState.errors.achievementType?.message}
            label={'Tipo de conquista'}
            select
            disabled={
              !['Hospitalar', 'Concessionária de alimentos'].includes(
                watch('serviceChannel') || ''
              )
            }
          >
            <MenuItem value={'Reversão'}>Reversão</MenuItem>
            <MenuItem value={'Renovação'}>Renovação</MenuItem>
            <MenuItem value={'Padronização'}>Padronização</MenuItem>
            <MenuItem value={'Perda'}>Perda</MenuItem>
          </ControlledTextField>
        </Grid>
        <Grid item xs={12} sm={4}>
          <ControlledRadioGroup
            control={control}
            label={'Município Foco'}
            name={'focusUf'}
            options={OptionsBooleanToYes}
            value={+watch('focusUf')}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ControlledRadioGroup
            control={control}
            label={'Licitação'}
            name={'isBidding'}
            options={OptionsBooleanToYes}
            value={+watch('isBidding')}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            name={'observation'}
            control={control}
            label={'Justificativa'}
            multiline
            minRows={3}
            inputProps={{
              maxLength: 256
            }}
            variant={'outlined'}
          />
        </Grid>
        <Grid item container justifyContent="flex-end">
          <Grid item xs={12} sm={2}>
            <Button type={'submit'} fullWidth variant={'contained'}>
              Avançar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}
