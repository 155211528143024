import { PaginationItem } from '@mui/lab';
import styled from 'styled-components';

interface WidthOptions {
  small: {
    height: string;
    width: string;
    font: string;
  };
  medium: {
    height: string;
    width: string;
    font: string;
  };
}

const defaultWidths: WidthOptions = {
  small: {
    height: '18px',
    width: '18px',
    font: '12px'
  },
  medium: {
    height: '25px',
    width: '25px',
    font: '14px'
  }
};

export const StyledPaginationItem = styled(PaginationItem)<{
  itemSize: keyof WidthOptions;
}>`
  font-size: ${(props) => defaultWidths[props.itemSize].font};
  min-width: ${(props) => defaultWidths[props.itemSize].width};
  height: ${(props) => defaultWidths[props.itemSize].height};
  margin: '0px';
  padding: '0px';
`;
