import { Box, CircularProgress, Grid } from '@mui/material';
import { ProductListTab } from 'modules/Admin/Products/Fragments/Tabs/ProductList';
import { DisableOrEnableModal } from 'modules/Admin/shared/Modals/DisableOrEnableModal';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { usePatch, useQuery } from 'services';
import { ProductCard } from 'shared/components/Cards/ProductCard';
import { RivalProductCard } from 'shared/components/Cards/RivalProductCard';
import { SearchAdmin } from 'shared/components/Header/Admin/SearchAdmin';
import { UIPagination } from 'shared/components/Pagination';
import { ProductsModel, RivalProductsModel } from 'types/models';
import { AdminProducts } from 'types/models/AdminProducts';

const itemsPerPage = 12;

export function ProductListFragment() {
  const history = useHistory();
  const [search, setSearch] = useState<string>();
  const [type, setType] = useState<string>('all');
  const [page, setPage] = useState<number>(0);
  const [disableOrEnableProduct, setDisableOrEnableProduct] = useState<{
    type: 'disable' | 'enable';
    productId: number;
  }>();
  const [, patchProduct] = usePatch<ProductsModel>('/products');
  const [productsData, productsRefetch, loading] = useQuery<AdminProducts[]>(
    '/products/admin',
    {
      $limit: -1
    }
  );

  const [rivalProducts, , rivalProductsLoading] = useQuery<
    RivalProductsModel[]
  >('/rival-products', {
    $limit: -1
  });

  const handleInactivate = async () => {
    if (disableOrEnableProduct?.productId) {
      await patchProduct(
        {
          disabled: disableOrEnableProduct?.type === 'disable'
        },
        disableOrEnableProduct?.productId
      );
      setDisableOrEnableProduct(undefined);
      toast.success('Atualização feita com sucesso');
      productsRefetch();
    }
  };

  const GetFilteredProducts = () => {
    const filterNotDisabled = (product: AdminProducts) => !product.disabled;
    const products: AdminProducts[] = [];
    if (productsData) {
      if (type === 'all') {
        products.push(...productsData.filter(filterNotDisabled));
      } else if (type === 'inactive') {
        products.push(...productsData?.filter((product) => product.disabled));
      } else {
        products.push(
          ...productsData
            ?.filter((product) => product.businessUnity === type)
            ?.filter(filterNotDisabled)
        );
      }
    }

    return products.filter((product) => {
      // TO-DO: Sounds good to me right now, but in the future a refact is gonna be nice.
      if (search) {
        let conditional = !!product.sku
          ?.toUpperCase()
          ?.match(search?.toUpperCase());
        conditional =
          conditional ||
          !!product.description?.toUpperCase()?.match(search?.toUpperCase());
        conditional =
          conditional ||
          !!product.CEST?.toUpperCase()?.match(search?.toUpperCase());
        return conditional;
      }
      return true;
    });
  };
  const GetFilteredRivalProducts = () => {
    return rivalProducts?.filter((product) => {
      // TO-DO: Sounds good to me right now blah blah.
      if (search) {
        let conditional = !!product.company
          ?.toUpperCase()
          ?.match(search?.toUpperCase());
        conditional =
          conditional ||
          !!product.description?.toUpperCase()?.match(search?.toUpperCase());
        return conditional;
      }
      return true;
    });
  };
  const filteredProducts = GetFilteredProducts() || [];
  const filteredRivalProducts = GetFilteredRivalProducts() || [];
  const cutProducts = filteredProducts.slice(
    page * itemsPerPage,
    page * itemsPerPage + 12
  );
  const cutRivalProducts = filteredRivalProducts.slice(
    page * itemsPerPage,
    page * itemsPerPage + 12
  );
  const total = Math.ceil(
    (type !== 'rival-products' ? filteredProducts : filteredRivalProducts)
      .length / itemsPerPage
  );

  return (
    <>
      <Grid container spacing={2} mb={3}>
        <Grid item xs={12}>
          <Box mb={2}>
            <SearchAdmin
              title={'Produtos'}
              buttonText={'Adicionar produto'}
              onButtonClick={() => history.push('/produto/novo')}
              searchPlaceholder={'Pesquisar produtos'}
              handleChangeSearch={(t) => {
                setSearch(t.target.value);
                if (page !== 0) setPage(0);
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <ProductListTab
            onChange={(step) => {
              setType(step);
              setPage(0);
            }}
          />
        </Grid>
        {loading && rivalProductsLoading ? (
          <Grid
            item
            xs={12}
            height="300px"
            justifyContent="center"
            alignItems="center"
            container
          >
            <CircularProgress />
          </Grid>
        ) : (
          <>
            {cutProducts?.map((product) => (
              <Grid item xs={12} sm={6} md={4} key={product.id}>
                <ProductCard
                  product={product}
                  onEdit={() => history.push(`/produto/editar/${product.id}`)}
                  onDelete={() =>
                    setDisableOrEnableProduct({
                      productId: product.id,
                      type: type === 'inactive' ? 'enable' : 'disable'
                    })
                  }
                />
              </Grid>
            ))}
            {type === 'rival-products'
              ? cutRivalProducts?.map((rivalProduct) => (
                  <Grid item xs={12} sm={6} md={4} key={rivalProduct.id}>
                    <RivalProductCard rivalProduct={rivalProduct} />
                  </Grid>
                ))
              : null}
            <Grid item container justifyContent={'flex-end'}>
              <UIPagination
                count={total}
                onChange={(_, newPage) => setPage(newPage - 1)}
                itemSize="medium"
                page={page + 1}
              />
            </Grid>
          </>
        )}
      </Grid>
      <DisableOrEnableModal
        open={!!disableOrEnableProduct}
        onClose={() => setDisableOrEnableProduct(undefined)}
        onSubmit={handleInactivate}
        type={disableOrEnableProduct?.type}
        content={{
          disable: {
            title: 'Desativar Produto',
            primaryText: 'Deseja desativar o produto?',
            secondaryText:
              'O produto não poderá ser mais usado em futuros processos.',
            buttonText: 'Inativar'
          },
          enable: {
            title: 'Reativar Produto',
            primaryText: 'Deseja reativar o produto?',
            secondaryText:
              'O produto poderá ser usado novamente em futuros processos.',
            buttonText: 'Reativar'
          }
        }}
      />
    </>
  );
}
