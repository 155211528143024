import { Button, Grid } from '@mui/material';
import { DistributorForm } from 'modules/Admin/Users/Distributor/Forms/DistributorForm';
import { DistributorFormRes } from 'modules/Admin/Users/Distributor/Forms/DistributorForm/domain';
import React from 'react';
import { toast } from 'react-toastify';
import history from 'routes/History';
import { usePost, useQuery } from 'services';
import UIPaper from 'shared/components/Paper';
import { AccessModel, DistributorModel } from 'types/models';
import { CatchAccessErrorValidate } from 'utils/Functions/Validate/AxiosAccessValidate';
import { ValidateDistributorHasAccess } from 'utils/Functions/Validate/DistributorHasAccess';

export function CreateDistributorFormFragment() {
  const [, , , , , fetchAxios] = usePost<AccessModel, AccessModel>('/access', {
    willFail: true
  });

  const [, fetchDistributor] = useQuery<DistributorModel[], DistributorModel>(
    '/distributor',
    {
      fetchOnInitialize: false
    }
  );

  const handleSuccess = (message: string) => {
    toast.success(message);
    history.push('/usuario');
  };

  const createPassword = (distributor: DistributorModel): string => {
    if (distributor.name) {
      return `${distributor?.name?.split(' ').join('')}-${distributor.sold}`;
    }
    const random = new Uint32Array(8);

    return String(window.crypto.getRandomValues(random));
  };

  const handleCreateDistributor = async (
    access: AccessModel,
    distributor: DistributorModel
  ) => {
    const password = createPassword(distributor);
    fetchAxios({
      ...access,
      password,
      distributor
    })
      .then(() => {
        handleSuccess('Distribuidor cadastrado com sucesso.');
      })
      .catch((error) => {
        CatchAccessErrorValidate(error);
      });
  };

  const handleCompleteForm = async (data: DistributorFormRes) => {
    if (data?.distributor && data?.distributor?.sold) {
      const hasAccess = await ValidateDistributorHasAccess({
        sold: data.distributor.sold,
        fetchDistributor
      });
      if (hasAccess) return;
      await handleCreateDistributor(data, data.distributor);
    }
  };

  return (
    <>
      <Grid container>
        <UIPaper title="Cadastrar Distribuidor">
          <>
            <DistributorForm onComplete={handleCompleteForm} />
            <Grid item container justifyContent={'flex-end'} mt={3} xs={12}>
              <Grid item container justifyContent={'flex-end'} sm={3} xs={12}>
                <Button
                  type={'submit'}
                  fullWidth
                  form="distributor-form"
                  variant={'contained'}
                >
                  Concluir
                </Button>
              </Grid>
            </Grid>
          </>
        </UIPaper>
      </Grid>
    </>
  );
}
