import { Grid } from '@mui/material';
import React from 'react';
import { UITabs } from 'shared/components/Tabs';
import { UITab } from 'shared/components/Tabs/Tab';

interface RegionalsTabProps {
  onChange: (step: string) => void;
  step: string;
}

export function RegionalsTab({ onChange, step }: RegionalsTabProps) {
  const handleChange = (event: React.SyntheticEvent, value: string) => {
    onChange(value);
  };

  return (
    <Grid container item xs={12} mb={1} mt={1}>
      <UITabs
        value={step}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        aria-label="scrollable force tabs example"
        onChange={handleChange}
      >
        <UITab value={'all'} label={'Todos'} />
        <UITab value={'CNN'} label={'CNN'} />
        <UITab value={'SSP'} label={'SSP'} />
      </UITabs>
    </Grid>
  );
}
