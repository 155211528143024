export const Segments = {
  public: 'Público',
  private: 'Privado'
};

export const InstitutionsSegments = {
  publicPrivate: 'Público e Privado',
  private: 'Privado'
};

export type SegmentsOptions = keyof typeof Segments;
export type InstitutionsSegmentsOptions = keyof typeof InstitutionsSegments;
