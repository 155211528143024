export const processFields = [
  'id',
  'status',
  'tradingCode',
  'pne',
  'city',
  'uf',
  'regional',
  'segment',
  'createdAt',
  'startPeriod',
  'endPeriod',
  'canExtend'
];
