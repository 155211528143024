/* eslint-disable no-return-assign */
import { Grid, Typography } from '@mui/material';
import React from 'react';
import { UIChip } from 'shared/components/Chip';
import { TitleLabel } from 'shared/components/TitleLabel';
import { theme } from 'shared/theme';
import { ProcessModel } from 'types/models';
import { Segments } from 'utils/Enums';

type E2OProcessDetailsProps = {
  process?: ProcessModel;
};

export default function E2OProcessDetails({ process }: E2OProcessDetailsProps) {
  return (
    <Grid container item mb={4} spacing={2}>
      <Grid container item xs={2} sm={2}>
        <Grid container item xs={2} gap={1} flexDirection="column">
          <Typography
            variant="h4"
            style={{
              color: theme.colors.TextDarkGrey,
              fontWeight: 'normal'
            }}
          >
            Segmento
          </Typography>
          <UIChip
            color={process?.segment === 'private' ? 'green' : 'blue'}
            fontWeight="800"
            title={process?.segment ? Segments[process?.segment] : ''}
            height="25px"
          />
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={4}
        sm={3}
        lg={2}
        gap={1}
        flexDirection="column"
        justifyContent="space-between"
      >
        <TitleLabel
          title="Número do pregão: "
          label={process?.tradingCode ?? '-'}
        />
      </Grid>
      <Grid
        container
        item
        xs={2}
        sm={1}
        lg={1}
        gap={1}
        flexDirection="column"
        justifyContent="space-between"
      >
        <TitleLabel title="PNE: " label={`${process?.pne}` ?? '-'} />
      </Grid>
      <Grid
        container
        item
        xs={4}
        sm={3}
        lg={2}
        gap={1}
        flexDirection="column"
        justifyContent="space-between"
      >
        <TitleLabel
          title="Instituição: "
          label={process?.institution?.name || '-'}
        />
      </Grid>
      <Grid
        container
        item
        xs={6}
        sm={6}
        lg={2}
        gap={1}
        flexDirection="column"
        justifyContent="space-between"
      >
        <TitleLabel
          title="Código do cliente: "
          label={process?.distributor?.sold || '-'}
        />
      </Grid>
      <Grid
        container
        item
        xs={6}
        sm={6}
        lg={2}
        gap={1}
        flexDirection="column"
        justifyContent="space-between"
      >
        <TitleLabel
          title="Cliente: "
          label={process?.distributor?.name || '-'}
        />
      </Grid>
    </Grid>
  );
}
