export const GenerateArrayFromEnum = <EnumType>(genericEnum: EnumType) => {
  return (Object.keys(genericEnum) as Array<keyof typeof genericEnum>).map(
    (key) => {
      return {
        key,
        value: genericEnum[key]
      };
    }
  );
};
