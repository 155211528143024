/* eslint-disable react-hooks/exhaustive-deps */
import { Button, CircularProgress, Grid } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { FormProps, UITextField } from 'shared/components/Inputs';
import { RemovePattern } from 'shared/components/Inputs/Pattern/RemovePattern';
import { PoliticalPricesModel } from 'types/models';
import { FormatPercent } from 'utils/Functions/FormatPercent';
import { GetPreApprovedPrice } from 'utils/Functions/GetPreApprovedPrice';
import { MoneyMask } from 'utils/masks';
import { PoliticalPriceSchema } from './domain';

interface PoliticalPriceTemplateFormProps
  extends FormProps<PoliticalPricesModel> {
  politicalPrice?: PoliticalPricesModel;
  uf?: string;
  type: string;
  loading: boolean;
}

export function PoliticalPriceTemplateForm({
  politicalPrice,
  onComplete,
  type,
  uf,
  loading
}: PoliticalPriceTemplateFormProps) {
  const initialValues = {
    id: politicalPrice?.id,
    price: MoneyMask(politicalPrice?.price || 0),
    preDiscount: FormatPercent((politicalPrice?.preDiscount || 0) * 100),
    referentialPrice: MoneyMask(politicalPrice?.referentialPrice || 0),
    type,
    uf
  };

  const { values, handleChange, handleSubmit, resetForm, errors, touched } =
    useFormik({
      initialValues,
      validationSchema: PoliticalPriceSchema,
      onSubmit: (fields) => {
        const data = { ...fields };

        const removedPatterns = RemovePattern(
          data
        ) as unknown as PoliticalPricesModel;
        removedPatterns.preDiscount = +(
          (removedPatterns.preDiscount || 0) / 100
        )?.toFixed(4);

        onComplete(removedPatterns);
      }
    });

  useEffect(() => {
    if (uf || politicalPrice) {
      resetForm({ values: initialValues });
    }
  }, [uf, politicalPrice]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <UITextField
            label={'(R$) Preço lista'}
            name={'price'}
            value={values.price}
            onChange={handleChange}
            pattern={'money'}
            error={!!errors.price && touched.price}
            helperText={touched.price && errors.price}
          />
        </Grid>
        <Grid item xs={12}>
          <UITextField
            label={'(%) Desconto pré-aprovado'}
            name={'preDiscount'}
            value={values.preDiscount}
            onChange={handleChange}
            pattern={'percentage'}
            error={!!errors.preDiscount && touched.preDiscount}
            helperText={touched.preDiscount && errors.preDiscount}
          />
        </Grid>
        <Grid item xs={12}>
          <UITextField
            label={'(R$) Preço referencial (UNI)'}
            name={'referentialPrice'}
            value={values.referentialPrice}
            onChange={handleChange}
            pattern={'money'}
            error={!!errors.referentialPrice && touched.referentialPrice}
            helperText={touched.referentialPrice && errors.referentialPrice}
          />
        </Grid>
        <Grid item xs={12}>
          <UITextField
            label={'(R$) Preço pré-aprovado (UNI)'}
            name={'preDiscount'}
            value={GetPreApprovedPrice(values?.preDiscount, values?.price)}
            disabled
          />
        </Grid>
        <Grid item xs={12} container justifyContent={'flex-end'}>
          <Grid item xs={6} container justifyContent={'flex-end'}>
            {loading ? (
              <CircularProgress size={30} />
            ) : (
              <Button fullWidth type={'submit'} variant={'contained'}>
                {politicalPrice?.id ? 'Salvar' : 'Cadastrar'}
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}
