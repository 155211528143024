export const FilterProcessStatus = [
  {
    status: 'lost',
    label: 'Perdidos'
  },
  {
    status: 'awaiting order',
    label: 'Em andamento'
  },
  {
    status: 'const recording',
    label: 'Consultor Cadastrando'
  },
  {
    status: 'dist recording',
    label: 'Distribuidor Cadastrando'
  },
  {
    status: 'awaiting consultant result',
    label: 'Aguard. Resultado Consultor'
  },
  {
    status: 'awaiting distributor result',
    label: 'Aguard. Resultado Distribuidor'
  },
  {
    status: 'suspended',
    label: 'Suspensos'
  },
  {
    status: 'awaiting financier',
    label: 'Aguard. Financeiro'
  },
  {
    status: 'finished',
    label: 'Finalizados'
  },
  {
    status: 'all',
    label: 'Todos'
  }
];
