import React from 'react';
import { Checkbox, TableCell, TableHead, TableRow } from '@mui/material';

interface ProcessOrderHeaderProps {
  handleSelectAll: () => void;
}

export function ProcessOrderHeader({
  handleSelectAll
}: ProcessOrderHeaderProps) {
  return (
    <TableHead>
      <TableRow>
        <TableCell align={'center'} padding={'none'} width={0} size={'small'}>
          <Checkbox
            onChange={handleSelectAll}
            color={'primary'}
            inputProps={{ 'aria-label': 'select all desserts' }}
            checked
          />
        </TableCell>
        <TableCell align={'center'} width={0} padding={'none'} size={'small'}>
          {''}
        </TableCell>
        <TableCell align={'center'} padding={'none'} width={0} size={'small'}>
          Item
        </TableCell>
        <TableCell align={'center'} width={200} size={'small'}>
          SKU
        </TableCell>
        <TableCell align={'center'} width={500}>
          Produto
        </TableCell>
        <TableCell align={'center'} width={400}>
          Valor NF Nestlé (R$) (UNI)
        </TableCell>
        <TableCell align={'center'} width={300}>
          Valor Instituição (R$) (UNI)
        </TableCell>
        <TableCell align={'center'} width={400}>
          Valor total do item (R$)
        </TableCell>
        <TableCell align={'center'} width={200}>
          Saldo do item (UNI)
        </TableCell>
        <TableCell align={'center'} width={300}>
          Quantidade (UNI)
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
