import React from 'react';
import { pdfjs } from 'react-pdf';
import { PdfRenderProps } from 'shared/components/Pdf/HtmlRender/PdfHtmlRender';
import { Grid } from '@mui/material';

import * as S from './styled';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export function PdfRender({ url, height = 600, ...rest }: PdfRenderProps) {
  return (
    <Grid item {...rest}>
      <S.RenderHtml data={url} type="application/pdf" height={height}>
        <embed src={url} type="application/pdf" />
      </S.RenderHtml>
    </Grid>
  );
}
