import * as yup from 'yup';

export interface LoginFormData {
  email: string;
  password: string;
}

export interface LoginFormResponse {
  email: string;
  password: string;
}

export const LoginFormSchema = yup.object({
  email: yup
    .string()
    .email('Digite um email valido')
    .required('Digite um email'),
  password: yup.string().required('Digite uma senha valida')
});
