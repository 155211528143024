import React from 'react';
import { TextField } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  ForgotPasswordDomainInputs,
  ForgotPasswordDomainResponse,
  ForgotPasswordValidationSchema
} from 'modules/Login/Forms/ForgotPasswordForm/domain';
import { yupResolver } from '@hookform/resolvers/yup';

export interface ForgotPasswordFormParams {
  handleResponse: (data: ForgotPasswordDomainResponse) => void;
  initialValue?: string;
}

export function ForgotPasswordForm({
  handleResponse,
  initialValue
}: ForgotPasswordFormParams) {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<ForgotPasswordDomainInputs>({
    resolver: yupResolver(ForgotPasswordValidationSchema)
  });

  const onSubmit: SubmitHandler<ForgotPasswordDomainResponse> = (data) => {
    handleResponse(data);
  };

  return (
    <form id="ForgotPasswordForm" onSubmit={handleSubmit(onSubmit)}>
      <TextField
        label={'Email'}
        fullWidth
        {...register('email')}
        defaultValue={initialValue}
        error={!!errors.email}
        helperText={errors.email?.message}
        inputProps={{ 'data-testid': '__forgotPassword_email' }}
      />
    </form>
  );
}
