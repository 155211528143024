import { useState } from 'react';
import { toast } from 'react-toastify';
import { FetchQuery } from 'services';
import { useFormData } from 'services/api/UseFormData';
import { UploadAttachment } from 'types/input/UploadAttachment';
import { AttachmentModel } from 'types/models/Attachments/Attachment.model';

type UseUploadAddOptions = {
  accessId?: number;
  table: string;
  tableId?: number;
  ocr?: boolean;
  putInHeaders?: boolean;
};

export function useUploadAdd(
  attachmentsRefetch: FetchQuery<AttachmentModel[], AttachmentModel>,
  {
    accessId,
    table,
    tableId,
    ocr = false,
    putInHeaders = false
  }: UseUploadAddOptions
) {
  const [file, setFile] = useState<File>();
  const [category, setCategory] = useState<string>('');

  const [attachmentAdd, attachmentLoadingAdd] = useFormData<
    UploadAttachment,
    AttachmentModel
  >(`/attachments`);

  const handleFile = (selectedFile: File) => {
    setFile(selectedFile);
  };

  const uploadAttachment = async () => {
    try {
      if (file && category) {
        const uploaded = await attachmentAdd(
          {
            file,
            category,
            tableId,
            table,
            accessId,
            ocr
          },
          putInHeaders
            ? {
                ocr,
                category
              }
            : undefined
        );
        if (!uploaded) {
          toast.error('Erro ao fazer upload do arquivo.');
        } else {
          toast.success('Documento anexado com sucesso.');
          attachmentsRefetch();
          setCategory('');
          setFile(undefined);
        }
      }
    } catch (error) {
      toast.error('Erro ao fazer upload do arquivo.');
    }
  };

  return {
    uploadAttachment,
    handleFile,
    attachmentLoadingAdd,
    file,
    setFile,
    category,
    setCategory,
    buttonDisabled: !file || !category
  };
}
