import React, { useState } from 'react';
import { Box, Button, Grid, InputAdornment, Typography } from '@mui/material';
import { UITextField } from 'shared/components/Inputs';
import SearchIcon from '@mui/icons-material/Search';
import { SearchAdminProps } from 'shared/components/Header/Admin/SearchAdmin/SearchAdminProps';
import { theme } from 'shared/theme';

export function SearchAdmin({
  title,
  buttonText,
  onButtonClick,
  handleChangeSearch,
  searchPlaceholder
}: SearchAdminProps) {
  const [search, setSearch] = useState('');

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { value } = event.target;
    setSearch(value);
    handleChangeSearch(event);
  };

  return (
    <Box mb={2} mt={2}>
      <Grid container spacing={3}>
        <Grid item container xs={12}>
          <Grid item container xs={12} md={3} alignItems={'center'}>
            <Typography
              variant={'h1'}
              style={{ color: theme.colors.TextDarkGrey }}
            >
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <UITextField
              name={'search'}
              value={search || ''}
              label={searchPlaceholder}
              onChange={handleChange}
              variant={'filled'}
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position={'start'}>
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              fullWidth
            />
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={3}
            justifyContent={'flex-end'}
            alignItems={'center'}
          >
            <Grid item>
              <Button variant={'contained'} onClick={onButtonClick}>
                {buttonText}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
