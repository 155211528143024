/* eslint-disable @typescript-eslint/no-explicit-any */
import { toast } from 'react-toastify';

export const CatchAccessErrorValidate = (error: any) => {
  toast.error('Algo de errado aconteceu.');
  const emailError = error?.response?.data?.errors?.find(
    (errorEmail: any) =>
      errorEmail.code === 'EMAIL_ALREADY_USE' ||
      errorEmail.path === 'access_email'
  );
  if (emailError) {
    toast.error('E-mail em uso.');
  }
  const cnpjError = error?.response?.data?.errors?.find(
    (errorCNPJ: any) =>
      errorCNPJ.code === 'CNPJ_ALREADY_USE' ||
      errorCNPJ.path === 'distributor_cnpj'
  );
  if (cnpjError) {
    toast.error('CNPJ em uso.');
  }
  const sapError = error?.response?.data?.errors?.find(
    (errorCNPJ: any) =>
      errorCNPJ.code === 'SAP_ALREADY_USE' || errorCNPJ.path === 'employee_sap'
  );
  if (sapError) {
    toast.error('SAP em uso.');
  }
};
