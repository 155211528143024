/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { SimpleFormModal, SimpleFormModalParams } from 'shared/components';
import { ProcessProductModel } from 'types/models';
import { AdminEditItemProcessForm } from '../../Forms/AdminEditItemProcessForm';

export interface AdminEditItemProcessModalProps
  extends Omit<SimpleFormModalParams, 'title'> {
  processItem?: Partial<ProcessProductModel>;
  onComplete: (data: Partial<ProcessProductModel>) => void;
}

export function AdminEditItemProcessModal({
  processItem,
  onComplete,
  onClose,
  ...rest
}: AdminEditItemProcessModalProps) {
  return (
    <SimpleFormModal
      title={'Editar Item'}
      {...rest}
      onClose={onClose}
      fullWidth
    >
      <AdminEditItemProcessForm
        onComplete={onComplete}
        processProduct={processItem}
        onClose={onClose}
        processId={processItem?.processId!}
      />
    </SimpleFormModal>
  );
}
