import { Paper } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import styled from 'styled-components';

export const SectionTableTop = styled.div`
  width: 100%;
  display: flex;

  h1 {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }
`;

export const SectionTopStart = styled.div`
  padding-left: 16px;
  display: flex;
  align-items: center;
`;

export const SectionTopEnd = styled.div`
  padding-right: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

export const StyledPaper = withStyles({
  root: {
    width: '100%',
    borderRadius: '4px 4px 0px 0px'
  }
})(Paper);
