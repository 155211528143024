import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ProcessItemModal } from 'modules/Process/PublicProcess/Modals/ProcessItem/Complete';
import { ProcessItemOrders } from 'modules/Process/shared/Tables/ProcessItems/rules/ProcessItemOrders';
import { useAuth } from 'Providers/Auth';
import React, { useState } from 'react';
import { FetchQuery, usePatch } from 'services';
import { UITable } from 'shared/components';
import { UIDataGridApiPagination } from 'shared/components/Tables/DataGridApiPagination';
import { UIToolbar } from 'shared/components/Tables/ToolbarTable';
import { theme } from 'shared/theme';
import { ProcessModel, ProcessProductModel } from 'types/models';
import { ProcessHistoryTable } from './Functions';

type ProcessHistoryItemTableProps = {
  loading: boolean;
  process: ProcessModel;
  processProductsRefetch: FetchQuery<
    ProcessProductModel[],
    ProcessProductModel
  >;
};

const useStyles = makeStyles({
  root: {
    '& .super-app-theme--result-cell': {
      backgroundColor: '#fff5e2'
    },
    '& .super-app-theme--result-divisor': {
      borderLeft: '1px solid rgba(224, 224, 224, 1)'
    },
    '& .super-app-theme--result-yes': {
      color: theme.colors.Success
    },
    '& .super-app-theme--result-no': {
      color: theme.colors.Error
    },
    '& .super-app-theme--financier-yes': {
      color: theme.colors.Primary
    },
    '& .super-app-theme--financier-no': {
      color: theme.colors.TextDarkGrey
    },
    '& .super-app-theme--end-cell': {
      backgroundColor: '#fff5e2',
      color: theme.colors.Orange
    },
    '& .super-app-theme--order-cell': {
      backgroundColor: '#e4ebf6'
    },
    '& .super-app-theme--info-cell': {
      backgroundColor: '#eaf5e6'
    }
  }
});

export function ProcessHistoryItemTable({
  loading,
  process,
  processProductsRefetch
}: ProcessHistoryItemTableProps) {
  const classes = useStyles();

  const [editItem, setEditItem] = useState<Partial<ProcessProductModel>>();

  const { auth } = useAuth();
  const distributor = !!auth?.distributor;
  const columns = ProcessHistoryTable(distributor, process?.status || '');
  const { products, orderColumns } = ProcessItemOrders(
    process?.process_products || []
  );
  const finalColumns = [...columns, ...orderColumns];
  const finalRows =
    products?.map((pp) => ({
      ...pp,
      quota: process?.quota?.toUpperCase() || '-'
    })) || [];

  const getActions = () => {
    // TO-DO: Do this feature
    // if (auth?.type === 'distributor') {
    //   return [
    //     {
    //       label: 'Contestar as informações',
    //       onClick: (data: Partial<ProcessProductModel>) => {
    //         console.log(data);
    //       }
    //     }
    //   ];
    // }
    if (process?.status === 'awaiting distributor result') {
      return [
        {
          label: 'Editar item',
          onClick: (data: Partial<ProcessProductModel>) => {
            setEditItem(data as Partial<ProcessProductModel>);
          }
        }
      ];
    }
    return [];
  };
  const actions = getActions();

  const onCloseEditItem = () => {
    setEditItem(undefined);
  };

  const [, processItemPatch] =
    usePatch<Partial<ProcessProductModel>>('/process-products');

  const onEditItem = async (data: Partial<ProcessProductModel>) => {
    const newData = data;
    if (editItem?.id && newData) {
      newData.initialQuantity = data.quantity;
      await processItemPatch(data, editItem.id);
      await processProductsRefetch();
    }
    onCloseEditItem();
  };

  return (
    <>
      <Grid item xs={12}>
        <div className={classes.root}>
          <UITable
            columns={finalColumns}
            rows={finalRows}
            rowHeight={36}
            headerHeight={36}
            pageSize={5}
            actions={actions.length === 0 ? undefined : actions}
            components={{
              Toolbar: () => (
                <UIToolbar
                  title={
                    <Typography variant="h2">Itens do processo</Typography>
                  }
                  elementsTopEnd={<UIDataGridApiPagination />}
                />
              )
            }}
            loading={loading}
          />
        </div>
      </Grid>
      <ProcessItemModal
        open={!!editItem}
        onComplete={onEditItem}
        onClose={onCloseEditItem}
        processItem={editItem}
        disabledByHistory
        processId={process?.id || 1}
      />
    </>
  );
}
