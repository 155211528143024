import { Container, Grid } from '@mui/material';
import AddUserTitle from 'modules/Admin/Users/All/Fragments/Title';
import { BreadcrumbDistributorAmin } from 'modules/Admin/Users/Distributor/Fragment/BreadcrumbDistributorAdmin';
import { CreateDistributorFormFragment } from 'modules/Admin/Users/Distributor/Fragment/DistributorFormFragment';
import React from 'react';

export function CreateDistributor() {
  return (
    <Container maxWidth={'lg'}>
      <Grid item>
        <BreadcrumbDistributorAmin create />
      </Grid>
      <AddUserTitle type="Distribuidor" />
      <Grid item>
        <CreateDistributorFormFragment />
      </Grid>
    </Container>
  );
}
