import { Accordion, AccordionDetails, Grid, MenuItem } from '@mui/material';
import { PoliticalPriceFormProps } from 'modules/Admin/Products/forms/PoliticalPriceForm/PoliticalPriceForm';
import { PoliticalPriceTemplateForm } from 'modules/Admin/Products/forms/PoliticalPriceTemplateForm';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { usePatch, usePost } from 'services';
import { UITextField } from 'shared/components/Inputs';
import { PoliticalPricesModel } from 'types/models';
import { States, STATES_ARRAY } from 'utils/Enums';
import { AccordionTitle } from './AccordionTitle';

export function PoliticalPriceForm({
  politicalPrices,
  refetchPoliticalPrices,
  id
}: PoliticalPriceFormProps) {
  const [, patchPolitical, patchLoading] =
    usePatch<PoliticalPricesModel>('/political-prices');
  const [, postPolitical, postLoading] = usePost<
    PoliticalPricesModel,
    PoliticalPricesModel
  >('/political-prices');

  const [selectedUf, setSelectedUf] = useState<string>('');

  const [accordionOpen, setAccordionOpen] = useState('');

  const handleOpenAccordion =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      if (selectedUf) setAccordionOpen(isExpanded ? panel : '');
      else
        toast.error('Selecione o estado para adicionar a política comercial');
    };

  const GetPoliticalPriceType = (type: string) => {
    return politicalPrices?.find(
      (politicalPrice) =>
        politicalPrice.uf === selectedUf && politicalPrice.type === type
    );
  };

  const handleEdit = async (data: PoliticalPricesModel) => {
    try {
      await patchPolitical({ ...data }, data?.id);
      toast.success(`Política comercial atualizada com sucesso`);
      refetchPoliticalPrices();
    } catch (error) {
      toast.error(
        `Erro ao atualizar a política comercial de preços de: ${data.uf}`
      );
    }
  };

  const handleCreate = async (data: PoliticalPricesModel) => {
    try {
      await postPolitical({ ...data, productId: id });
      toast.success(`Política comercial criada com sucesso`);
      refetchPoliticalPrices();
    } catch (error) {
      toast.error(
        `Erro ao criar uma nova política comercial de preços de: ${data.uf}`
      );
    }
  };

  const handleConfirm = (data: PoliticalPricesModel) => {
    if (data.id) {
      handleEdit(data);
    } else {
      handleCreate(data);
    }
  };

  const distributorPoliticalPrice = GetPoliticalPriceType('distributor');
  const hospitalPoliticalPrice = GetPoliticalPriceType('hospital');
  const dealershipPoliticalPrice = GetPoliticalPriceType('dealership');
  const basicBasketPoliticalPrice = GetPoliticalPriceType('basicBasket');

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <UITextField
            name={'uf'}
            label={'Estado (UF)'}
            value={selectedUf}
            onChange={(e) => {
              setSelectedUf(e.target.value);
              refetchPoliticalPrices();
            }}
            select
          >
            {STATES_ARRAY.map(({ uf }) => (
              <MenuItem value={uf} key={uf}>
                {States[uf]}
              </MenuItem>
            ))}
          </UITextField>
        </Grid>
        <Grid item xs={12}>
          <Accordion
            expanded={accordionOpen === 'distributorAccordion'}
            onChange={handleOpenAccordion('distributorAccordion')}
          >
            <AccordionTitle
              isUfSelected={!!selectedUf}
              title="Distribuidor"
              isCreated={!!distributorPoliticalPrice}
            />
            <AccordionDetails>
              <PoliticalPriceTemplateForm
                loading={patchLoading || postLoading}
                politicalPrice={distributorPoliticalPrice}
                type="distributor"
                uf={selectedUf || ''}
                onComplete={handleConfirm}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={accordionOpen === 'hospitalAccordion'}
            onChange={handleOpenAccordion('hospitalAccordion')}
          >
            <AccordionTitle
              isUfSelected={!!selectedUf}
              title="Hospital"
              isCreated={!!hospitalPoliticalPrice}
            />
            <AccordionDetails>
              <PoliticalPriceTemplateForm
                loading={patchLoading || postLoading}
                politicalPrice={hospitalPoliticalPrice}
                type="hospital"
                uf={selectedUf || ''}
                onComplete={handleConfirm}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={accordionOpen === 'dealershipAccordion'}
            onChange={handleOpenAccordion('dealershipAccordion')}
          >
            <AccordionTitle
              isUfSelected={!!selectedUf}
              title="Concessionária"
              isCreated={!!dealershipPoliticalPrice}
            />
            <AccordionDetails>
              <PoliticalPriceTemplateForm
                loading={patchLoading || postLoading}
                politicalPrice={dealershipPoliticalPrice}
                type="dealership"
                uf={selectedUf || ''}
                onComplete={handleConfirm}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={accordionOpen === 'basicBasketAccordion'}
            onChange={handleOpenAccordion('basicBasketAccordion')}
          >
            <AccordionTitle
              isUfSelected={!!selectedUf}
              title="Cesta básica"
              isCreated={!!basicBasketPoliticalPrice}
            />
            <AccordionDetails>
              <PoliticalPriceTemplateForm
                loading={patchLoading || postLoading}
                politicalPrice={basicBasketPoliticalPrice}
                type="basicBasket"
                uf={selectedUf || ''}
                onComplete={handleConfirm}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </>
  );
}
