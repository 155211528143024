import styled from 'styled-components';
import { Grid } from '@mui/material';

export const Wrapper = styled(Grid)<{ borderColor?: string }>`
  min-height: 130px;
  min-width: 100%;
  height: 100%;
  border-left: 5px solid;
  border-color: ${({ borderColor }) => borderColor};
  border-radius: 3px;
  background: #fff;
  padding: 0.9rem;
  margin-bottom: 0.3rem;
`;
