import { HeaderOptionsProps } from 'types/Config/Headers';

export const AdminHeader: HeaderOptionsProps['options'] = [
  {
    key: 'dashboard',
    label: 'Processos',
    linkHref: '/dashboard'
  },
  {
    key: 'institutions',
    label: 'Instituições',
    linkHref: '/instituicao'
  },
  {
    key: 'clients',
    label: 'Clientes',
    linkHref: '/clientes'
  },
  {
    key: 'users',
    label: 'Usuários',
    linkHref: '/usuario'
  },
  {
    key: 'produto',
    label: 'Produtos',
    linkHref: '/admin/produtos'
  },
  {
    key: 'imports',
    label: 'Importações',
    linkHref: '/importacoes'
  },
  {
    key: 'regionals',
    label: 'Regionais',
    linkHref: '/regionais'
  }
];
