/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Grid, MenuItem } from '@mui/material';
import { useFormik } from 'formik';
import { UITextField } from 'shared/components/Inputs';
import {
  ProductFormProps,
  ProductFormSchema
} from 'modules/Admin/Products/forms/product/ProductForm';
import { ProductsModel } from 'types/models';
import { ProductCategory } from 'utils/Constants/ProductCategory';
import { ProductTypeOfFormula } from 'utils/Constants/ProductTypeOfFormula';

export function ProductForm({ onComplete, product }: ProductFormProps) {
  const {
    values,
    handleChange,
    handleSubmit,
    resetForm,
    setFieldValue,
    errors,
    touched
  } = useFormik({
    validationSchema: ProductFormSchema,
    initialValues: {
      sku: product?.sku || '',
      description: product?.description || '',
      unity: 'UNI',
      grammage: product?.grammage || 0,
      businessUnity: product?.businessUnity || '',
      fiscalClassification: product?.fiscalClassification || '',
      CEST: product?.CEST || '',
      EAN: product?.EAN || '',
      category: product?.category || ''
    },
    onSubmit: (fields) => {
      const data = fields;
      if (fields.grammage) data.grammage = fields.grammage / 1000;
      onComplete(data as ProductsModel);
      if (fields.grammage) data.grammage *= 1000;
    }
  });

  useEffect(() => {
    if (product) {
      resetForm({
        values: {
          sku: product?.sku || '',
          description: product?.description || '',
          unity: 'UNI',
          grammage: product?.grammage ? product?.grammage * 1000 : 0,
          businessUnity: product?.businessUnity || '',
          fiscalClassification: product?.fiscalClassification || '',
          CEST: product?.CEST || '',
          EAN: product?.EAN || '',
          category: product?.category || ''
        }
      });
    }
  }, [product]);

  return (
    <form id={'product-form'} onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <UITextField
            name={'sku'}
            label={'SKU'}
            value={values.sku}
            onChange={handleChange}
            fullWidth
            error={!!errors.sku && touched.sku}
            helperText={touched.sku && errors.sku}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <UITextField
            name={'description'}
            label={'Nome do produto'}
            value={values.description}
            onChange={handleChange}
            fullWidth
            error={!!errors.description && touched.description}
            helperText={touched.description && errors.description}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <UITextField
            name={'unity'}
            label={'Unidade'}
            value={values.unity}
            onChange={handleChange}
            fullWidth
            disabled
            error={!!errors.unity && touched.unity}
            helperText={touched.unity && errors.unity}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <UITextField
            name={'grammage'}
            label={'Gramatura'}
            value={values.grammage}
            onChange={handleChange}
            fullWidth
            error={!!errors.grammage && touched.grammage}
            helperText={touched.grammage && errors.grammage}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <UITextField
            name={'businessUnity'}
            label={'Categoria'}
            value={values.businessUnity}
            onChange={(e) => {
              handleChange(e);
              const { value } = e.target;
              if (value !== 'Fórmulas infantis') {
                setFieldValue('category', null);
              }
            }}
            fullWidth
            select
            error={!!errors.businessUnity && touched.businessUnity}
            helperText={touched.businessUnity && errors.businessUnity}
          >
            {ProductCategory.map((categoryProduct) => (
              <MenuItem value={categoryProduct} key={categoryProduct}>
                {categoryProduct}
              </MenuItem>
            ))}
          </UITextField>
        </Grid>
        <Grid item xs={12} md={4}>
          <UITextField
            name={'category'}
            label={'Tipo de fórmula'}
            value={values.category}
            onChange={handleChange}
            disabled={values.businessUnity !== 'Fórmulas infantis'}
            fullWidth
            select
            error={!!errors.category && touched.category}
            helperText={touched.category && errors.category}
          >
            {ProductTypeOfFormula.map((typeOfFormula) => (
              <MenuItem value={typeOfFormula} key={typeOfFormula}>
                {typeOfFormula}
              </MenuItem>
            ))}
          </UITextField>
        </Grid>
        <Grid item xs={12} md={4}>
          <UITextField
            name={'fiscalClassification'}
            label={'Classificação Fiscal'}
            value={values.fiscalClassification}
            onChange={handleChange}
            fullWidth
            error={
              !!errors.fiscalClassification && touched.fiscalClassification
            }
            helperText={
              touched.fiscalClassification && errors.fiscalClassification
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <UITextField
            name={'CEST'}
            label={'CEST'}
            value={values.CEST}
            onChange={handleChange}
            fullWidth
            error={!!errors.CEST && touched.CEST}
            helperText={touched.CEST && errors.CEST}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <UITextField
            name={'EAN'}
            label={'EAN (Unidade)'}
            value={values.EAN}
            onChange={handleChange}
            fullWidth
            error={!!errors.EAN && touched.EAN}
            helperText={touched.EAN && errors.EAN}
          />
        </Grid>
      </Grid>
    </form>
  );
}
