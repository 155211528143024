import { ERRORS } from 'utils/Errors';
import * as yup from 'yup';
import { AccessModel } from 'types/models';
import { FormProps } from 'shared/components/Inputs';

export interface DistributorFormProps extends FormProps<AccessModel> {
  access?: AccessModel;
}

export type DistributorFormRes = Omit<
  AccessModel,
  | 'balance'
  | 'employee'
  | 'distributorId'
  | 'employeeId'
  | 'lastSee'
  | 'lastSeen'
>;

export const CreateDistributorSchema = yup.object().shape({
  distributor: yup.object().shape({
    name: yup.string().required(ERRORS.REQUIRED_FIELD),
    telephone: yup.string().required(ERRORS.REQUIRED_FIELD),
    contact: yup.string().required(ERRORS.REQUIRED_FIELD),
    regional: yup.string().required(ERRORS.REQUIRED_FIELD),
    socialName: yup.string().required(ERRORS.REQUIRED_FIELD),
    cnpj: yup.string().required(ERRORS.REQUIRED_FIELD),
    sold: yup.string().required(ERRORS.REQUIRED_FIELD),
    uf: yup.string().required(ERRORS.REQUIRED_FIELD),
    city: yup.string().required(ERRORS.REQUIRED_FIELD)
  }),
  email: yup
    .string()
    .email(ERRORS.EMAIL_INVALID)
    .required(ERRORS.REQUIRED_FIELD)
});
