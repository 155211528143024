import React from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';

export type RouteWrapperProps = RouteProps & {
  key?: string;
  isPrivate?: boolean;
};

export function RouteWrapper({ isPrivate, ...rest }: RouteWrapperProps) {
  const history = useHistory();

  const token = localStorage.getItem('token');

  if ((isPrivate && token) || !isPrivate) {
    return <Route {...rest} />;
  }

  history.push('/login');
  return <></>;
}
