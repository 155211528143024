import { ERRORS } from 'utils/Errors';
import * as yup from 'yup';
import { AccessModel } from 'types/models';

export const CreateEmployeeSchema = yup.object().shape({
  employee: yup.object().shape({
    name: yup.string().required(ERRORS.REQUIRED_FIELD),
    lastName: yup.string().required(ERRORS.REQUIRED_FIELD),

    accessLevel: yup.string().required(ERRORS.REQUIRED_FIELD),
    regional: yup.string().required(ERRORS.REQUIRED_FIELD),
    sap: yup.string().required(ERRORS.REQUIRED_FIELD)
  }),
  email: yup
    .string()
    .email(ERRORS.EMAIL_INVALID)
    .required(ERRORS.REQUIRED_FIELD)
});

export interface AddEmployeeValues {
  name: string;
  lastName: string;
  email: string;
  accessLevel: string;
  regional: string;
  sap: string;
}

export type CreateEmployeeFormRes = Omit<
  AccessModel,
  | 'balance'
  | 'distributor'
  | 'distributorId'
  | 'employeeId'
  | 'lastSee'
  | 'lastSeen'
>;
