export const ProcessDocuments = [
  'Edital',
  'Notificação',
  'Contrato Homologado',
  'Ata de Registro',
  'Nota Fiscal',
  'Autorização de Fornecimento',
  'Cotação Hospitalar',
  'E-mail de aceite dos valores',
  'Outros'
];
