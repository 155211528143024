import { Button, Grid } from '@mui/material';
import { CreateEmployeeForm } from 'modules/Admin/Users/Employee/Forms/CreateEmployee';
import { CreateEmployeeFormRes } from 'modules/Admin/Users/Employee/Forms/CreateEmployee/domain';
import { MakeCreateEmployee } from 'modules/Admin/Users/Employee/Fragment/CreateEmployee/domain/CreateEmployeeDomain';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import history from 'routes/History';
import { usePost, useQuery } from 'services';
import UIPaper from 'shared/components/Paper';
import { AccessModel, EmployeeModel } from 'types/models';
import { CatchAccessErrorValidate } from 'utils/Functions/Validate/AxiosAccessValidate';

export function CreateEmployeeFragment() {
  const [, fetchCreateUser, , error] = usePost<MakeCreateEmployee>('/access', {
    willFail: true
  });
  // I Needed to do that because of API.
  const [, fetchEmployee] = useQuery<EmployeeModel[], EmployeeModel>(
    '/employee',
    {
      fetchOnInitialize: false,
      $limit: -1
    }
  );
  const [, fetchAccess] = useQuery<AccessModel[], AccessModel>('/access', {
    fetchOnInitialize: false,
    $limit: -1
  });

  const handleSuccess = (message: string) => {
    toast.success(message);
    history.push('/usuario');
  };

  const handleCreateEmployee = async (data: CreateEmployeeFormRes) => {
    const password = `${data.employee?.name}-${data.employee?.sap}`;
    const hasEmployee = await fetchEmployee({
      sap: data.employee?.sap
    });
    const hasEmail = await fetchAccess({
      email: data.email
    });
    if (hasEmployee?.length === 0 && hasEmail?.length === 0) {
      if (data.employee && data.email) {
        const response = await fetchCreateUser({
          employee: data.employee,
          email: data?.email,
          password
        });

        if (response) {
          handleSuccess('Usuário criado com sucesso');
        }
      }
    } else {
      if (hasEmployee?.length !== 0) {
        toast.error('SAP em uso.');
      }
      if (hasEmail?.length !== 0) {
        toast.error('E-mail em uso.');
      }
    }
  };

  useEffect(() => {
    if (error) {
      CatchAccessErrorValidate(error);
    }
  }, [error]);

  const handleCompleteEmployeeForm = async (data: CreateEmployeeFormRes) => {
    await handleCreateEmployee(data);
  };

  return (
    <Grid container>
      <UIPaper title="Cadastrar Usuário Interno">
        <>
          <CreateEmployeeForm onComplete={handleCompleteEmployeeForm} />
          <Grid item container justifyContent={'flex-end'} mt={3} xs={12}>
            <Grid item container justifyContent={'flex-end'} sm={3} xs={12}>
              <Button
                type={'submit'}
                fullWidth
                form="employee-form"
                variant={'contained'}
              >
                Concluir
              </Button>
            </Grid>
          </Grid>
        </>
      </UIPaper>
    </Grid>
  );
}
