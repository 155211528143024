import { Grid, Typography, Box } from '@mui/material';
import React from 'react';
import { theme } from 'shared/theme';

type DescriptionStepProps = {
  step: number;
  description: string;
  title: string;
  active?: boolean;
};

export function DescriptionStep({
  title,
  description,
  step,
  active = false
}: DescriptionStepProps) {
  const contentStyle = (isActive: boolean) => {
    if (isActive) {
      return {
        bgcolor: theme.colors.Primary,
        color: theme.colors.White,
        descriptionColor: theme.colors.Primary
      };
    }
    return {
      bgcolor: `${theme.colors.Primary}0D`,
      color: theme.colors.Primary,
      descriptionColor: theme.colors.TextDarkGrey
    };
  };
  return (
    <Grid item container xs={12} md gap={1}>
      <Grid
        item
        container
        height="34px"
        width="34px"
        justifyContent="center"
        alignItems="center"
        bgcolor={contentStyle(active).bgcolor}
        color={contentStyle(active).color}
        borderRadius={100}
      >
        {step}
      </Grid>
      <Grid item xs>
        <Typography
          variant="h4"
          style={{
            fontWeight: theme.font.semiBold,
            color: contentStyle(active).descriptionColor
          }}
        >
          {title}
        </Typography>
        <Box mb={1} />
        <Typography
          variant="h5"
          style={{
            color: contentStyle(active).descriptionColor
          }}
        >
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
}
