import React from 'react';
import { Box, Grid } from '@mui/material';
import * as S from 'shared/components/Tables/ToolbarTable/styled';

interface ToolbarProps {
  title?: JSX.Element | string;
  children?: JSX.Element;
  elementsTopEnd?: JSX.Element;
  elementsTopStart?: JSX.Element;
  height?: string;
  noStart?: boolean;
}

export function UIToolbar({
  title,
  children,
  elementsTopEnd,
  elementsTopStart,
  height = '36px',
  noStart = false
}: ToolbarProps) {
  return (
    <S.StyledPaper>
      <S.SectionTableTop>
        <Grid container justifyContent="space-between" alignItems="center">
          {!noStart ? (
            <Grid item xs={5}>
              <S.SectionTopStart>
                <Grid
                  item
                  xs={12}
                  style={{
                    height,
                    alignItems: 'center',
                    display: 'flex'
                  }}
                >
                  {title && title}
                </Grid>
                <Grid item xs={6}>
                  <Box pl={2}>{elementsTopStart}</Box>
                </Grid>
              </S.SectionTopStart>
            </Grid>
          ) : null}

          <Grid container item justifyContent="flex-end" xs>
            <S.SectionTopEnd>{elementsTopEnd}</S.SectionTopEnd>
          </Grid>
        </Grid>
      </S.SectionTableTop>
      {children}
    </S.StyledPaper>
  );
}
