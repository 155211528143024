import { Typography } from '@mui/material';
import React from 'react';
import { UIBreadcrumb, UILink } from 'shared/components';

type Props = {
  edit: boolean;
};

export function ProductBreadcrumb({ edit }: Props) {
  return (
    <UIBreadcrumb>
      <UILink to={'/dashboard'}> Dashboard </UILink>
      <UILink to={'/admin/produtos'}> Produtos </UILink>
      {edit ? (
        <Typography> Editar Produto </Typography>
      ) : (
        <Typography> Cadastrar Produto </Typography>
      )}
    </UIBreadcrumb>
  );
}
