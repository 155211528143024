import React from 'react';
import { UIBreadcrumb } from 'shared/components/Breadcrumb';
import { Typography } from '@mui/material';
import { UILink } from 'shared/components/Link';

export function EmployeeBreadcrumb() {
  return (
    <UIBreadcrumb>
      <UILink to={'/dashboard'}> Dashboard </UILink>
      <Typography> Editar Informações </Typography>
    </UIBreadcrumb>
  );
}
