import { Box, Typography } from '@mui/material';
import React from 'react';
import { UILink } from 'shared/components';
import { UIBreadcrumb } from 'shared/components/Breadcrumb';

interface InformResultBreadcrumbParams {
  id: string;
}

export function InformResultBreadcrumb({ id }: InformResultBreadcrumbParams) {
  return (
    <Box mb={4}>
      <UIBreadcrumb>
        <UILink to={'/'} color={'inherit'}>
          Dashboard
        </UILink>
        <UILink to={`/processo/${id}`} color={'inherit'}>
          Processo
        </UILink>
        <Typography color={'primary'}>Informar Resultado </Typography>
      </UIBreadcrumb>
    </Box>
  );
}
