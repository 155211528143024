import React from 'react';
import {
  Badge,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Popover,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useAuth } from 'Providers/Auth';
import { usePatch, useQuery } from 'services';
import { Paginated } from 'types/services';
import { PendenciesModel } from 'types/models';
import EmailIcon from '@mui/icons-material/Email';
import history from 'routes/History';
import { useAnchor } from 'hooks';

export function Notifications() {
  const { auth } = useAuth();

  const { anchorEl, handleClick, handleClose } = useAnchor();

  const [getPendencies, resendPendencies] = useQuery<
    Paginated<PendenciesModel>
  >('/pendencies', {
    $limit: 50,
    distributorId: auth?.distributorId,
    employeeId: auth?.employeeId,
    read: false,
    '$sort[createdAt]': -1
  });

  const [, updatePendency] = usePatch<PendenciesModel>('/pendencies');

  const pendencys = getPendencies?.data || [];

  const handleReadPendency = async (readNotification: PendenciesModel) => {
    history.push(`/processo/${readNotification.processId}`, {
      id: readNotification.processId
    });
    await updatePendency({ read: true }, readNotification.id);
    await resendPendencies();
  };

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <>
      <IconButton onClick={handleClick} size="large">
        <Badge
          badgeContent={pendencys.length}
          color={matches ? 'primary' : 'secondary'}
        >
          <NotificationsIcon color={matches ? 'primary' : 'secondary'} />
        </Badge>
      </IconButton>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        elevation={1}
      >
        <Box
          pt={2}
          pl={2}
          pb={2}
          style={{
            position: 'fixed',
            zIndex: 1,
            backgroundColor: 'white',
            width: '100%'
          }}
        >
          <Typography variant={'h2'} color={'primary'}>
            Notificações
          </Typography>
        </Box>
        <Box pt={5}>
          <Paper
            style={{
              maxHeight: '200px',
              width: '40ch',
              overflow: 'scroll',
              overflowX: 'hidden'
            }}
            elevation={0}
          >
            <List>
              {pendencys.map((notification) => (
                <ListItem
                  button
                  key={notification?.id}
                  onClick={() => handleReadPendency(notification)}
                >
                  <ListItemAvatar>
                    <EmailIcon color={'primary'} />
                  </ListItemAvatar>
                  <ListItemText>
                    <Typography variant={'h4'}>{notification?.text}</Typography>
                    <Typography
                      variant={'h5'}
                      component={'span'}
                      color={'primary'}
                    >
                      PNE: {notification?.process?.pne} -
                    </Typography>
                    <Typography variant={'h5'} component={'span'}>
                      {notification?.process?.tradingCode} -{' '}
                    </Typography>

                    <Typography variant={'h5'} component={'span'}>
                      {notification?.process?.city}
                    </Typography>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </Paper>
        </Box>
      </Popover>
    </>
  );
}
