import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { theme } from 'shared/theme';
import { AccessModel } from 'types/models';

export type ReturnUserModalProps = {
  onClose: () => void;
  onSubmit: () => void;
  type: string;
  open: boolean;
  access: AccessModel;
};

export function ReturnUserModal({
  open,
  type,
  onSubmit,
  onClose,
  access
}: ReturnUserModalProps) {
  const content = {
    'return vacancy': {
      title: 'Suspender período de férias',
      subtitle:
        'Deseja realmente suspender o período de férias do usuário abaixo?',
      buttonTitle: 'Suspender'
    },
    inactive: {
      title: 'Reativar usuário',
      subtitle: 'Deseja realmente reativar usuário abaixo?',
      buttonTitle: 'Reativar'
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Typography
          variant="h4"
          style={{
            fontWeight: theme.font.semiBold,
            color: theme.colors.TextDarkGrey
          }}
        >
          {content[type]?.title ?? '-'}
        </Typography>
        <IconButton aria-label={'close'} onClick={onClose} size="large">
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container md={12}>
          <Typography variant="h4">{content[type]?.subtitle ?? '-'}</Typography>
        </Grid>
        <Grid container md={12} mt={3}>
          <Typography
            variant="h4"
            style={{
              color: theme.colors.TextDarkGrey,
              fontWeight: theme.font.semiBold
            }}
          >
            E-mail: {access?.email ?? '-'}
          </Typography>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          item
          container
          direction={'row'}
          justifyContent={'flex-end'}
          xs={12}
        >
          <Grid item style={{ marginRight: '20px' }}>
            <Button variant="outlined" onClick={onClose} color={'primary'}>
              Voltar
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color={'primary'}
              onClick={() => onSubmit()}
            >
              {content[type]?.buttonTitle ?? '-'}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
