import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';

export const useFab = makeStyles(() => ({
  fab: {
    position: 'fixed',
    bottom: useTheme().spacing(2),
    right: useTheme().spacing(2)
  },
  fabCentered: {
    position: 'fixed',
    bottom: useTheme().spacing(2),
    left: useTheme().breakpoints.values.lg
  }
}));
