import { Box, Typography } from '@mui/material';
import React from 'react';
import { theme } from 'shared/theme';

type InformDiscountTitleProps = {
  title: string;
  subtitle: string;
};

export default function InformDiscountTitle({
  title,
  subtitle
}: InformDiscountTitleProps) {
  return (
    <>
      <Box mt={3} />
      <Typography
        variant="h2"
        style={{
          fontSize: theme.font.sizes.large
        }}
      >
        {title}
      </Typography>
      <Box mt={2} />
      <Typography
        variant="h3"
        style={{
          fontSize: theme.font.sizes.medium,
          color: theme.colors.TextGrey
        }}
      >
        {subtitle}
      </Typography>
      <Box mt={1} />
      <Typography
        variant="h3"
        style={{
          fontSize: theme.font.sizes.medium,
          color: theme.colors.TextGrey
        }}
      >
        Dê dois cliques para informar o desconto.
      </Typography>
      <Box mt={1} />
    </>
  );
}
