export const ReportsType = [
  {
    value: 'basic',
    text: 'Básico sem produtos'
  },
  {
    value: 'basic-with-products',
    text: 'Básico com produtos'
  }
];
