/* eslint-disable react-hooks/exhaustive-deps */
import { Container, Grid, Typography } from '@mui/material';
import { EmployeeBreadcrumb } from 'modules/Profiles/employee/breadcrumb';
import { ProfileEmployeeFragment } from 'modules/Profiles/employee/fragments/ProfileEmployeeFragment';
import { SyncEmployeeCityFragment } from 'modules/Profiles/employee/fragments/SyncEmployeeCityFragment';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'services';
import { theme } from 'shared/theme';
import { AccessModel } from 'types/models';
import { UrlParams } from 'types/services';

export function EmployeeUpdate() {
  const params = useParams() as UrlParams;
  const id = params?.id;

  const [access] = useQuery<AccessModel, AccessModel>(`/access/${id}`);

  return (
    <Container maxWidth={'lg'}>
      <EmployeeBreadcrumb />
      <Grid container pt={3} pl={2} alignItems="center">
        <Typography
          variant="h2"
          style={{
            color: theme.colors.TextDarkGrey,
            fontWeight: theme.font.semiBold
          }}
        >
          Editar Informações
        </Typography>
      </Grid>
      <ProfileEmployeeFragment access={access || {}} />
      {access?.employee?.accessLevel === 'consultant' ? (
        <SyncEmployeeCityFragment access={access || {}} />
      ) : null}
    </Container>
  );
}
