export const PT = {
  financier: 'Financeiro',
  consultant: 'Consultor',
  distributor: 'Distribuidor',
  supervisor: 'Supervisor',
  manager: 'Gerente',
  admin: 'Administrador',
  Admin: 'Administrador',
  processStatus: {
    suspended: { value: 'Suspenso', color: 'red' },
    suspending: { value: 'Suspendendo', color: 'red' },
    'total win': { value: 'Ganho total', color: 'green' },
    'parcial win': { value: 'Ganho parcial', color: 'blue' },
    'dist recording': {
      value: 'Distribuidor Cadastrando',
      color: 'orange'
    },
    'const recording': { value: 'Consultor Cadastrando', color: 'orange' },
    'awaiting financier': { value: 'Aguard. Financeiro', color: 'blue' },
    'waiting to confirm consultant values': {
      value: 'Aguard. Consultor',
      color: 'orange'
    },
    'awaiting manager': { value: 'Aguard. Gerente', color: 'blue' },
    'awaiting order': { value: 'Em andamento', color: 'green' },
    lost: { value: 'Perda Total', color: 'red' },
    'awaiting consultant result': {
      value: 'Aguard. Resultado Consultor',
      color: 'orange'
    },
    'awaiting distributor result': {
      value: 'Aguard. Resultado Distribuidor',
      color: 'orange'
    },
    default: { value: '-', color: 'default' },
    finished: { value: 'Finalizado', color: 'blue' }
  },
  discountOrderStatus: {
    requested: { value: 'Desconto Solicitado', color: 'orange' },
    contested: { value: 'Desconto Contestado', color: 'orange' },
    approved: { value: 'Desconto Aprovado', color: 'green' },
    denied: { value: 'Desconto Negado', color: 'red' },
    default: {
      value: '-',
      color: 'default'
    }
  },
  segments: {
    public: 'Público',
    private: 'Privado'
  }
};

export default PT;
