import React, { ReactNode } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Breadcrumbs } from '@mui/material';

export function UIBreadcrumb({ children }: { children: ReactNode[] }) {
  return (
    <Breadcrumbs separator={<ChevronRightIcon />} aria-label="breadcrumb">
      {children}
    </Breadcrumbs>
  );
}
