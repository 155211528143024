import styled from 'styled-components';
import { DataGrid } from '@mui/x-data-grid';

type TableAdditionalParams = {
  headerColor?: string;
  headerFontColor?: string;
  headerFontSize?: string;
};

export const Table = styled(DataGrid)<TableAdditionalParams>`
  margin-bottom: 10px;
  border-radius: initial;
  font-size: 14px !important;
  border: initial;
  border-bottom: initial;

  .MuiDataGrid-window::-webkit-scrollbar-track {
    background-color: #f4f4f4;
  }
  .MuiDataGrid-window::-webkit-scrollbar {
    background: #e5e5e5;
  }
  .MuiDataGrid-window::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 0px 0px 4px 4px;
  }
  .MuiDataGrid-window::-webkit-scrollbar-track-piece {
    background: #e5e5e5;
  }

  .MuiDataGrid-root,
  .MuiDataGrid-colCell:focus,
  .MuiDataGrid-cell:focus,
  .MuiDataGrid-cell:focus-within {
    outline: none !important;
  }
  .MuiDataGrid-row {
    border-left: 5px solid transparent !important;
    border-bottom: 1px solid #e5e5e5;
  }

  .MuiDataGrid-columnSeparator {
    display: none !important;
  }

  .MuiDataGrid-colCellWrapper {
    background-color: #ffffff;
    color: #b11ada;
  }

  .MuiDataGrid-columnHeader {
    background-color: ${(params) =>
      params?.headerColor ?? '#5082c9'} !important;
    color: ${(params) => params?.headerFontColor ?? '#ffffff'} !important;
    font-size: ${(params) => params?.headerFontSize ?? '12px'};
  }

  .MuiDataGrid-cell {
    border-bottom: initial;
  }

  .MuiDataGrid-main,
  .MuiDataGrid-columnsContainer,
  .MuiDataGrid-root {
    background-color: white !important;
    border-radius: initial;
    color: #565656;
    font-size: 12px;
    font-weight: 600;
    border: initial;
  }
`;
