import React from 'react';
import { Skeleton } from '@mui/lab';
import { Box } from '@mui/material';

export function LoadingActions() {
  return (
    <Box>
      <Skeleton
        sx={{ width: { xs: 1, md: 276 } }}
        variant="rectangular"
        height={156}
        animation="wave"
      />
    </Box>
  );
}
