import React from 'react';

import * as S from './styles';
import { STypography } from './styles';

export interface UITextProps {
  text?: string;
  textColor?: string;
  fontSize?: string;
  fontWeight?: string;
  textAlign?: string;
  textOverflow?: string;
  overflow?: string;
  whiteSpace?: string;
  onClick?: () => void;
  cursor?: string;
}

export default function UIText({
  text,
  textColor,
  fontSize,
  fontWeight,
  textAlign,
  textOverflow,
  overflow,
  whiteSpace,

  onClick = () => {},
  cursor = 'default'
}: STypography) {
  return (
    <S.Typography
      textColor={textColor}
      fontSize={fontSize}
      fontWeight={fontWeight}
      textAlign={textAlign}
      textOverflow={textOverflow}
      overflow={overflow}
      whiteSpace={whiteSpace}
      onClick={onClick}
      cursor={cursor}
    >
      {text}
    </S.Typography>
  );
}
