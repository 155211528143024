/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Autocomplete, Grid, MenuItem, TextField } from '@mui/material';
import { FormProps, UITextField } from 'shared/components/Inputs';
import { useFormik } from 'formik';
import { AccessModel } from 'types/models';
import { useCities } from 'hooks/useCities';
import { States, STATES_ARRAY } from 'utils/Enums';
import { UpdateDistributor, UpdateDistributorSchema } from './domain';

export interface DistributorProfileFormProps
  extends FormProps<UpdateDistributor> {
  access?: AccessModel;
  isAdmin?: boolean;
}

export function DistributorProfileForm({
  access,
  isAdmin,
  onComplete
}: DistributorProfileFormProps) {
  const { citiesData } = useCities();
  const {
    values,
    handleChange,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    handleSubmit,
    resetForm
  } = useFormik<UpdateDistributor>({
    validationSchema: UpdateDistributorSchema,
    validateOnBlur: true,
    initialValues: {
      email: access?.email || '',
      distributor: access?.distributor || {
        name: '',
        socialName: '',
        telephone: '',
        contact: '',
        cnpj: '',
        uf: '',
        city: ''
      },
      password: '',
      confirmPassword: ''
    },
    onSubmit: (fields) => {
      const data = fields;
      if (!fields.confirmPassword || !fields.password) {
        delete data.password;
        delete data.confirmPassword;
      }
      onComplete(data);
    }
  });

  useEffect(() => {
    if (access) {
      resetForm({
        values: {
          email: access?.email || '',
          distributor: access?.distributor || {
            name: '',
            socialName: '',
            telephone: '',
            contact: '',
            cnpj: '',
            uf: '',
            city: ''
          },
          password: '',
          confirmPassword: ''
        }
      });
    }
  }, [access]);

  return (
    <form id="profile-update" onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <UITextField
            label={'Nome'}
            name={'distributor.name'}
            value={values?.distributor?.name}
            onChange={handleChange}
            disabled={!isAdmin}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <UITextField
            label={'Razão social'}
            name={'distributor.socialName'}
            value={values?.distributor?.socialName}
            onChange={handleChange}
            disabled={!isAdmin}
            error={
              touched.distributor?.socialName &&
              !!errors.distributor?.socialName
            }
            helperText={
              touched.distributor?.socialName && errors.distributor?.socialName
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <UITextField
            label={'Email'}
            name={'email'}
            value={values?.email}
            onChange={handleChange}
            disabled={!isAdmin}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <UITextField
            label={'Telefone'}
            pattern="phone"
            name={'distributor.telephone'}
            value={values?.distributor?.telephone}
            onChange={handleChange}
            disabled={!isAdmin}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <UITextField
            label={'Contato'}
            name={'distributor.contact'}
            value={values?.distributor?.contact}
            onChange={handleChange}
            disabled={!isAdmin}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <UITextField
            label={'CNPJ'}
            name={'distributor.cnpj'}
            value={values?.distributor?.cnpj}
            pattern="cnpj"
            onChange={handleChange}
            disabled={!isAdmin}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <UITextField
            name={'distributor.uf'}
            value={values.distributor?.uf?.toUpperCase()}
            label={'Estado'}
            onChange={handleChange}
            error={touched.distributor?.uf && !!errors.distributor?.uf}
            helperText={touched.distributor?.uf && errors.distributor?.uf}
            onBlur={handleBlur}
            disabled={!isAdmin}
            select
            fullWidth
          >
            {STATES_ARRAY.map(({ uf }) => (
              <MenuItem value={uf} key={uf}>
                {States[uf]}
              </MenuItem>
            ))}
          </UITextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            renderInput={(props) => (
              <TextField
                {...props}
                name={'distributor.city'}
                label={'Cidade'}
                error={!!errors.distributor?.city && touched.distributor?.city}
                helperText={
                  touched.distributor?.city && errors.distributor?.city
                }
                onBlur={handleBlur}
              />
            )}
            options={citiesData.filter(
              (city) => city.uf === values.distributor?.uf?.toUpperCase()
            )}
            value={
              values.distributor?.city
                ? citiesData.find(
                    (option) => option.city === values.distributor?.city
                  )
                : null
            }
            getOptionLabel={(option) => option.city}
            onChange={(_, value) => {
              _.preventDefault();
              if (value && value.city) {
                setFieldValue('distributor.city', value.city);
              } else {
                setFieldValue('distributor.city', null);
              }
            }}
            fullWidth
            loading
            disabled={!isAdmin}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <UITextField
            name={'password'}
            label={'Senha'}
            onChange={handleChange}
            type="password"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <UITextField
            name={'confirmPassword'}
            label={'Confirmar Senha'}
            onChange={handleChange}
            error={touched.confirmPassword && !!errors.confirmPassword}
            helperText={touched.confirmPassword && errors.confirmPassword}
            type="password"
          />
        </Grid>
      </Grid>
    </form>
  );
}
