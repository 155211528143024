import { Button, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ProcessItemMinimalFormResponse } from 'modules/Process/PublicProcess/Forms/ScopeRegistration/minimal/domain';
import { ProcessItemModal } from 'modules/Process/PublicProcess/Modals/ProcessItem/Complete';
import { ProcessItemMinimalModal } from 'modules/Process/PublicProcess/Modals/ProcessItem/Minimal';
import { ProcessItemsTable } from 'modules/Process/shared/Tables/ProcessItems';
import { ProcessItemsTableProps } from 'modules/Process/shared/Tables/ProcessItems/ProcessItems';
import React, { useState } from 'react';
import { UIDataGridApiPagination } from 'shared/components/Tables/DataGridApiPagination';
import { UIToolbar } from 'shared/components/Tables/ToolbarTable';
import { OcrProducts, ProcessProductModel } from 'types/models';

type PartialProcessProductModel = Partial<ProcessProductModel>;

interface ProcessItemFormTableProps {
  onAddProduct: (data: PartialProcessProductModel) => void;
  onDelete: (id: number) => Promise<void>;
  onEditProduct: (
    data: PartialProcessProductModel,
    newArray: PartialProcessProductModel[]
  ) => void;
  processItemData: PartialProcessProductModel[];
  tableProps?: ProcessItemsTableProps;
  isDistributor?: boolean;
  processId: number;
  ocrProducts?: OcrProducts[];
}

const useStyles = makeStyles({
  root: {
    '& .super-app-theme--winner-approve': {
      backgroundColor: 'rgba(101, 172, 40, 0.25)'
    },
    '& .super-app-theme--not-approve': {
      backgroundColor: 'rgba(201, 80, 80, 0.16)'
    },
    '& .super-app-theme--not-financier': {
      backgroundColor: 'rgba(80, 183, 201, 0.16)'
    },
    '& .super-app-theme--not-concluded': {
      backgroundColor: 'rgba(255, 148, 9, 0.16)'
    }
  }
});

export function ProcessItemFormTable({
  onAddProduct,
  onEditProduct,
  processItemData,
  tableProps,
  isDistributor = false,
  processId,
  onDelete,
  ocrProducts
}: ProcessItemFormTableProps) {
  const dgClasses = useStyles();

  const [handleCreate, setHandleCreate] = useState(false);
  const [editItem, setEditItem] = useState<Partial<ProcessProductModel>>();

  const onHandleCreate = (data: Partial<ProcessProductModel>) => {
    setHandleCreate(false);
    onAddProduct(data);
  };

  const onEditProcessItem = (item: Partial<ProcessProductModel>) => {
    setEditItem(undefined);
    const index = processItemData.findIndex((filterItem) => {
      return String(filterItem.id) === String(item.id);
    });

    if (index !== undefined) {
      const newProcessItems = [...processItemData];
      newProcessItems[index] = { ...item };
      onEditProduct(item, newProcessItems);
    }
  };

  const onCompleteProcessFormModal = (data: ProcessItemMinimalFormResponse) => {
    const processItem = data as Partial<ProcessProductModel>;
    processItem.initialQuantity = processItem.quantity;
    if (!editItem) {
      onHandleCreate(processItem);
    } else {
      processItem.id = editItem.id;
      onEditProcessItem(processItem);
    }
  };

  const handleEditItem = (data: Partial<ProcessProductModel>) => {
    setEditItem(data);
  };

  const handleDelete = (data: Partial<ProcessProductModel>) => {
    if (data.id) {
      onDelete(data.id);
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <div className={dgClasses.root}>
            <ProcessItemsTable
              isDistributor={isDistributor}
              processProduct={processItemData}
              rowHeight={36}
              headerHeight={36}
              pageSize={5}
              actions={[
                {
                  label: 'Editar',
                  onClick: (data) => {
                    handleEditItem(data as Partial<ProcessProductModel>);
                  }
                },
                {
                  label: 'Remover',
                  onClick: (data) => {
                    handleDelete(data as Partial<ProcessProductModel>);
                  }
                }
              ]}
              components={{
                Toolbar: () => (
                  <UIToolbar
                    title={
                      <Typography variant="h2">Itens do processo </Typography>
                    }
                    elementsTopEnd={
                      <>
                        <Grid
                          container
                          item
                          alignItems="center"
                          justifyContent="flex-end"
                          xs={12}
                          p={1}
                        >
                          <Grid container justifyContent="flex-end" item xs>
                            <UIDataGridApiPagination itemSize="small" />
                          </Grid>
                          <Grid container item xs={3}>
                            <Button
                              fullWidth
                              variant="text"
                              onClick={() => setHandleCreate(true)}
                            >
                              Adicionar Item
                            </Button>
                          </Grid>
                        </Grid>
                      </>
                    }
                  />
                )
              }}
              getRowClassName={(params) => {
                if (!params?.row?.nfPrice) {
                  return 'super-app-theme--not-concluded';
                }
                if (params?.row?.discount_orders?.[0]?.id)
                  return `super-app-theme--not-financier`;
                if (params?.row?.approve === false)
                  return `super-app-theme--not-approve`;
                if (params?.row?.approve === true)
                  return `super-app-theme--winner-approve`;
                return '';
              }}
              {...tableProps}
            />
          </div>
        </Grid>
        {isDistributor ? (
          <ProcessItemMinimalModal
            open={handleCreate || !!editItem}
            onHandleComplete={onCompleteProcessFormModal}
            onClose={() => {
              setEditItem(undefined);
              setHandleCreate(false);
            }}
            processItem={editItem}
          />
        ) : (
          <ProcessItemModal
            open={handleCreate || !!editItem}
            onComplete={onCompleteProcessFormModal}
            onClose={() => {
              setEditItem(undefined);
              setHandleCreate(false);
            }}
            processItem={editItem}
            processId={processId}
            ocrProducts={
              (ocrProducts?.length || 0) > 0 ? ocrProducts : undefined
            }
          />
        )}
      </Grid>
    </>
  );
}
