import styled from 'styled-components';
import CancelIcon from '@mui/icons-material/Cancel';
import { ThemeColors } from 'shared/enum';

export const CardBox = styled.div`
  min-height: 70px;
  background: rgba(201, 80, 80, 0.16);
  border-left: 3px solid ${({ theme }) => theme.colors.Error};
  border-radius: ${({ theme }) => theme.border.radius.medium};
  padding: 0.3rem;
`;
export const Title = styled.p`
  font-weight: 600;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.Error};
`;
export const Message = styled.p`
  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.Error};
`;

export const ErrorIcon = styled(CancelIcon)`
  font-size: 4em !important;
  width: 100%;
  color: ${ThemeColors.Error};
  display: block;
`;
