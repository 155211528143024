/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable no-await-in-loop */
import { useState } from 'react';
import { api } from 'services';
import { DownloadFile } from 'utils/Functions/Download/DownloadFile';
import { sleep } from 'utils/Functions/Sleep';

export type ReportOptions = {
  'createdAt[$gte]': string | number;
  'createdAt[$lte]': string | number;
} & Record<string, any>;

export type FetchUseReport = (
  type: string,
  params: ReportOptions,
  filename: string
) => Promise<void>;

export type FetchFindReport = (
  hashId: string,
  times: number,
  filename: string
) => Promise<boolean>;

export type UseReportReturn = [
  boolean,
  FetchUseReport,
  FetchFindReport,
  string | undefined,
  boolean,
  () => void
];

/**
 * @return
 * loading, fetchReport, refetchReport, hashId, hadError
 */
export default function useReport(): UseReportReturn {
  const [errorReport, setErrorReport] = useState(false);
  const [hashId, setHashId] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  async function FindReport(
    searchHashId: string,
    times: number,
    filename: string
  ) {
    try {
      setLoading(true);
      let founded = false;
      for (let index = 1; index < times; index += 1) {
        await sleep(10000);
        const response = await api.get('/report-log', {
          params: {
            hashId: searchHashId
          }
        });
        if (response.data.data.length > 0) {
          console.log(
            '[report] Founded Report in: ',
            index,
            'tries, in a total of ',
            times
          );
          founded = true;
          await DownloadFile(response.data.data[0].url, filename);
          index = times + 2;
          setErrorReport(false);
          setHashId(undefined);
        } else
          console.log(
            '[report] Not founded try number ',
            index,
            ' in a total of ',
            times
          );
      }
      if (!founded) {
        setErrorReport(true);
      }
      setLoading(false);
      return founded;
    } catch (error) {
      setErrorReport(true);
      setLoading(false);
      return false;
    }
  }

  async function ExportReport(
    type: string,
    params: ReportOptions,
    filename: string
  ) {
    try {
      setLoading(true);
      const response = await api.get<{
        hashId: string;
      }>(`report/${type}`, {
        params
      });

      if (response.status === 200) {
        if (response.data.hashId) {
          setHashId(response.data.hashId);
          console.log('[report] hash id: ', response.data.hashId);
          const foundedReport = await FindReport(
            response.data.hashId,
            24,
            filename
          );
          if (!foundedReport) {
            setLoading(false);
            setErrorReport(true);
          }
        } else {
          setLoading(false);
          setErrorReport(true);
        }
      }
    } catch (error) {
      setLoading(false);
      setErrorReport(true);
    } finally {
      setLoading(false);
    }
  }

  function errorClear() {
    setErrorReport(false);
  }

  return [loading, ExportReport, FindReport, hashId, errorReport, errorClear];
}
