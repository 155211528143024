import { FormProps } from 'shared/components/Inputs';
import { ProductsModel } from 'types/models';
import { ERRORS } from 'utils/Errors';
import * as yup from 'yup';

export interface ProductFormProps extends FormProps<ProductsModel> {
  product?: ProductsModel;
}

export const ProductFormSchema = yup.object().shape({
  sku: yup
    .string()
    .required(ERRORS.REQUIRED_FIELD)
    .typeError(ERRORS.REQUIRED_FIELD),
  description: yup
    .string()
    .required(ERRORS.REQUIRED_FIELD)
    .typeError(ERRORS.REQUIRED_FIELD),
  grammage: yup.number().nullable().typeError('Digite um número válido'),
  businessUnity: yup.string().nullable(),
  fiscalClassification: yup
    .string()
    .required(ERRORS.REQUIRED_FIELD)
    .typeError(ERRORS.REQUIRED_FIELD),
  category: yup.string().nullable(),
  CEST: yup.string().nullable(),
  EAN: yup
    .string()
    .required(ERRORS.REQUIRED_FIELD)
    .typeError(ERRORS.REQUIRED_FIELD)
});
