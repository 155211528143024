import React, { useEffect, useState } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { TableTranslate } from 'shared/components/Tables/TableTranslate/TableTranslate';
import { TableEditOption } from 'shared/components/Tables/TableActions';
import { UITableComponentProps } from 'shared/components/Tables/UITable';

import * as S from './styled';

type UITableProps = {
  headerColor?: string;
  headerFontColor?: string;
  headerFontSize?: string;
} & UITableComponentProps;

export function UITable({
  pageSize = 10,
  paginationMode = 'client',
  columns,
  actions,
  actionsPosition = 'initial',
  headerColor,
  headerFontColor,
  headerFontSize,
  showActions,
  ...rest
}: UITableProps) {
  // I need to do that because react was updating the state on an unmounted component
  // Time lost bc of this bug: 1 hour and 13 minutes
  const newColumns = [...columns];
  const [tableRendered, setTableRendered] = useState(false);

  useEffect(() => {
    const slider = document.querySelector(
      '.MuiDataGrid-window'
    ) as HTMLDivElement;
    let isDown = false;
    let startX: number;
    let scrollLeft: number;
    const scrollSpeed = 1;

    slider?.addEventListener('mousedown', (e) => {
      isDown = true;
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });
    slider?.addEventListener('mouseleave', () => {
      isDown = false;
    });
    slider?.addEventListener('mouseup', () => {
      isDown = false;
    });
    slider?.addEventListener('mousemove', (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * scrollSpeed; // scroll-fast
      slider.scrollLeft = scrollLeft - walk;
    });
  }, [tableRendered]);

  if (actions) {
    const actionColumn: GridColDef = {
      field: '',
      headerName: '-',
      disableColumnMenu: true,
      width: 60,
      align: 'right',
      renderCell: (gridCell) =>
        TableEditOption({ showActions, actions, ...gridCell })
    };
    if (actionsPosition === 'initial') {
      newColumns.unshift(actionColumn);
    } else {
      newColumns.push(actionColumn);
    }
  }

  return (
    <S.Table
      localeText={TableTranslate}
      paginationMode={paginationMode}
      onRowEnter={() => setTableRendered(true)}
      pageSize={pageSize}
      columns={newColumns.map((column) => ({
        ...column,
        sortable: false,
        headerAlign: 'center',
        align: 'center'
      }))}
      autoHeight
      // disableColumnResize
      disableColumnSelector
      disableColumnFilter
      disableColumnMenu
      disableSelectionOnClick
      hideFooter
      headerColor={headerColor}
      headerFontColor={headerFontColor}
      headerFontSize={headerFontSize}
      {...rest}
    />
  );
}
