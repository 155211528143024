import React from 'react';
import { Tooltip } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { MoneyMask } from 'utils/masks';

export const OrderItemsColumns: GridColDef[] = [
  { field: 'id', headerName: 'ID', hide: true },
  {
    field: 'process_products.itemNumber',
    headerName: 'Item',
    valueFormatter: ({ value }) => value || '-',
    width: 80,
    valueGetter: ({ row }) => row?.process_product?.itemNumber
  },
  {
    field: 'process_products.product.sku',
    headerName: 'SKU',
    valueGetter: ({ row }) => row?.process_product?.product?.sku,
    valueFormatter: ({ value }) => value ?? '-',
    width: 100
  },
  {
    field: 'process_products.description',
    headerName: 'Descritivo',
    valueFormatter: ({ value }) => value ?? '-',
    width: 200,
    renderCell: ({ row }: GridRenderCellParams) => (
      <>
        <Tooltip title={row?.process_product?.specification}>
          <span className="table-cell-trucate">
            {row?.process_product?.specification}
          </span>
        </Tooltip>
      </>
    )
  },
  {
    field: 'order_item.observation',
    headerName: 'Observação',
    valueFormatter: ({ value }) => value ?? '-',
    width: 220,
    renderCell: ({ row }: GridRenderCellParams) => (
      <>
        <Tooltip title={row?.observation}>
          <span className="table-cell-trucate">{row?.observation}</span>
        </Tooltip>
      </>
    )
  },
  {
    field: 'process_products.nfPrice',
    headerName: '(R$) Preço NF nestlé',
    width: 150,
    valueFormatter: ({ value }) => (value ? MoneyMask(`${value}`) : '-'),
    valueGetter: ({ row }) => row?.process_product?.nfPrice
  },
  {
    field: 'quantity',
    headerName: 'Quantidade Solicitada',
    width: 150,
    valueFormatter: ({ value }) => value ?? '-',
    valueGetter: ({ row }) => row?.quantity
  },
  {
    field: 'total',
    headerName: '(R$) Total',
    width: 150,
    valueFormatter: ({ value }) => (value ? MoneyMask(`${value}`) : '-'),
    valueGetter: ({ row }) => row?.total,
    flex: 1
  },
  {
    field: 'process_products.quantity',
    headerName: 'Quantidade Atual do item',
    width: 200,
    valueFormatter: ({ value }) => value ?? '-',
    valueGetter: ({ row }) => row?.process_product?.quantity
  }
];
