import React, { useState } from 'react';

export function usePopover() {
  const [popoverAnchorEl, setPopoverAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  const handleClickToPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPopoverAnchorEl(event.currentTarget);
  };
  const handleCloseToPopoverEl = () => {
    setPopoverAnchorEl(null);
  };

  return {
    popoverAnchorEl,
    setPopoverAnchorEl,
    handleClickToPopover,
    handleCloseToPopoverEl
  };
}
