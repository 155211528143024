import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogProps,
  Grid
} from '@mui/material';
import * as S from './styles';

interface ErrorModalParams extends DialogProps {
  title?: string;
  subtitle?: string;
  buttonConfirmText?: string;
  buttonCancelText?: string;
  onConfirm?: () => void;
}

export default function ErrorModal({
  title,
  onClose,
  buttonCancelText = 'Cancelar',
  buttonConfirmText = 'Confirmar',
  subtitle,
  onConfirm,
  ...rest
}: ErrorModalParams) {
  return (
    <Dialog maxWidth={'xs'} {...rest} onClose={onClose} fullWidth>
      <S.Wrapper>
        <Grid
          container
          justifyContent={'center'}
          alignItems={'center'}
          direction={'column'}
        >
          <S.ErrorIcon />
          <S.Title>{title}</S.Title>
          {subtitle && <S.Subtitle>{subtitle}</S.Subtitle>}
        </Grid>

        <DialogActions>
          <Grid
            item
            container
            direction={'row'}
            justifyContent={'space-evenly'}
            xs={12}
          >
            <Grid item xs={4}>
              {onClose && (
                <Button
                  variant="contained"
                  color={'primary'}
                  onClick={(e) => onClose(e, 'escapeKeyDown')}
                  fullWidth
                >
                  {buttonCancelText}
                </Button>
              )}
            </Grid>
            <Grid item xs={4}>
              <Button
                form={'ForgotPasswordForm'}
                variant="outlined"
                color={'primary'}
                type={'submit'}
                fullWidth
                onClick={onConfirm}
              >
                {buttonConfirmText}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </S.Wrapper>
    </Dialog>
  );
}
