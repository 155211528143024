import { OrderModel } from 'types/models/Order/Order.model';

export const GetUiChipContent = (
  selectedOrder?: OrderModel,
  length?: number
): {
  color: 'green' | 'red' | 'blue' | 'orange' | 'default';
  title: string;
} => {
  if (typeof length === 'number') {
    if (length === 0)
      return {
        title: 'Nenhum pedido realizado',
        color: 'red'
      };
  }
  if (selectedOrder?.status === 'Processando')
    return {
      title: 'Processando',
      color: 'green'
    };
  if (selectedOrder?.status === 'Concluido')
    return {
      title: 'Concluído',
      color: 'blue'
    };
  return {
    title: '-',
    color: 'default'
  };
};
