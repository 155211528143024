import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState } from 'react';
import { theme } from 'shared/theme';
import { AccessModel } from 'types/models';
import { UITextField } from 'shared/components/Inputs';
import { add, format } from 'date-fns';

export type ReturnUserModalProps = {
  onClose: () => void;
  onSubmit: (newAccess: AccessModel, period?: number) => void;
  type: string;
  open: boolean;
  access: AccessModel;
  newAccesses: AccessModel[];
  loading: boolean;
};

export function VacancyOrInactiveModal({
  open,
  type,
  onSubmit,
  onClose,
  access,
  newAccesses,
  loading
}: ReturnUserModalProps) {
  const [selectedNewAccess, setSelectedNewAccess] = useState<AccessModel>();
  const [period, setPeriod] = useState<number>();

  const onCancel = () => {
    setSelectedNewAccess(undefined);
    setPeriod(undefined);
    onClose();
  };

  const content = {
    vacancy: {
      title: 'Inserir período de férias',
      subtitle: 'Deseja realmente colocar o usuário abaixo de férias?',
      buttonTitle: 'Confirmar'
    },
    active: {
      title: 'Inativar usuário',
      subtitle: 'Deseja realmente inativar o usuário abaixo?',
      buttonTitle: 'Inativar'
    }
  };

  const GetValuesByType = () => {
    if (access?.distributor) {
      return {
        textValue: selectedNewAccess?.distributor?.sold || '',
        textLabel: 'SOLD',
        username: selectedNewAccess?.distributor?.name || ''
      };
    }
    if (access?.employee) {
      return {
        textValue: selectedNewAccess?.employee?.sap || '',
        textLabel: 'SAP',
        username:
          `${selectedNewAccess?.employee?.name || ''} ${
            selectedNewAccess?.employee?.lastName || ''
          }` || ''
      };
    }
    return {
      textValue: '',
      textLabel: '',
      username: ''
    };
  };

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>
        <Typography
          variant="h4"
          style={{
            fontWeight: theme.font.semiBold,
            color: theme.colors.TextDarkGrey
          }}
        >
          {content[type]?.title ?? '-'}
        </Typography>
        <IconButton aria-label={'close'} onClick={onCancel} size="large">
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container md={12}>
          <Typography variant="h4">{content[type]?.subtitle ?? '-'}</Typography>
        </Grid>
        <Grid container md={12} mt={3} justifyContent="center">
          <Typography
            variant="h4"
            style={{
              color: theme.colors.TextDarkGrey,
              fontWeight: theme.font.semiBold
            }}
          >
            {access?.email ?? '-'}
          </Typography>
        </Grid>
        <Grid container md={12} mt={3}>
          <Typography variant="h4">
            Insira um novo usuário que herdará os processos ativos
          </Typography>
        </Grid>
        <Grid container item md={12} mt={1}>
          <Autocomplete
            fullWidth
            options={newAccesses}
            value={selectedNewAccess || null}
            getOptionLabel={(opt) =>
              `${opt?.id ?? ''} - ${
                opt?.distributor ? opt?.distributor.sold : ''
              } ${opt?.employee ? opt?.employee?.sap : ''}`
            }
            renderInput={(params) => (
              <UITextField
                value={GetValuesByType().textValue}
                label={GetValuesByType().textLabel}
                {...params}
              />
            )}
            onChange={(event, value) =>
              setSelectedNewAccess(value as AccessModel)
            }
          />
        </Grid>
        <Grid container item md={12} mt={1}>
          <UITextField
            label={'E-mail'}
            value={selectedNewAccess?.email || ''}
            disabled
          />
        </Grid>
        <Grid container item md={12} mt={1}>
          <UITextField
            label={'Nome do usuário'}
            value={GetValuesByType().username || ''}
            disabled
          />
        </Grid>
        {type === 'vacancy' ? (
          <>
            <Grid container item md={12} mt={1}>
              <UITextField
                type="number"
                label={'Período em dias'}
                value={period || ''}
                onChange={(e) => setPeriod(+e.target.value)}
              />
            </Grid>
            <Grid container item md={12} mt={1}>
              <UITextField
                disabled
                label={'Fim das férias'}
                value={
                  period
                    ? format(
                        add(new Date(), {
                          days: period
                        }),
                        'dd/MM/yyyy'
                      )
                    : ''
                }
              />
            </Grid>
          </>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Grid
          item
          container
          direction={'row'}
          justifyContent={'flex-end'}
          spacing={2}
          xs={12}
        >
          <Grid container item xs={6}>
            <Button
              variant="outlined"
              fullWidth
              onClick={onCancel}
              color={'primary'}
            >
              Voltar
            </Button>
          </Grid>
          <Grid
            container
            item
            xs={6}
            justifyContent="center"
            alignItems="center"
          >
            {loading ? (
              <CircularProgress size={30} />
            ) : (
              <Button
                fullWidth
                variant="contained"
                color={'primary'}
                onClick={() => {
                  if (selectedNewAccess) {
                    setSelectedNewAccess(undefined);
                    setPeriod(undefined);
                    onSubmit(selectedNewAccess, period);
                  }
                }}
                disabled={!selectedNewAccess || (!period && type === 'vacancy')}
              >
                {content[type]?.buttonTitle ?? '-'}
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
