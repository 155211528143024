/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-types */
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Autocomplete,
  Button,
  Collapse,
  createFilterOptions,
  Divider,
  Grid,
  TextField
} from '@mui/material';
import { CalcDiscountInput } from 'modules/Process/Domain/ProcessItem/Discount';
import { CalcMarkupInput } from 'modules/Process/Domain/ProcessItem/Markup';
import { TotalItem } from 'modules/Process/Domain/ProcessItem/TotalItem';
import { TotalKg } from 'modules/Process/Domain/ProcessItem/TotalKg';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'services';
import BlockCard from 'shared/components/Cards/BlockCard/index';
import { ControlledAutocomplete } from 'shared/components/ControlledComponents/ControlledAutocomplete';
import { ControlledRadioGroup } from 'shared/components/ControlledComponents/ControlledRadioGroup';
import { ControlledTextField } from 'shared/components/ControlledComponents/ControlledTextField';
import { FormProps, UITextField } from 'shared/components/Inputs';
import {
  ProcessProductModel,
  ProductsModel,
  RivalProductsModel
} from 'types/models';
import { OptionsBooleanToYes } from 'utils/Constants/BooleanToYes';
import { PoliticalPriceType } from 'utils/Constants/PoliticalPriceType';
import { States } from 'utils/Enums';
import { FormatPercent } from 'utils/Functions/FormatPercent';
import {
  MoneyMask,
  MoneyRemoveMask,
  PercentMask,
  PercentMaskRemove
} from 'utils/masks';
import {
  PrivateScopeRegistrationInputs,
  PrivateScopeRegistrationValidation
} from './domain';
import { submitAdapter } from './domain/submitAdapter';

export interface PrivateScopeRegistrationFormParams
  extends FormProps<Partial<ProcessProductModel>> {
  processProduct?: Partial<ProcessProductModel>;
  onClose?: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void;
  processDirect: boolean;
  distributorUf?: string;
  processId: number;
  isAdmin?: boolean;
}

export function PrivateScopeRegistrationItemForm({
  onComplete,
  onClose,
  processProduct,
  processDirect,
  processId,
  distributorUf,
  isAdmin = false
}: PrivateScopeRegistrationFormParams) {
  const [products] = useQuery<ProductsModel[], ProductsModel>('/products', {
    $limit: '-1',
    processId,
    disabled: 0
  });

  const { handleSubmit, formState, watch, control, reset, setValue } =
    useForm<PrivateScopeRegistrationInputs>({
      resolver: yupResolver(PrivateScopeRegistrationValidation),
      mode: 'onTouched',
      defaultValues: {
        hasRival: 0,
        priceList: MoneyMask(0),
        initialValue: MoneyMask(0),
        finalValue: MoneyMask(0)
      }
    });

  useEffect(() => {
    if (processProduct) {
      reset({
        discountValue: FormatPercent(processProduct?.discountValue || 0),
        finalValue: MoneyMask(processProduct?.finalValue || 0),
        hasResult: processProduct?.finalValue ? 1 : 0,
        initialValue: MoneyMask(processProduct?.initialValue || 0),
        initialQuantity: processProduct?.initialQuantity,
        hasRival: processProduct?.rivalProductId ? 1 : 0,
        itemNumber: processProduct?.itemNumber,
        markup: processProduct?.markup
          ? PercentMask(processProduct.markup)
          : undefined,
        priceList: MoneyMask(processProduct?.priceList || 0),
        productId: processProduct?.productId,
        rivalProductId: processProduct?.rivalProductId,
        rivalFinalValue: processProduct?.rivalFinalValue
          ? MoneyMask(processProduct?.rivalFinalValue || 0)
          : null,
        rivalInitialValue: processProduct?.rivalInitialValue
          ? MoneyMask(processProduct?.rivalInitialValue || 0)
          : null,
        winner:
          processProduct?.winner === false
            ? 0
            : processProduct?.winner === true
            ? 1
            : processProduct?.winner
      });
    }
  }, [processProduct]);

  const filterOptions = <T extends unknown>() =>
    createFilterOptions<T>({
      limit: 20
    });

  const product =
    products?.find(
      (selectedProduct) => selectedProduct.id === watch('productId')
    ) || null;

  const nfPrice =
    +CalcDiscountInput(
      watch('priceList') || 0,
      watch('discountValue') || 0
    ).discountValue?.toFixed(2) || '';

  const institutionValue = +CalcMarkupInput(
    nfPrice,
    +PercentMaskRemove(String(watch('markup'))) || 0
  )?.markup?.toFixed(2);
  const totalKg = TotalKg(product?.grammage, watch('initialQuantity'));

  const [getRivals] = useQuery<RivalProductsModel[]>('/rival-products', {
    $limit: -1
  });

  const rivalProducts = getRivals || [];

  const FilterConcurrencyCompanies = () => {
    const allCompanies = rivalProducts.map(
      (concurrency) => concurrency.company
    );

    return [...new Set(allCompanies)];
  };
  const rivalCompanies = FilterConcurrencyCompanies();

  const rivalProduct =
    rivalProducts?.find(
      (selectedRivalProduct) =>
        selectedRivalProduct.id === watch('rivalProductId')
    ) || null;

  const formSubmit = (data: PrivateScopeRegistrationInputs) => {
    const submitData = submitAdapter(data);
    submitData.nfPrice = typeof nfPrice === 'number' ? nfPrice : 0;
    submitData.institutionValue = institutionValue;
    submitData.approve = true;
    submitData.discount = submitData?.discountValue
      ? submitData?.discountValue > 0
      : false;
    onComplete(submitData);
  };

  return (
    <form id={'private-process-item'} onSubmit={handleSubmit(formSubmit)}>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            name={'itemNumber'}
            control={control}
            label={'Número do item'}
            type="number"
            errors={formState.errors.itemNumber?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            name={'initialQuantity'}
            control={control}
            label={'Qtde. (UNI)'}
            type="number"
            errors={formState.errors.initialQuantity?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledAutocomplete<PrivateScopeRegistrationInputs, ProductsModel>
            name={'productId'}
            control={control}
            label={'Descrição do produto'}
            errors={formState.errors.productId?.message}
            options={products || []}
            getOptionLabel={(option) => option?.description || '-'}
            filterOptions={filterOptions<ProductsModel>()}
            onChange={(_, value) => {
              _.preventDefault();
              if (value && !Array.isArray(value)) {
                const findProduct = products?.find(
                  (selectedProduct) => selectedProduct.id === value.id
                );
                const priceList = findProduct?.political_prices?.[0]?.price;
                if (priceList) {
                  setValue('priceList', MoneyMask(priceList));
                }
                setValue('productId', value.id);
              } else {
                setValue('productId', undefined);
              }
            }}
            value={product}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <UITextField
            label="SKU"
            fullWidth
            value={product?.sku || ''}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <UITextField
            label="Gramatura"
            fullWidth
            value={
              product?.grammage && +product?.grammage
                ? +product?.grammage * 1000
                : ''
            }
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <UITextField
            label="Qtde. total (KG)"
            fullWidth
            value={totalKg}
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <UITextField
            label="Política Comercial"
            fullWidth
            value={
              PoliticalPriceType[product?.political_prices?.[0]?.type || '']
                ?.label || ''
            }
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <UITextField
            label="Estado Sold To"
            fullWidth
            value={
              distributorUf ? States[distributorUf?.toUpperCase()] || '' : ''
            }
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            name={'priceList'}
            control={control}
            label={'(R$) Preço Lista'}
            pattern="money"
            errors={formState.errors.priceList?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <UITextField
            label="Desconto Pré-aprovado"
            fullWidth
            value={
              (product?.political_prices?.[0]?.preDiscount &&
                FormatPercent(
                  product?.political_prices?.[0]?.preDiscount * 100
                )) ||
              ''
            }
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            name={'discountValue'}
            control={control}
            label={'Desconto Proposto'}
            pattern="percentage"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <UITextField
            label="(R$) Preço NF Nestlé"
            fullWidth
            value={MoneyMask(nfPrice)}
            disabled
          />
        </Grid>
        {PercentMaskRemove(watch('discountValue') || '0') >
          (product?.political_prices?.[0]?.preDiscount || 0) * 100 &&
        !isAdmin ? (
          <Grid item xs={12}>
            <BlockCard
              title="Desconto excede política pré-aprovada"
              message="Será necessário a aprovação do financeiro para prosseguir com esse valor."
            />
          </Grid>
        ) : null}
        {!processDirect ? (
          <Grid item xs={12} sm={6}>
            <ControlledTextField
              name={'markup'}
              control={control}
              label={'Markup Aplicado'}
              pattern="percentage"
            />
          </Grid>
        ) : null}
        <Grid item xs>
          <UITextField
            label="(R$) Preço Instituição"
            fullWidth
            value={MoneyMask(institutionValue || '')}
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledRadioGroup
            control={control}
            label={'Possui resultado?'}
            name={'hasResult'}
            options={OptionsBooleanToYes}
            value={+watch('hasResult')}
            onChange={(event) => {
              const { value } = event.target;
              if (value) {
                setValue('hasResult', value === '0' ? 0 : 1);
                if (value === '0') {
                  const zeroValue = MoneyMask(0);
                  setValue('hasRival', 0);
                  setValue('winner', 0);
                  setValue('finalValue', zeroValue);
                  setValue('initialValue', zeroValue);
                  setValue('rivalProductId', null);
                  setValue('rivalFinalValue', null);
                  setValue('rivalInitialValue', null);
                }
              }
            }}
            errors={formState.errors?.hasResult?.message}
          />
        </Grid>
        <Collapse in={!!+watch('hasResult')}>
          <Grid container item xs={12} spacing={1} pl={2} pr={2}>
            <Grid item xs={12}>
              <ControlledRadioGroup
                control={control}
                label={'Item ganho'}
                name={'winner'}
                options={OptionsBooleanToYes}
                value={+watch('winner')}
                errors={formState.errors?.winner?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ControlledTextField
                name={'initialValue'}
                control={control}
                label={'(R$) Preço Inicial'}
                errors={formState.errors?.initialValue?.message}
                pattern="money"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ControlledTextField
                name={'finalValue'}
                control={control}
                label={'(R$) Preço Final'}
                pattern="money"
                errors={formState.errors?.finalValue?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider orientation="horizontal" flexItem />
            </Grid>
            <Grid item xs={12}>
              <ControlledRadioGroup
                control={control}
                label={'Disputa Concorrente'}
                name={'hasRival'}
                options={OptionsBooleanToYes}
                value={+watch('hasRival')}
                onChange={(event) => {
                  const { value } = event.target;
                  if (value) {
                    setValue('hasRival', value === '0' ? 0 : 1);
                    if (value === '0') {
                      setValue('rivalProductId', null);
                      setValue('rivalFinalValue', null);
                      setValue('rivalInitialValue', null);
                    }
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Collapse in={!!+watch('hasRival')}>
                <Grid container item xs={12} spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      disabled
                      renderInput={(opts) => (
                        <TextField label={'Empresa Concorrente'} {...opts} />
                      )}
                      options={rivalCompanies}
                      value={rivalProduct?.company || null}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ControlledAutocomplete<
                      PrivateScopeRegistrationInputs,
                      RivalProductsModel
                    >
                      name={'rivalProductId'}
                      control={control}
                      label={'Produto Concorrente'}
                      errors={formState.errors.rivalProductId?.message}
                      options={rivalProducts || []}
                      getOptionLabel={(option) => option?.description || '-'}
                      groupBy={(optionGroup) => optionGroup.company}
                      onChange={(_, value) => {
                        _.preventDefault();
                        if (value && !Array.isArray(value)) {
                          setValue('rivalProductId', value.id);
                        } else {
                          setValue('rivalProductId', undefined);
                        }
                      }}
                      value={rivalProduct}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ControlledTextField
                      name={'rivalInitialValue'}
                      control={control}
                      label={'(R$) Preço inicial Conc.'}
                      pattern="money"
                      errors={formState.errors.rivalInitialValue?.message}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ControlledTextField
                      name={'rivalFinalValue'}
                      control={control}
                      label={'(R$) Preço final Conc.'}
                      pattern="money"
                      errors={formState.errors.rivalFinalValue?.message}
                    />
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
          </Grid>
        </Collapse>

        <Grid item xs={12}>
          <Divider orientation="horizontal" flexItem />
        </Grid>
        <Grid item xs={12} sm={6}>
          <UITextField
            label="(R$) Total Item"
            fullWidth
            value={MoneyMask(
              TotalItem({
                nfPrice,
                initialQuantity: watch('initialQuantity'),
                priceList: MoneyRemoveMask(watch('priceList') || '0')
              }).totalItem
            )}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <UITextField
            label="(R$) Total Item (GPR)"
            fullWidth
            value={MoneyMask(
              TotalItem({
                nfPrice,
                initialQuantity: watch('initialQuantity'),
                priceList: MoneyRemoveMask(watch('priceList') || '0')
              }).totalItemGPR
            )}
            disabled
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1} mt={2}>
        <Grid item xs={6}>
          <Button
            fullWidth
            variant={'outlined'}
            onClick={() => onClose && onClose({}, 'backdropClick')}
          >
            Voltar
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button type={'submit'} fullWidth variant={'contained'}>
            Avançar
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
