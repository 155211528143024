import { Box, Button, Grid } from '@mui/material';
import React from 'react';
import { FetchQuery } from 'services';
import UIUploadAddSection from 'shared/components/Upload/UploadAddSection';
import UploadList from 'shared/components/Upload/UploadList';
import { useUploadAdd } from 'shared/hooks/UseUploadAdd';
import { AttachmentModel } from 'types/models/Attachments/Attachment.model';
import { PrivateProcessDocuments } from 'utils/Constants/DocumentTypes/PrivateProcessDocuments';
import { DownloadFromFile } from 'utils/Functions/Download/DownloadFromFile';
import { GetFileSize } from 'utils/Functions/FileSize';

interface AdminUploadSectionProps {
  files: SelectedFiles[];
  setFiles: (files: SelectedFiles[]) => void;
  onFinished: () => void;
  buttonText?: string;
  onFinishedLoading: boolean;
}

export function AdminUploadSection({
  files,
  setFiles,
  onFinished,
  buttonText,
  onFinishedLoading
}: AdminUploadSectionProps) {
  const attachmentQuery = (() => {}) as FetchQuery<
    AttachmentModel[],
    AttachmentModel
  >;
  const { buttonDisabled, handleFile, file, setFile, setCategory, category } =
    useUploadAdd(attachmentQuery, {
      accessId: 0,
      table: '',
      tableId: 0
    });

  const returnLastId = () => {
    if (files.length === 0) return 0;
    return Math.max(...files.map((selectedFile) => selectedFile.id)) || 0;
  };

  return (
    <Grid container spacing={4} mt={1} mb={5}>
      <Grid container item md={6} xs={12}>
        <Grid item xs={12}>
          <UploadList
            height="100%"
            title="Documentos da alteração do processo"
            items={
              files?.map((selectedFile) => ({
                name: selectedFile.file.name || '-',
                category: selectedFile.category || '-',
                size: GetFileSize(selectedFile.file.size) || '-',
                onDownload: async () =>
                  DownloadFromFile(selectedFile.file, selectedFile.file.name),
                onDelete: () =>
                  setFiles(
                    files.filter((filesArr) => filesArr.id !== selectedFile.id)
                  ),
                loading: false
              })) || []
            }
          />
        </Grid>
      </Grid>
      <Box mt={4} />
      <Grid item md={6} xs={12}>
        <UIUploadAddSection
          title="Documento (obrigatório)"
          buttonDisabled={buttonDisabled}
          buttonOnClick={() => {
            if (file) {
              setFiles([...files, { file, category, id: returnLastId() + 1 }]);
              setCategory('');
              setFile(undefined);
            }
          }}
          handleFile={handleFile}
          loading={false}
          selectedFilename={file?.name || ''}
          selectLabel={'Documento'}
          selectOnChange={({ target }) => setCategory(target.value)}
          selectOptions={PrivateProcessDocuments}
          selectValue={category}
        />
      </Grid>
      <Grid container item justifyContent="flex-end" xs={12} mt={2}>
        <Grid item xs={12} sm={4} md={3}>
          <Button
            fullWidth
            onClick={onFinished}
            variant="contained"
            color="primary"
            disabled={onFinishedLoading}
          >
            {buttonText || 'Salvar Informações editadas'}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

interface SelectedFiles {
  file: File;
  category: string;
  id: number;
}
