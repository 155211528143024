import React from 'react';
import { toast } from 'react-toastify';
import { UITabs } from 'shared/components/Tabs';
import { UITab } from 'shared/components/Tabs/Tab';
import { ThemeColors } from 'shared/enum';

type AdminEditProcessTabsProps = {
  step: number;
};

export function AdminEditProcessTabs({ step }: AdminEditProcessTabsProps) {
  const actualColor: (keyof typeof ThemeColors)[] = [
    'Primary',
    'Pink',
    'Success'
  ];

  const handleChangeStep = (pos: number) => {
    if (pos !== step) {
      toast.warning(
        'Para preservar a consistência dos dados, não é possível navegar entre as etapas.'
      );
    }
  };

  return (
    <UITabs
      value={step}
      onChange={(_, pos) => handleChangeStep(pos)}
      variant="scrollable"
      scrollButtons
      allowScrollButtonsMobile
      aria-label="scrollable force tabs example"
      colorIndicator={actualColor[step] || actualColor[0]}
    >
      <UITab label={'Informações do Processo'} />
      <UITab label={'Itens do Processo'} />
      <UITab label={'Anexos'} />
    </UITabs>
  );
}
