import { UITabs } from 'shared/components/Tabs';
import { UITab } from 'shared/components/Tabs/Tab';
import React, { useState } from 'react';
import { Box } from '@mui/material';

interface InstitutionsListTabsProps {
  onChange: (step: string) => void;
}

export function InstitutionsListTab({ onChange }: InstitutionsListTabsProps) {
  const [step, setStep] = useState('all');

  const handleChange = (event: React.SyntheticEvent, value: string) => {
    setStep(value);
    onChange(value);
  };

  return (
    <>
      <UITabs value={step} onChange={handleChange}>
        <UITab value={'all'} label={'Todos'} />
        <UITab value={'Programa governamental'} label={'Governamental'} />
        <UITab value={'Hospitalar'} label={'Hospitalar'} />
        <Box flex="1" />
        <UITab value="inactive" label={'Inativo'} />
      </UITabs>
    </>
  );
}
