/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, MenuItem, Popover, Skeleton } from '@mui/material';
import { usePopover } from 'hooks';
import { InstitutionCard } from 'modules/Admin/Institutions/Cards/Institution';
import { DisableOrEnableModal } from 'modules/Admin/shared/Modals/DisableOrEnableModal';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { usePatch, useQuery } from 'services';
import { SearchAdmin } from 'shared/components/Header/Admin/SearchAdmin';
import { UIPagination } from 'shared/components/Pagination';
import { InstitutionModel } from 'types/models';
import { InstitutionsListTab } from './InstitutionListTab';

const itemsPerPage = 12;

export function InstitutionsList() {
  const history = useHistory();

  const [search, setSearch] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [type, setType] = useState<string>('all');
  const [disableOrEnableInstitution, setDisableOrEnableInstitution] = useState<{
    type: 'disable' | 'enable';
    institutionId?: number;
  }>();
  const [, patchInstitution] = usePatch<InstitutionModel>('/institution');

  const [institutions, refetch, loading] = useQuery<InstitutionModel[]>(
    '/institution',
    {
      $limit: -1,
      $select: [
        'id',
        'city',
        'uf',
        'name',
        'segment',
        'serviceChannel',
        'disabled'
      ]
    }
  );

  const GetFilteredInstitutions = () => {
    const filterNotDisabled = (institution: InstitutionModel) =>
      !institution.disabled;
    const filtInstitutions: InstitutionModel[] = [];
    if (institutions) {
      if (type === 'all') {
        filtInstitutions.push(...institutions.filter(filterNotDisabled));
      } else if (type === 'inactive') {
        filtInstitutions.push(
          ...institutions?.filter((institution) => institution.disabled)
        );
      } else {
        filtInstitutions.push(
          ...institutions
            ?.filter((product) => product.serviceChannel === type)
            ?.filter(filterNotDisabled)
        );
      }
    }

    return filtInstitutions.filter((institution) => {
      // TO-DO: Sounds good to me right now, but in the future a refact is gonna be nice.
      if (search) {
        let conditional = !!institution.name
          ?.toUpperCase()
          ?.match(search?.toUpperCase());
        conditional =
          conditional ||
          !!institution.city?.toUpperCase()?.match(search?.toUpperCase());
        conditional =
          conditional ||
          !!institution.uf?.toUpperCase()?.match(search?.toUpperCase());
        conditional =
          conditional ||
          !!institution.segment?.toUpperCase()?.match(search?.toUpperCase());
        conditional =
          conditional ||
          !!institution.serviceChannel
            ?.toUpperCase()
            ?.match(search?.toUpperCase());
        return conditional;
      }
      return true;
    });
  };

  const filteredInstitutions = GetFilteredInstitutions() || [];
  const total = Math.ceil((filteredInstitutions?.length || 0) / itemsPerPage);
  const cutInstitutions = filteredInstitutions.slice(
    page * itemsPerPage,
    page * itemsPerPage + 12
  );

  const handleInactivate = async () => {
    if (disableOrEnableInstitution?.institutionId) {
      await patchInstitution(
        {
          disabled: disableOrEnableInstitution?.type === 'disable'
        },
        disableOrEnableInstitution?.institutionId
      );
      setDisableOrEnableInstitution(undefined);
      toast.success('Atualização feita com sucesso');
      refetch();
    }
  };
  const { handleCloseToPopoverEl, popoverAnchorEl, handleClickToPopover } =
    usePopover();

  return (
    <>
      <Grid container spacing={2} mb={3}>
        <Grid item xs={12}>
          <Box mb={2}>
            <SearchAdmin
              title={'Instituições'}
              buttonText={'Adicionar instituição'}
              onButtonClick={handleClickToPopover}
              searchPlaceholder={'Pesquisar instituições'}
              handleChangeSearch={(t) => {
                setSearch(t.target.value);
                if (page !== 0) setPage(0);
              }}
            />
          </Box>
        </Grid>
        <Popover
          open={!!popoverAnchorEl}
          anchorEl={popoverAnchorEl}
          onClose={handleCloseToPopoverEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          <MenuItem
            onClick={() => history.push('/instituicao/governamental/adicionar')}
          >
            Governamental
          </MenuItem>
          <MenuItem
            onClick={() => history.push('/instituicao/hospitalar/adicionar')}
          >
            Hospitalar
          </MenuItem>
        </Popover>
      </Grid>
      <Grid item xs={12}>
        <InstitutionsListTab
          onChange={(step) => {
            setType(step);
            setPage(0);
          }}
        />
      </Grid>
      <Grid container mb={4} mt={3}>
        <Grid item container xs={12} spacing={3}>
          {loading ? (
            Array.from(new Array(12)).map(() => (
              <Grid item xs={12} md={4}>
                <Skeleton
                  variant="rectangular"
                  width={'100%'}
                  height={'131px'}
                  animation="wave"
                />
              </Grid>
            ))
          ) : (
            <Grid item container spacing={3}>
              {(cutInstitutions || []).map((institution: InstitutionModel) => (
                <InstitutionCard
                  institution={institution}
                  key={institution.id}
                  onDelete={() =>
                    setDisableOrEnableInstitution({
                      institutionId: institution.id,
                      type: type === 'inactive' ? 'enable' : 'disable'
                    })
                  }
                  onEdit={() => {
                    if (
                      institution?.serviceChannel === 'Programa governamental'
                    ) {
                      history.push(
                        `/instituicao/governamental/editar/${institution.id}`
                      );
                    }
                    if (institution?.serviceChannel === 'Hospitalar') {
                      history.push(
                        `/instituicao/hospitalar/editar/${institution.id}`
                      );
                    }
                  }}
                />
              ))}
              <Grid item container justifyContent={'flex-end'}>
                <UIPagination
                  count={total}
                  onChange={(_, newPage) => setPage(newPage - 1)}
                  itemSize="medium"
                  page={page + 1}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <DisableOrEnableModal
        content={{
          disable: {
            title: 'Desativar Instituição',
            primaryText: 'Deseja desativar a instituição?',
            secondaryText:
              'A instituição não poderá ser mais usado em futuros processos.',
            buttonText: 'Inativar'
          },
          enable: {
            title: 'Reativar Produto',
            primaryText: 'Deseja reativar a instituição?',
            secondaryText:
              'A instituição poderá ser usado novamente em futuros processos.',
            buttonText: 'Reativar'
          }
        }}
        open={!!disableOrEnableInstitution}
        onClose={() => setDisableOrEnableInstitution(undefined)}
        onSubmit={handleInactivate}
        type={disableOrEnableInstitution?.type}
      />
    </>
  );
}
