/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Grid } from '@mui/material';
import React from 'react';
import { FetchQuery } from 'services';
import UploadList from 'shared/components/Upload/UploadList';
import { useUploadList } from 'shared/hooks/UseUploadList';
import { AttachmentModel } from 'types/models/Attachments/Attachment.model';
import { DeleteAttachmentModal } from './DeleteModal';

export type UploadListSectionProps = {
  attachments: AttachmentModel[];
  attachmentsLoading: boolean;
  attachmentsRefetch: FetchQuery<AttachmentModel[], AttachmentModel>;
};

export function UploadListSection({
  attachments,
  attachmentsLoading,
  attachmentsRefetch
}: UploadListSectionProps) {
  const {
    downloadAttachment,
    findLoading,
    onClickDelete,
    deleteModal,
    setDeleteModal,
    onDelete
  } = useUploadList(attachmentsRefetch);

  return (
    <>
      <Grid item md={5} xs={12}>
        <UploadList
          height="470px"
          title="Documentos anexados"
          items={
            attachments?.map((attachment) => ({
              name: attachment.name || '-',
              category: attachment.category || '-',
              size: attachment.size || '-',
              onDownload: () => downloadAttachment(attachment.id),
              loading: findLoading(attachment.id!),
              onDelete: () => onClickDelete(attachment.id!)
            })) || []
          }
          loading={attachmentsLoading}
        />
      </Grid>
      <DeleteAttachmentModal
        open={deleteModal.open}
        setOpen={setDeleteModal}
        onDelete={onDelete}
      />
    </>
  );
}
