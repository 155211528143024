/* eslint-disable react-hooks/exhaustive-deps */
import SearchIcon from '@mui/icons-material/Search';
import { Grid, InputAdornment, MenuItem, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQuery } from 'services';
import { UITextField } from 'shared/components/Inputs';
import { ThemeColors } from 'shared/enum';
import { Cities, UfsRegionals } from 'types/models';
import { States, STATES_ARRAY } from 'utils/Enums';
import { AlterRegionalForm } from '../AlterRegional';
import { RegionalsTab } from '../RegionalsTab';
import RegionalsTable from '../RegionalsTable';

export function RegionalsList() {
  const [cities, , citiesLoading] = useQuery<Cities[]>('/cities', {
    $limit: -1
  });
  const [ufsRegionals, refetch, ufsRegionalsLoading] = useQuery<
    UfsRegionals[],
    UfsRegionals
  >('/ufs-regionals', {
    $limit: -1
  });

  const [filterUf, setFilterUf] = useState<string>('all');
  const [step, setStep] = useState<string>('all');
  const filterByUf =
    (cities || []).filter((city) =>
      filterUf !== 'all' ? city.uf === filterUf : true
    ) || [];

  const { control, watch } = useForm<{ search: string }>();
  const filterBySearch = filterByUf.filter(
    (city) => !!city.city?.toUpperCase()?.match(watch('search')?.toUpperCase())
  );

  return (
    <>
      <Grid container spacing={2} mb={3}>
        <Grid item container xs={12} md={3} alignItems={'center'} mt={3}>
          <Typography
            variant={'h1'}
            style={{ color: ThemeColors.TextDarkGrey }}
          >
            Regionais
          </Typography>
        </Grid>
        <Grid container item xs={12} md mt={3}>
          <Grid container item xs={8}>
            <Controller
              name={'search'}
              control={control}
              render={({ field }) => (
                <UITextField
                  label={'Pesquisar Municípios'}
                  variant={'filled'}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position={'start'}>
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                  fullWidth
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid container item xs={4}>
            <UITextField
              label={'Estado'}
              value={filterUf}
              fullWidth
              variant={'filled'}
              select
              onChange={(e) => setFilterUf(e.target.value)}
              InputProps={{
                disableUnderline: true
              }}
            >
              <MenuItem value={'all'}>Todos</MenuItem>
              {STATES_ARRAY.map(({ uf }) => (
                <MenuItem value={uf} key={uf}>
                  {States[uf]}
                </MenuItem>
              ))}
            </UITextField>
          </Grid>
        </Grid>
        <RegionalsTab step={step} onChange={(newStep) => setStep(newStep)} />
        <RegionalsTable
          cities={filterBySearch || []}
          ufsRegionals={ufsRegionals || []}
          loading={ufsRegionalsLoading || citiesLoading}
          step={step}
        />
        <AlterRegionalForm
          refetch={refetch}
          ufsRegionals={ufsRegionals || []}
        />
      </Grid>
    </>
  );
}
