import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link, LinkProps } from '@mui/material';

interface UILinkProps extends LinkProps {
  to: string;
}

export function UILink({ to, children, ...rest }: UILinkProps) {
  return (
    <Link to={to} component={RouterLink} {...rest}>
      {children}
    </Link>
  );
}
