/* eslint-disable react/react-in-jsx-scope */
import { Tooltip } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { TotalItem } from 'modules/Process/Domain/ProcessItem/TotalItem';
import { BooleanToYes } from 'utils/Constants/BooleanToYes';
import { FormatPercent } from 'utils/Functions/FormatPercent';
import { MoneyMask } from 'utils/masks';
import { HelpOutline } from '@mui/icons-material';

export const PrivateProcessItemsColumns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'id',
    hide: true
  },
  {
    field: 'status',
    headerName: '-',
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams) => {
      const needsFinancier = !!row?.discount_orders?.[0]?.id;
      const needsResult = !row?.finalValue;

      const tooltips: React.ReactElement[] = [];
      if (needsFinancier) {
        tooltips.push(
          <Tooltip
            placement="top"
            title="Preço fora da política. Será necessário aprovação do financeiro."
          >
            <HelpOutline color="primary" />
          </Tooltip>
        );
      }
      if (needsResult) {
        tooltips.push(
          <Tooltip
            placement="top"
            title="Resultado não cadastrado. Será necessário a etapa de informar resultado posteriormente."
          >
            <HelpOutline color="warning" />
          </Tooltip>
        );
      }
      return <>{tooltips.map((tooltip) => tooltip)}</>;
    }
  },
  {
    field: 'itemNumber',
    headerName: 'Item',
    width: 80,
    valueGetter: ({ row }) => row?.itemNumber || '-'
  },
  {
    field: 'initialQuantity',
    headerName: 'Quantidade',
    width: 100,
    valueGetter: ({ row }) => row?.initialQuantity || '-'
  },
  {
    field: 'product.description',
    headerName: 'Produto',
    width: 250,
    valueGetter: ({ row }) => row?.product?.description || '-',
    renderCell: ({ row }: GridRenderCellParams) => (
      <>
        <Tooltip title={row?.product?.description}>
          <span className="table-cell-trucate">
            {row?.product?.description}
          </span>
        </Tooltip>
      </>
    )
  },
  {
    field: 'product.sku',
    headerName: 'SKU',
    width: 100,
    valueGetter: ({ row }) => row?.product?.sku || '-'
  },
  {
    field: 'product.grammage',
    headerName: 'Gramatura',
    width: 100,
    valueGetter: ({ row }) => {
      if (+row?.product?.grammage)
        return (+row?.product?.grammage * 1000).toFixed(0);
      return '-';
    }
  },
  {
    field: 'kgTotal',
    headerName: 'Qtde. total (KG)',
    width: 120,
    valueGetter: ({ row }) => {
      if (+row?.product?.grammage && +row?.initialQuantity)
        return (+row?.product?.grammage * +row?.initialQuantity).toFixed(2);
      return '-';
    }
  },
  {
    field: 'priceList',
    headerName: '(R$) Preço lista',
    width: 150,
    valueGetter: ({ row }) => row?.priceList || '-',
    valueFormatter: ({ value }) => MoneyMask(Number(value || ''))
  },
  {
    field: 'discountValue',
    headerName: 'Desconto Proposto',
    width: 150,
    valueGetter: ({ row }) => row?.discountValue || '-',
    valueFormatter: ({ value }) => FormatPercent(String(value)) || '-'
  },

  {
    field: 'needsFinancier',
    headerName: 'Necessita financeiro?',
    width: 150,
    valueGetter: ({ row }) => (row?.discount_orders?.[0]?.id ? 'Sim' : 'Não')
  },
  {
    field: 'nfPrice',
    headerName: '(R$) Preço NF liberado',
    width: 180,
    valueGetter: ({ row }) => row?.nfPrice || '-',
    valueFormatter: ({ value }) => MoneyMask(Number(value))
  },
  {
    field: 'markup',
    headerName: 'Markup',
    width: 100,
    valueGetter: ({ row }) => row?.markup || '-',
    valueFormatter: ({ value }) => FormatPercent(String(value)) || '-'
  },
  {
    field: 'institutionValue',
    headerName: '(R$) Preço Instituição',
    width: 180,
    valueGetter: ({ row }) => row?.institutionValue || '-',
    valueFormatter: ({ value }) => MoneyMask(Number(value || ''))
  },
  {
    field: 'winner',
    headerName: 'Item Ganho',
    width: 100,
    valueGetter: ({ row }) => row?.winner,
    valueFormatter: ({ value }) => BooleanToYes[String(value)]
  },
  {
    field: 'initialValue',
    headerName: '(R$) Preço Inicial',
    width: 180,
    valueGetter: ({ row }) => row?.initialValue || '-',
    valueFormatter: ({ value }) => MoneyMask(Number(value))
  },
  {
    field: 'finalValue',
    headerName: '(R$) Preço FInal',
    width: 180,
    valueGetter: ({ row }) => row?.finalValue || '-',
    valueFormatter: ({ value }) => MoneyMask(Number(value))
  },
  {
    field: 'rivalProduct',
    headerName: 'Disputa concorrente',
    width: 180,
    valueGetter: ({ row }) => {
      if (row?.rivalProductId) return 'true';
      return 'false';
    },
    valueFormatter: ({ value }) => BooleanToYes[String(value)]
  },
  {
    field: 'rivalProductCompany',
    headerName: 'Empresa Concorrente',
    width: 150,
    valueGetter: ({ row }) => row?.rival_product?.company || '-'
  },
  {
    field: 'rivalProductDescription',
    headerName: 'Produto Concorrente',
    width: 200,
    valueGetter: ({ row }) => row?.rival_product?.description || '-'
  },
  {
    field: 'totalItem',
    headerName: '(R$) Total Item',
    width: 200,
    valueGetter: ({ row }) => {
      if (+row?.nfPrice && +row?.initialQuantity)
        return (+row?.nfPrice * +row?.initialQuantity).toFixed(2);
      return '-';
    },
    valueFormatter: ({ value }) => MoneyMask(Number(value || ''))
  },
  {
    field: 'totalItemGPR',
    headerName: '(R$) Total Item GPR',
    width: 200,
    valueGetter: ({ row }) => {
      if (+row?.initialQuantity && +row?.nfPrice && +row?.priceList) {
        return TotalItem({
          nfPrice: +row?.nfPrice,
          initialQuantity: +row?.initialQuantity,
          priceList: +row?.priceList
        }).totalItemGPR;
      }
      return '-';
    },
    valueFormatter: ({ value }) => MoneyMask(Number(value || ''))
  }
];
