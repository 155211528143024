import { ProcessProductModel } from 'types/models';
import { OrderColumns } from 'modules/Process/shared/Tables/ProcessItems/constants/orderColumns';
import { GridColDef } from '@mui/x-data-grid';

export const ProcessItemOrders = (
  processProducts: Partial<ProcessProductModel>[]
) => {
  const products = [...processProducts];

  const newColumns: number[] = [];
  processProducts?.forEach((processProduct, key) => {
    processProduct?.order_items?.forEach((order) => {
      products[key][`order_${order.orderId}`] = order;

      if (!newColumns.find((col) => order.orderId === col)) {
        newColumns.push(order.orderId);
      }
    });
  });

  const orderColumns: GridColDef[] = [];
  newColumns.reverse().forEach((createColumn, index) => {
    orderColumns.push(...OrderColumns(createColumn, newColumns.length - index));
  });

  return { products, orderColumns };
};
