import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { UIChip } from 'shared/components/Chip';
import { MoneyMask } from 'utils/masks';
import { ParseDateString } from 'utils/Functions/ParseDateString';
import lang from 'utils/lang';
import { Typography } from '@mui/material';
import { Segments } from 'utils/Enums';

export const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', hide: true },
  {
    field: 'status',
    headerName: 'Status',
    renderCell: ({ value }) => (
      <UIChip
        color={lang.processStatus[value?.toString() || 'default'].color}
        title={lang.processStatus[value?.toString() || 'default'].value || ''}
        height="20px"
      />
    ),
    valueFormatter: ({ value }) => value ?? '-',
    width: 200
  },
  {
    field: 'tradingCode',
    headerName: 'Nº do pregão',
    valueFormatter: ({ value }) => value ?? '-',
    renderCell: ({ value }) => (
      <Typography color="primary" variant="h1" style={{ fontSize: '12px' }}>
        {value || '-'}
      </Typography>
    )
  },
  {
    field: 'pne',
    headerName: 'PNE',
    valueFormatter: ({ value }) => value ?? '-'
  },
  {
    field: 'city',
    headerName: 'Local',
    width: 150,
    valueFormatter: ({ value }) => value ?? '-'
  },
  {
    field: 'institution.name',
    headerName: 'Instituição',
    width: 250,
    valueGetter: ({ row }) => row?.institution?.name,
    valueFormatter: ({ value }) => value ?? '-'
  },
  {
    field: 'regional',
    headerName: 'Regional',
    valueFormatter: ({ value }) => value?.toString()?.toUpperCase() ?? '-'
  },
  {
    field: 'segment',
    headerName: 'Segmento',
    renderCell: ({ value }) =>
      value ? (
        <UIChip
          color={value?.toString() === 'private' ? 'green' : 'blue'}
          fontWeight="800"
          title={Segments[value?.toString()] || ''}
          height="20px"
        />
      ) : (
        '-'
      )
  },
  {
    field: 'realClassification',
    headerName: 'Classificação',
    width: 150,
    valueFormatter: ({ value }) => value ?? '-'
  },
  {
    field: 'totalValue',
    width: 200,
    headerName: '(R$) Valor',
    valueFormatter: ({ value }) => (value ? MoneyMask(`${value}`) : '-')
  },
  {
    field: 'quantity',
    width: 150,
    headerName: '(Kg) Quantidade',
    valueFormatter: ({ value }) => value ?? '-'
  },
  {
    field: 'createdAt',
    width: 150,
    headerName: 'Data Abertura',
    valueFormatter: ({ value }) => ParseDateString(String(value))
  },
  {
    field: 'startPeriod',
    width: 150,
    headerName: 'Início fornecimento',
    valueFormatter: ({ value }) => ParseDateString(String(value))
  },
  {
    field: 'endPeriod',
    width: 150,
    headerName: 'Término fornecimento',
    type: 'date',
    valueFormatter: ({ value }) => ParseDateString(String(value))
  },
  {
    field: 'distributor.sold',
    width: 150,
    headerName: 'Código do Cliente',
    valueGetter: ({ row }) => row?.distributor?.sold,
    valueFormatter: ({ value }) => value ?? '-'
  },
  {
    field: 'distributor.name',
    width: 200,
    headerName: 'Cliente',
    valueGetter: ({ row }) => row?.distributor?.name,
    valueFormatter: ({ value }) => value ?? '-'
  },
  {
    field: 'employee.sap',
    width: 200,
    headerName: 'SAP do Consultor',
    valueGetter: ({ row }) => row?.employee?.sap,
    valueFormatter: ({ value }) => value ?? '-'
  },
  {
    field: 'employee.name',
    width: 200,
    headerName: 'Nome do Consultor',
    valueGetter: ({ row }) => row?.employee?.name,
    valueFormatter: ({ value }) => value ?? '-'
  },
  {
    field: 'canExtend',
    headerName: 'Pode extender?',
    width: 120,
    type: 'boolean',
    valueFormatter: ({ value }) => value ?? '-'
  }
];
