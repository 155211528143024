import React from 'react';
import { SimpleFormModal } from 'shared/components';
import { ProcessItemMinimalForm } from 'modules/Process/PublicProcess/Forms/ScopeRegistration';
import { ProcessItemMinimalModalParams } from 'modules/Process/PublicProcess/Modals/ProcessItem/Minimal/ProcessItemMinimal';

export function ProcessItemMinimalModal({
  title = 'Itens do processo',
  onHandleComplete,
  processItem,
  ...rest
}: ProcessItemMinimalModalParams) {
  return (
    <SimpleFormModal title={title} {...rest} fullWidth>
      <ProcessItemMinimalForm
        onComplete={onHandleComplete}
        processProduct={processItem}
      />
    </SimpleFormModal>
  );
}
