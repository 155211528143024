import { Collapse, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import UIPaper from 'shared/components/Paper';
import { theme } from 'shared/theme';
import { content } from './content';
import { DescriptionStep } from './Step';

type Props = {
  step: number;
};

export function DistCreateProcessDescriptionSteps({ step }: Props) {
  const [open, setOpen] = useState<boolean>(true);
  return (
    <UIPaper title="Instruções do cadastro" onClick={() => setOpen(!open)}>
      <>
        <Collapse in={open}>
          <Grid container xs={12} gap={2}>
            <DescriptionStep
              step={content[0].step}
              title={content[0].title}
              description={content[0].description}
              active={step === 0}
            />
            <DescriptionStep
              step={content[1].step}
              title={content[1].title}
              description={content[1].description}
              active={step === 1}
            />
            <DescriptionStep
              step={content[2].step}
              title={content[2].title}
              description={content[2].description}
              active={step === 2}
            />
            <DescriptionStep
              step={content[3].step}
              title={content[3].title}
              description={content[3].description}
              active={step === 3}
            />
          </Grid>
        </Collapse>
        {!open && (
          <Grid container justifyContent="center">
            <Typography
              variant="h4"
              style={{
                fontWeight: theme.font.semiBold
              }}
            >
              Clique para expandir.
            </Typography>
          </Grid>
        )}
      </>
    </UIPaper>
  );
}
