/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CheckCircleOutline,
  ErrorOutline,
  HelpOutline
} from '@mui/icons-material';
import { DatePicker } from '@mui/lab';
import {
  Button,
  createFilterOptions,
  Grid,
  LinearProgress,
  MenuItem,
  TextField,
  Tooltip
} from '@mui/material';
import { add, isValid } from 'date-fns';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'services';
import { ControlledAutocomplete } from 'shared/components/ControlledComponents/ControlledAutocomplete';
import { ControlledRadioGroup } from 'shared/components/ControlledComponents/ControlledRadioGroup';
import { ControlledTextField } from 'shared/components/ControlledComponents/ControlledTextField';
import { FormProps } from 'shared/components/Inputs';
import { AccessModel, DistributorModel, ProcessModel } from 'types/models/';
import { OptionsBooleanToYes } from 'utils/Constants/BooleanToYes';
import { ComercialPolitics } from 'utils/Constants/ComercialPolitics';
import { CompetitionModalities } from 'utils/Constants/CompetitionModality';
import { ProgramName } from 'utils/Constants/ProgramNames';
import { Months } from 'utils/Functions/Months';
import {
  PrivateProcessProposalAnalysisInputs,
  PrivateProcessProposalAnalysisValidation
} from './domain';

export interface PrivProcessProposalAnalysisFormParams
  extends FormProps<PrivateProcessProposalAnalysisInputs> {
  process?: Partial<ProcessModel>;
  loading?: boolean;
}

export function PrivateProcessProposalAnalysisForm({
  process,
  onComplete,
  loading = false
}: PrivProcessProposalAnalysisFormParams) {
  const { handleSubmit, watch, control, reset, setValue, formState } =
    useForm<PrivateProcessProposalAnalysisInputs>({
      resolver: yupResolver(PrivateProcessProposalAnalysisValidation),
      mode: 'all',
      defaultValues: {
        startPeriod: new Date().toISOString(),
        tradingDate: new Date().toISOString(),
        endPeriod: new Date().toISOString(),
        canExtend: 0
      }
    });

  useEffect(() => {
    if (process) {
      reset({
        tradingDate:
          typeof process?.tradingDate === 'string'
            ? process?.tradingDate
            : new Date().toISOString(),
        distributorId: process?.distributorId,
        distributorSocialName: process?.distributor?.socialName,
        distributorSold: process?.distributor?.sold,
        programName: process?.programName,
        comercialPolitics: process?.comercialPolitics,
        competitionModality: process?.competitionModality,
        tradingCode: process?.tradingCode,
        processDirect: process?.processDirect ? 1 : 0,
        startPeriod:
          typeof process?.startPeriod === 'string'
            ? process?.startPeriod
            : new Date().toISOString(),
        period: process?.period,
        endPeriod:
          typeof process?.endPeriod === 'string'
            ? process?.endPeriod
            : new Date().toISOString(),
        canExtend: process?.canExtend ? 1 : 0,
        extendMonths: process?.extendMonths
      });
    }
  }, [process]);

  const [distributorQueryRes, , distributorLoading] = useQuery<
    DistributorModel[]
  >('/distributor', {
    $limit: -1,
    $select: ['id', 'socialName', 'sold', 'name', 'type']
  });
  const distributorsData = distributorQueryRes || [];
  const filterOptions = createFilterOptions<DistributorModel>({
    limit: 20
  });

  const [distributorAccesses, , distributorAccessLoading] = useQuery<
    AccessModel[],
    AccessModel
  >('/access', {
    $limit: -1,
    $select: ['distributorId', 'id'],
    'distributorId[$ne]': 'null',
    notInclude: ['distributor', 'employee', 'balance']
  });

  const distributorSelected = distributorsData.find(
    (distributor) => distributor.id === watch('distributorId')
  );

  const hasAccess = !!(distributorAccesses || []).find(
    (access) => access.distributorId === distributorSelected?.id
  );

  return loading || distributorLoading || distributorAccessLoading ? (
    <LinearProgress />
  ) : (
    <form onSubmit={handleSubmit(onComplete)}>
      <Grid item container spacing={2}>
        <Grid item xs={12} sm={4}>
          <DatePicker
            value={watch('tradingDate') || new Date().toISOString()}
            onChange={(date: Date | null) => {
              if (date && isValid(date)) {
                setValue('tradingDate', date.toISOString());
              }
            }}
            label={'Data de abertura'}
            renderInput={(props) => (
              <TextField
                {...props}
                name={'tradingDate'}
                error={!!formState.errors.tradingDate}
                helperText={formState?.errors?.tradingDate?.message}
              />
            )}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={4}
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Grid item xs={watch('distributorId') ? 11 : 12}>
            <ControlledAutocomplete<
              PrivateProcessProposalAnalysisInputs,
              DistributorModel
            >
              name={'distributorSold'}
              control={control}
              label={'Sold to'}
              errors={formState.errors.distributorId?.message}
              options={[...distributorsData]}
              getOptionLabel={(option) =>
                option?.sold ? `${option?.id} - ${option?.sold}` : ''
              }
              filterOptions={filterOptions}
              onChange={(_, value) => {
                _.preventDefault();
                if (value && !Array.isArray(value) && value?.id) {
                  setValue('distributorId', value.id);
                  setValue('distributorSocialName', value?.name);
                  setValue('distributorSold', value?.sold);
                  if (value?.type) setValue('comercialPolitics', value?.type);
                } else {
                  setValue('distributorId', undefined);
                  setValue('distributorSocialName', undefined);
                  setValue('distributorSold', undefined);
                }
              }}
              value={
                distributorsData.find(
                  (distributor) => distributor.id === watch('distributorId')
                ) || null
              }
            />
          </Grid>
          {watch('distributorId') ? (
            <Grid container item justifyContent="flex-end" xs={1}>
              <Tooltip
                placement="top"
                title={
                  !hasAccess
                    ? 'Cliente selecionado não possui acesso, o consultor fará todas as ações.'
                    : 'Cliente com acesso! As ações serão feitas por ele.'
                }
              >
                {!hasAccess ? (
                  <ErrorOutline color="error" />
                ) : (
                  <CheckCircleOutline color="success" />
                )}
              </Tooltip>
            </Grid>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={4}>
          <ControlledTextField
            disabled
            name={'distributorSocialName'}
            control={control}
            label={'Razão Social'}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ControlledTextField
            name={'programName'}
            control={control}
            errors={formState.errors.programName?.message}
            label={'Nome do Programa'}
            select
          >
            {ProgramName.map((programName) => (
              <MenuItem value={programName} key={programName}>
                {programName}
              </MenuItem>
            ))}
          </ControlledTextField>
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={4}
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Grid item xs={11}>
            <ControlledTextField
              name={'comercialPolitics'}
              control={control}
              errors={formState.errors.comercialPolitics?.message}
              label={'Política Comercial'}
              select
              disabled={!!distributorSelected?.type}
            >
              {ComercialPolitics.map((comercialPolitic) => (
                <MenuItem value={comercialPolitic} key={comercialPolitic}>
                  {comercialPolitic}
                </MenuItem>
              ))}
            </ControlledTextField>
          </Grid>
          <Grid container item justifyContent="flex-end" xs={1}>
            <Tooltip
              placement="top"
              title="A Política Comercial será atualizada automaticamente caso o cliente esteja cadastrado corretamente."
            >
              <HelpOutline color="primary" />
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4}>
          <ControlledTextField
            name={'competitionModality'}
            control={control}
            errors={formState.errors.competitionModality?.message}
            label={'Modalidade de concorrência'}
            select
          >
            {CompetitionModalities.map((competitionModality) => (
              <MenuItem value={competitionModality} key={competitionModality}>
                {competitionModality}
              </MenuItem>
            ))}
          </ControlledTextField>
        </Grid>
        <Grid item xs={12} sm={4}>
          <ControlledTextField
            name={'tradingCode'}
            control={control}
            errors={formState.errors.tradingCode?.message}
            label={'Número do processo'}
            autoComplete="nope"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ControlledTextField
            name={'processDirect'}
            control={control}
            errors={formState.errors.processDirect?.message}
            label={'Forma de participação'}
            value={+watch('processDirect') ? '1' : '0'}
            select
          >
            <MenuItem value={'1'}>Direta</MenuItem>
            <MenuItem value={'0'}>Indireta</MenuItem>
          </ControlledTextField>
        </Grid>
        <Grid item xs={12} sm={4}>
          <DatePicker
            value={watch('startPeriod') || new Date().toISOString()}
            onChange={(date: Date | null) => {
              if (date && isValid(date)) {
                setValue('startPeriod', date.toISOString());
                if (watch('period')) {
                  setValue(
                    'endPeriod',
                    add(date, {
                      months: watch('period')
                    }).toISOString()
                  );
                }
              }
            }}
            clearable
            label={'Inicio de fornecimento'}
            renderInput={(props) => (
              <TextField
                {...props}
                name={'startPeriod'}
                error={!!formState.errors.startPeriod}
                helperText={formState?.errors?.startPeriod?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ControlledTextField
            name={'period'}
            control={control}
            errors={formState.errors.period?.message}
            label={'Período de fornecimento (meses)'}
            onChange={(event) => {
              const { value } = event.target;
              setValue('period', +value);
              if (+value && watch('startPeriod')) {
                setValue(
                  'endPeriod',
                  add(new Date(watch('startPeriod')), {
                    months: +value
                  }).toISOString()
                );
              }
            }}
            value={watch('period')}
            select
          >
            {Months().map((month) => (
              <MenuItem value={month} key={month}>
                {month}
              </MenuItem>
            ))}
          </ControlledTextField>
        </Grid>
        <Grid item xs={12} sm={4}>
          <DatePicker
            value={watch('endPeriod') || new Date().toISOString()}
            onChange={() => {}}
            disabled
            label={'Término de fornecimento'}
            renderInput={(props) => <TextField {...props} name={'endPeriod'} />}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ControlledRadioGroup
            control={control}
            label={'Prorrogar'}
            name={'canExtend'}
            options={OptionsBooleanToYes}
            value={+watch('canExtend')}
            onChange={(event) => {
              const { value } = event.target;
              if (value) {
                setValue('canExtend', value === '0' ? 0 : 1);
                if (value === '0') setValue('extendMonths', null);
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ControlledTextField
            name={'extendMonths'}
            control={control}
            errors={formState.errors.extendMonths?.message}
            label={'Meses a ser prorrogado'}
            select
            disabled={+watch('canExtend') !== 1}
          >
            {Months().map((month) => (
              <MenuItem value={month} key={month}>
                {month}
              </MenuItem>
            ))}
          </ControlledTextField>
        </Grid>
        <Grid item container justifyContent="flex-end">
          <Grid item xs={12} sm={2}>
            <Button type={'submit'} fullWidth variant={'contained'}>
              Avançar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}
