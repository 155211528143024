import { ProcessItemsColumns } from 'modules/Process/shared/Tables/ProcessItems/constants/columns';
import { TableColumnsDistributorCantSee } from 'utils/Constants/ColumnsConsultantCantSee';

export function ProcessItemRemoveColumns(isDistributor: boolean) {
  if (!isDistributor) {
    return [...ProcessItemsColumns];
  }
  return [...ProcessItemsColumns].filter(
    (values) => !TableColumnsDistributorCantSee.includes(values.field)
  );
}
