import { Box, Container, Grid } from '@mui/material';
import { ApproveProcessDiscountAction } from 'modules/Dashboard/Cards/Actions';
import { FinishRegisterProcessAction } from 'modules/Dashboard/Cards/Actions/ConsultantActions/FinishRegisterProcess';
import { DistributorIncompleteProcessAction } from 'modules/Dashboard/Cards/Actions/DistributorActions/IncompleteProcess';
import { InformDiscountAction } from 'modules/Dashboard/Cards/Actions/FinancierActions/InformDiscount';
import { DashboardMetricsCards } from 'modules/Dashboard/Cards/DashboardMetrics';
import { DashboardPendency } from 'modules/Dashboard/Fragments/Pendency';
import { ProcessTable } from 'modules/Dashboard/Tables/ProcessTable';
import { useAuth } from 'Providers/Auth';
import React from 'react';
import { useQuery } from 'services/api/';
import { ProcessModel } from 'types/models';
import { Paginated } from 'types/services';
import { processFields } from './Constants';

export function Dashboard() {
  const itemsPerPage = 10;

  const { auth } = useAuth();

  const [responseData, resend, loading] = useQuery<
    Paginated<ProcessModel>,
    ProcessModel
  >('/process', {
    $limit: itemsPerPage,
    $select: processFields,
    $skip: 0
  });

  return (
    <Container maxWidth={'lg'}>
      <Grid container spacing={4}>
        <Grid item>
          <DashboardMetricsCards />
        </Grid>
        {auth?.distributor ? <DistributorIncompleteProcessAction /> : <> </>}
        {auth?.employee?.accessLevel === 'consultant' ? (
          <FinishRegisterProcessAction />
        ) : (
          <> </>
        )}
        {auth?.employee?.accessLevel === 'financier' ? (
          <InformDiscountAction />
        ) : (
          <> </>
        )}
        {auth?.employee?.accessLevel === 'manager' ? (
          <ApproveProcessDiscountAction />
        ) : (
          <> </>
        )}
        <ProcessTable
          pageSize={itemsPerPage}
          responseData={responseData}
          loading={loading}
          resend={resend}
        />
        {auth?.employee?.accessLevel !== 'admin' ? (
          <Grid item xs={12} md={4}>
            <Box height={'447px'}>
              <DashboardPendency
                distributorId={auth?.distributorId}
                employeeId={auth?.employeeId}
              />
            </Box>
          </Grid>
        ) : null}
      </Grid>
    </Container>
  );
}
