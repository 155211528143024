import { parse, isDate, isValid } from 'date-fns';

export function ParseDateStringYup(value: string, originalValue: string) {
  if (isDate(originalValue) && isValid(originalValue)) return originalValue;
  const firstParse = parse(originalValue, 'yyyy-MM-dd', new Date());
  const secondParse = parse(originalValue, 'dd/mm/yyyy', new Date());
  if (isDate(firstParse) && isValid(firstParse)) return firstParse;
  if (isDate(secondParse) && isValid(secondParse)) return secondParse;
  return new Date();
}
