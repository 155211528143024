import { useGridSlotComponentProps } from '@mui/x-data-grid';
import { PaginationProps } from '@mui/material/Pagination';
import React from 'react';
import { UIPagination } from 'shared/components/Pagination';

interface UIPaginationProps extends PaginationProps {
  itemSize?: 'medium' | 'small';
}

export function UIDataGridApiPagination({ ...rest }: UIPaginationProps) {
  const { state, apiRef } = useGridSlotComponentProps();
  return (
    <UIPagination
      count={state.pagination.pageCount}
      page={state.pagination.page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
      {...rest}
    />
  );
}
