import React, { useState } from 'react';
import { Box, Table, TableBody, TableContainer } from '@mui/material';
import { toast } from 'react-toastify';
import { ProcessOrderHeader } from 'modules/Process/shared/Tables/ProcessOrder/ProcessOrderHeader';
import { OrderItems } from 'types/models/Order/Order.model';
import { ProcessOrderE2ORow } from 'modules/Process/shared/Tables/ProcessOrder/ProcessOrderE2ORow';

interface ProcessOrderProps {
  rows: OrderItems[];
  onComplete: (orderItems: OrderItems[]) => void;
}

export function ProcessOrderE2O({ rows, onComplete }: ProcessOrderProps) {
  const [selected, setSelected] = useState<OrderItems[]>([]);

  const handleSelectAll = () => {
    if (selected.length === rows.length) {
      setSelected([]);
    } else {
      setSelected([...rows]);
    }
  };

  const handleSelect = (row: OrderItems) => {
    const findIndex = selected.findIndex((data) => data.id === row.id);
    if (findIndex === -1) {
      setSelected([...selected, row]);
    } else {
      const values = [...selected];
      values.splice(findIndex, 1);
      setSelected([...values]);
    }
  };

  const handleChangeQuantity = (id: number, quantity: number) => {
    const indexOf = selected.findIndex((row) => row.id === id);

    if (indexOf !== -1) {
      const selectedProducts = selected;
      selectedProducts[indexOf] = {
        ...selectedProducts[indexOf],
        quantity
      };
      setSelected([...selectedProducts]);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (selected.length === 0) {
      toast.error('Adicione a quantidade em um produto pelo menos');
      return;
    }

    const withoutQuantity = selected
      .filter((selectedProduct) => !selectedProduct.quantity)
      .map((result) => result.process_product?.itemNumber);

    if (withoutQuantity.length > 0) {
      toast.error(
        `Adicione a quantidade nos items: ${JSON.stringify(withoutQuantity)}`
      );
      return;
    }
    onComplete(selected);
  };

  return (
    <form id={'order-items-table'} onSubmit={handleSubmit}>
      <Box bgcolor={'white'}>
        <TableContainer>
          <Table>
            <ProcessOrderHeader handleSelectAll={handleSelectAll} />
            <TableBody>
              {rows.map((orderItem) => (
                <ProcessOrderE2ORow
                  orderItems={orderItem}
                  key={orderItem.id}
                  onChange={handleSelect}
                  onChangeQuantity={handleChangeQuantity}
                  isSelected={
                    !!selected.find((select) => select.id === orderItem.id)
                  }
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </form>
  );
}
