import { GridColDef } from '@mui/x-data-grid';
import { ParseDateString } from 'utils/Functions/ParseDateString';

export const pendenciesColumns: GridColDef[] = [
  { field: 'id', headerName: 'ID', hide: true },
  {
    field: 'text',
    width: 150,
    headerName: 'Pendências',
    valueFormatter: ({ value, row }) =>
      `${row?.process?.city || ''} - ${value}` ?? '-',
    flex: 1
  },
  {
    field: 'createdAt',
    width: 100,
    headerName: 'Vencimento',
    type: 'date',
    valueFormatter: ({ value }) => ParseDateString(String(value))
  }
];
