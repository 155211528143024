import { GridColDef } from '@mui/x-data-grid';
import React from 'react';
import { UIChip } from 'shared/components/Chip';
import { PoliticalPriceType } from 'utils/Constants/PoliticalPriceType';
import { States } from 'utils/Enums';
import { FormatPercent } from 'utils/Functions/FormatPercent';
import { GetPreApprovedPrice } from 'utils/Functions/GetPreApprovedPrice';
import { MoneyMask } from 'utils/masks';

export const ProductUfColumns: GridColDef[] = [
  { field: 'id', headerName: 'ID', hide: true },
  {
    field: 'type',
    headerName: 'Tipo',
    width: 150,
    renderCell: ({ value }) => (
      <UIChip
        color={PoliticalPriceType[value?.toString() || 'distributor']?.color}
        title={PoliticalPriceType[value?.toString() || 'distributor']?.label}
        height="20px"
        width="80%"
      />
    )
  },
  {
    field: 'state',
    width: 168,
    headerName: 'Estado',
    valueGetter: ({ row }) => {
      return States[row?.uf] || '-';
    }
  },
  {
    field: 'price',
    headerName: '(R$) Preço Lista (UNI)',
    width: 200,
    valueFormatter: ({ value }) => MoneyMask(String(value))
  },
  {
    field: 'preDiscount',
    headerName: '(%) Desconto pré-aprovado\n',
    width: 200,
    valueFormatter: ({ value }) =>
      value ? FormatPercent(String(+value * 100 || 0)) : '-'
  },
  {
    field: 'referentialPrice',
    headerName: '(R$) Preço referencial (UNI)',
    width: 200,
    valueFormatter: ({ value }) => MoneyMask(String(value))
  },
  {
    field: 'preApprovedPrice',
    headerName: '(R$) Preço pré-aprovado (UNI)',
    width: 200,
    valueGetter: ({ row }) => {
      if (row?.price && row?.preDiscount)
        return GetPreApprovedPrice(row?.preDiscount, row?.price);
      return 'R$ 00,00';
    }
  }
];
