/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { ProcessItemFormTable } from 'modules/Process/PublicProcess/Fragments/ProcessItem/ProcessItemFormTable';
import { usePatch, usePost, useQuery } from 'services';
import { OcrProducts, ProcessProductModel } from 'types/models';
import { toast } from 'react-toastify';
import { AttachmentModel } from 'types/models/Attachments/Attachment.model';
import { PdfRender } from 'shared/components/Pdf/HtmlRender';
import { Paginated } from 'types/services';
import { Button, Collapse, Grid } from '@mui/material';
import { ConsultantProcessItemsProps } from 'modules/Process/PublicProcess/Forms/ConsultantProcessForm/ConsultantProcessItems/ConsultantProcessItems';
import { useDelete } from 'services/api/UseDelete';

export function ConsultantProcessItems({
  id,
  onComplete
}: ConsultantProcessItemsProps) {
  const [processProductData, processProductRefetch, loading] = useQuery<
    ProcessProductModel[]
  >('/process-products', {
    $limit: -1,
    processId: id
  });

  const [attachmentData] = useQuery<Paginated<Partial<AttachmentModel>>>(
    `/attachments?category=Edital&tableId=${id}&table=process`
  );
  const pdfUrl = attachmentData?.data[0]?.key || '';

  const [ocrData, fetchOcrData] = useQuery<OcrProducts[]>(
    `/ocr/${attachmentData?.data[0]?.id}/products`,
    {
      fetchOnInitialize: false
    }
  );

  useEffect(() => {
    if (attachmentData?.data[0]?.id && attachmentData?.data[0]?.ocr) {
      fetchOcrData();
    }
  }, [attachmentData]);

  const [, setProcessItems] = useState<Partial<ProcessProductModel>[]>([]);

  const [, processItemPost] = usePost<
    Partial<ProcessProductModel>,
    Partial<ProcessProductModel>
  >('/process-products');

  const [, processItemPatch] =
    usePatch<Partial<ProcessProductModel>>('/process-products');

  const [, processItemDelete] =
    useDelete<Partial<ProcessProductModel>>('/process-products');

  /* POST function to save a process item */
  const handleAddProduct = async (data: Partial<ProcessProductModel>) => {
    if (id) {
      const newData = data;
      newData.processId = +id;
      const response = await processItemPost(newData);
      if (response) {
        toast.success(`Item cadastrado com sucesso`);
        await processProductRefetch();
      }
    } else {
      toast.info(
        'Cadastre os dados basicos do processo antes de prosseguir com a adicao de produtos'
      );
    }
  };

  /* PATCH function to update a process item */
  const handleEditProduct = async (
    data: Partial<ProcessProductModel>,
    array: Partial<ProcessProductModel>[]
  ) => {
    if (data.id) {
      await processItemPatch(data, data.id);
      setProcessItems([...array]);
      await processProductRefetch();
    }
  };

  const handleComplete = () => {
    const hasProductsNotConcluded = (processProductData || [])?.some(
      (processProduct) => !processProduct.nfPrice
    );
    if (hasProductsNotConcluded) {
      toast.warn(
        `Existem produtos não finalizados, termine o cadastro dos itens faltantes.`
      );
      return;
    }
    if (processProductData && processProductData.length > 0) {
      onComplete(processProductData);
    } else {
      toast.warn(`Preencha ao menos um item para seguir para a próxima etapa.`);
    }
  };

  const handleDelete = async (itemId: number) => {
    try {
      await processItemDelete(itemId);
      toast.success(`Item excluído com sucesso.`);
      await processProductRefetch();
    } catch (error) {
      toast.error(`Ocorreu um erro ao excluir item.`);
    }
  };

  return (
    <>
      <Collapse in={!!pdfUrl}>
        <PdfRender url={pdfUrl} xs={12} height={700} />
      </Collapse>
      <ProcessItemFormTable
        onAddProduct={handleAddProduct}
        onEditProduct={handleEditProduct}
        processItemData={processProductData || []}
        tableProps={{ loading }}
        isDistributor={false}
        processId={+id}
        onDelete={handleDelete}
        ocrProducts={ocrData}
      />
      <Grid container item direction={'row-reverse'} mt={2} mb={2}>
        <Grid item xs={12} sm={2}>
          <Button
            type={'submit'}
            onClick={handleComplete}
            fullWidth
            variant={'contained'}
          >
            Avançar
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
