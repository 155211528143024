/* eslint-disable @typescript-eslint/no-use-before-define */
import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Typography
} from '@mui/material';
import InformDiscountProcessInformation from 'modules/Process/InformDiscount/Modals/InformDiscountModal/shared/ProcessInformation';
import InformDiscountProductInformation from 'modules/Process/InformDiscount/Modals/InformDiscountModal/shared/ProductInformation';
import InformDiscountProductSubtitle from 'modules/Process/InformDiscount/Modals/InformDiscountModal/shared/Subtitles';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { FetchPatch, FetchQuery } from 'services';
import { ControlledTextField } from 'shared/components/ControlledComponents/ControlledTextField';
import {
  DiscountOrderModel,
  ProcessModel,
  ProcessProductModel,
  ProductsModel
} from 'types/models';
import { PercentMask } from 'utils/masks';
import {
  getAlterData,
  InformDiscountConsultant,
  InformDiscountConsultantSchema
} from './domain';

type Props = {
  selectedProduct: ProcessProductModel;
  process: ProcessModel;
  open: boolean;
  onClose: () => void;
  patchDiscountOrder: FetchPatch<DiscountOrderModel>;
  patchProcessProduct: FetchPatch<ProcessProductModel>;
  processProductsRefetch: FetchQuery<
    ProcessProductModel[],
    ProcessProductModel
  >;
  loading: boolean;
  product?: ProductsModel;
};
export default function ConsultantInformDiscountModal({
  selectedProduct,
  process,
  open,
  onClose,
  patchDiscountOrder,
  processProductsRefetch,
  patchProcessProduct,
  loading,
  product
}: Props) {
  const { handleSubmit, formState, control, reset } =
    useForm<InformDiscountConsultant>({
      resolver: yupResolver(InformDiscountConsultantSchema),
      mode: 'onTouched',
      defaultValues: {
        discountValue: selectedProduct?.discountValue
          ? PercentMask(selectedProduct?.discountValue)
          : '',
        justify: ''
      }
    });

  useEffect(() => {
    if (selectedProduct?.nfPrice) {
      reset({
        discountValue: selectedProduct?.discountValue
          ? PercentMask(selectedProduct?.discountValue)
          : '',
        justify: ''
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct]);

  const onComplete = async (data: InformDiscountConsultant) => {
    try {
      if (
        selectedProduct?.priceList &&
        selectedProduct?.discount_orders?.[0]?.id
      ) {
        const { newDiscount, institutionValue, nfPrice } = getAlterData(
          selectedProduct,
          data.discountValue
        );
        await patchDiscountOrder(
          {
            ...data,
            discountValue: newDiscount,
            status: 'contested'
          },
          selectedProduct?.discount_orders?.[0]?.id
        );
        await patchProcessProduct(
          {
            nfPrice,
            institutionValue,
            discountValue: newDiscount,
            discount: true
          },
          selectedProduct?.id
        );
        toast.success('Desconto foi contestado com sucesso');
        processProductsRefetch();
        onClose();
      } else {
        toast.error(
          'Erro ao contestar o desconto, alguma informação está incompleta'
        );
      }
    } catch (error) {
      toast.error('Erro ao contestar o desconto');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        <Typography variant="h3" align={'center'}>
          Contestar valores
        </Typography>
        <IconButton aria-label={'close'} onClick={onClose} size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <InformDiscountProductSubtitle subtitle="Informações do processo" />
      <InformDiscountProcessInformation process={process} product={product} />
      <InformDiscountProductSubtitle subtitle="Item selecionado" />
      <InformDiscountProductInformation
        processProduct={selectedProduct}
        product={product}
      />
      <form id={'inform-consultant-result'} onSubmit={handleSubmit(onComplete)}>
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ControlledTextField
                name={'discountValue'}
                control={control}
                label={'Desconto solicitado'}
                pattern="percentage"
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledTextField
                name={'justify'}
                control={control}
                label={'Justificativa'}
                multiline
                minRows={3}
                inputProps={{
                  maxLength: 256
                }}
                variant={'outlined'}
              />
            </Grid>
          </Grid>
        </Box>
      </form>
      <Divider />
      <DialogActions>
        <Grid
          item
          container
          direction={'row'}
          justifyContent={'space-between'}
          xs={12}
        >
          <Grid item xs={3}>
            <Button
              fullWidth
              color={'primary'}
              onClick={onClose}
              variant="outlined"
            >
              Voltar
            </Button>
          </Grid>
          <Grid container item justifyContent={'space-between'} xs={6}>
            {loading ? (
              <Box width="100%" p={2}>
                <LinearProgress color="primary" />
              </Box>
            ) : (
              <Button
                fullWidth
                color={'primary'}
                variant="contained"
                disabled={!formState.isValid || loading}
                form={'inform-consultant-result'}
                type={'submit'}
              >
                Contestar valor
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
