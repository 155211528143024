import VMasker from 'vanilla-masker';

/**
 * return ['(99) 9999-9999', '(99) 99999-9999']
 */
export const PhoneMask = (value: string): string => {
  const pattern = ['(99) 9999-9999', '(99) 99999-9999'];
  if (value.length > 10) {
    return VMasker.toPattern(value, pattern[1]);
  }
  return VMasker.toPattern(value, pattern[0]);
};

export const PhoneReplaceRegex = /[() -]/gm;

export const PhoneRemoveMask = (phone: string) => {
  return phone.replace(PhoneReplaceRegex, '');
};

export const PhoneRegex = /(?:\()[0-9]{2}(?:\))\s[0-9]{4,5}-[0-9]{4}/;
