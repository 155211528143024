import { PercentMask } from 'utils/masks';
import VMasker from 'vanilla-masker';

const format = (value: number) => {
  if (value >= 100) {
    return VMasker.toPattern(value.toFixed(2), '999,99%');
  }

  return PercentMask(`0${value.toFixed(2)}`.slice(-5));
};

export const FormatPercent = (value?: string | number | null) => {
  if (value === 0 || value === '0') {
    return '00,00%';
  }
  if (value) {
    if (+value) {
      return typeof value === 'string' ? format(+value) : format(value);
    }
  }
  return '-';
};
