import SearchIcon from '@mui/icons-material/Search';
import { Grid, InputAdornment, MenuItem, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { UITable } from 'shared/components';
import { UITextField } from 'shared/components/Inputs';
import { UIDataGridApiPagination } from 'shared/components/Tables/DataGridApiPagination';
import { UIToolbar } from 'shared/components/Tables/ToolbarTable';
import { useDelete } from 'services/api/UseDelete';
import { ThemeColors } from 'shared/enum';
import { Cities, CitiesEmployee, UfsRegionals } from 'types/models';
import { States, STATES_ARRAY } from 'utils/Enums';
import { EmployeeCityColumns } from './columns';

type Props = {
  cities: Cities[];
  citiesEmployees: CitiesEmployee[];
  ufsRegionals: UfsRegionals[];
  loading: boolean;
  refetch: () => void;
};

export default function EmployeeCityTable({
  cities = [],
  citiesEmployees = [],
  ufsRegionals = [],
  loading,
  refetch
}: Props) {
  const [filterUf, setFilterUf] = useState<string>('all');
  const citiesIds = citiesEmployees.map((cityEmployee) => cityEmployee.cityId);
  const realCities =
    cities.filter((city) => (city.id ? citiesIds.includes(city.id) : false)) ||
    [];
  const filterByUf =
    realCities.filter((city) =>
      filterUf !== 'all' ? city.uf === filterUf : true
    ) || [];

  const { control, watch } = useForm<{ search: string }>();
  const filterBySearch = filterByUf.filter(
    (city) => !!city.city?.toUpperCase()?.match(watch('search')?.toUpperCase())
  );
  const mapRows = filterBySearch?.map((city) => ({
    ...city,
    createdAt: citiesEmployees.find(
      (cityEmployee) => cityEmployee.cityId === city?.id
    )?.createdAt,
    regional: ufsRegionals.find((uf) => uf.uf === city.uf)?.regional || '-'
  }));

  const [, deleteCityEmployee] = useDelete<CitiesEmployee>(`/cities-employees`);

  const unSyncEmployee = async (selectedCity: Cities) => {
    const findCityEmployee = citiesEmployees.find(
      (cityEmployee) => cityEmployee.cityId === selectedCity.id
    );
    if (findCityEmployee?.id) {
      await deleteCityEmployee(findCityEmployee?.id);
      refetch();
    }
  };

  return (
    <>
      <Grid container item xs={12} sm={6} md={7}>
        <Grid container item xs={8}>
          <Controller
            name={'search'}
            control={control}
            render={({ field }) => (
              <UITextField
                label={'Pesquisar Municípios'}
                variant={'filled'}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position={'start'}>
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
                fullWidth
                {...field}
              />
            )}
          />
        </Grid>
        <Grid container item xs={4}>
          <UITextField
            label={'Estado'}
            value={filterUf}
            fullWidth
            variant={'filled'}
            select
            onChange={(e) => setFilterUf(e.target.value)}
            InputProps={{
              disableUnderline: true
            }}
          >
            <MenuItem value={'all'}>Todos</MenuItem>
            {STATES_ARRAY.map(({ uf }) => (
              <MenuItem value={uf} key={uf}>
                {States[uf]}
              </MenuItem>
            ))}
          </UITextField>
        </Grid>
        <UITable
          rows={mapRows}
          loading={loading}
          columns={EmployeeCityColumns}
          rowHeight={36}
          headerHeight={36}
          pageSize={7}
          actions={[
            {
              label: 'Desvincular',
              onClick: unSyncEmployee
            }
          ]}
          components={{
            Toolbar: () => (
              <UIToolbar
                title={
                  <Typography
                    variant={'h3'}
                    style={{ color: ThemeColors.TextDarkGrey, fontWeight: 500 }}
                  >
                    Municípios Vinculados
                  </Typography>
                }
                elementsTopEnd={
                  <>
                    <UIDataGridApiPagination itemSize="medium" />
                  </>
                }
              />
            )
          }}
        />
      </Grid>
    </>
  );
}
