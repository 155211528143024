import React from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { SimpleFormModalParams } from 'shared/components/Modal/SimpleFormModal/SimpleFormModal';

export function SimpleFormModal({
  title,
  onClose,
  children,
  bottomActions,
  ...rest
}: SimpleFormModalParams) {
  return (
    <Dialog {...rest}>
      <DialogTitle>
        <Box mt={2} mb={2}>
          <Typography variant="h2" align={'center'}>
            {title}
          </Typography>
        </Box>

        {onClose && (
          <IconButton
            aria-label={'close'}
            onClick={(event) => onClose(event.target, 'escapeKeyDown')}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent dividers>
        <Box pt={2} pb={2}>
          {children}
        </Box>
      </DialogContent>
      {bottomActions && bottomActions}
    </Dialog>
  );
}
