import { Button, CircularProgress, Grid } from '@mui/material';
import { EmployeeProfileForm } from 'modules/Profiles/employee/forms';
import { ProfilePictureForm } from 'modules/Profiles/shared/form/ProfilePicture';
import { useAuth } from 'Providers/Auth';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { usePatch } from 'services';
import UIPaper from 'shared/components/Paper';
import { AccessModel } from 'types/models';
import { CatchAccessErrorValidate } from 'utils/Functions/Validate/AxiosAccessValidate';
import { UpdateEmployee } from '../../forms/domain';

export interface ProfileEmployeeFragmentProps {
  access?: AccessModel;
}

export function ProfileEmployeeFragment({
  access
}: ProfileEmployeeFragmentProps) {
  const history = useHistory();
  const [, fetchAccess, accessLoading, errorAccess] = usePatch<AccessModel>(
    '/access',
    {
      willFail: true
    }
  );
  const [, fetchEmployee, employeeLoading] = usePatch<AccessModel>('/employee');
  const handleSubmitForm = async (data: UpdateEmployee) => {
    try {
      if (access?.id && data) {
        const fetchedAccess = await fetchAccess(data, access?.id);
        if (fetchedAccess) {
          await fetchEmployee(data.employee, data?.employee?.id);
          history.goBack();
          toast.success('Informações salvas com sucesso');
        }
      }
    } catch (error) {
      toast.error('Erro ao atualizar usuário');
    }
  };

  useEffect(() => {
    if (errorAccess) {
      CatchAccessErrorValidate(errorAccess);
    }
  }, [errorAccess]);

  const { auth } = useAuth();

  return (
    <UIPaper title="Informações do usuário">
      <Grid container spacing={2}>
        <Grid
          item
          container
          direction={'column'}
          xs={12}
          md={4}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <ProfilePictureForm
            defaultPicture={access?.profilePicture}
            accessId={access?.id}
          />
        </Grid>

        <Grid item xs={12} md={8} sx={{ pb: 2 }}>
          <EmployeeProfileForm
            access={access}
            onComplete={handleSubmitForm}
            isAdmin={auth?.employee?.accessLevel === 'admin'}
          />
        </Grid>
        <Grid
          item
          container
          justifyContent={{ md: 'flex-end' }}
          xs={12}
          sx={{ mr: { md: 3 }, mb: 2 }}
        >
          <Grid container item xs={12} md={3} justifyContent="center">
            {accessLoading || employeeLoading ? (
              <CircularProgress size={25} />
            ) : (
              <Button
                type="submit"
                form="profile-update"
                variant={'contained'}
                fullWidth
              >
                Confirmar
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </UIPaper>
  );
}
