export const PoliticalPriceType = {
  distributor: {
    label: 'Distribuidor',
    color: 'pink'
  },
  hospital: {
    label: 'Hospital',
    color: 'blue'
  },
  dealership: {
    label: 'Concessionária',
    color: 'green'
  },
  basicBasket: {
    label: 'Cesta básica',
    color: 'orange'
  }
};
