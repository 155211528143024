/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { Box, Card, Container, Grid, Typography } from '@mui/material';
import { LoginForm } from 'modules/Login/Forms/LoginForm';
import { LoginFormResponse } from 'modules/Login/Forms/LoginForm/domain';
import { ForgotPasswordModal } from 'modules/Login/Modals/ForgotPasswordModal';

import { usePost } from 'services/api/UsePost';
import {
  ForgotPassword,
  MakeLogin,
  ResponseLogin
} from 'pages/Login/domain/requestResponses';
import { LoginSuccess } from 'pages/Login/domain/LoginSuccess';
import { TermsOfUseModal } from 'modules/Login/Modals/TermsOfUse';
import { toast } from 'react-toastify';
import { ForgotPasswordDomainResponse } from 'modules/Login/Forms/ForgotPasswordForm/domain';
import { useQuery } from 'services';
import { TermsModel } from 'types/models/Terms/Terms.model';

export function Login() {
  const [forgotPassword, setForgotPassword] = useState<string>();
  const [termsOfUse, setTermsOfUse] = useState<TermsModel[]>();

  const [loginData, fetchLogin] = usePost<MakeLogin, ResponseLogin>(
    '/authentication'
  );
  const [, fetchForgot] = usePost<ForgotPassword>('/forgotPassword');
  const [, fetchTermsToApprove] = useQuery<TermsModel[], TermsModel>(`/terms`, {
    fetchOnInitialize: false,
    toApprove: true
  });

  const handleMakeLogin = async (data: LoginFormResponse) => {
    fetchLogin({
      email: data.email,
      password: data.password,
      strategy: 'local'
    });
  };

  const handleAcceptTerms = () => {
    if (loginData) {
      LoginSuccess(loginData);
      window.location.reload();
    }
  };

  const getTermsToApprove = async () => {
    try {
      if (loginData?.access?.id) {
        const terms = await fetchTermsToApprove({
          additionalAxiosOptions: {
            headers: {
              Authorization: `Bearer ${loginData?.accessToken}`
            }
          }
        });
        if (!terms || terms?.length === 0) {
          LoginSuccess(loginData);
          window.location.reload();
        } else {
          setTermsOfUse(terms);
        }
      }
    } catch (error) {
      toast.error('Erro ao buscar termos');
    }
  };

  useEffect(() => {
    if (loginData?.accessToken) {
      getTermsToApprove();
    }
  }, [loginData]);

  const handleForgotPassword = async (data: ForgotPasswordDomainResponse) => {
    const response = await fetchForgot({ email: data.email });
    if (response) {
      toast.success('Email enviado com sucesso');
      setForgotPassword(undefined);
    }
  };

  return (
    <Container maxWidth={'sm'}>
      <Box pt={12} />
      <Card elevation={0}>
        <Box p={2}>
          <Grid item>
            <Box pt={1} pb={1}>
              <Typography variant="h1">Vendas Institucionais</Typography>
            </Box>
          </Grid>

          <Grid item>
            <Typography variant="h3" component="p">
              Bem-vindo a Plataforma de Análise de processos
            </Typography>
          </Grid>
          <Grid item>
            <LoginForm
              onHandleComplete={handleMakeLogin}
              onHandleForgotClick={(email) => setForgotPassword(email || ' ')}
            />
          </Grid>
        </Box>
      </Card>

      <ForgotPasswordModal
        email={forgotPassword}
        open={!!forgotPassword}
        onHandleComplete={handleForgotPassword}
        onClose={() => setForgotPassword(undefined)}
      />
      <TermsOfUseModal
        onComplete={handleAcceptTerms}
        open={!!termsOfUse}
        onClose={() => setTermsOfUse(undefined)}
        termsOfUse={termsOfUse || []}
        token={`Bearer ${loginData?.accessToken}`}
        accessId={loginData?.access?.id}
      />
    </Container>
  );
}
