import { ProcessProductModel } from 'types/models';
import { MoneyRemoveMask, PercentMaskRemove } from 'utils/masks';
import { PrivateScopeRegistrationInputs } from '.';

export const submitAdapter = (
  data: PrivateScopeRegistrationInputs
): Partial<ProcessProductModel> => {
  return {
    ...data,
    rivalInitialValue: +MoneyRemoveMask(data.rivalInitialValue || ''),
    rivalFinalValue: +MoneyRemoveMask(data.rivalFinalValue || ''),
    priceList: +MoneyRemoveMask(data.priceList || ''),
    finalValue: +MoneyRemoveMask(data.finalValue || ''),
    initialValue: +MoneyRemoveMask(data.initialValue || ''),
    discountValue: +PercentMaskRemove(data.discountValue || ''),
    markup: +PercentMaskRemove(data.markup || ''),
    winner: !!+data.winner,
    quantity: data.initialQuantity,
    unity: 'UNI'
  };
};
