import { Box, Container, Typography } from '@mui/material';
import { DistributorProcessForm } from 'modules/Process/PublicProcess/Forms/DistributorProcessForm';
import { CreateProcessBreadcrumb } from 'modules/Process/PublicProcess/Fragments/CreateProcess/shared/Breadcrumb';
import { DistCreateProcessDescriptionSteps } from 'modules/Process/PublicProcess/Fragments/CreateProcess/distributor/DescriptionSteps';
import { DistCreateProcessTabs } from 'modules/Process/PublicProcess/Fragments/CreateProcess/distributor/Tabs';
import { useAuth } from 'Providers/Auth';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { usePatch, useQuery } from 'services';
import { theme } from 'shared/theme';
import { ProcessModel } from 'types/models';
import { AttachmentModel } from 'types/models/Attachments/Attachment.model';
import { steps } from './Constants/steps';

export function DistributorCreateProcess() {
  const queryParams = new URLSearchParams(useLocation().search);

  const id = queryParams.get('processo');
  const { auth } = useAuth();

  const [process] = useQuery<ProcessModel>(`/process/${id}`, {
    fetchOnInitialize: !!id,
    $select: ['step']
  });
  const [, patchProcess] = usePatch<ProcessModel>(`/process`);

  const attachmentUseQuery = useQuery<AttachmentModel[], AttachmentModel>(
    `/attachments`,
    {
      table: 'process',
      $limit: '-1',
      tableId: id ?? '',
      accessId: auth?.id,
      fetchOnInitialize: false
    }
  );

  const [step, setStep] = useState(0);
  const handleChangeStep = (newValue: number) => {
    setStep(newValue);
    if (id) {
      patchProcess(
        {
          step: steps[newValue]
        },
        +id
      );
    }
  };

  useEffect(() => {
    if (process?.step) {
      const indexOf = steps.indexOf(process?.step);
      setStep(indexOf === -1 ? 0 : indexOf);
    }
  }, [process]);

  return (
    <>
      <Container>
        <Box mb={4}>
          <CreateProcessBreadcrumb />
        </Box>
        <Box mb={3}>
          <Typography
            variant="h2"
            style={{
              color: theme.colors.TextDarkGrey,
              fontSize: theme.font.sizes.large
            }}
          >
            Adicionar processo
          </Typography>
        </Box>
        <DistCreateProcessTabs
          id={id}
          step={step}
          handleChangeStep={handleChangeStep}
        />
        <DistCreateProcessDescriptionSteps step={step} />
        <DistributorProcessForm
          id={id || undefined}
          step={step}
          handleChangeStep={handleChangeStep}
          attachmentsQuery={attachmentUseQuery}
        />
        <Box mt={4} />
      </Container>
    </>
  );
}
