import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { theme } from 'shared/theme';

export type DeleteAttachmentModalProps = {
  open: boolean;
  setOpen: ({ open: boolean }) => void;
  onDelete: () => void;
};

export function DeleteAttachmentModal({
  open,
  setOpen,
  onDelete
}: DeleteAttachmentModalProps) {
  return (
    <Dialog open={open} onClose={() => setOpen({ open: false })}>
      <DialogTitle>
        <Typography
          variant="h4"
          style={{
            fontWeight: theme.font.semiBold,
            color: theme.colors.TextDarkGrey
          }}
        >
          Excluir Documento Anexado.
        </Typography>
        <IconButton
          aria-label={'close'}
          onClick={() => setOpen({ open: false })}
          size="large"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container md={12} justifyContent="center">
          <Typography variant="h4">
            Deseja excluir o documento anexado?
          </Typography>
        </Grid>
        <Grid container md={12} justifyContent="center">
          <Box pt={2}>
            <Typography
              variant="h4"
              style={{
                color: theme.colors.Error,
                fontWeight: theme.font.semiBold
              }}
            >
              Essa ação não pode ser revertida!
            </Typography>
          </Box>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          item
          container
          direction={'row'}
          justifyContent={'flex-end'}
          xs={12}
        >
          <Grid item style={{ marginRight: '20px' }}>
            <Button
              variant="outlined"
              onClick={() => setOpen({ open: false })}
              color={'primary'}
            >
              Voltar
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color={'primary'}
              onClick={() => onDelete()}
            >
              Excluir
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
