import { CreateOrUpdateEmployee } from 'pages/Admin/Users/Employee/Create';
import { Dashboard } from 'pages/Dashboard';
import { ProcessHistory } from 'pages/Process/ProcessHistory';
import { ConsultantCreateProcess } from 'pages/PublicProcess/Create/consultant';
import { DistributorCreateProcess } from 'pages/PublicProcess/Create/distributor';
import { RouteWrapperProps } from 'routes/RoutesConfig/RouteWrapper';
import { ProcessOrder } from 'pages/Process/Order';
import { Institutions } from 'pages/Admin/Institution/list';
import { InformResultDistributor } from 'pages/PublicProcess/InformResult/distributor';
import { InformResultConsultant } from 'pages/PublicProcess/InformResult/consultant';
import { EmailToOrder } from 'pages/Process/EmailToOrder';
import { InformDiscountFinancier } from 'pages/Process/InformDiscount/financier';
import { InformDiscountConsultant } from 'pages/Process/InformDiscount/consultant';
import { CreateDistributor } from 'pages/Admin/Users/Distributor/CreateDistributor';
import { ListUsers } from 'pages/Admin/Users/List';
import { ProfilePage } from 'pages/Profile';
import { CreatePrivateProcess } from 'pages/PrivateProcess/Create';
import { OrderDetails } from 'pages/Process/OrderDetails';
import { ProductsPage } from 'pages/Admin/Products/list';
import { History } from 'pages/History';
import { ProductCreateUpdatePage } from 'pages/Admin/Products/CreateUpdate';
import { Pendencies } from 'pages/Pendencies';
import { EmployeeUpdate } from 'pages/Admin/Users/Employee/Update';
import { DistributorUpdate } from 'pages/Admin/Users/Distributor/Update';
import { SuspendProcess } from 'pages/Process/SuspendProcess';
import { GovernmentInstitutionCreateUpdatePage } from 'pages/Admin/Institution/CreateUpdate/Government';
import { HospitalInstitutionCreateUpdatePage } from 'pages/Admin/Institution/CreateUpdate/Hospital';
import { Clients } from 'pages/Admin/Clients/List';
import { ClientCreateUpdatePage } from 'pages/Admin/Clients/CreateUpdate';
import { Imports } from 'pages/Admin/Imports';
import { Regionals } from 'pages/Admin/Regionals/List';
import { AdminEditProcess } from 'pages/Process/AdminEditProcess';
import { ApproveDiscount } from 'pages/Process/ApproveDiscount';

export const GLOBAL_PATHS_ROUTES: RouteWrapperProps[] = [
  {
    key: 'dashboard',
    path: '/dashboard',
    exact: true,
    component: Dashboard,
    isPrivate: true
  },
  {
    key: 'users',
    path: '/usuario',
    exact: true,
    component: ListUsers,
    isPrivate: true
  },
  {
    key: 'create-employee',
    path: [
      '/admin/usuario/funcionario/adicionar',
      '/admin/usuario/funcionario/adicionar/:id'
    ],
    exact: true,
    component: CreateOrUpdateEmployee,
    isPrivate: true
  },
  {
    key: 'create-distributor',
    path: ['/admin/usuario/distribuidor/adicionar'],
    exact: true,
    component: CreateDistributor,
    isPrivate: true
  },
  {
    key: 'consultant-process',
    path: '/cadastro/processo/consultor',
    exact: true,
    component: ConsultantCreateProcess,
    isPrivate: true
  },
  {
    key: 'distributor-process',
    path: '/cadastro/processo/distribuidor',
    exact: true,
    component: DistributorCreateProcess,
    isPrivate: true
  },
  {
    key: 'process-history',
    path: '/processo/:id',
    exact: true,
    isPrivate: true,
    component: ProcessHistory
  },
  {
    key: 'create-product',
    path: ['/produto/novo', '/produto/editar/:id'],
    component: ProductCreateUpdatePage,
    isPrivate: true
  },
  {
    key: 'institution',
    path: '/instituicao',
    component: Institutions,
    isPrivate: true,
    exact: true
  },
  {
    key: 'process-order',
    path: '/processo/:id/pedido',
    component: ProcessOrder,
    isPrivate: true
  },
  {
    key: 'result-distributor',
    path: '/processo/:id/informar-resultado/distribuidor',
    component: InformResultDistributor,
    isPrivate: true
  },
  {
    key: 'result-consultant',
    path: '/processo/:id/informar-resultado/consultor',
    component: InformResultConsultant,
    isPrivate: true
  },
  {
    key: 'e2o',
    path: '/processo/:id/email-to-order',
    component: EmailToOrder,
    isPrivate: true
  },
  {
    key: 'discount-financier',
    path: '/processo/:id/informar-desconto/financeiro',
    component: InformDiscountFinancier,
    isPrivate: true
  },
  {
    key: 'discount-consultant',
    path: '/processo/:id/informar-desconto/consultor',
    component: InformDiscountConsultant,
    isPrivate: true
  },
  {
    key: 'discount-approve',
    path: '/processo/:id/aprovar-desconto',
    component: ApproveDiscount,
    isPrivate: true
  },
  {
    key: 'profile',
    path: '/perfil',
    component: ProfilePage,
    isPrivate: true
  },
  {
    key: 'private-process',
    path: '/cadastro/processo/privado',
    exact: true,
    component: CreatePrivateProcess,
    isPrivate: true
  },
  {
    key: 'order-details',
    path: '/processo/pedido/:id',
    component: OrderDetails,
    isPrivate: true
  },
  {
    key: 'list-products',
    path: '/admin/produtos',
    component: ProductsPage,
    isPrivate: true
  },
  {
    key: 'history',
    path: '/historico',
    component: History,
    isPrivate: true
  },
  {
    key: 'pendencies',
    path: '/pendencias',
    component: Pendencies,
    isPrivate: true
  },
  {
    key: 'edit-employee',
    path: '/admin/usuario/funcionario/editar/:id',
    component: EmployeeUpdate,
    isPrivate: true
  },
  {
    key: 'edit-distributor',
    path: '/admin/usuario/distribuidor/editar/:id',
    component: DistributorUpdate,
    isPrivate: true
  },
  {
    key: 'suspend-process',
    path: '/processo/:id/suspender',
    component: SuspendProcess,
    isPrivate: true
  },
  {
    key: 'create-government-institution',
    path: [
      '/instituicao/governamental/adicionar',
      '/instituicao/governamental/editar/:id'
    ],
    component: GovernmentInstitutionCreateUpdatePage,
    isPrivate: true
  },
  {
    key: 'create-hospital-institution',
    path: [
      '/instituicao/hospitalar/adicionar',
      '/instituicao/hospitalar/editar/:id'
    ],
    component: HospitalInstitutionCreateUpdatePage,
    isPrivate: true
  },
  {
    key: 'list-clients',
    path: '/clientes',
    component: Clients,
    isPrivate: true,
    exact: true
  },
  {
    key: 'create-update-client',
    path: ['/clientes/cadastro', '/clientes/editar/:id'],
    component: ClientCreateUpdatePage,
    isPrivate: true
  },
  {
    key: 'imports',
    path: '/importacoes',
    component: Imports,
    isPrivate: true
  },
  {
    key: 'regionals',
    path: '/regionais',
    component: Regionals,
    isPrivate: true
  },
  {
    key: 'admin-edit-item',
    path: '/processo/admin/:id/editar',
    component: AdminEditProcess,
    isPrivate: true
  }
];
