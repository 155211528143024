/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import * as S from 'shared/components/Cards/ActionCard/styles';
import { Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ChipProps, UIChip } from 'shared/components/Chip';
import format from 'date-fns/format/index';
import ptBR from 'date-fns/locale/pt-BR/index';
import ErrorModal from 'shared/components/Modals/ErrorModal';
import { isValid } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'Providers/Auth';

export type ActionCardProps = {
  title?: string;
  action?: string;
  city?: string;
  institution?: string;
  tradingCode?: string;
  createdAt?: string;
  tradingDate?: string;
  chips?: ChipProps[];
  onDelete?: () => void;
  redirect: string;
};

type DateInfo = {
  label: string;
  date: string;
};

export default function ActionCard({
  action,
  city,
  institution,
  title,
  tradingCode,
  createdAt,
  chips,
  onDelete,
  redirect,
  tradingDate
}: ActionCardProps) {
  const history = useHistory();
  const { auth } = useAuth();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  const getDateInfo = (): DateInfo => {
    const date =
      auth?.employee?.accessLevel === 'consultant' ? tradingDate : createdAt;
    return {
      label:
        auth?.employee?.accessLevel === 'consultant'
          ? 'Data de Abertura'
          : 'Data criação do processo',
      date:
        isValid(new Date(date || '')) && date
          ? format(new Date(date), "dd 'de' MMMM 'de' Y", {
              locale: ptBR
            })
          : '-'
    };
  };

  const dateInfo = getDateInfo();

  return (
    <>
      <S.Wrapper>
        <Grid container justifyContent="space-between" alignItems="center">
          <S.Title>{title}</S.Title>
          {onDelete && (
            <IconButton aria-label={'close'} onClick={handleModal} size="large">
              <CloseIcon fontSize="small" color="inherit" />
            </IconButton>
          )}
        </Grid>
        <div
          onClick={() => {
            history.push(redirect);
          }}
        >
          <S.Action>{action}</S.Action>
          <Grid container alignItems="center">
            <S.Location>{city}</S.Location>|
            <S.Institution>{institution}</S.Institution>
          </Grid>
          <S.Bar />
          <S.Details container justifyContent="space-between">
            <Grid item>
              <p className="details-title">{dateInfo.label}</p>
              <p className="details-values">{dateInfo.date}</p>
            </Grid>
            <Grid item>
              <p className="details-title">Nº do pregão</p>
              <p className="details-values">{tradingCode}</p>
            </Grid>
          </S.Details>
          <Grid container justifyContent="flex-end">
            {chips &&
              chips.map((chip) => (
                <UIChip color={chip.color} title={chip.title} height="1.3rem" />
              ))}
          </Grid>
        </div>
      </S.Wrapper>

      <ErrorModal
        open={openDeleteModal}
        title={'Deseja excluir esse processo?'}
        buttonCancelText={'Cancelar'}
        buttonConfirmText={'Confirmar'}
        onConfirm={onDelete}
        onClose={() => setOpenDeleteModal(false)}
      />
    </>
  );
}
