/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Grow } from '@mui/material';
import { LoadingActions } from 'modules/Dashboard/Cards/Actions/LoadingActions';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'services';
import ActionCard from 'shared/components/Cards/ActionCard';
import { UIPagination } from 'shared/components/Pagination';
import { ProcessModel } from 'types/models';
import { Paginated } from 'types/services';
import lang from 'utils/lang';

export function ManagerActionPendencies() {
  const actionPerPage = 12;
  const [page, setPage] = useState<number>(0);

  const [getProcessToApprove, , discountLoading] = useQuery<number[]>(
    '/discount-order',
    {
      isManager: 1
    }
  );

  const [getActionProcess, refetchActions, actionsLoading] = useQuery<
    Paginated<ProcessModel>
  >('/process', {
    fetchOnInitialize: false,
    $limit: actionPerPage
  });

  useEffect(() => {
    if (getProcessToApprove) {
      if (getProcessToApprove.length > 0) {
        refetchActions({
          'id[$in]': (getProcessToApprove || []).slice(0, actionPerPage)
        });
      }
    }
  }, [getProcessToApprove]);

  const actionsProcess = getActionProcess?.data || ([] as ProcessModel[]);

  const redirect = (id?: number) => {
    if (id) return `processo/${id}/aprovar-desconto`;
    return 'dashboard';
  };

  const loading = actionsLoading || discountLoading;

  return (
    <Grid item container direction="column">
      <Grid item container spacing={2} direction={{ xs: 'column', md: 'row' }}>
        {loading ? (
          <>
            {Array.from(new Array(12)).map(() => (
              <Grid item>
                <LoadingActions />
              </Grid>
            ))}
          </>
        ) : (
          <>
            {actionsProcess.map((actionProcess) => (
              <Grow in={!loading}>
                <Grid item md={3}>
                  <ActionCard
                    title="Gerente"
                    redirect={redirect(actionProcess?.id)}
                    action="Aprovar desconto no sistema "
                    chips={[
                      {
                        color:
                          actionProcess?.segment === 'public'
                            ? 'blue'
                            : 'green',
                        title: lang.segments[actionProcess?.segment || '']
                      }
                    ]}
                    city={actionProcess?.city}
                    tradingCode={actionProcess?.tradingCode || '-'}
                    createdAt={String(actionProcess?.createdAt)}
                  />
                </Grid>
              </Grow>
            ))}
          </>
        )}
      </Grid>
      <Grid item container justifyContent="flex-end" mt={2}>
        <UIPagination
          count={Math.ceil((getProcessToApprove?.length || 0) / actionPerPage)}
          onChange={(e, newPage) => {
            const normalizedPage = newPage - 1;
            setPage(normalizedPage);
            refetchActions({
              'id[$in]': (getProcessToApprove || []).slice(
                normalizedPage * actionPerPage,
                normalizedPage * actionPerPage + actionPerPage
              )
            });
          }}
          itemSize="small"
          page={page + 1}
        />
      </Grid>
    </Grid>
  );
}
