import styled from 'styled-components';
import CancelIcon from '@mui/icons-material/Cancel';
import { ThemeColors } from 'shared/enum';

export const Subtitle = styled.p`
  font-size: 18px;
`;

export const Title = styled.p`
  color: #565656;
  font-size: 24px;
  font-weight: 600;
`;

export const ErrorIcon = styled(CancelIcon)`
  font-size: 8rem !important;
  color: ${ThemeColors.Error};
`;

export const Wrapper = styled.div`
  padding: 2rem;
`;
