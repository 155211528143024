import React from 'react';
import { Container, Typography } from '@mui/material';
import { AllUsersFragments } from 'modules/Admin/Users/All/Fragments/AllUsersFragments';
import { UIBreadcrumb } from 'shared/components/Breadcrumb';
import { UILink } from 'shared/components';

export function ListUsers() {
  return (
    <Container maxWidth={'lg'}>
      <UIBreadcrumb>
        <UILink to={'/dashboard'}> Dashboard </UILink>
        <Typography color={'primary'}> Usuários </Typography>
      </UIBreadcrumb>

      <AllUsersFragments />
    </Container>
  );
}
