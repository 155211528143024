import { Box, Typography } from '@mui/material';
import React from 'react';
import { theme } from 'shared/theme';

type InformDiscountTitleProps = {
  title: string;
  subtitles: string[];
};

export default function NewPageTitle({
  title,
  subtitles
}: InformDiscountTitleProps) {
  return (
    <>
      <Box mt={3} />
      <Typography
        variant="h2"
        style={{
          fontSize: theme.font.sizes.large
        }}
      >
        {title}
      </Typography>
      <Box mt={2} />
      {subtitles
        ? subtitles.map((subtitle) => (
            <>
              <Typography
                variant="h3"
                style={{
                  fontSize: theme.font.sizes.medium,
                  color: theme.colors.TextGrey
                }}
              >
                {subtitle}
              </Typography>
              <Box mt={1} />
            </>
          ))
        : null}
    </>
  );
}
