/* eslint-disable @typescript-eslint/no-explicit-any */
import { CalcDiscountInput } from 'modules/Process/Domain/ProcessItem/Discount';
import { ERRORS } from 'utils/Errors';
import { MoneyRemoveMask } from 'utils/masks';
import * as yup from 'yup';

export interface PrivateScopeRegistrationInputs {
  itemNumber: number;
  initialQuantity: number;
  productId?: number;
  priceList: string;
  discountValue: string;
  markup?: string;
  winner: 1 | 0;
  hasResult: 1 | 0;
  initialValue: string;
  finalValue: string;
  hasRival: 1 | 0;
  rivalProductId?: number | null;
  rivalInitialValue?: string | null;
  rivalFinalValue?: string | null;
  type: string;
}

const hasBoolean = (has: boolean) => !!has;

export const PrivateScopeRegistrationValidation = yup.object({
  itemNumber: yup
    .number()
    .required(ERRORS.REQUIRED_FIELD)
    .typeError(ERRORS.REQUIRED_FIELD),
  initialQuantity: yup
    .number()
    .required(ERRORS.REQUIRED_FIELD)
    .typeError(ERRORS.REQUIRED_FIELD),
  productId: yup
    .number()
    .required(ERRORS.REQUIRED_FIELD)
    .typeError(ERRORS.REQUIRED_FIELD),
  priceList: yup
    .string()
    .required(ERRORS.REQUIRED_FIELD)
    .typeError(ERRORS.REQUIRED_FIELD)
    .test('greaterThan', 'Insira um valor maior que 0', (val?: string) => {
      return val ? +MoneyRemoveMask(val) > 0 : false;
    }),
  discountValue: yup.string().typeError(ERRORS.REQUIRED_FIELD),
  markup: yup.string().when('type', {
    is: 'indirect',
    then: yup
      .string()
      .required(ERRORS.REQUIRED_FIELD)
      .typeError(ERRORS.REQUIRED_FIELD)
  }),
  hasResult: yup
    .number()
    .required(ERRORS.REQUIRED_FIELD)
    .typeError(ERRORS.REQUIRED_FIELD),
  winner: yup
    .number()
    .nullable()
    .optional()
    .when('hasResult', {
      is: hasBoolean,
      then: yup
        .number()
        .required(ERRORS.REQUIRED_FIELD)
        .typeError(ERRORS.REQUIRED_FIELD)
    }),
  initialValue: yup
    .string()
    .nullable()
    .optional()
    .when('hasResult', {
      is: hasBoolean,
      then: yup
        .string()
        .required(ERRORS.REQUIRED_FIELD)
        .typeError(ERRORS.REQUIRED_FIELD)
        .test('greaterThan', 'Insira um valor maior que 0', (val?: string) => {
          return val ? +MoneyRemoveMask(val) > 0 : false;
        })
    }),
  finalValue: yup
    .string()
    .nullable()
    .optional()
    .when('hasResult', {
      is: hasBoolean,
      then: yup
        .string()
        .required(ERRORS.REQUIRED_FIELD)
        .typeError(ERRORS.REQUIRED_FIELD)
        .test('greaterThan', 'Insira um valor maior que 0', (val?: string) => {
          return val ? +MoneyRemoveMask(val) > 0 : false;
        })
        .test(
          'greaterThanNfPrice',
          'Insira um valor maior ou igual ao Preço NF Nestlé',
          (val?: string, form?: any) => {
            if (form?.parent) {
              const { priceList, discountValue } = form?.parent;
              if (priceList && discountValue) {
                const nfPrice =
                  +CalcDiscountInput(
                    priceList,
                    discountValue
                  ).discountValue?.toFixed(2) || '';
                return val ? +MoneyRemoveMask(val) >= nfPrice : false;
              }
            }
            return false;
          }
        )
    }),
  hasRival: yup
    .number()
    .nullable()
    .optional()
    .when('hasResult', {
      is: hasBoolean,
      then: yup
        .number()
        .required(ERRORS.REQUIRED_FIELD)
        .typeError(ERRORS.REQUIRED_FIELD)
    }),
  rivalProductId: yup
    .number()
    .optional()
    .nullable()
    .when('hasResult', {
      is: hasBoolean,
      then: yup
        .number()
        .optional()
        .nullable()
        .when('hasRival', {
          is: hasBoolean,
          then: yup
            .number()
            .required(ERRORS.REQUIRED_FIELD)
            .typeError(ERRORS.REQUIRED_FIELD)
        })
    }),
  rivalFinalValue: yup
    .string()
    .optional()
    .nullable()
    .when('hasResult', {
      is: hasBoolean,
      then: yup
        .string()
        .optional()
        .nullable()
        .when('hasRival', {
          is: hasBoolean,
          then: yup
            .string()
            .required(ERRORS.REQUIRED_FIELD)
            .typeError(ERRORS.REQUIRED_FIELD)
            .test(
              'greaterThan',
              'Insira um valor maior que 0',
              (val?: string) => {
                return val ? +MoneyRemoveMask(val) > 0 : false;
              }
            )
        })
    }),
  rivalInitialValue: yup
    .string()
    .optional()
    .nullable()
    .when('hasResult', {
      is: hasBoolean,
      then: yup
        .string()
        .optional()
        .nullable()
        .when('hasRival', {
          is: hasBoolean,
          then: yup
            .string()
            .required(ERRORS.REQUIRED_FIELD)
            .typeError(ERRORS.REQUIRED_FIELD)
            .test(
              'greaterThan',
              'Insira um valor maior que 0',
              (val?: string) => {
                return val ? +MoneyRemoveMask(val) > 0 : false;
              }
            )
        })
    })
});
