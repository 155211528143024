import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  RadioGroupProps
} from '@mui/material';
import React from 'react';
import { Control, Controller, Path } from 'react-hook-form';

interface RadioGroupOption {
  value: unknown;
  label: string;
}

interface ControlledFieldProps<T> {
  name: Path<T>;
  label: string;
  control: Control<T>;
  options: RadioGroupOption[];
  disabled?: boolean;
  errors?: string;
}
export function ControlledRadioGroup<T>({
  name,
  control,
  label,
  options,
  disabled,
  errors,
  ...rest
}: ControlledFieldProps<T> & RadioGroupProps) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <FormControl
            disabled={disabled}
            component={'fieldset'}
            error={!!errors}
          >
            <FormLabel>{label}</FormLabel>
            <RadioGroup aria-label={name} {...field} {...rest} row>
              {options.map((option) => (
                <FormControlLabel
                  value={option.value}
                  control={<Radio color="primary" />}
                  label={option.label}
                  key={option.label}
                />
              ))}
            </RadioGroup>
            <FormHelperText>{errors}</FormHelperText>
          </FormControl>
        );
      }}
    />
  );
}
