import React from 'react';
import { UITable } from 'shared/components';
import { ProcessItemsTableProps } from 'modules/Process/shared/Tables/ProcessItems/ProcessItems';
import { ProcessItemRemoveColumns } from 'modules/Process/shared/Tables/ProcessItems/rules/ProcessItemRemoveColumns/ProcessItemRemoveColumns';
import { useAuth } from 'Providers/Auth';
import { ProcessItemOrders } from 'modules/Process/shared/Tables/ProcessItems/rules/ProcessItemOrders';

export function ProcessItemsTable({
  processProduct = [],
  ...rest
}: ProcessItemsTableProps) {
  const { auth } = useAuth();

  const distributor = !!auth?.distributor;
  const columns = ProcessItemRemoveColumns(distributor);

  const { products, orderColumns } = ProcessItemOrders(processProduct);
  const finalColumns = [...columns, ...orderColumns];

  return <UITable columns={finalColumns} rows={products} {...rest} />;
}
