import { ERRORS } from 'utils/Errors';
import * as yup from 'yup';

export const UpdateEmployeeSchema = yup.object().shape({
  email: yup.string().required(ERRORS.REQUIRED_FIELD),
  employee: yup.object().shape({
    name: yup.string().required(ERRORS.REQUIRED_FIELD),
    lastName: yup.string().optional().nullable(),
    regional: yup.string().optional().nullable(),
    sap: yup.string().optional().nullable(),
    accessLevel: yup.string().optional().nullable()
  }),
  password: yup.string().nullable().optional(),
  confirmPassword: yup.string().when('password', {
    is: (password: string) => password?.length > 0,
    then: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Senhas não correspondem')
      .required(ERRORS.REQUIRED_FIELD)
  })
});

export interface UpdateEmployee {
  email: string;
  employee: {
    id?: number;
    name?: string;
    lastName?: string;
    regional?: string;
    sap?: string;
    accessLevel?: string;
  };
  password?: string;
  confirmPassword?: string;
  id?: number;
}
