import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from '@mui/lab';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import { format, isValid } from 'date-fns';
import { useAuth } from 'Providers/Auth';
import React, { useState } from 'react';
import useReport, { ReportOptions } from 'services/api/UseReport';
import { UITextField } from 'shared/components/Inputs';
import { theme } from 'shared/theme';
import { ReportsType } from 'utils/Constants/ReportTypes';

export type GenerateReportModalProps = {
  open: boolean;
  onClose: () => void;
};

export function GenerateReportModal({
  open,
  onClose
}: GenerateReportModalProps) {
  const { auth } = useAuth();

  const [reportType, setReportType] = useState<string>('basic');
  const [startPeriod, setStartPeriod] = useState<string>(
    new Date().toISOString()
  );
  const [endPeriod, setEndPeriod] = useState<string>(new Date().toISOString());

  const [
    loadingReport,
    fetchReport,
    refetchReport,
    hashId,
    hasError,
    clearReport
  ] = useReport();

  const filename = `Relatório ${
    ReportsType.find((type) => type.value === reportType)?.text
  } ${format(new Date(startPeriod), 'dd-MM-yyyy')}.xlsx`;

  const onReport = () => {
    const params: ReportOptions = {
      'createdAt[$gte]': startPeriod,
      'createdAt[$lte]': endPeriod
    };
    if (auth?.employee?.accessLevel === 'consultant')
      params.employeeId = auth?.employee?.id;
    if (auth?.distributorId) params.distributorId = auth?.distributorId;

    fetchReport(reportType, params, filename);
  };

  const getErrorTitle = () => {
    if (hasError && hashId) return 'Relatório ainda não foi finalizado';
    return 'Erro ao gerar relatório';
  };

  const getErrorBody = () => {
    if (hasError && hashId)
      return 'Relatório ainda não foi finalizado, clique em "Tentar novamente" para realizar uma nova busca, ou "Voltar" para fechar a janela.';
    return 'Deseja tentar novamente?';
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth>
        <DialogTitle>
          <Typography
            variant="h4"
            style={{
              fontWeight: theme.font.semiBold,
              color: theme.colors.TextDarkGrey
            }}
          >
            Definir período do relatório
          </Typography>
          <IconButton aria-label={'close'} onClick={onClose} size="large">
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container md={12}>
            <Grid container md={7}>
              <UITextField
                label={'Tipo'}
                value={reportType || 'basic'}
                fullWidth
                select
                onChange={(e) => setReportType(e.target.value)}
              >
                {ReportsType.map(({ text, value }) => (
                  <MenuItem key={value} value={value}>
                    {text}
                  </MenuItem>
                ))}
              </UITextField>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2} mt={2}>
            <Grid item xs={12} sm={6}>
              <DatePicker
                value={startPeriod}
                onChange={(date: Date | null) => {
                  if (date && isValid(date)) {
                    setStartPeriod(date.toISOString());
                  }
                }}
                label={'Período Inicial'}
                renderInput={(props) => (
                  <TextField {...props} name={'startPeriod'} />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePicker
                value={endPeriod}
                onChange={(date: Date | null) => {
                  if (date && isValid(date)) {
                    setEndPeriod(date.toISOString());
                  }
                }}
                label={'Período Final'}
                renderInput={(props) => (
                  <TextField {...props} name={'endPeriod'} />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            item
            container
            direction={'row'}
            justifyContent={'flex-end'}
            xs={12}
          >
            {loadingReport ? (
              <>
                <Grid
                  display={'flex'}
                  alignItems="center"
                  justifyContent={'space-between'}
                  gap={2}
                >
                  <Typography
                    variant="h4"
                    color="darkslategray"
                    fontWeight={800}
                  >
                    Gerando relatório, essa ação pode levar minutos em períodos
                    longos
                  </Typography>
                  <CircularProgress />
                </Grid>
              </>
            ) : (
              <>
                <Grid item style={{ marginRight: '20px' }}>
                  <Button
                    variant="outlined"
                    onClick={onClose}
                    color={'primary'}
                  >
                    Voltar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    fullWidth
                    color={'primary'}
                    onClick={() => {
                      onReport();
                    }}
                  >
                    Gerar Relatório
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </DialogActions>
      </Dialog>
      <Dialog open={hasError} onClose={() => clearReport()} maxWidth={'xs'}>
        <DialogTitle>
          <Typography
            variant="h4"
            style={{
              fontWeight: theme.font.semiBold,
              color: theme.colors.TextDarkGrey
            }}
          >
            {getErrorTitle()}
          </Typography>
          <IconButton
            aria-label={'close'}
            onClick={() => clearReport()}
            size="large"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container md={12}>
            <Typography variant="h4">{getErrorBody()}</Typography>
          </Grid>
        </DialogContent>
        <DialogActions>
          {loadingReport ? (
            <CircularProgress size={25} />
          ) : (
            <Grid
              item
              container
              direction={'row'}
              justifyContent={'flex-end'}
              xs={12}
            >
              <>
                <Grid item style={{ marginRight: '20px' }}>
                  <Button
                    variant="outlined"
                    onClick={onClose}
                    color={'primary'}
                  >
                    Voltar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color={'primary'}
                    onClick={() => {
                      if (hashId) {
                        refetchReport(hashId, 10, filename);
                      } else {
                        onReport();
                      }
                    }}
                  >
                    Tentar Novamente
                  </Button>
                </Grid>
              </>
            </Grid>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
