import {
  Autocomplete,
  AutocompleteChangeDetails,
  FilterOptionsState
} from '@mui/material';
import { AutocompleteChangeReason } from '@mui/material/useAutocomplete';
import React from 'react';
import { Control, Controller, Path } from 'react-hook-form';
import { UITextField } from 'shared/components/Inputs';

interface ControlledFieldProps<T, OT> {
  name: Path<T>;
  label: string;
  control: Control<T>;
  options: OT[];
  getOptionLabel?: (option: OT) => string;
  groupBy?: (option: OT) => string;
  onChange?: (
    event: React.SyntheticEvent<Element, Event>,
    value: OT | OT[] | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<OT> | undefined
  ) => void;
  fullWidth?: boolean;
  loading?: boolean;
  disableClearable?: boolean;
  errors?: string;
  value?: OT | OT[] | null;
  filterOptions?: (options: OT[], state: FilterOptionsState<OT>) => OT[];
}
export function ControlledAutocomplete<T, OT>({
  name,
  control,
  errors,
  label,
  ...rest
}: ControlledFieldProps<T, OT>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <Autocomplete
            renderInput={(props) => (
              <UITextField
                {...props}
                label={label}
                error={!!errors}
                helperText={errors}
              />
            )}
            {...field}
            {...rest}
          />
        );
      }}
    />
  );
}
