import { AxiosInstance } from 'axios';

export function SetApiHeader(api: AxiosInstance) {
  const token = localStorage.getItem('token');

  const returnApi = api;

  returnApi.defaults.headers.Authorization = `Bearer ${token}`;

  return returnApi;
}
