import React from 'react';
import { UIBreadcrumb } from 'shared/components/Breadcrumb';
import { Typography } from '@mui/material';
import { UILink } from 'shared/components/Link';

interface BreadcrumbAdminProps {
  create?: boolean;
}

export function BreadcrumbDistributorAmin({ create }: BreadcrumbAdminProps) {
  return (
    <UIBreadcrumb>
      <UILink to={'/dashboard'}> Dashboard </UILink>
      <UILink to={'/usuario'}> Usuários </UILink>
      {create ? (
        <Typography> Adicionar Distribuidor </Typography>
      ) : (
        <Typography> Editar Distribuidor </Typography>
      )}
    </UIBreadcrumb>
  );
}
