import { States } from 'utils/Enums';
import { ERRORS } from 'utils/Errors';
import * as yup from 'yup';

export interface AlterUfsRegionals {
  uf: keyof typeof States;
  regional: string;
}

export const AlterUfsRegionalsSchema = yup.object().shape({
  uf: yup.string().required(ERRORS.REQUIRED_FIELD),
  regional: yup.string().required(ERRORS.REQUIRED_FIELD)
});
