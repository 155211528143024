import * as yup from 'yup';
import { ERRORS } from 'utils/Errors';

export const ProcessItemMinimalSchema = yup.object({
  itemNumber: yup
    .number()
    .typeError(ERRORS.NUMBER_FORMAT)
    .required(ERRORS.REQUIRED_FIELD),
  specification: yup.string().required(ERRORS.REQUIRED_FIELD),
  quantity: yup
    .number()
    .min(1, `${ERRORS.NUMBER_GREATER_THAN} 0`)
    .required(ERRORS.REQUIRED_FIELD),
  approve: yup.boolean().required(ERRORS.REQUIRED_FIELD),
  justify: yup.string().when('approve', {
    is: false,
    then: yup.string().required(ERRORS.REQUIRED_FIELD)
  }),
  productId: yup.number().required(ERRORS.REQUIRED_FIELD)
});
