import React, { useEffect, useState } from 'react';
import { Step, StepLabel, Stepper, StepperProps } from '@mui/material';
import { ProcessStatusSteppersConstant } from 'utils/Constants/ProcessStatusSteppers/ProcessStatusSteppers.constant';
import { ProcessStatus } from 'utils/Constants/ProcessStatus';

interface StatusStepperProps extends StepperProps {
  status?: string;
}

export function StatusStepper({ status, ...rest }: StatusStepperProps) {
  const [pos, setPos] = useState(0);

  useEffect(() => {
    const setStatus = () => {
      const getPosition = ProcessStatus.find((processStatus) =>
        processStatus.status.includes(status || '')
      )?.position;
      setPos(getPosition || -1);
    };

    setStatus();
  }, [status]);

  return (
    <Stepper activeStep={pos - 1 || 0} {...rest} alternativeLabel>
      {ProcessStatusSteppersConstant.map((label) => (
        <Step key={label}>
          <StepLabel> {label} </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}
