export const RequestDocuments = [
  'Carta de credenciamento',
  'Ficha técnica',
  'Registro do produto',
  'Licença de funcionamento',
  'Contrato nestlé',
  'CNPJ',
  'Atestado de responsabilidade técnica',
  'Certidões',
  'Outros documentos',
  'Boas práticas de fabricação'
];
