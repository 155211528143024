export enum BooleanToYes {
  'Sim' = 'true',
  'Não' = 'false',
  'true' = 'Sim',
  'false' = 'Não'
}

export const OptionsBooleanToYes = [
  {
    value: 1,
    label: BooleanToYes.true
  },
  {
    value: 0,
    label: BooleanToYes.false
  }
];
