import { Button, Grid } from '@mui/material';
import { DistributorProcessItemProps } from 'modules/Process/PublicProcess/Forms/DistributorProcessForm/DistributorProcessItem/DistributorProcessItem';
import { ProcessItemFormTable } from 'modules/Process/PublicProcess/Fragments/ProcessItem/ProcessItemFormTable';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { usePatch, usePost, useQuery } from 'services';
import { useDelete } from 'services/api/UseDelete';
import { ProcessProductModel } from 'types/models';

export function DistributorProcessItem({
  id,
  onFail,
  onComplete
}: DistributorProcessItemProps) {
  const ProcessItemsQuery = useQuery<ProcessProductModel[]>(
    '/process-products',
    {
      $limit: -1,
      processId: id
    }
  );

  const processItemsData = ProcessItemsQuery[0];
  const processItemsRefetch = ProcessItemsQuery[1];

  const processItemLoading = ProcessItemsQuery[2];

  const [processItems, setProcessItems] = useState<
    Partial<ProcessProductModel>[]
  >([]);

  useEffect(() => {
    if (processItemsData) {
      setProcessItems(processItemsData);
    }
  }, [processItemsData]);

  const [, processItemPost] = usePost<
    Partial<ProcessProductModel>,
    Partial<ProcessProductModel>
  >('/process-products');

  const [, processItemPatch] =
    usePatch<Partial<ProcessProductModel>>('/process-products');

  const [, processItemDelete] =
    useDelete<Partial<ProcessProductModel>>('/process-products');

  /* POST function to save a process item */
  const handleAddProduct = async (data: Partial<ProcessProductModel>) => {
    if (id) {
      const newData = data;
      newData.processId = +id;
      const response = await processItemPost(newData);
      if (response) {
        toast.success(`Item cadastrado com sucesso`);
        await processItemsRefetch();
      }
    } else {
      toast.info(
        'Cadastre os dados básicos do processo antes de prosseguir com a adição de produtos'
      );
      onFail();
    }
  };

  /* PATCH function to update a process item */
  const handleEditProduct = async (
    data: Partial<ProcessProductModel>,
    array: Partial<ProcessProductModel>[]
  ) => {
    if (data.id) {
      await processItemPatch(data, data.id);
      setProcessItems([...array]);
      await processItemsRefetch();
    }
  };

  const handleComplete = () => {
    if (processItemsData && processItemsData.length > 0) {
      onComplete();
    }
  };

  const handleDelete = async (itemId: number) => {
    try {
      await processItemDelete(itemId);
      toast.success(`Item excluído com sucesso.`);
      await processItemsRefetch();
    } catch (error) {
      toast.error(`Ocorreu um erro ao excluir item.`);
    }
  };

  return (
    <>
      <ProcessItemFormTable
        onAddProduct={handleAddProduct}
        onEditProduct={handleEditProduct}
        onDelete={handleDelete}
        processItemData={processItems}
        tableProps={{ loading: processItemLoading }}
        isDistributor
        processId={+id}
      />
      <Grid container item direction={'row-reverse'}>
        <Grid item xs={12} sm={2}>
          <Button
            type={'submit'}
            onClick={handleComplete}
            fullWidth
            variant={'contained'}
          >
            Avançar
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
