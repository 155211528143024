import React from 'react';
import { Divider, Grid, IconButton, Paper, Typography } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { UIChip } from 'shared/components/Chip';
import { ProductCardProps } from 'shared/components/Cards/ProductCard/ProductCard';
import { ProductCategoryColors } from 'utils/Constants/ProductCategoryColors';

export function ProductCard({ product, onDelete, onEdit }: ProductCardProps) {
  const handleEditClick = () => {
    return onEdit && onEdit();
  };

  const handleDeleteClick = () => {
    return onDelete && onDelete();
  };

  return (
    <Paper
      sx={{
        borderLeft: `5px solid ${ProductCategoryColors[product.businessUnity]}`
      }}
    >
      <Grid item container justifyContent={'space-between'} sx={{ my: 1 }}>
        <Grid item xs={2} sx={{ pl: 2, pt: 1 }}>
          <UIChip
            color={'white'}
            title={product.businessUnity}
            backgroundColor={ProductCategoryColors[product.businessUnity]}
            height={'20px'}
          />
        </Grid>
        <Grid item container xs={4} justifyContent={'flex-end'}>
          <Grid item>
            <IconButton onClick={handleEditClick}>
              <CreateIcon color={'primary'} />
            </IconButton>
            <IconButton onClick={handleDeleteClick}>
              <DeleteForeverIcon color={'error'} />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container justifyContent={'center'} sx={{ mb: 1 }}>
        <Grid item xs={12} sx={{ pb: 2, pl: 1 }}>
          <Typography variant={'h2'}> {product.description} </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
          mr={2}
        >
          <Typography variant={'h5'} color={'inherit'} textAlign={'center'}>
            SKU
          </Typography>
          <Typography textAlign={'center'}>{product.sku}</Typography>
        </Grid>
        <Grid
          item
          xs={3}
          sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
          mr={2}
        >
          <Typography variant={'h5'} color={'inherit'} textAlign={'center'}>
            CEST
          </Typography>
          <Typography textAlign={'center'}>{product?.CEST || '-'}</Typography>
        </Grid>
        <Grid item xs={3} sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
          <Typography variant={'h5'} color={'inherit'} textAlign={'center'}>
            Gramatura
          </Typography>
          <Typography textAlign={'center'}>{product?.price}</Typography>
        </Grid>
      </Grid>

      <Divider />
      <Grid item sx={{ my: 1 }}>
        <Typography align={'center'}>
          Utilizado em: {product?.ppTotal} processos
        </Typography>
      </Grid>
    </Paper>
  );
}
