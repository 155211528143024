import { Button, CircularProgress, Grid } from '@mui/material';
import { useAuth } from 'Providers/Auth';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { usePatch, useQuery, UseQueryReturn } from 'services';
import UploadAttachmentsSection from 'shared/components/Upload/UploadSection';
import { ProcessModel, ProcessProductModel } from 'types/models';
import { AttachmentModel } from 'types/models/Attachments/Attachment.model';
import { ProcessDocuments } from 'utils/Constants/DocumentTypes/ProcessDocuments';

interface PrivateProcessAttachmentsProps {
  id: string;
  attachmentsQuery: UseQueryReturn<AttachmentModel[], AttachmentModel>;
}

export function ConsultantProcessAttachments({
  id,
  attachmentsQuery
}: PrivateProcessAttachmentsProps) {
  const { auth } = useAuth();
  const history = useHistory();
  const [, patchProcess] = usePatch<ProcessModel>('/process');
  const [processProducts, , loading] = useQuery<ProcessProductModel[]>(
    '/process-products',
    {
      $limit: -1,
      processId: id,
      $select: ['id', 'nfPrice'],
      notInclude: ['product', 'products']
    }
  );

  const onComplete = async () => {
    const hasProductsNotConcluded = (processProducts || [])?.some(
      (processProduct) => !processProduct.nfPrice
    );
    if (hasProductsNotConcluded) {
      toast.warn(
        `Existem produtos não finalizados, termine o cadastro dos itens faltantes.`
      );
      return;
    }
    if (processProducts) {
      if (processProducts.length > 0) {
        const needsFinancier = processProducts.some(
          (pp) => pp?.discount_orders?.[0]?.id
        );
        if (needsFinancier) {
          try {
            await patchProcess(
              {
                status: 'awaiting financier'
              },
              +id
            );
            toast.warn(
              'Processo incluído no sistema com sucesso. Porém possui itens que necessitam aprovação do financeiro.'
            );
            history.push('/dashboard');
          } catch (error) {
            toast.error(
              'Erro ao gerar atualizar status para aguardando financeiro.'
            );
          }
        } else {
          try {
            await patchProcess(
              {
                status: 'awaiting distributor result'
              },
              +id
            );
            toast.success(
              `Processo incluído no sistema com sucesso. O Distribuidor já pode inserir o resultado na plataforma.`
            );
            history.push(`/processo/${id}`);
          } catch (error) {
            toast.error('Erro ao trocar Status para Resultado Distribuidor.');
          }
        }
      } else {
        toast.error('Cadastre ao menos um produto para concluir o processo.');
      }
    }
  };

  return (
    <>
      <UploadAttachmentsSection
        attachmentsQuery={attachmentsQuery}
        selectOptions={ProcessDocuments}
        accessId={auth?.id}
        tableId={+id}
        listTitle="Documentos do processo"
      />
      {loading ? (
        <CircularProgress />
      ) : (
        <Grid container item direction={'row-reverse'} mt={4}>
          <Grid item xs={12} sm={2}>
            <Button
              type={'submit'}
              onClick={onComplete}
              fullWidth
              variant={'contained'}
            >
              Concluir
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
}
