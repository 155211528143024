import { Button, Grid, LinearProgress } from '@mui/material';
import { EmailToOrderForm } from 'modules/Process/shared/forms/emailToOrder';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { usePatch, usePost, useQuery } from 'services';
import UIPaper from 'shared/components/Paper';
import { EmailToOrderInput } from 'types/input/EmailToOrder';
import { ProcessModel } from 'types/models';
import { OrderModel } from 'types/models/Order/Order.model';
import { Paginated } from 'types/services';

interface EmailToOrderFragmentProps {
  processId: string;
}

export function EmailToOrderFragment({ processId }: EmailToOrderFragmentProps) {
  const [processData] = useQuery<ProcessModel>(`/process/${processId}`);
  const history = useHistory();
  const [order] = useQuery<Paginated<OrderModel>>('/order', {
    processId,
    $limit: 1,
    '$sort[createdAt]': -1,
    status: 'Processando'
  });

  const [, postOrder] = usePost<EmailToOrderInput>('/e2o');
  const [, patchOrder] = usePatch<OrderModel>('/order');

  const handleComplete = async (data: EmailToOrderInput) => {
    if (data.orderId) {
      const response = await postOrder(data);
      await patchOrder(
        {
          approvedDate: new Date().toISOString(),
          status: 'Concluido'
        },
        +data.orderId
      );
      if (response) {
        toast.success('E2O finalizado');
        history.push(`/processo/${processId}`);
      }
    }
  };

  return (
    <>
      {processData && order ? (
        <>
          <UIPaper title="Informações E2O">
            <Grid container spacing={2} p={2}>
              <EmailToOrderForm
                process={processData}
                order={order?.data[0]}
                onComplete={handleComplete}
              />
            </Grid>
          </UIPaper>
          <Grid container item justifyContent="flex-end" xs={12}>
            <Grid item xs={12} sm={3} mt={2}>
              <Button
                type={'submit'}
                form={'email-to-order'}
                variant={'contained'}
                fullWidth
              >
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </>
      ) : (
        <LinearProgress />
      )}
    </>
  );
}
