/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Typography } from '@mui/material';
import { DataGridProps } from '@mui/x-data-grid';
import useDebounce from 'hooks/useDebounce';
import { ProcessSearch } from 'modules/Dashboard/Search';
import { useAuth } from 'Providers/Auth';
import React, { useEffect, useState } from 'react';
import history from 'routes/History';
import { FetchQuery } from 'services';
import { UITable } from 'shared/components';
import { UIDataGridApiPagination } from 'shared/components/Tables/DataGridApiPagination';
import { UIToolbar } from 'shared/components/Tables/ToolbarTable';
import { ThemeColors } from 'shared/enum';
import { ProcessModel } from 'types/models';
import { Paginated } from 'types/services';
import { columns } from './Constants/columns';

interface ProcessTableProps extends Omit<DataGridProps, 'rows' | 'columns'> {
  responseData?: Paginated<ProcessModel>;
  loading: boolean;
  resend: FetchQuery<Paginated<ProcessModel>, ProcessModel>;
  isHistory?: boolean;
}

interface Params {
  status?: string;
  search?: string;
  $limit?: number;
  $skip?: number;
}

export function ProcessTable({
  responseData,
  resend,
  pageSize = 10,
  isHistory = false,
  ...rest
}: ProcessTableProps) {
  const { auth } = useAuth();

  const [search, setSearch] = useState<string>('');
  const [status, setStatus] = useState<string>('all');

  const debouncedValue = useDebounce<string>(search, 500);

  const onPageChange = (page = 0) => {
    const params: Params = {};
    params.$limit = pageSize;
    params.$skip = pageSize * page;
    if (debouncedValue) {
      params.search = debouncedValue;
    }
    if (status) {
      if (status !== 'all') {
        params.status = status;
      }
    }

    resend(params);
  };

  useEffect(() => {
    onPageChange();
  }, [debouncedValue, status]);

  return (
    <>
      <ProcessSearch
        onChange={(value) => setSearch(value)}
        setStatus={setStatus}
        actualStatus={status}
        isHistory={isHistory}
      />
      <Grid
        xs={12}
        md={auth?.employee?.accessLevel === 'admin' || isHistory ? 12 : 8}
        item
      >
        <UITable
          rows={responseData?.data || []}
          columns={columns}
          onPageChange={(page) => onPageChange(page)}
          rowCount={responseData?.total}
          paginationMode="server"
          rowHeight={36}
          onRowDoubleClick={(row) => history.push(`/processo/${row.id}`, {})}
          headerHeight={36}
          components={{
            Toolbar: () => (
              <UIToolbar
                title={
                  <Typography
                    variant={'h3'}
                    style={{ color: ThemeColors.TextDarkGrey, fontWeight: 500 }}
                  >
                    Processos em aberto
                  </Typography>
                }
                elementsTopEnd={<UIDataGridApiPagination itemSize="small" />}
              />
            )
          }}
          {...rest}
        />
      </Grid>
    </>
  );
}
