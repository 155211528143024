import {
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React from 'react';
import { UIChip } from 'shared/components/Chip';
import { ThemeColors } from 'shared/enum';
import { Segments, SegmentsOptions } from 'utils/Enums';

type ProcessInformationHeaderProps = {
  segment?: SegmentsOptions;
  clientName?: string;
  clientCode?: string;
};

export function ProcessInformationHeader({
  segment,
  clientName,
  clientCode
}: ProcessInformationHeaderProps) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Grid container>
      <Grid
        container
        p={1}
        pl={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item container xs={12} md={6} sm={6}>
          <Grid item xs={8}>
            <Typography
              variant="h3"
              style={{ color: ThemeColors.TextDarkGrey, fontWeight: 500 }}
            >
              Informações do processo
            </Typography>
          </Grid>
          {segment && (
            <Grid xs>
              <UIChip
                color={segment === 'public' ? 'blue' : 'green'}
                title={Segments[segment] ?? 'Desconhecido'}
                fontWeight="bold"
                fontSize="10px"
                height="20px"
              />
            </Grid>
          )}
        </Grid>
        <Grid item container xs={12} sm={6} md={6} justifyContent="center">
          <Grid
            item
            container
            justifyContent={matches ? 'flex-end' : 'flex-start'}
            xs={12}
            sm={7}
          >
            <Typography variant="h4" style={{ color: ThemeColors.TextGrey }}>
              Código do cliente:
            </Typography>
            <Typography variant="h4" style={{ color: ThemeColors.Primary }}>
              {clientCode ? `#${clientCode}` : '-'}
            </Typography>
          </Grid>
          <Divider
            style={{ margin: '0 5px 0 5px' }}
            variant="middle"
            orientation="vertical"
            flexItem
          />
          <Grid item container xs={12} sm={4} alignItems="center">
            <Typography
              variant="h4"
              style={{ color: ThemeColors.Primary }}
              noWrap
            >
              {clientName ?? '-'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
}
