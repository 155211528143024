import React from 'react';
import { SimpleFormModal, SimpleFormModalParams } from 'shared/components';
import { ProcessProductModel } from 'types/models';
import { PrivateScopeRegistrationItemForm } from '../../Forms/PrivateProcessScopeRegistrationItem';

export interface PrivateProcessItemModalParams
  extends Omit<SimpleFormModalParams, 'title'> {
  title?: string;
  processItem?: Partial<ProcessProductModel>;
  onComplete: (data: Partial<ProcessProductModel>) => void;
  processDirect: boolean;
  distributorUf?: string;
  processId: number;
  isAdmin?: boolean;
}

export function PrivateProcessItemModal({
  title = 'Adicionar item',
  processItem,
  onComplete,
  onClose,
  processDirect,
  processId,
  distributorUf,
  isAdmin = false,
  ...rest
}: PrivateProcessItemModalParams) {
  return (
    <SimpleFormModal title={title} {...rest} onClose={onClose} fullWidth>
      <PrivateScopeRegistrationItemForm
        onComplete={onComplete}
        processProduct={processItem}
        onClose={onClose}
        processDirect={processDirect}
        distributorUf={distributorUf}
        processId={processId}
        isAdmin={isAdmin}
      />
    </SimpleFormModal>
  );
}
