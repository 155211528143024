import { ERRORS } from 'utils/Errors';
import * as yup from 'yup';

export const UpdateDistributorSchema = yup.object().shape({
  email: yup.string().required(ERRORS.REQUIRED_FIELD),
  distributor: yup.object().shape({
    name: yup.string().required(ERRORS.REQUIRED_FIELD),
    socialName: yup.string().required(ERRORS.REQUIRED_FIELD),
    contact: yup.string().optional().nullable(),
    telephone: yup.string().optional().nullable(),
    cnpj: yup.string().optional().nullable(),
    uf: yup.string().optional().nullable(),
    city: yup.string().optional().nullable()
  }),
  password: yup.string().nullable().optional(),
  confirmPassword: yup.string().when('password', {
    is: (password: string) => password?.length > 0,
    then: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Senhas não correspondem')
      .required(ERRORS.REQUIRED_FIELD)
  })
});

export interface UpdateDistributor {
  email: string;
  distributor: {
    id?: number;
    name?: string;
    socialName?: string;
    contact?: string;
    telephone?: string;
    cnpj?: string;
    uf?: string;
    city?: string;
  };
  password?: string;
  confirmPassword?: string;
}
