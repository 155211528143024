/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-types */
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, createFilterOptions, Grid } from '@mui/material';
import { CalcDiscountInput } from 'modules/Process/Domain/ProcessItem/Discount';
import { CalcMarkupInput } from 'modules/Process/Domain/ProcessItem/Markup';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'services';
import { ControlledAutocomplete } from 'shared/components/ControlledComponents/ControlledAutocomplete';
import { ControlledTextField } from 'shared/components/ControlledComponents/ControlledTextField';
import { FormProps, UITextField } from 'shared/components/Inputs';
import { ProcessProductModel, ProductsModel } from 'types/models';
import { FormatPercent } from 'utils/Functions/FormatPercent';
import { MoneyMask, MoneyRemoveMask, PercentMaskRemove } from 'utils/masks';
import {
  AdminEditItemProcessInputs,
  AdminEditItemProcessSchema
} from './domain';

export interface PrivateScopeRegistrationFormParams
  extends FormProps<Partial<ProcessProductModel>> {
  processProduct?: Partial<ProcessProductModel>;
  onClose?: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void;
  processId: number;
}

export function AdminEditItemProcessForm({
  onComplete,
  onClose,
  processProduct,
  processId
}: PrivateScopeRegistrationFormParams) {
  const [products] = useQuery<ProductsModel[], ProductsModel>('/products', {
    $limit: '-1',
    processId,
    disabled: 0
  });

  const { handleSubmit, formState, watch, control, reset, setValue } =
    useForm<AdminEditItemProcessInputs>({
      resolver: yupResolver(AdminEditItemProcessSchema),
      mode: 'onTouched'
    });

  useEffect(() => {
    if (processProduct) {
      reset({
        discountValue: FormatPercent(processProduct?.discountValue || 0),
        initialQuantity: processProduct?.initialQuantity,
        quantity: processProduct?.quantity,
        maxQuantity: processProduct?.maxQuantity,
        itemNumber: processProduct?.itemNumber,
        priceList: MoneyMask(processProduct?.priceList || 0),
        productId: processProduct?.productId,
        newQuantity: processProduct?.initialQuantity,
        markup: processProduct?.markup
          ? FormatPercent(processProduct.markup)
          : undefined
      });
    }
  }, [processProduct]);

  const filterOptions = <T extends unknown>() =>
    createFilterOptions<T>({
      limit: 20
    });

  const product =
    products?.find(
      (selectedProduct) => selectedProduct.id === watch('productId')
    ) || null;

  const nfPrice =
    +CalcDiscountInput(
      watch('priceList') || 0,
      watch('discountValue') || 0
    ).discountValue?.toFixed(2) || 0;

  const institutionValue = +CalcMarkupInput(
    nfPrice,
    +PercentMaskRemove(String(watch('markup'))) || 0
  )?.markup?.toFixed(2);

  const totalQuantity = watch('maxQuantity') - watch('quantity');

  const formSubmit = (data: AdminEditItemProcessInputs) => {
    const newMaxQuantity =
      data.maxQuantity - data.initialQuantity !== 0
        ? +(data.newQuantity * 1.25)?.toFixed(0)
        : data.newQuantity;

    onComplete({
      itemNumber: data.itemNumber,
      productId: data.productId,
      priceList: +(+MoneyRemoveMask(data.priceList))?.toFixed(2),
      discountValue: +(+PercentMaskRemove(data.discountValue))?.toFixed(2),
      institutionValue,
      nfPrice,
      initialQuantity: data.newQuantity,
      maxQuantity: newMaxQuantity,
      quantity: newMaxQuantity - totalQuantity
    });
  };

  return (
    <form id={'private-process-item'} onSubmit={handleSubmit(formSubmit)}>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            name={'itemNumber'}
            control={control}
            label={'Número do item'}
            type="number"
            errors={formState.errors.itemNumber?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            name={'newQuantity'}
            control={control}
            label={'Qtde. (UNI)'}
            type="number"
            errors={formState.errors.newQuantity?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <UITextField
            label="Quantidade Prod."
            fullWidth
            value={totalQuantity || '0'}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledAutocomplete<AdminEditItemProcessInputs, ProductsModel>
            name={'productId'}
            control={control}
            label={'SKU do produto'}
            errors={formState.errors.productId?.message}
            options={products || []}
            getOptionLabel={(option) => option?.sku || '-'}
            filterOptions={filterOptions<ProductsModel>()}
            onChange={(_, value) => {
              _.preventDefault();
              if (value && !Array.isArray(value)) {
                const findProduct = products?.find(
                  (selectedProduct) => selectedProduct.id === value.id
                );
                const priceList = findProduct?.political_prices?.[0]?.price;
                if (priceList) {
                  setValue('priceList', MoneyMask(priceList));
                }
                setValue('productId', value.id);
              } else {
                setValue('productId', undefined);
              }
            }}
            value={product}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <UITextField
            label="Descrição do produto"
            fullWidth
            value={product?.description || ''}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            name={'priceList'}
            control={control}
            label={'(R$) Preço Lista'}
            pattern="money"
            errors={formState.errors.priceList?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <UITextField
            label="Desconto Pré-aprovado"
            fullWidth
            value={
              (product?.political_prices?.[0]?.preDiscount &&
                FormatPercent(
                  product?.political_prices?.[0]?.preDiscount * 100
                )) ||
              ''
            }
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            name={'discountValue'}
            control={control}
            label={'Desconto Proposto'}
            pattern="percentage"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <UITextField
            label="(R$) Preço NF Nestlé"
            fullWidth
            value={MoneyMask(nfPrice)}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <UITextField
            label="Markup aplicado"
            fullWidth
            value={watch('markup')}
            disabled
          />
        </Grid>
        <Grid item xs>
          <UITextField
            label="(R$) Preço Instituição"
            fullWidth
            value={MoneyMask(institutionValue || '')}
            disabled
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={1} mt={2}>
        <Grid item xs={6}>
          <Button
            fullWidth
            variant={'outlined'}
            onClick={() => onClose && onClose({}, 'backdropClick')}
          >
            Voltar
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button type={'submit'} fullWidth variant={'contained'}>
            Avançar
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

// Change quantity is a hard logic, may the explanation below help you
// ==== Initial Quantity Logic ====
// The new quantity is gonna be the initialQuantity.
// ====== Max Quantity Logic ======
// See, if the segment is public
// or process is indirect,
// or some how, maxQuantity - initialQuantity != 0
// maxQuantity is going to be the newValue plus 25%, or, newValue * 1.25
// if neither of the conditions above is true, maxQuantity is newValue
// ======== Quantity Logic ========
// quantity is the hardest part to understand,
// quantity represents the left quantity of the product
// so, quantity is:
// new max quantity - (old max quantity - old quantity)
