import { ERRORS } from 'utils/Errors';
import * as yup from 'yup';

export interface CreateUpdateDistributorPrice {
  productId?: number;
  price?: string;
  distributorPriceId?: number;
}

export const CreateUpdateDistributorPriceSchema = yup.object().shape({
  productId: yup
    .number()
    .required(ERRORS.REQUIRED_FIELD)
    .typeError(ERRORS.REQUIRED_FIELD),
  price: yup
    .string()
    .required(ERRORS.REQUIRED_FIELD)
    .typeError(ERRORS.REQUIRED_FIELD)
});
