/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  Paper,
  Popover,
  Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useAuth } from 'Providers/Auth';
import PT from 'utils/lang';
import { Notifications } from 'shared/components/Header/HeaderNotifications';
import history from 'routes/History';
import { useQuery } from 'services';
import { AccessModel } from 'types/models';

const useStyles = makeStyles({
  root: {
    width: '240px'
  }
});

export function HeaderProfile() {
  const { auth, setAuth } = useAuth();
  const [, fetchAccess] = useQuery<AccessModel>(`/access/${auth?.id}`, {
    fetchOnInitialize: false
  });
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseEl = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();

  const nameInitials = () => {
    const name = auth?.distributor
      ? auth?.distributor.name
      : auth?.employee?.name;
    const lastName = auth?.distributor
      ? auth?.distributor.name?.split('/')[
          auth?.distributor.name?.split('/').length - 1
        ]
      : auth?.employee?.lastName;

    return `${name?.charAt(0)}${lastName?.charAt(0)}`;
  };

  const accessFetch = async () => {
    const accessFetched = await fetchAccess();
    if (accessFetched) {
      setAuth(accessFetched);
      localStorage.setItem('user', JSON.stringify(accessFetched));
    }
  };

  useEffect(() => {
    const getProfilePicture = async () => {
      try {
        const response = await fetch(auth?.profilePicture || '');
        if (response.status !== 200) {
          await accessFetch();
        }
      } catch (error) {
        await accessFetch();
        console.log('Error while get profile picture');
      }
    };
    getProfilePicture();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.profilePicture]);

  return (
    <Grid container justifyContent={'flex-end'}>
      <Grid item container justifyContent={'flex-end'} xs>
        <Notifications />
      </Grid>
      <Grid
        item
        container
        direction={'column'}
        alignItems={'flex-end'}
        justifyContent={'center'}
        xs={5}
      >
        <Grid
          item
          style={{
            overflow: 'hidden',
            wordBreak: 'break-all',
            textOverflow: 'ellipsis',
            height: '16px'
          }}
        >
          <Typography variant="h4">
            {auth?.distributor
              ? auth?.distributor?.name
              : `${auth?.employee?.name} ${auth?.employee?.lastName}`}
          </Typography>
        </Grid>
        <Grid item>
          <Typography color={'primary'} variant="h4">
            {auth?.distributor ? 'Distribuidor' : null}
            {auth?.employee && auth?.employee?.accessLevel
              ? PT[auth?.employee?.accessLevel]
              : null}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={3}>
        <Button onClick={handleClick}>
          {auth?.profilePicture ? (
            <Avatar src={auth?.profilePicture} />
          ) : (
            <Avatar> {nameInitials()} </Avatar>
          )}
        </Button>
        <Popover
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={handleCloseEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <Paper className={classes.root} elevation={8}>
            <Box p={2} pb={1}>
              <Grid item container>
                <Grid item xs={12}>
                  <Typography variant="h3" color={'primary'}>
                    {auth?.distributor?.name ||
                      `${auth?.employee?.name} ${auth?.employee?.lastName}`}
                  </Typography>
                </Grid>
                <Box pb={1} />
                <Grid item xs={12}>
                  <Typography variant={'subtitle2'}>
                    {auth?.distributor ? 'Distribuidor' : null}
                    {auth?.employee && auth?.employee?.accessLevel
                      ? PT[auth?.employee?.accessLevel]
                      : null}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <Box pt={1} pb={1}>
              <MenuItem onClick={() => history.push('/perfil')}>
                Perfil
              </MenuItem>
              <MenuItem
                onClick={() => {
                  localStorage.removeItem('token');
                  localStorage.removeItem('user');
                  history.go(0);
                }}
              >
                Logout
              </MenuItem>
            </Box>
          </Paper>
        </Popover>
      </Grid>
    </Grid>
  );
}
