import { Button, Grid, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { UITable } from 'shared/components';
import { UITextField } from 'shared/components/Inputs';
import { UIDataGridApiPagination } from 'shared/components/Tables/DataGridApiPagination';
import { UIToolbar } from 'shared/components/Tables/ToolbarTable';
import { ImportsModel } from 'types/models/Imports/Imports.model';
import { ImportsType } from 'utils/Constants/ImportsType';
import { ImportsColumns } from './columns';

type Props = {
  imports?: ImportsModel[];
  loading: boolean;
  refetchImports: () => void;
};

export default function ImportsTable({
  imports = [],
  refetchImports,
  loading
}: Props) {
  const [type, setType] = useState<string>('all');

  const filteredImports =
    imports.filter((newImport) =>
      type !== 'all' ? newImport.type === type : true
    ) || [];

  return (
    <>
      <Grid container spacing={2} justifyContent="flex-end" mb={2} mt={2}>
        <Grid item xs={12} sm={3}>
          <UITextField
            label={'Tipo'}
            value={type}
            fullWidth
            select
            onChange={(e) => setType(e.target.value)}
          >
            {['all', ...Object.keys(ImportsType)].map((key) => (
              <MenuItem key={key} value={key}>
                {ImportsType[key]?.label || 'Todos'}
              </MenuItem>
            ))}
          </UITextField>
        </Grid>
      </Grid>
      <UITable
        rows={filteredImports}
        loading={loading}
        columns={ImportsColumns}
        rowHeight={36}
        headerHeight={36}
        pageSize={10}
        components={{
          Toolbar: () => (
            <UIToolbar
              elementsTopEnd={
                <>
                  <Grid
                    container
                    item
                    alignItems="center"
                    justifyContent="flex-end"
                    xs={12}
                    p={1}
                  >
                    <Grid container justifyContent="flex-end" item xs>
                      <UIDataGridApiPagination itemSize="small" />
                    </Grid>
                    <Grid container item xs={3}>
                      <Button
                        fullWidth
                        variant="outlined"
                        onClick={() => refetchImports()}
                      >
                        Atualizar Tabela
                      </Button>
                    </Grid>
                  </Grid>
                </>
              }
            />
          )
        }}
      />
    </>
  );
}
