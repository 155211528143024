import React from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import ptBRLocale from 'date-fns/locale/pt-BR';

interface DatePickerProviderParams {
  children: React.ReactNode;
}

export function DatePickerProvider({ children }: DatePickerProviderParams) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBRLocale}>
      {children}
    </LocalizationProvider>
  );
}
