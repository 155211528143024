/* eslint-disable no-await-in-loop */
/* eslint-disable react-hooks/exhaustive-deps */
import CheckIcon from '@mui/icons-material/Check';
import { Container, Fab, LinearProgress } from '@mui/material';
import InformDiscountBottom from 'modules/Process/InformDiscount/Fragments/Bottom';
import InformDiscountItemsTable from 'modules/Process/InformDiscount/Fragments/ItemsTable';
import InformDiscountTitle from 'modules/Process/InformDiscount/Fragments/Title';
import NewPageBreadcrumb from 'modules/Process/shared/Fragment/NewPageBreadcrumb';
import { DeniedValuesConfirmModal } from 'pages/Process/modals/DeniedValuesConfirm';
import { useAuth } from 'Providers/Auth';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { usePatch, useQuery } from 'services';
import { useFab } from 'shared/components/Fab';
import {
  DiscountOrderModel,
  ProcessModel,
  ProcessProductModel,
  ProductsModel
} from 'types/models';
import { AttachmentModel } from 'types/models/Attachments/Attachment.model';
import { UrlParams } from 'types/services';
import { sleep } from 'utils/Functions/Sleep';
import { GetItemsWithStatus } from '../Functions/GetItemsWithStatus';

export function InformDiscountConsultant() {
  const { id } = useParams() as UrlParams;
  const { auth } = useAuth();
  const history = useHistory();

  const [processProducts, processProductsRefetch] = useQuery<
    ProcessProductModel[],
    ProcessProductModel
  >(`/process-products`, {
    discount: 1,
    $limit: '-1',
    processId: id
  });

  const [process] = useQuery<ProcessModel, ProcessModel>(`/process/${id}`);

  const [attachments, attachmentsRefetch, attachmentsLoading] = useQuery<
    AttachmentModel[],
    ProcessProductModel
  >(`/attachments`, {
    table: 'process',
    $limit: '-1',
    tableId: id,
    accessId: auth?.id,
    fetchOnInitialize: false
  });

  const [productsToPolitical, fetchProductsToPolitical] = useQuery<
    ProductsModel[],
    ProductsModel
  >(`/products`, {
    fetchOnInitialize: false,
    $limit: -1,
    $select: ['id', 'sku'],
    processId: id
  });
  useEffect(() => {
    if (processProducts) {
      fetchProductsToPolitical({
        'id[$in]': processProducts?.map(
          (processProduct) => processProduct.productId
        )
      });
    }
  }, [processProducts]);

  const [, patchDiscountOrder, patchDiscountOrderLoading] =
    usePatch<DiscountOrderModel>(`/discount-order`);
  const [, patchProcessProduct, patchProcessProductLoading] =
    usePatch<ProcessProductModel>(`/process-products`);
  const [, patchProcess, patchProcessLoading] =
    usePatch<ProcessModel>(`/process`);

  useEffect(() => {
    if (auth) {
      if (auth.employee?.accessLevel !== 'consultant') {
        history.push(`/processo/${id}`);
      }
    }
    attachmentsRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.id]);

  const [deniedModal, setDeniedModal] = useState(false);

  const handleToggleDeniedModal = () => {
    setDeniedModal(!deniedModal);
  };

  const handleSubmit = async () => {
    const contestedOrRequested = GetItemsWithStatus(processProducts || [], [
      'requested',
      'contested'
    ]);
    const denied = GetItemsWithStatus(processProducts || [], ['denied']);

    if (denied?.length !== 0) {
      toast.error(
        `Os seguintes itens foram negados e não contestados: ${denied}`
      );
      setDeniedModal(true);
    } else if (contestedOrRequested?.length !== 0) {
      await patchProcess(
        {
          status: 'awaiting financier'
        },
        +id
      );
      toast.success(`Descontos contestados com sucesso. `);
      history.push(`/processo/${id}`);
    } else if (contestedOrRequested?.length === 0) {
      if (process?.segment === 'private') {
        const needsInformResult = (processProducts || []).some(
          (pp) => !pp?.finalValue
        );
        if (needsInformResult) {
          await patchProcess(
            {
              status: 'awaiting consultant result'
            },
            +id
          );
          toast.warn(
            'O processo possui itens que necessitam o informe do resultado. Informe o resultado para gerar PNE.'
          );
        } else {
          const updatedProcess = await patchProcess(
            {
              status: 'awaiting order'
            },
            +id
          );
          if (updatedProcess?.pne) {
            toast.success(
              `Todos os descontos foram aprovados. O processo gerou a PNE: ${updatedProcess?.pne}`
            );
          } else {
            toast.error(`Erro ao gerar PNE após informar os descontos`);
          }
        }
      } else if (process?.segment === 'public') {
        await patchProcess(
          {
            status: 'awaiting distributor result'
          },
          +id
        );
        toast.success(
          `Todos os descontos foram aprovados. O distribuidor já pode informar o resultado.`
        );
      }
      history.push(`/processo/${id}`);
    }
  };

  const [handleSubmitAgain, setHandleSubmitAgain] = useState(false);

  const handleConfirmDeniedModal = async () => {
    if (processProducts && processProducts.length > 0) {
      for (let i = 0; i < processProducts.length; i += 1) {
        const processProduct = processProducts[i];
        const discountId = processProduct?.discount_orders?.[0]?.id;
        const discountStatus = processProduct?.discount_orders?.[0]?.status;
        if (discountId && discountStatus && discountStatus === 'denied') {
          await patchDiscountOrder({ status: 'approved' }, discountId);
        }
      }

      setDeniedModal(false);
      const response = await processProductsRefetch();

      if (response) {
        setHandleSubmitAgain(true);
      }
    }
  };

  useEffect(() => {
    if (handleSubmitAgain) {
      sleep(1000).then(() => {
        handleSubmit();
      });
    }
  }, [handleSubmitAgain]);

  const fabClasses = useFab();

  return (
    <>
      <Container maxWidth={'lg'}>
        <NewPageBreadcrumb id={id} title="Contestar Descontos" />
        {patchProcessLoading && <LinearProgress color="primary" />}
        <InformDiscountTitle
          title="Contestar Descontos"
          subtitle="Analise os descontos aprovados e negados pelo financeiro e conteste-os."
        />
        <InformDiscountItemsTable
          type="consultant"
          processProducts={processProducts}
          process={process || {}}
          patchDiscountOrder={patchDiscountOrder}
          processProductsRefetch={processProductsRefetch}
          patchProcessProduct={patchProcessProduct}
          loading={patchProcessProductLoading || patchDiscountOrderLoading}
          productsToPolitical={productsToPolitical}
        />
        <InformDiscountBottom
          setJustify={() => {}}
          attachments={attachments || []}
          attachmentsRefetch={attachmentsRefetch}
          attachmentsLoading={attachmentsLoading}
          accessId={auth?.id}
          processId={+id}
          observationTitle="A observação criada pelo financeiro aparecerá abaixo."
          justify={process?.justify}
          disabled
        />
      </Container>
      <DeniedValuesConfirmModal
        open={deniedModal}
        onCancel={handleToggleDeniedModal}
        onConfirm={handleConfirmDeniedModal}
      />

      <Fab
        className={fabClasses.fab}
        color={'primary'}
        variant={'extended'}
        aria-label="add"
        onClick={handleSubmit}
        disabled={patchProcessLoading}
      >
        <CheckIcon />
        Concluir
      </Fab>
    </>
  );
}
