import { States } from 'utils/Enums';
import { ERRORS } from 'utils/Errors';
import * as yup from 'yup';

export interface AdminEditProcessForm {
  employeeId?: number;
  institutionId?: number;
  distributorId?: number;
  uf?: States;
  city?: string;
  regional?: string;
  serviceChannel?: string;
  focusUf?: boolean;
  status?: string;

  // fields that will not go to back end
  willExtend?: boolean;
  newEndPeriod?: Date;
  moreMonths?: number;
}

export const AdminEditProcessFormSchema = yup.object({
  institutionId: yup.number().nullable().optional(),
  employeeId: yup.number().nullable().optional(),
  distributorId: yup.number().nullable().optional(),
  uf: yup.string().nullable().optional(),
  city: yup.string().nullable().optional(),
  regional: yup.string().nullable().optional(),
  serviceChannel: yup.string().nullable().optional(),
  class: yup.string().nullable().optional(),
  focusUf: yup.boolean().nullable().optional(),
  status: yup.string().nullable().optional(),
  willExtend: yup.boolean().nullable().optional(),
  newEndPeriod: yup
    .date()
    .nullable()
    .optional()
    .when('willExtend', {
      is: true,
      then: yup
        .date()
        .required(ERRORS.REQUIRED_FIELD)
        .typeError(ERRORS.REQUIRED_FIELD)
    }),
  moreMonths: yup
    .number()
    .nullable()
    .optional()
    .when('willExtend', {
      is: true,
      then: yup
        .number()
        .required(ERRORS.REQUIRED_FIELD)
        .typeError(ERRORS.REQUIRED_FIELD)
    })
});
