import { Grid, Typography } from '@mui/material';
import { ProcessItemMinimalFormResponse } from 'modules/Process/PublicProcess/Forms/ScopeRegistration/minimal/domain';
import React, { useState } from 'react';
import { theme } from 'shared/theme';
import { ProcessProductModel } from 'types/models';
import { PrivateProcessItemModal } from '../../Modals/ProcessItem';
import { PrivateProcessItemsTable } from './PrivateProcessItemsTable';

interface ProcessItemFormTableProps {
  onAddProduct: (data: Partial<ProcessProductModel>) => Promise<void>;
  onEditProduct: (data: Partial<ProcessProductModel>) => Promise<void>;
  onDelete: (id: number) => Promise<void>;
  processItemData: ProcessProductModel[];
  loading: boolean;
  processDirect: boolean;
  processId: number;
  distributorUf?: string;
}

export function PrivateProcessItemFormTable({
  onAddProduct,
  onEditProduct,
  onDelete,
  processItemData,
  loading,
  processDirect,
  processId,
  distributorUf
}: ProcessItemFormTableProps) {
  const [handleCreate, setHandleCreate] = useState(false);
  const [editItem, setEditItem] = useState<Partial<ProcessProductModel>>();

  const onHandleCreate = async (data: Partial<ProcessProductModel>) => {
    await onAddProduct(data);
    setHandleCreate(false);
  };

  const onEditProcessItem = async (item: Partial<ProcessProductModel>) => {
    await onEditProduct(item);
    setEditItem(undefined);
  };

  const onCompleteProcessFormModal = (data: ProcessItemMinimalFormResponse) => {
    const processItem = data as Partial<ProcessProductModel>;
    if (!editItem) {
      onHandleCreate(processItem);
    } else {
      processItem.id = editItem.id;
      onEditProcessItem(processItem);
    }
  };

  const handleEditItem = (data: Partial<ProcessProductModel>) => {
    setEditItem(data);
  };

  const handleDelete = (data: Partial<ProcessProductModel>) => {
    if (data.id) {
      onDelete(data.id);
    }
  };

  return (
    <>
      <Grid container pt={2}>
        <Grid container item pt={2} pb={2} alignItems="center">
          <Typography
            variant="h2"
            style={{ fontSize: '24px', marginRight: '15px' }}
          >
            Itens adicionados
          </Typography>
          <Typography variant="h5" style={{ color: theme.colors.TextGrey }}>
            {processItemData?.length}
            {processItemData?.length !== 1
              ? ' itens adicionados'
              : ' item adicionado'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <PrivateProcessItemsTable
            onRowDoubleClick={(data) => {
              handleEditItem(data?.row as Partial<ProcessProductModel>);
            }}
            processProduct={processItemData}
            actions={[
              {
                label: 'Editar',
                onClick: (data) => {
                  handleEditItem(data as Partial<ProcessProductModel>);
                }
              },
              {
                label: 'Remover',
                onClick: (data) => {
                  handleDelete(data as Partial<ProcessProductModel>);
                }
              }
            ]}
            loading={loading}
            onAddItemClick={() => setHandleCreate(true)}
          />
        </Grid>
        <PrivateProcessItemModal
          open={handleCreate || !!editItem}
          onComplete={onCompleteProcessFormModal}
          onClose={() => {
            setEditItem(undefined);
            setHandleCreate(false);
          }}
          title={handleCreate ? 'Adicionar Item' : 'Editar Item'}
          processItem={editItem}
          processDirect={processDirect}
          processId={processId}
          distributorUf={distributorUf}
        />
      </Grid>
    </>
  );
}
