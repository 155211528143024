import { Grid, Typography } from '@mui/material';
import React from 'react';
import { UIBreadcrumb, UILink } from 'shared/components';
import { theme } from 'shared/theme';

export default function ImportsTitle() {
  return (
    <>
      <UIBreadcrumb>
        <UILink to={'/dashboard'}> Dashboard </UILink>
        <Typography color={'primary'}> Importações </Typography>
      </UIBreadcrumb>
      <Grid container spacing={3} mb={3} mt={1}>
        <Grid item container xs={12}>
          <Grid item container xs={12} md={3} alignItems={'center'}>
            <Typography
              variant={'h1'}
              style={{ color: theme.colors.TextDarkGrey }}
            >
              Importações
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
