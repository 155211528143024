import React from 'react';
import { ptBR } from '@mui/material/locale';
import { createTheme } from '@mui/material/styles';
import { ThemeColors } from 'shared/enum';
import { UITextField } from 'shared/components/Inputs';

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    pink: Palette['primary'];
    orange: Palette['primary'];
  }
  interface PaletteOptions {
    pink: PaletteOptions['primary'];
    orange: PaletteOptions['primary'];
  }
}

export const MaterialGlobal = createTheme(
  {
    typography: {
      fontFamily: [
        '-apple-system',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif'
      ].join(','),
      h1: {
        fontSize: '2em',
        color: ThemeColors.Primary,
        fontWeight: 'bold'
      },
      h2: {
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '19px',
        color: ThemeColors.TextDarkGrey
      },
      h3: {
        fontSize: '16px',
        lineHeight: '135%'
      },
      h4: {
        fontSize: '14px'
      },
      h5: {
        fontSize: '12px'
      },
      h6: {
        fontSize: '10px'
      }
    },
    palette: {
      pink: {
        main: '#ff73c3'
      },
      primary: {
        main: '#5082c9'
      },
      success: {
        main: '#65ac28'
      },
      error: {
        main: '#c95050'
      },
      grey: {
        '300': '#e5e5e5',
        '400': '#ebebeb',
        '500': '#c4c4c4',
        '600': '#565656'
      },
      orange: {
        main: '#ef851b'
      },
      background: {
        default: '#f6f9fc'
      }
    },
    components: {
      MuiButton: {
        styleOverrides: {
          containedPrimary: {
            backgroundColor: '#5082C9',
            color: '#FFF',
            fontSize: '16px',
            fontWeight: 500,
            textTransform: 'capitalize',
            '&:hover': {
              backgroundColor: 'rgb(56, 91, 140)'
            }
          },
          outlined: {
            border: `1px solid #5082c9`,
            color: '#5082c9',
            fontSize: '16px',
            borderColor: '#5082c9',
            textTransform: 'capitalize',
            '&:hover': {
              color: '#5082c9',
              border: `1px solid #06a8d1`
            }
          },
          outlinedPrimary: {
            fontWeight: 600,
            border: `2px solid #5082c9`,
            '&:hover': {
              color: '#5082c9',
              border: `2px solid #5082c9`
            }
          }
        }
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            backgroundColor: 'white'
          }
        }
      },
      MuiTypography: {
        styleOverrides: {
          caption: {
            color: 'rgb(80, 130, 201)',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 'normal',
            lineHeight: 'normal',
            letterSpacing: 0
          }
        }
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            padding: '0px 28px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignContent: 'center',
            alignItems: 'center'
          }
        }
      },
      MuiSvgIcon: {
        styleOverrides: {
          colorSecondary: {
            color: 'white'
          }
        }
      },
      MuiTextField: {
        defaultProps: {
          variant: 'standard',
          fullWidth: true
        }
      },
      MuiAutocomplete: {
        defaultProps: {
          renderInput: (props) => <UITextField {...props} />
        }
      }
    }
  },
  ptBR
);
