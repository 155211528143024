/* eslint-disable react-hooks/exhaustive-deps */
import { DatePicker } from '@mui/lab';
import { Box, Container, Grid, TextField } from '@mui/material';
import { add, isValid } from 'date-fns';
import { InformResultFragment } from 'modules/Process/PublicProcess/Fragments/InformResult';
import { InformResultPreviewDocument } from 'modules/Process/PublicProcess/Fragments/InformResult/Fragments/consultant/InformResultPreviewDocument';
import { InformResultBreadcrumb } from 'modules/Process/PublicProcess/Fragments/InformResult/Fragments/InformResultBreadcrumb';
import { InformResultTitle } from 'modules/Process/PublicProcess/Fragments/InformResult/Fragments/InformResultTitle';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { usePatch, usePost, useQuery } from 'services';
import { ProcessModel } from 'types/models';
import { AttachmentModel } from 'types/models/Attachments/Attachment.model';
import { Paginated, UrlParams } from 'types/services';

interface MakeCreateInvalidDocumentPendency {
  distributorId: number;
  processId: number;
  status: 'consultantReportingAttachment';
  text: string;
}

export function InformResultConsultant() {
  const { id } = useParams() as UrlParams;
  const history = useHistory();
  const [startPeriod, setStartPeriod] = useState<string>(
    new Date().toISOString()
  );
  const [endPeriod, setEndPeriod] = useState<string>(new Date().toISOString());
  const [period, setPeriod] = useState<number>();

  const [process] = useQuery<ProcessModel, ProcessModel>(`/process/${id}`, {
    notInclude: ['comments', 'pendencies'],
    $select: ['period', 'id', 'distributorId', 'tradingCode', 'segment']
  });

  useEffect(() => {
    if (process?.period) {
      setPeriod(process?.period);
      const addDate = add(new Date(startPeriod), {
        months: +process?.period
      }).toISOString();
      setEndPeriod(addDate);
    }
  }, [process]);

  const [, patchProcess] = usePatch<Partial<ProcessModel>>('/process');
  const [processDocument] = useQuery<
    Paginated<AttachmentModel>,
    AttachmentModel
  >('/attachments', {
    table: 'process',
    $limit: 50,
    tableId: id,
    'category[$in]': ['Ata']
  });

  const handleComplete = async () => {
    const response = await patchProcess(
      { status: 'awaiting order', startPeriod, endPeriod },
      +id
    );
    if (response) {
      toast.success(
        `Resultado informado com sucesso, a PNE gerada foi: ${response.pne}`
      );
      history.push(`/processo/${id}`);
    } else {
      toast.success(`Erro ao concluir processo`);
    }
  };

  const [, postPendency] =
    usePost<MakeCreateInvalidDocumentPendency>('/pendencies');

  const handleSuccessInvalidDocument = () => {
    toast.success('Documento invalidado com sucesso');
    if (process && process.id) {
      history.push(`/processo/${process.id}`);
    }
  };

  const handleInvalidDocument = async () => {
    await patchProcess(
      {
        status: 'awaiting distributor result',
        isNotPendencie: 'true'
      },
      +id
    );

    if (process && process.distributorId && process.id && process.tradingCode) {
      const pendencyResponse = await postPendency({
        distributorId: process.distributorId,
        processId: process.id,
        status: 'consultantReportingAttachment',
        text: `Consultor contestou ata do processo ${process.tradingCode}`
      });
      if (pendencyResponse) {
        handleSuccessInvalidDocument();
      }
    }
  };

  return (
    <Container maxWidth={'lg'}>
      <InformResultBreadcrumb id={id} />
      <InformResultTitle />
      {process?.segment !== 'private' ? (
        <>
          <InformResultPreviewDocument
            processDocument={processDocument}
            onInvalidDocument={handleInvalidDocument}
          />
          <Box mt={2} />
          <Grid container item xs={12} spacing={2} justifyContent="center">
            <Grid item xs={3} mt={2} mb={2}>
              <DatePicker
                value={startPeriod}
                onChange={(date: Date | null) => {
                  if (date && isValid(date)) {
                    setStartPeriod(date.toISOString());
                    if (period) {
                      const addDate = add(date, {
                        months: +period
                      }).toISOString();
                      setEndPeriod(addDate);
                    }
                  }
                }}
                label={'Data da assinatura'}
                renderInput={(props) => (
                  <TextField {...props} name={'startPeriod'} />
                )}
              />
            </Grid>
            <Grid item xs={3} mt={2} mb={2}>
              <DatePicker
                value={endPeriod}
                onChange={(date: Date | null) => {
                  if (date && isValid(date)) {
                    setEndPeriod(date.toISOString());
                  }
                }}
                label={'Término de fornecimento'}
                renderInput={(props) => (
                  <TextField {...props} name={'endPeriod'} />
                )}
              />
            </Grid>
            <Grid item xs={1} mt={2} mb={2}>
              <TextField
                value={period || ''}
                name={'period'}
                label={'Período'}
                disabled
              />
            </Grid>
          </Grid>
        </>
      ) : null}

      <InformResultFragment processId={id} onComplete={handleComplete} />
    </Container>
  );
}
