import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  Grid,
  Typography
} from '@mui/material';

interface DeniedValuesConfirmModalProps extends DialogProps {
  onConfirm: () => void;
  onCancel: () => void;
}

export function DeniedValuesConfirmModal({
  onConfirm,
  onCancel,
  ...rest
}: DeniedValuesConfirmModalProps) {
  return (
    <Dialog {...rest}>
      <Box sx={{ width: 400 }}>
        <DialogTitle sx={{ my: 2 }}>
          <Typography variant={'h2'}>
            Valores contestados encontrados
          </Typography>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Typography variant={'h4'} textAlign={'center'}>
            Existem pedidos de descontos negados e com seus valores não
            contestados
          </Typography>

          <br />
          <Typography variant={'h2'} textAlign={'center'} color={'error'}>
            Deseja prosseguir com os valores informados pelo financeiro?
          </Typography>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Grid container justifyContent={'center'} spacing={2}>
            <Grid item>
              <Button variant={'contained'} onClick={onCancel}>
                Não
              </Button>
            </Grid>
            <Grid item>
              <Button variant={'contained'} color={'error'} onClick={onConfirm}>
                Sim
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
