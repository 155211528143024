type TotalItemReturn = {
  totalItem: number;
  totalItemGPR: number;
};

type TotalItemProps = {
  nfPrice?: number | string | null;
  initialQuantity?: number | string | null;
  priceList?: number | string | null;
};

export function TotalItem({
  nfPrice,
  initialQuantity,
  priceList
}: TotalItemProps): TotalItemReturn {
  const returnData: TotalItemReturn = {
    totalItem: 0,
    totalItemGPR: 0
  };
  if (nfPrice && initialQuantity) {
    if (+nfPrice && +initialQuantity)
      returnData.totalItem = +(+nfPrice * +initialQuantity).toFixed(2);
  }

  if (initialQuantity && nfPrice && priceList) {
    if (+initialQuantity && +nfPrice && +priceList) {
      returnData.totalItemGPR = +(
        (+priceList - +nfPrice) *
        +initialQuantity
      ).toFixed(2);
    }
  }

  return returnData;
}
