import { Container, Grid, Typography } from '@mui/material';
import E2OProcessDetails from 'modules/Process/EmailToOrder/E20ProcessDetails';
import { EmailToOrderFragment } from 'modules/Process/shared/Fragment/EmailToOrderFragment';
import { EmailToOrderStepOne } from 'modules/Process/shared/Fragment/EmailToOrderStepOne';
import NewPageBreadcrumb from 'modules/Process/shared/Fragment/NewPageBreadcrumb';
import React, { ChangeEvent, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'services';
import { UITabs } from 'shared/components/Tabs';
import { UITab } from 'shared/components/Tabs/Tab';
import { theme } from 'shared/theme';
import { ProcessModel } from 'types/models';
import { UrlParams } from 'types/services';

export function EmailToOrder() {
  const { id } = useParams() as UrlParams;
  const [step, setStep] = useState<number>(0);

  const [process] = useQuery<ProcessModel, ProcessModel>(`/process/${id}`, {
    notInclude: ['comments', 'pendencies', 'process_products']
  });

  const handleChangeStep = (
    event: ChangeEvent<unknown>,
    newStep: string | number
  ) => {
    setStep(+newStep);
  };

  return (
    <Container maxWidth={'lg'}>
      <NewPageBreadcrumb id={id} title="Faturar Pedido" />
      <Grid container flexDirection="column" gap={1} mb={3}>
        <Grid
          container
          item
          xs={12}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            variant="h2"
            style={{
              fontSize: theme.font.sizes.large
            }}
          >
            Faturar Pedido
          </Typography>
        </Grid>
      </Grid>
      <E2OProcessDetails process={process} />
      <UITabs value={step} onChange={handleChangeStep}>
        <UITab label={'Pedido'} />
        <UITab label={'E2O'} />
      </UITabs>
      <Grid container pt={1} pb={2}>
        {step === 0 ? (
          <EmailToOrderStepOne
            processId={id}
            onComplete={() => handleChangeStep({} as ChangeEvent, 1)}
          />
        ) : null}
        {step === 1 ? <EmailToOrderFragment processId={id} /> : null}
      </Grid>
    </Container>
  );
}
