import { Button, Grid, LinearProgress } from '@mui/material';
import { ProcessOrderE2O } from 'modules/Process/shared/Tables/ProcessOrder/ProcessOrderE2O';
import React from 'react';
import { useQuery } from 'services';
import { OrderModel } from 'types/models/Order/Order.model';
import { Paginated } from 'types/services';

interface EmailToOrderStepOneProps {
  processId: number | string;
  onComplete: () => void;
}

export function EmailToOrderStepOne({
  processId,
  onComplete
}: EmailToOrderStepOneProps) {
  const [order, , loading] = useQuery<Paginated<OrderModel>>('/order', {
    processId,
    $limit: 1,
    '$sort[createdAt]': -1,
    status: 'Processando'
  });

  const orders = order?.data[0] || null;
  const orderItems = orders?.order_items || [];

  return (
    <>
      {loading ? (
        <LinearProgress />
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <ProcessOrderE2O rows={orderItems} onComplete={() => null} />
          </Grid>
          <Grid item container justifyContent={'flex-end'} xs={12}>
            <Grid item container mt={2} xs={12} sm={3}>
              <Button fullWidth variant={'contained'} onClick={onComplete}>
                Avançar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}
