import React from 'react';
import { SimpleFormModal } from 'shared/components/Modal/SimpleFormModal';
import { ProcessItemResultForm } from 'modules/Process/PublicProcess/Forms/ProcessItemResult';
import { Button, DialogActions, Grid } from '@mui/material';
import { ProcessProductModel } from 'types/models';
import { MoneyMask } from 'utils/masks';
import { SimpleFormModalParams } from 'shared/components/Modal/SimpleFormModal/SimpleFormModal';

interface InformResultModalParams extends Omit<SimpleFormModalParams, 'title'> {
  processProduct: ProcessProductModel;
  onHandleComplete: (data: Partial<ProcessProductModel>) => void;
}
export function InformResultModal({
  processProduct,
  onHandleComplete,
  open,
  onClose,
  ...rest
}: InformResultModalParams) {
  return (
    <SimpleFormModal
      title={'Informar Resultado'}
      open={open}
      onClose={onClose}
      {...rest}
      fullWidth
      bottomActions={
        <DialogActions>
          <Grid
            item
            container
            direction={'row'}
            justifyContent={'flex-end'}
            spacing={2}
            xs={12}
          >
            <Grid item xs={6}>
              <Button
                fullWidth
                form={'ProcessItemForm'}
                variant="outlined"
                color={'primary'}
                onClick={() => (onClose ? onClose({}, 'escapeKeyDown') : null)}
              >
                Voltar
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                form={'ProcessItemForm'}
                variant="contained"
                color={'primary'}
                type={'submit'}
              >
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      }
    >
      <Grid container>
        <Grid item container xs={12}>
          <Grid item sm={2}>
            Item: {processProduct?.itemNumber}
          </Grid>
          <Grid item sm={4}>
            SKU: {processProduct?.product?.sku}
          </Grid>
          <Grid item sm={6}>
            (R$) Preço NF liberado: {MoneyMask(processProduct?.nfPrice || '0')}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ProcessItemResultForm
            processProduct={processProduct}
            onHandleComplete={onHandleComplete}
          />
        </Grid>
      </Grid>
    </SimpleFormModal>
  );
}
