import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { UIChip } from 'shared/components/Chip';
import { MoneyMask } from 'utils/masks';

export const ValidateOfficialProcessItemsColumns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'id',
    hide: true
  },
  {
    field: 'itemNumber',
    headerName: 'Item',
    width: 80,
    valueGetter: ({ row }) => row?.itemNumber || '-'
  },
  {
    field: 'product.description',
    headerName: 'Produto',
    width: 330,
    valueGetter: ({ row }) => row?.product?.description || '-'
  },
  {
    field: 'winner',
    headerName: 'Ganhador',
    width: 120,
    renderCell: ({ value }) => {
      const content: {
        color: 'green' | 'red' | 'orange';
        value: string;
      } = {
        color: 'orange',
        value: 'Aguardando'
      };
      if (value === true) {
        content.color = 'green';
        content.value = 'Ganho';
      }
      if (value === false) {
        content.color = 'red';
        content.value = 'Perda';
      }
      return (
        <UIChip
          color={content.color}
          title={content.value || ''}
          height="20px"
        />
      );
    },
    valueFormatter: ({ value }) => value ?? '-'
  },
  {
    field: 'initialValue',
    headerName: 'Valor inicial',
    width: 150,
    valueGetter: ({ row }) => row?.initialValue,
    valueFormatter: ({ value }) => (value ? MoneyMask(String(value)) : '-')
  },
  {
    field: 'finalValue',
    headerName: 'Valor final',
    width: 150,
    valueGetter: ({ row }) => row?.finalValue,
    valueFormatter: ({ value }) => (value ? MoneyMask(String(value)) : '-')
  },
  {
    field: 'rival_product.company',
    headerName: 'Concorrente',
    width: 200,
    valueGetter: ({ row }) => row?.rival_product?.company || '-'
  },
  {
    field: 'rival_product.description',
    headerName: 'Produto Rival',
    width: 200,
    valueGetter: ({ row }) => row?.rival_product?.description || '-'
  },
  {
    field: 'rivalInitialValue',
    headerName: 'Valor inicial (Concorrente)',
    width: 200,
    valueGetter: ({ row }) => row?.rivalInitialValue,
    valueFormatter: ({ value }) => (value ? MoneyMask(String(value)) : '-')
  },
  {
    field: 'rivalFinalValue',
    headerName: 'Valor final (Concorrente)',
    width: 200,
    valueGetter: ({ row }) => row?.rivalFinalValue,
    valueFormatter: ({ value }) => (value ? MoneyMask(String(value)) : '-')
  },
  {
    field: 'justify',
    headerName: 'Justificativa',
    width: 200,
    valueGetter: ({ row }) => row?.justify || '-'
  }
];
