import { MoneyMask, MoneyRemoveMask, PercentMaskRemove } from 'utils/masks';

export const GetPreApprovedPrice = (
  preDiscount: number | string,
  priceList: number | string
) => {
  const values = {
    preDiscount:
      typeof preDiscount === 'string'
        ? PercentMaskRemove(preDiscount)
        : preDiscount,
    priceList:
      typeof priceList === 'string' ? +MoneyRemoveMask(priceList) : priceList
  };
  const newValue = values.priceList * (1 - values?.preDiscount / 100);
  return MoneyMask(newValue.toFixed(2));
};
