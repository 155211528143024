import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { ERRORS } from 'utils/Errors';
import api from '../config';

export type FetchPost<DataType, ResponseType> = (
  data: DataType,
  isMultiple?: boolean,
  additionalAxiosOptions?: AxiosRequestConfig
) => Promise<ResponseType | undefined>;

export type FetchPostReturnAxios<DataType, ResponseType> = (
  data: DataType,
  isMultiple?: boolean,
  additionalAxiosOptions?: AxiosRequestConfig
) => Promise<AxiosResponse<ResponseType> | undefined>;

export type UsePostReturnObject<DataType, ResponseType> = {
  nodes: ResponseType | undefined;
  fetch: FetchPost<DataType, ResponseType> | Promise<void>;
  loading: boolean;
  errors: unknown;
};

export type UsePostOptions = {
  willFail?: boolean;
};

export type UsePostReturn<DataType, ResponseType> = [
  ResponseType | undefined,
  FetchPost<DataType, ResponseType>,
  boolean,
  unknown,
  UsePostReturnObject<DataType, ResponseType>,
  FetchPostReturnAxios<DataType, ResponseType>
];

/**
 * @param query
 * @return
 * data, fetch, loading, errors
 */
export function usePost<DataType = void, ResponseType = void>(
  query: string,
  options?: UsePostOptions
): UsePostReturn<DataType, ResponseType> {
  const [loading, setLoading] = useState<boolean>(false);
  const [responseData, setResponseData] = useState<ResponseType>();
  const [error, setError] = useState<unknown>(null);

  async function fetch(
    data: DataType,
    isMultiple = false,
    additionalAxiosOptions?: AxiosRequestConfig
  ): Promise<ResponseType | undefined> {
    try {
      if (
        (!Array.isArray(data) && !isMultiple) ||
        (Array.isArray(data) && isMultiple)
      ) {
        setLoading(true);
        const response = await api.post<ResponseType>(
          query,
          data,
          additionalAxiosOptions
        );
        if (response.status.toString()[0] === '2') {
          setResponseData(response.data);
          setLoading(false);
          return response.data;
        }
        setLoading(false);
      }
      if (!options?.willFail) {
        toast.error(ERRORS.SOMETHING_WENT_WRONG);
      }
    } catch (err) {
      if (!options?.willFail) {
        toast.error(ERRORS.SOMETHING_WENT_WRONG);
      }
      setLoading(false);
      setError(err);
    }
    return undefined;
  }

  async function fetchReturnAxios(
    data: DataType,
    isMultiple = false
  ): Promise<AxiosResponse<ResponseType> | undefined> {
    if (
      (!Array.isArray(data) && !isMultiple) ||
      (Array.isArray(data) && isMultiple)
    ) {
      setLoading(true);
      const response = await api.post<ResponseType>(query, data);
      setResponseData(response.data);
      setLoading(false);
      return response;
    }
    if (!options?.willFail) {
      toast.error(ERRORS.SOMETHING_WENT_WRONG);
    }
    return undefined;
  }

  return [
    responseData,
    fetch,
    loading,
    error,
    { nodes: responseData, fetch, loading, errors: error },
    fetchReturnAxios
  ];
}
