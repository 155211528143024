/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Box, Grid } from '@mui/material';
import { DeleteAttachmentModal } from 'modules/Process/History/Fragments/UploadSection/List/DeleteModal';
import React, { useEffect } from 'react';
import { UseQueryReturn } from 'services';
import UIUploadAddSection from 'shared/components/Upload/UploadAddSection';
import UploadList from 'shared/components/Upload/UploadList';
import { useUploadAdd } from 'shared/hooks/UseUploadAdd';
import { useUploadList } from 'shared/hooks/UseUploadList';
import { AttachmentModel } from 'types/models/Attachments/Attachment.model';

type Props = {
  attachmentsQuery: UseQueryReturn<AttachmentModel[], AttachmentModel>;
  accessId?: number;
  table?: string;
  tableId: number;
  listTitle?: string;
  addTitle?: string;
  addLabel?: string;
  selectOptions: string[];
  onUpload?: () => void;
  spacing?: number;
};
export default function UploadAttachmentsSection({
  attachmentsQuery,
  accessId,
  table = 'process',
  tableId,
  listTitle = 'Seus Documentos Anexados',
  addTitle = 'Anexos Adicionais (opcional)',
  addLabel = 'Documento',
  onUpload,
  selectOptions,
  spacing = 4
}: Props) {
  useEffect(() => {
    attachmentsQuery[1]();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    downloadAttachment,
    findLoading,
    onClickDelete,
    deleteModal,
    setDeleteModal,
    onDelete
  } = useUploadList(attachmentsQuery[1]);

  const {
    buttonDisabled,
    uploadAttachment,
    handleFile,
    attachmentLoadingAdd,
    file,
    setCategory,
    category
  } = useUploadAdd(attachmentsQuery[1], {
    accessId,
    table,
    tableId
  });

  const handleUpload = async () => {
    await uploadAttachment();
    if (onUpload) {
      onUpload();
    }
  };

  return (
    <>
      <Grid container spacing={spacing} mt={1}>
        <Grid container item md={6} xs={12}>
          <Grid item xs={12}>
            <UploadList
              height="100%"
              title={listTitle}
              items={
                attachmentsQuery[0]?.map((attachment) => ({
                  name: attachment.name || '-',
                  category: attachment.category || '-',
                  size: attachment.size || '-',
                  onDownload: () => downloadAttachment(attachment.id),
                  loading: findLoading(attachment.id!),
                  onDelete: () => onClickDelete(attachment.id!)
                })) || []
              }
              loading={attachmentsQuery[3]}
            />
          </Grid>
        </Grid>
        <Box mt={4} />
        <Grid item md={6} xs={12}>
          <UIUploadAddSection
            title={addTitle}
            buttonDisabled={buttonDisabled}
            buttonOnClick={handleUpload}
            handleFile={handleFile}
            loading={attachmentLoadingAdd}
            selectedFilename={file?.name || ' '}
            selectLabel={addLabel}
            selectOnChange={({ target }) => setCategory(target.value)}
            selectOptions={selectOptions}
            selectValue={category}
          />
        </Grid>
      </Grid>
      <DeleteAttachmentModal
        open={deleteModal.open}
        setOpen={setDeleteModal}
        onDelete={onDelete}
      />
    </>
  );
}
