import React, { useEffect, useState } from 'react';
import { Avatar, Button, CircularProgress } from '@mui/material';
import { useFormData } from 'services/api/UseFormData';
import { UploadAttachment } from 'types/input/UploadAttachment';
import { AttachmentModel } from 'types/models/Attachments/Attachment.model';
import { useAuth } from 'Providers/Auth';

export interface ProfilePictureFormProps {
  defaultPicture?: string;
  accessId?: number;
}

export function ProfilePictureForm({
  defaultPicture,
  accessId
}: ProfilePictureFormProps) {
  const [profilePicture, setProfilePicture] = useState<string | undefined>(
    defaultPicture
  );

  useEffect(() => {
    setProfilePicture(defaultPicture);
  }, [defaultPicture]);

  const [attachmentAdd, attachmentLoadingAdd] = useFormData<
    UploadAttachment,
    AttachmentModel
  >(`/attachments`);
  const { setAuth, auth } = useAuth();

  const handleClickChangePicture = () => {
    document.getElementById('profilePicture')?.click();
  };

  const onChangeProfilePicture = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file && accessId && auth?.id) {
      const uploaded = await attachmentAdd({
        file,
        category: 'profile',
        tableId: accessId,
        table: 'access',
        accessId
      });
      if (uploaded?.key) {
        setProfilePicture(uploaded.key);
        if (accessId === auth.id) {
          setAuth({ ...auth, profilePicture: uploaded?.key });
          localStorage.setItem(
            'user',
            JSON.stringify({ ...auth, profilePicture: uploaded?.key })
          );
        }
      }
    }
  };

  return (
    <>
      {attachmentLoadingAdd ? (
        <CircularProgress />
      ) : (
        <Avatar
          sx={{ width: 150, height: 150 }}
          src={profilePicture}
          onClick={handleClickChangePicture}
        />
      )}

      <input
        id="profilePicture"
        name={'profilePicture'}
        type={'file'}
        accept={'image/*'}
        onChange={onChangeProfilePicture}
        hidden
      />
      <Button
        variant="outlined"
        component="span"
        sx={{ mt: 3 }}
        onClick={handleClickChangePicture}
      >
        Alterar imagem
      </Button>
    </>
  );
}
