/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Grid } from '@mui/material';
import React from 'react';
import { FetchQuery } from 'services';
import { AttachmentModel } from 'types/models/Attachments/Attachment.model';
import { UploadAddSection } from './Add';
import { UploadListSection } from './List';

export type UploadSectionProps = {
  attachments: AttachmentModel[];
  attachmentsLoading: boolean;
  attachmentsRefetch: FetchQuery<AttachmentModel[], AttachmentModel>;
  processId: number;
  accessId?: number;
};

export function UploadSection({
  attachments,
  attachmentsLoading,
  attachmentsRefetch,
  processId,
  accessId
}: UploadSectionProps) {
  return (
    <Grid container item xs={12} spacing={2} direction="row">
      <UploadListSection
        attachments={attachments || []}
        attachmentsLoading={attachmentsLoading}
        attachmentsRefetch={attachmentsRefetch}
      />
      <UploadAddSection
        attachmentsRefetch={attachmentsRefetch}
        processId={processId}
        accessId={accessId}
      />
    </Grid>
  );
}
