import { Container, Typography } from '@mui/material';
import { ListClients } from 'modules/Admin/Clients/Fragments/ListClients';
import React from 'react';
import { UIBreadcrumb, UILink } from 'shared/components';

export function Clients() {
  return (
    <Container maxWidth={'lg'}>
      <UIBreadcrumb>
        <UILink to={'/dashboard'}> Dashboard </UILink>
        <Typography color={'primary'}> Clientes </Typography>
      </UIBreadcrumb>
      <ListClients />
    </Container>
  );
}
