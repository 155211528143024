import { useAuth } from 'Providers/Auth';
import React from 'react';
import { UseQueryReturn } from 'services';
import UploadAttachmentsSection from 'shared/components/Upload/UploadSection';
import { AttachmentModel } from 'types/models/Attachments/Attachment.model';

interface PrivateProcessAttachmentsProps {
  processId: string;
  attachmentsQuery: UseQueryReturn<AttachmentModel[], AttachmentModel>;
}

export function InformResultUpload({
  processId,
  attachmentsQuery
}: PrivateProcessAttachmentsProps) {
  const { auth } = useAuth();

  return (
    <>
      <UploadAttachmentsSection
        attachmentsQuery={attachmentsQuery}
        selectOptions={['Ata', 'Outros Anexos']}
        accessId={auth?.id}
        tableId={+processId}
        table="process"
        listTitle="Documentos anexados deste processo"
        addTitle="Anexo da Ata"
      />
    </>
  );
}
