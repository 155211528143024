import { Typography } from '@mui/material';
import { DataGridProps } from '@mui/x-data-grid';
import React from 'react';
import { UITable } from 'shared/components';
import { UIToolbar } from 'shared/components/Tables/ToolbarTable';
import { ThemeColors } from 'shared/enum';
import { PendenciesModel } from 'types/models/Pendencies/Pendencies.model';
import history from 'routes/History';
import { pendenciesColumns } from './Constants/columns';

interface ProcessTableProps extends Omit<DataGridProps, 'rows' | 'columns'> {
  pendencies: PendenciesModel[];
  loading: boolean;
  height?: string;
  totalPendencies?: number;
}

export function PendenciesTable({
  pendencies,
  loading,

  totalPendencies = 0,
  ...rest
}: ProcessTableProps) {
  return (
    <UITable
      headerColor={`${ThemeColors.TextGrey}1a`}
      headerFontColor={ThemeColors.TextGrey}
      headerFontSize={'14px'}
      rows={pendencies}
      columns={pendenciesColumns}
      rowCount={pendencies?.length}
      rowHeight={36}
      headerHeight={46}
      onRowClick={({ row }) =>
        history.push(`/processo/${(row as PendenciesModel)?.process?.id}`)
      }
      autoPageSize={false}
      pageSize={100}
      components={{
        Toolbar: () => (
          <UIToolbar
            height="46px"
            title={
              <Typography style={{ fontSize: '14px' }} color="textPrimary">
                Pendências
              </Typography>
            }
            elementsTopEnd={
              <Typography style={{ fontSize: '12px' }}>
                {totalPendencies}{' '}
                {totalPendencies > 1
                  ? 'Pendências encontradas'
                  : 'Pendência encontrada'}
              </Typography>
            }
          />
        )
      }}
      {...rest}
    />
  );
}
