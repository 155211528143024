import React from 'react';
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { FormProps, UITextField } from 'shared/components/Inputs';
import { MoneyMask } from 'utils/masks';
import { useFormik } from 'formik';
import { ProcessModel } from 'types/models';
import { OrderModel } from 'types/models/Order/Order.model';
import { EmailToOrderInput } from 'types/input/EmailToOrder';
import { EmailToOrderSchema } from 'modules/Process/shared/forms/emailToOrder/domain';
import { DatePicker } from '@mui/lab';
import { theme } from 'shared/theme';

export interface EmailToOrderFormProps extends FormProps<EmailToOrderInput> {
  process: ProcessModel;
  order: OrderModel;
}

export function EmailToOrderForm({
  process,
  order,
  onComplete
}: EmailToOrderFormProps) {
  const { values, handleChange, handleSubmit, setFieldValue, errors, touched } =
    useFormik({
      initialValues: {
        organization: 'BRN4-06',
        language: 'PT',
        currency: 'BRL',
        orderType: '',
        date: new Date(),
        receiver: '',
        process: process || {
          id: 'id'
        },
        distributor: process?.distributor || {
          id: '',
          name: '',
          sold: ''
        },
        employee: process?.employee || {
          id: '',
          name: '',
          sap: ''
        },
        gtm: false,
        orderId: order.id || '',
        billingPlant: '',
        total: order?.total || '',
        comment: ''
      },
      validationSchema: EmailToOrderSchema,
      onSubmit: (fields) => {
        const e2o = {
          ...fields,
          employeeId: fields.employee.id,
          distributorId: fields.distributor.id,
          processId: fields.process.id
        };

        if (e2o.process) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          delete e2o.process;
        }

        if (e2o.employee) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          delete e2o.employee;
        }

        if (e2o.distributor) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          delete e2o.distributor;
        }

        if (e2o.total) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          delete e2o.total;
        }

        if (e2o.employeeId && e2o.distributorId && e2o.processId) {
          onComplete(e2o as EmailToOrderInput);
        }
      }
    });

  return (
    <form id={'email-to-order'} onSubmit={handleSubmit}>
      <Grid container>
        <Grid item container spacing={2}>
          <Grid item xs={6}>
            <UITextField
              label={'Organização de vendas'}
              name={'organization'}
              value={values.organization}
              onChange={handleChange}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <UITextField
              label="Tipo de Ordem"
              name={'orderType'}
              onChange={handleChange}
              value={values.orderType}
              error={!!touched.orderType && !!errors.orderType}
              helperText={touched.orderType && errors.orderType}
              select
              fullWidth
            >
              <MenuItem value={'ZRR - Return Request'}>
                ZRR - Return Request
              </MenuItem>
              <MenuItem value={'OR- Standard Order'}>
                OR- Standard Order
              </MenuItem>
            </UITextField>
          </Grid>
          <Grid item xs={6}>
            <UITextField
              label="Idioma"
              name={'language'}
              value={values.language}
              onChange={handleChange}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <UITextField
              label="Consultor"
              name={'employee.name'}
              value={values.employee.name}
              onChange={handleChange}
              disabled
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Divider orientation={'horizontal'} />
          <Grid item xs={12}>
            <Box mt={2} pt={2}>
              <Typography
                variant="h3"
                style={{
                  color: theme.colors.TextDarkGrey,
                  fontWeight: theme.font.semiBold
                }}
              >
                Dados gerais do cabeçalho
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <UITextField
              label={'Emissor da ordem'}
              name={'distributor.sold'}
              value={values.distributor.sold}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <UITextField
              label={'Recebedor da ordem'}
              name={'receiver'}
              value={values.receiver}
              onChange={handleChange}
              error={!!touched.receiver && !!errors.receiver}
              helperText={touched.receiver && errors.receiver}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <UITextField
              label={'No. Ped. Cliente'}
              name={'pne'}
              value={values.process.pne}
              onChange={handleChange}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              value={values.date}
              label={'Data de remessa'}
              renderInput={(props) => <TextField name={'date'} {...props} />}
              onChange={(value) => setFieldValue('date', value)}
            />
          </Grid>
          <Grid item xs={6}>
            <UITextField
              label={'Moeda'}
              name={'currency'}
              value={values.currency}
              onChange={handleChange}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <UITextField
              label={'Planta faturadora'}
              name={'billingPlant'}
              value={values.billingPlant}
              onChange={handleChange}
              error={!!touched.receiver && !!errors.receiver}
              helperText={touched.receiver && errors.receiver}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <UITextField
              label={'ID Parceiro 1'}
              name={'distributor.name'}
              value={values.distributor.name}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <UITextField
              label={'Parceiro 1'}
              name={'employee.sap'}
              value={values.employee.sap}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <UITextField
              label={'R$ Valor total da nota'}
              name={'employee.sap'}
              value={MoneyMask(values.total)}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl>
              <FormControlLabel
                control={
                  <Switch
                    color={'primary'}
                    name={'gtm'}
                    value={values.gtm}
                    onChange={handleChange}
                  />
                }
                label={'GTM'}
                labelPlacement={'end'}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <UITextField
              label={'Texto 1'}
              name={'comment'}
              value={values.comment}
              placeholder={'Insira o texto...'}
              onChange={handleChange}
              multiline
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}
