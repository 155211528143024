import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ borderColor?: string }>`
  border-left: 5px solid;
  border-color: ${({ borderColor }) => borderColor || '#5082c9'};
  border-radius: 4px;
  background: #fff;
  padding: 0.5rem 0.8rem;
  height: 5.5rem;
`;

export const Title = styled.span`
  display: block;
  min-height: 2rem;
  color: ${({ theme }) => theme.colors.TextDarkGrey};
`;

export const Percentage = styled.span<{ color: string }>`
  ${({ theme, color }) => css`
    display: flex;
    align-items: center;
    color: ${color || '#000'};
    font-size: ${theme.font.sizes.xxsmall};
    svg {
      margin-left: 1rem;
      width: 1rem;
    }
  `}
`;

export const Number = styled.strong`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    font-size: ${theme.font.sizes.xxsmall};
    svg {
      margin-left: 1rem;
      width: 12px;
    }
  `}
`;

export const Data = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
