/* eslint-disable @typescript-eslint/no-use-before-define */
import { Box, Grid, Typography } from '@mui/material';
import { TotalItem } from 'modules/Process/Domain/ProcessItem/TotalItem';
import React from 'react';
import { theme } from 'shared/theme';
import { ProcessProductModel, ProductsModel } from 'types/models';
import { FormatPercent } from 'utils/Functions/FormatPercent';
import { MoneyMask } from 'utils/masks';
import { ProductDescribeFragment } from './ProductDescribe';

type Props = {
  processProduct: ProcessProductModel;
  product?: ProductsModel;
  showReferentialPrice?: boolean;
};
export default function InformDiscountProductInformation({
  processProduct,
  product,
  showReferentialPrice = false
}: Props) {
  return (
    <Box pl={2} pr={2} pb={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h3"
            style={{
              color: theme.colors.TextDarkGrey,
              fontWeight: theme.font.semiBold,
              fontSize: '20px'
            }}
          >
            {processProduct?.product?.description || '-'}
          </Typography>
        </Grid>
        <ProductDescribeFragment
          size={2}
          label="Item: "
          value={processProduct?.itemNumber || '-'}
        />
        <ProductDescribeFragment
          size={3}
          label="SKU: "
          value={processProduct?.product?.sku || '-'}
        />
        <ProductDescribeFragment
          size={3}
          label="Quant.: "
          value={processProduct?.maxQuantity || '-'}
        />
        <ProductDescribeFragment
          size={4}
          label="Preço lista: "
          value={
            processProduct.priceList
              ? MoneyMask(processProduct.priceList?.toFixed(2))
              : '-'
          }
        />
        <ProductDescribeFragment
          size={5}
          label="Preço Proposto: "
          value={
            processProduct.nfPrice
              ? MoneyMask(processProduct.nfPrice?.toFixed(2))
              : '-'
          }
        />
        <ProductDescribeFragment
          size={3}
          label="Markup: "
          value={
            processProduct.markup ? FormatPercent(processProduct.markup) : '-'
          }
        />
        <ProductDescribeFragment
          size={4}
          label="Preço Instituição: "
          value={
            processProduct.institutionValue
              ? MoneyMask(processProduct.institutionValue?.toFixed(2))
              : '-'
          }
        />
        <ProductDescribeFragment
          size={5}
          label="Desconto Pré-aprovado: "
          value={
            product?.political_prices?.[0]?.preDiscount
              ? FormatPercent(product?.political_prices?.[0]?.preDiscount * 100)
              : '-'
          }
        />
        <ProductDescribeFragment
          size={5}
          label="Desconto Proposto: "
          value={
            FormatPercent(
              processProduct?.discount_orders?.[0]?.discountValue
            ) || '-'
          }
        />
        {showReferentialPrice &&
        product?.political_prices?.[0]?.referentialPrice ? (
          <ProductDescribeFragment
            size={5}
            label="Preço Referencial: "
            value={MoneyMask(product?.political_prices?.[0]?.referentialPrice)}
          />
        ) : null}
        <ProductDescribeFragment
          size={6}
          label="Total GPR: "
          value={
            processProduct
              ? MoneyMask(
                  TotalItem({
                    nfPrice: processProduct.nfPrice,
                    initialQuantity: processProduct.initialQuantity,
                    priceList: processProduct.priceList
                  }).totalItemGPR
                )
              : '-'
          }
        />
      </Grid>
    </Box>
  );
}
